import React from 'react';
import ContainerComponent from '../../helpers/components/container.component';
import PageHeaderComponent from '../../helpers/components/page_header.component';
import CreateStockTransfer from './components/create_stock_transfer';

const CreateNewStockTransfer = () => {
    return (
        <>
            <ContainerComponent span={22} top={20}>
                <PageHeaderComponent />
                <CreateStockTransfer />
            </ContainerComponent>
        </>
    );
}

export default CreateNewStockTransfer;