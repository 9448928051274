import { Image } from 'antd';
import React from 'react';

const EmptyComponent = () => {
    return (
        <>
            <div style={{padding:24}}>
                <Image src='/banners/empty.png' preview={false} style={{width:'100%', width : 120, height: 120, objectFit:'contain'}}/>
            </div>
        </>
    );
}

export default EmptyComponent;