import { SearchOutlined, ExclamationCircleOutlined, EllipsisOutlined } from '@ant-design/icons';
import { Button, Input, Row, Col, Spin, Table, Drawer, Form, Space, notification, Modal, Dropdown, List, Alert } from 'antd';
import React, { useEffect, useState } from 'react';
import InputWrapper from '../../../helpers/components/input_wrapper.component';
import itemCategoryService from '../../../services/config/item_category.service';
import itemService from '../../../services/config/item.service';

const { confirm } = Modal;

const ItemCategoryListTable = () => {
    const [form] = Form.useForm();
    const [state, setState] = useState({
        item_category: [],
        loading: false,
        isDisabled: true,
        isUpdate: null,
        item_category_id: null,
        update_loading: false,
    });
    const [filter, setFilter] = useState({
        search: null,
    });

    const [timer, setTimer] = useState(null);
    const [open, setOpen] = useState(false);


    const fetchItemCategoryList = async () => {
        setState(state => ({ ...state, loading: true, }));

        const itemCategoryResponse = await itemCategoryService.getItemCategory({
            search: filter?.search,
        });

        setTimeout(() => {
            setState(state => ({
                ...state,
                item_category: itemCategoryResponse?.item_category,
                loading: false,
            }))
        }, 800);

    }

    const addUpdateItemCategory = async () => {
        setState(state => ({ ...state, update_loading: true }));
        const { name, description } = form.getFieldsValue();

        //call api to insert into database
        if (state.isUpdate === true) {
            const updateItemCategoryResponse = await itemCategoryService.updateItemCategory(state?.item_category_id, {
                name: name,
                description: description,
            });
        } else {
            const storeItemCategoryResponse = await itemCategoryService.storeItemCategory({
                name: name,
                description: description,
            });
        }


        //after success insert/update
        setTimeout(() => {
            notification.success({
                message: (<span style={{ fontSize: 14, fontWeight: 500, }}>{state?.isUpdate ? 'Item Group Updated' : 'New Item Group Added'}</span>),
                duration: 3,
                placement: 'topRight'
            });
            clearAll();
            refresh();
        }, 800);
    }

    const doneTyping = async (input) => {
        clearTimeout(timer);
        const newTimer = setTimeout(() => {
            setFilter(filter => ({ ...filter, search: input }));
        }, 300);
        setTimer(newTimer);
    }
    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        clearAll()
        setOpen(false);
    };
    const openDrawer = (item_category, isUpdate) => {
        setState(state => ({
            ...state,
            isDisabled: isUpdate,
            isUpdate: isUpdate,
            item_category_id: item_category?.item_category_id
        }));
        if (isUpdate === true) {
            form.setFieldsValue({
                name: item_category.name,
                description: item_category.description,
            });
        }

        showDrawer();
    }

    const onEdit = () => {
        setState(state => ({
            ...state,
            isDisabled: false,
        }));
    };
    const offEdit = () => {
        if (state.isUpdate !== true) {
            onClose();
        }
        setState(state => ({
            ...state,
            isDisabled: true,
        }));
    };

    const onDelete = async (data) => {
        const items = await checkLinkage(data?.item_category_id);
        confirm({
            icon: <ExclamationCircleOutlined />,
            content: (
                <>
                    <Space direction='vertical'>
                        <div>
                            <span>Are your sure to delete <span style={{ fontWeight: 500 }}>{data.name}</span>?</span>
                        </div>
                        <div>
                            {
                                items?.count > 0 &&
                                (
                                    <>
                                        <div>
                                            <Alert
                                                icon={true}
                                                type='warning'
                                                message={<span>{items?.count} items is found in this category, by deleting this item group, items will be unbind with it.</span>}
                                                style={{ borderRadius: 6 }}
                                            />
                                        </div>
                                        <div style={{ marginTop: 12 }}>
                                            <List
                                                size="small"
                                                bordered
                                                dataSource={items?.rows}
                                                style={{ borderRadius: 6 }}
                                                renderItem={(item, index) => {
                                                    return (
                                                        <List.Item key={item?.item_id}>
                                                            <div>
                                                                <div>
                                                                    <span>{item?.name}</span>
                                                                </div>
                                                                <div>
                                                                    <span style={{ fontSize: 12, }}>{item?.item_code}</span>
                                                                </div>
                                                            </div>

                                                        </List.Item>
                                                    )
                                                }}
                                            />
                                        </div>
                                    </>
                                )
                            }
                        </div>
                    </Space>
                </>
            ),
            async onOk() {
                const deleteItemCategoryResponse = await itemCategoryService.deleteItemCategory(data.item_category_id);
                notification.success({
                    message: (<span style={{ fontSize: 14, fontWeight: 500, }}>Item Group Deleted</span>),
                    duration: 3,
                    placement: 'topRight'
                });
                clearAll();
                refresh();
            },
            onCancel() {
            },
        });

    };

    const checkLinkage = async (item_category_id) => {
        const itemResponse = await itemService.getItem({
            item_category_id: item_category_id
        });

        return itemResponse?.items;
    }

    const refresh = () => {
        onClose();
        fetchItemCategoryList();
    };

    const clearAll = () => {
        setState(state => ({
            ...state,
            isDisabled: true,
            isUpdate: null,
            item_category_id: null,
            update_loading: false,
        }));
        form.resetFields();
    }

    useEffect(() => {
        fetchItemCategoryList();
    }, [filter?.search])

    return (
        <>
            <div className='filter-panel' style={{ height: 60, }}>
                <div style={{ display: 'flex' }}>
                    <div style={{ minWidth: 400 }}>
                        <InputWrapper>
                            <Input
                                onChange={(e) => doneTyping(e.target.value)}
                                bordered={false}
                                prefix={<div style={{ paddingRight: 12, paddingLeft: 8 }}><SearchOutlined style={{ color: '#8c8c8c' }} /></div>}
                                placeholder={`Search Item Group`}
                                style={{ fontSize: 14 }}
                                size='large'
                            />
                        </InputWrapper>
                    </div>
                    <div style={{ marginLeft: 'auto' }}>
                        <Button
                            type="primary"
                            // icon={<PlusOutlined />}
                            onClick={() => openDrawer(null, false)}
                            style={{ borderRadius: 6 }}
                        >
                            Add Item Group
                        </Button>
                    </div>

                </div>
            </div>

            <Spin spinning={state?.loading}>
                <Table
                    className='hub-table'
                    pagination={false}
                    dataSource={state?.item_category}
                    columns={[{
                        title: 'Item Group',
                        dataIndex: 'name',
                        key: 'name',
                        // render: (text) => <span>{text}</span>,
                    }, {
                        title: 'Description',
                        dataIndex: 'description',
                        key: 'description',
                    }, {
                        title: 'Action',
                        key: 'action',
                        width: '10%',
                        render: (text, row, index) => {
                            return (
                                <>
                                    <div>
                                        <Dropdown
                                            placement='bottomRight'
                                            overlayStyle={{ borderRadius: 12, minWidth: 200 }}
                                            menu={{
                                                items: [{
                                                    key: '1',
                                                    type: 'group',
                                                    label: "Action",
                                                    children: [{
                                                        key: '1-1',
                                                        label: "Edit",
                                                        onClick: async () => {
                                                            openDrawer(row, true);
                                                        }
                                                    }, {
                                                        key: '1-2',
                                                        label: "Delete",
                                                        onClick: async () => {
                                                            await onDelete(row);
                                                        }
                                                    },]
                                                },]
                                            }}
                                        >
                                            <Button size='small' icon={<EllipsisOutlined />} style={{ borderRadius: 6 }} />
                                        </Dropdown>
                                    </div>
                                </>
                            )
                        }
                    }]}
                />
            </Spin>

            <Drawer title={state?.isUpdate ? 'Item Group Detail' : 'Add Item Group'} onOk={() => form.submit()}
                placement="right"
                width={window.innerWidth > 900 ? 800 : window.innerWidth - 100}
                onClose={onClose}
                open={open}
                extra={
                    <Space>
                        {
                            state?.isUpdate ?
                                (
                                    <>
                                        <Button loading={state?.update_loading} type="primary" onClick={() => form.submit()} style={{ borderRadius: 6 }}>
                                            Update
                                        </Button>
                                    </>
                                )
                                :
                                (
                                    <>
                                        <Space>
                                            <Button onClick={offEdit} style={{ borderRadius: 6 }}>Cancel</Button>
                                            <Button loading={state?.update_loading} type="primary" onClick={() => form.submit()} style={{ borderRadius: 6 }}>
                                                OK
                                            </Button>
                                        </Space>
                                    </>
                                )
                        }
                    </Space>
                }>

                <Form layout="vertical"
                    onFinish={addUpdateItemCategory}
                    form={form} >

                    <Form.Item
                        name="name"
                        label="Item Group Name"
                        rules={[{ required: true, message: 'Please enter item group name' }]}
                    >
                        <Input placeholder="Please enter item group name" style={{ borderRadius: 6 }} />
                    </Form.Item>

                    <Form.Item
                        name="description"
                        label="Description"
                        rules={[
                            {
                                required: true,
                                message: 'please enter description',
                            },
                        ]}
                    >
                        <Input.TextArea rows={4} placeholder="please enter description" style={{ borderRadius: 6 }} />
                    </Form.Item>
                </Form>
            </Drawer>
        </>
    );
}

export default ItemCategoryListTable;