import { Card, Col, Image, PageHeader, Row, Space, Spin } from 'antd';
import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import accessService from '../../services/administrator/access.service';
import { RightOutlined } from '@ant-design/icons';

const SubMenuComponent = () => {
    const location = useLocation();
    const branch_id = localStorage.getItem('branch');
    const [state, setState] = useState({
        loading : false,
        menu : null
    });

    const fetchMenu = async () => {
        setState(state => ({...state, loading : true, }));
        const menuResponse = await accessService.getMenu({
            link : location?.pathname,
            display : 1,
        });


        if(menuResponse?.menus?.length > 0){
            const menu = menuResponse?.menus[0];
            setState(state => ({...state, menu : menu, loading : false,}));
        }

    }

    useEffect(() => {
        fetchMenu();
    }, [])
    return (
        <>
            <div>
                <PageHeader
                title={state?.menu?.name}
                style={{paddingLeft:0}}
                />
            </div>
            {
                branch_id ?
                (
                    <>
                        <div style={{minHeight:200}}>
                            <Spin spinning={state?.loading}>
                                <Row gutter={[20, 20]}>
                                {
                                    state?.menu?.sub_menus?.map((sub_menu, index) => {
                                        return (
                                            <Col key={index} xs={24} sm={12} md={12} xl={8} span={8} style={{height:'auto'}}>
                                                <Link to={sub_menu?.link}>
                                                    <div style={{height:'100%'}} className="sub-menu-select">
                                                        <Card
                                                        className='sub-menu-card'
                                                        bordered={false}
                                                        style={{borderRadius:8, height:'inherit'}}
                                                        >
                                                            <div style={{textAlign:'left'}}>
                                                                <div style={{display:'flex'}}>
                                                                    <Space size={20} align="start" style={{width:'100%'}}>
                                                                        <div>
                                                                            <Image src={sub_menu?.icon} preview={false} style={{width:40, height:40}} />
                                                                        </div>

                                                                        <div>
                                                                            <div>
                                                                                <span style={{fontSize:16, fontWeight:500, color:'#595959'}}>{sub_menu?.name}</span>
                                                                            </div>
                                                                            <div>
                                                                                <span style={{color:'#7e7e7e'}}>{sub_menu?.description}</span>
                                                                            </div>
                                                                        </div>
                                                                    </Space>
                                                                    <div style={{marginLeft:'auto'}}>
                                                                        <RightOutlined className='right-arrow' />
                                                                    </div>
                                                                </div>
                                                                
                                                            </div>
                                                        </Card>
                                                    </div>
                                                </Link>
                                            </Col>
                                        )
                                    })
                                }
                                </Row>
                            </Spin>
                        </div>
                    </>
                )
                :
                (
                    <>
                        <div>
                            <span style={{color:'#8c8c8c'}}>Please Select Branch</span>
                        </div>
                    </>
                )
            }
            
        
        </>
    );
}

export default SubMenuComponent;