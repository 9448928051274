import { Button, Divider, Dropdown, Form, Modal, PageHeader, Space, Spin, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import billingItemService from '../../../services/billing/billing_item.service';
import { EllipsisOutlined } from '@ant-design/icons';
import BillingItemAdd from './billing_items_add';
import { useDispatch, useSelector } from 'react-redux';
import { set_update_billing } from '../../../actions/billing.action';
import billingService from '../../../services/billing/billing.service';


const { confirm } = Modal;
const BillingItem = ({ billing_id, edit, location_id }) => {
    // const billing_redux = useSelector(state => state.billing);
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [state, setState] = useState({
        loading: false,
        billing_items: [],
        edit_modal: false,
        refresh: 0,
        edit_modal_id: null,
        billing: null,
        item: []
    })
    const [timer, setTimer] = useState(null)

    const fetchBillingItems = async () => {
        setState(state => ({ ...state, loading: true }))
        const billingResponse = await billingService.getBillingById(billing_id);
        const billingItemsResponse = await billingItemService?.getBillingItems({
            billing_id: billing_id,
        });
        const billing_items = billingItemsResponse?.billing_items;
        // const totalCost = billing_items.reduce((acc, billing_item) => {

        //     const itemCost = (billing_item.quantity * ((billing_item.price * 1) * (1 + (billing_item.markup / 100))))
        //     return acc + (itemCost * 1);
        // }, 0);
        // console.log(totalCost.toFixed(3))
        setState(state => ({ ...state, billing_items: billing_items, loading: false, billing: billingResponse?.billings }))
    }

    const addBillingItem = async () => {
        form.validateFields()
            .then(async (values) => {
                const { itemId, quantity, cost, markup } = form.getFieldsValue();
                let data = {
                    billing_id: billing_id,
                    item_id: itemId,
                    price: cost,
                    quantity: quantity,
                    markup: markup
                }
                let billingItemsResponse;
                if (state?.edit_modal_id) {
                    //update
                    billingItemsResponse = await billingItemService.updateBillingItems(state?.edit_modal_id, data)
                } else {
                    //store a new billing items
                    billingItemsResponse = await billingItemService.createBillingItems(data);
                }
                dispatch(set_update_billing(true));

                setState(state => ({ ...state, refresh: (state?.refresh + 1), edit_modal: false, }));
            })
            .catch(() => {
            });

    }

    const openEditModal = ({ billing_item_id = null, item_id, quantity, price, markup }) => {
        setTimeout(function () {
            if (billing_item_id) {
                form.setFieldsValue({ itemId: item_id, quantity: quantity, cost: (price * 1).toFixed(2), price: ((price * 1) * (1 + (markup / 100))).toFixed(2), markup: markup });

            }
        }, 0);
        setState(state => ({ ...state, edit_modal: true, edit_modal_id: billing_item_id }));
        // dispatch(
        //     set_selected_items({
        //         item_id: item_id,
        //         price: price.toFixed(2),
        //         quantity: quantity,
        //         markup: markup,
        //     })
        // )

        // }, 500);
        // setTimer(newTimer);

    }

    const deleteConfirmationModal = async (billing_item_id) => {
        confirm({
            title: 'Do you want to delete this billing item?',
            onOk() {
                return new Promise((resolve, reject) => {
                    setTimeout(async () => {
                        await deleteBillingItem(billing_item_id);
                        resolve();
                    }, 500);
                }).catch((err) => {

                })
            }
        })
    }

    const deleteBillingItem = async (billing_item_id) => {
        const billingItemsResponse = await billingItemService.deleteBillingItems(billing_item_id, { billing_id: billing_id, });
        dispatch(set_update_billing(true));
        setState(state => ({ ...state, refresh: (state?.refresh + 1) }));
    }

    const cancelAddItem = () => {
        form.resetFields();
        setState(state => ({ ...state, edit_modal: false, }));
    }

    useEffect(() => {
        fetchBillingItems();
    }, [state?.refresh, billing_id])


    return (
        <>
            <div style={{ textAlign: 'left', padding: '12px 0' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div>
                        <span style={{ fontWeight: 500, fontSize: 16 }}>Billing Items</span>
                    </div>
                    {
                        edit &&
                        (
                            <>
                                <div style={{ marginLeft: 'auto' }}>
                                    <span onClick={() => openEditModal({})} className='mini-button mini-style-text'>
                                        Add Item
                                    </span>
                                </div>
                            </>
                        )
                    }


                </div>
            </div>
            <Divider style={{ marginTop: 12 }} />
            <Spin spinning={state?.loading}>
                <Table
                    pagination={false}
                    dataSource={state?.billing_items}
                    className='hub-table'
                    columns={[{
                        title: 'Item Code',
                        dataIndex: 'item',
                        key: 'item_code',
                        render: (text, row, index) => {
                            return (
                                <>
                                    <div>
                                        <span>{text?.item_code}</span>
                                    </div>
                                </>
                            )
                        }
                    }, {
                        title: 'Item Name',
                        dataIndex: 'item',
                        key: 'name',
                        render: (text, row, index) => {
                            return (
                                <>
                                    <div>
                                        <span>{text?.name}</span>
                                    </div>
                                </>
                            )
                        }
                    }, {
                        title: 'Quantity',
                        dataIndex: 'quantity',
                        key: 'quantity',
                    }, {
                        title: ' Price (RM)',
                        dataIndex: 'price',
                        key: 'price',
                        render: (text, row, index) => {
                            return (
                                <>
                                    <div>
                                        <span>{(row?.price * (1 + (row?.markup / 100))).toFixed(3)}</span>
                                    </div>
                                </>
                            )
                        }
                    }, {
                        title: 'Total Price (RM)',
                        dataIndex: 'index',
                        key: 'index',
                        render: (text, row, index) => {
                            return (
                                <>
                                    <div>
                                        <span>{(row?.quantity * (row?.price * (1 + (row?.markup / 100)))).toFixed(3)}</span>
                                    </div>
                                </>
                            )
                        }
                    }, {
                        ...edit && ({
                            title: 'Action',
                            key: 'action',
                            dataIndex: 'billing_item_id',
                            width: '10%',
                            render: (text, row, index) => {
                                return (
                                    <>
                                        <div>
                                            <Dropdown
                                                placement='bottomRight'
                                                overlayStyle={{ borderRadius: 12, minWidth: 200 }}
                                                menu={{
                                                    items: [{
                                                        key: '1',
                                                        type: 'group',
                                                        label: "Action",
                                                        children: [{
                                                            key: '1-1',
                                                            label: "Edit",
                                                            onClick: async () => {
                                                                openEditModal({ billing_item_id: text, item_id: row?.item_id, quantity: row?.quantity, price: row?.price, markup: row?.markup })
                                                            }
                                                        }, {
                                                            key: '1-2',
                                                            label: "Delete",
                                                            onClick: async () => {
                                                                deleteConfirmationModal(text);
                                                            }
                                                        },]
                                                    },]
                                                }}
                                            >
                                                <Button size='small' icon={<EllipsisOutlined />} style={{ borderRadius: 6 }} />
                                            </Dropdown>
                                        </div>
                                    </>
                                )
                            }
                        })
                    },
                    ]}
                />
            </Spin>


            <Modal
                // forceRender
                open={state?.edit_modal}
                transitionName="ant-fade"
                maskTransitionName="ant-fade"
                title={<><span>{state?.edit_modal_id ? 'Update Billing Item' : 'Add Billing Item'}</span></>}
                width={500}
                closable={false}
                className="custom-modal"
                onOk={() => form.submit()}
                onCancel={cancelAddItem}
            >
                <Form
                    id='billing_item_form'
                    onFinish={addBillingItem}
                    form={form}
                    layout='vertical'
                >
                    <BillingItemAdd form={form} location_id={location_id} edit_modal={state?.edit_modal_id} billing={state?.billing} />
                </Form>
            </Modal>
        </>
    );
}

export default BillingItem;