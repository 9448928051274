import React from 'react';

const InputWrapper = ({children}) => {
    return (
        <>
            <div className='input_wrapper'>
                {children}
            </div>
        </>
    );
}

export default InputWrapper;