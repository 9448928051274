import { ConsoleSqlOutlined } from '@ant-design/icons';
import { render } from '@testing-library/react';
import { Col, Divider, Form, Input, Row, Select, Space, InputNumber } from 'antd';
import React, { useEffect, useState } from 'react';
import orderItemService from '../../../services/purchase_order/purchase_order_item.service';

const { TextArea } = Input;
const { Option } = Select;

const CreditNoteAdd = ({ form, order_id, edit_modal_id }) => {
    const [timer, setTimer] = useState(null)
    const [state, setState] = useState({
        loading: true,
        // return items
        order_items: [],
        selected_order_items: null,
        returning_amount: 0,
        max_quantity: null,

    });

    const fetchOrderItems = async () => {
        const orderItemsResponse = await orderItemService?.getOrderItems({
            search: state?.input,
            order_id: order_id,
        });

        const order_items = orderItemsResponse?.order_items?.map(x => {
            return {
                value: x?.item?.item_id,
                label: x?.item?.name,
                item_code: x?.item?.item_code,
                unit_price: x?.unit_price,
                max_quantity: x?.quantity + x?.free
            }
        });
        setState(state => ({ ...state, order_items: order_items, }));
    }

    const doneTyping = (input) => {
        clearTimeout(timer);
        const newTimer = setTimeout(() => {
            // fetchItemOnSearch(input);
            setState(state => ({ ...state, input: input }));
        }, 500);
        setTimer(newTimer);
    }

    const onDropdownSelected = (item_id) => {
        //get latest item added
        const items = state?.order_items?.find(x => x.value === item_id);
        clearTimeout(timer);
        const newTimer = setTimeout(() => {
            // fetchItemOnSearch(input);
            setState(state => ({ ...state, max_quantity: items?.max_quantity }));
        }, 100);
        setTimer(newTimer);
        form.setFieldsValue({ quantity: null, unit_price: items?.unit_price })
    }


    useEffect(() => {
        fetchOrderItems();

    }, [state?.input])


    return (
        <>
            <Form.Item name='itemId' label="Item" rules={[{ required: true, message: 'Please select an item' }]} shouldUpdate>
                <Select
                    // disabled={edit_modal ? true : false}
                    filterOption={false}
                    showSearch
                    className='border-input'
                    style={{ borderRadius: 6 }}
                    onSearch={doneTyping}
                    onChange={onDropdownSelected}
                    disabled={edit_modal_id != null}
                >
                    {
                        state?.order_items?.map((item, index) => {
                            return (
                                <Option value={item?.value} key={index}>
                                    <div>
                                        <div><span>{item?.label}</span></div>
                                        <div><span style={{ fontSize: 12, color: '#8c8c8c' }}>{item?.item_code}</span></div>
                                    </div>
                                </Option>
                            )
                        })
                    }
                </Select>
            </Form.Item>

            <Form.Item name='quantity' label="Quantity" rules={[{ required: true, message: 'Please enter a quantity' }]}>
                <InputNumber placeholder='1' min={1} max={state?.max_quantity} style={{ width: '100%', borderRadius: 6 }} disabled={edit_modal_id != null} />
            </Form.Item>
            <Form.Item name="remarks" label="Remarks">
                <TextArea placeholder='Write your remarks here' rows={4} className='border-input' allowClear style={{ resize: 'none' }} />
            </Form.Item>
            <Form.Item name="unit_price" hidden>
                <TextArea placeholder='Write your remarks here' rows={4} className='border-input' allowClear style={{ resize: 'none' }} />
            </Form.Item>

        </>
    );
}

export default CreditNoteAdd;