import { Button, Card, Col, Form, Image, Input, Modal, Pagination, Row, Space, InputNumber, notification, Select, message, Tooltip, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import InputWrapper from '../../../helpers/components/input_wrapper.component';
import { SearchOutlined, InfoCircleOutlined } from '@ant-design/icons';
import ItemCard from './item_card';
import { useDispatch, useSelector } from 'react-redux';
import OrderAddedItem from './order_added_item';
import LoadingItemCard from '../../components/loading.item_card';
import SubTotal from './sub_total';
import EmptyComponent from '../../../helpers/components/empty.component';
import { set_purchase_items, set_supplier_id, set_location_id } from '../../../actions/purchase_order.action';
import LoadingSubTotal from './loading.sub_total';
import itemService from '../../../services/config/item.service';
import purchaseOrderService from '../../../services/purchase_order/purchase_order.service';
import supplierService from '../../../services/config/supplier.service';
import locationService from '../../../services/config/location.service';
import CreatePurchaseOrderConfirmation from './create_purchase_order_confirmation';
import { Link, useHistory } from 'react-router-dom';

const CreatePurchaseOrder = () => {
    const purchase_order_redux = useSelector(state => state.purchase_order);
    const dispatch = useDispatch();
    const history = useHistory();
    const [showAddToPO, setAddToPO] = useState(false);
    const [form] = Form.useForm();


    const [state, setState] = useState({
        items: [],
        item_count: 0,
        item_loading: false,
        loading: false,
        customer_edit: false,
        create_loading: false,
        update_order_loading: false,
        confirmation_modal: null,
        item: null,
        supplier: [],
        location: [],
        invoice_number: null,
    });

    const [filter, setFilter] = useState({
        page: 1,
        search: null,
        input: null,
        locationInput: null,
    });

    //add to purchase order functions
    const addToPurchaseOrder = () => {
        form.validateFields()
            .then(values => {
                modifyPurchaseItems(values);
                setAddToPO(false);
            })
            .catch(() => {
            });
    };

    //cancel add to purchase order functions
    const cancelAddToPurchaseOrder = () => {
        setAddToPO(false);
        clearAll();
    };

    //get item data from item card
    const getItem = (item) => {
        form.setFieldsValue({ item_unit_price: item?.cost })

        setAddToPO(true);
        setState(state => ({
            ...state,
            item: item,
        }))
    }

    const [timer, setTimer] = useState(null)

    const modifyPurchaseItems = ({ t_item, item_unit_price, free }) => {
        let purchase_order_items = (purchase_order_redux.purchase_items);

        //find whether list already have this item
        let existing_item = purchase_order_items?.find(x => x.item_id === state?.item?.item_id);

        if (existing_item) {
            const calculated_quantity = t_item;
            existing_item.quantity = calculated_quantity;
            existing_item.free = free ?? 0;
            existing_item.unit_price = item_unit_price;

            let changed_quantity = purchase_order_items;
            if (calculated_quantity > 0) {
                changed_quantity = purchase_order_items?.map((x) => {
                    return (x?.item_id === state?.item?.item_id) ? existing_item : x;
                })
            } else {
                changed_quantity = purchase_order_items?.filter((x) => {
                    return (x?.item_id != state?.item?.item_id);
                })
            }

            dispatch(set_purchase_items(changed_quantity));
        } else {
            //push to it
            if (t_item > 0) {
                dispatch(
                    set_purchase_items([
                        ...purchase_order_items,
                        {
                            item_id: state?.item?.item_id,
                            item_code: state?.item?.item_code,
                            name: state?.item?.name,
                            quantity: t_item,
                            unit_price: item_unit_price,
                            free: free ?? 0
                        }
                    ])
                );
            }
        }
        clearAll();
    }

    const fetchSupplier = async () => {
        const supplierResponse = await supplierService.getSupplier();
        const supplier = [];
        supplierResponse?.supplier.forEach((data, index) => {
            supplier[index] = { value: data.supplier_id, label: data.name };
        });

        setState(state => ({
            ...state,
            supplier: supplier,
        }))
    }

    const fetchItem = async () => {
        setState(state => ({ ...state, item_loading: true, }));
        //get items
        const itemResponse = await itemService.getItem({
            page: (filter?.page - 1),
            size: 8,
            //filter
            search: filter?.search
        });

        const items = itemResponse?.items?.rows;
        const item_count = itemResponse?.items?.count;

        setTimeout(() => {
            setState(state => ({ ...state, items: items, item_count: item_count, item_loading: false, }));
        }, 800);

    }

    const fetchLocation = async () => {
        setState(state => ({ ...state }));
        let locationResponse;
        locationResponse = await locationService.getLocation({
            search: filter?.locationInput,
        });

        const location = locationResponse?.location?.map(x => {
            return {
                value: x.location_id,
                label: x.name,
            }
        })

        setState(state => ({ ...state, location: location }));
    }

    const doneTyping = (input) => {
        clearTimeout(timer);
        const newTimer = setTimeout(() => {
            setFilter(filter => ({ ...filter, search: input, page: 1, }));
        }, 300);
        setTimer(newTimer);
    }

    const createOrder = async () => {
        //check if theres item and supplier data 
        if (purchase_order_redux.purchase_items.length == 0 || purchase_order_redux?.supplier_id == null || purchase_order_redux?.location_id == null) {
            notification.error({
                message: (<span style={{ fontSize: 14, fontWeight: 500, }}>
                    {
                        purchase_order_redux.purchase_items.length == 0 ? "Add Item to Continue" : (purchase_order_redux?.supplier_id == null ? "Select a Supplier to Continue" : purchase_order_redux?.location_id == null ? "Select HQ to Continue" : "Oops. Something is missing")
                    }
                </span>),
                duration: 3,
                placement: 'topRight'
            });
            return;
        }
        setState(state => ({ ...state, create_loading: true }));
        const createOrderResponse = await purchaseOrderService.createOrder({
            purchase_items: purchase_order_redux?.purchase_items?.map(x => {
                const { item_id, name, unit_price, quantity, free } = x;
                return {
                    item_id: item_id,
                    item_name: name,
                    unit_price: unit_price,
                    quantity: quantity,
                    free: free,
                }
            }),
            supplier_id: purchase_order_redux?.supplier_id,
            location_id: purchase_order_redux?.location_id,
            invoice_number: state?.invoice_number
        });

        setTimeout(() => {
            setState(state => ({ ...state, create_loading: false, invoice_number: null, confirmation_modal: createOrderResponse?.order }));
        }, 800);

        setTimeout(() => {
            notification.success({
                message: (<span style={{ fontSize: 14, fontWeight: 500, }}>Order Created</span>),
                description: (
                    <>
                        <a
                            onClick={() => history.push(`/purchases/order/${createOrderResponse?.order?.order_id}`)}
                        >
                            {createOrderResponse?.order?.running_number}
                        </a>
                    </>
                ),
                duration: null,
                placement: 'bottomLeft'
            });
            setState(state => ({ ...state, update_order_loading: false }));

        }, 500);
        dispatch(set_purchase_items([]));
        dispatch(set_supplier_id(null));
        dispatch(set_location_id(null));
        clearAll();
    }

    const clearAll = () => {
        setState(state => ({
            ...state,
            item: null,
        }));
        form.resetFields();
    }

    useEffect(() => {
        fetchItem();
    }, [filter?.page, filter?.search])

    useEffect(() => {
        fetchSupplier();
        fetchLocation();
    }, [])

    const updateOrder = async () => {
        setState(state => ({ ...state, update_order_loading: true }));
        const updateOrderResponse = await purchaseOrderService.updateOrder(state?.confirmation_modal?.order_id, {
            status: 1,
        });

        setTimeout(() => {

            notification.success({
                message: (<span style={{ fontSize: 14, fontWeight: 500, }}>Order Completed</span>),
                description: (
                    <>
                        <a
                            onClick={() => history.push(`/purchases/order/${state?.confirmation_modal?.order_id}`)}
                        >
                            {state?.confirmation_modal?.running_number}
                        </a>
                    </>
                ),
                duration: null,
                placement: 'bottomLeft'
            });
            closeConfirmationModal();
            setState(state => ({ ...state, update_order_loading: false }));

        }, 500);
    }

    const editOrder = () => {
        history.push(`/purchases/order/${state?.confirmation_modal?.order_id}`);
        closeConfirmationModal();
    }

    const closeConfirmationModal = () => {
        setState(state => ({ ...state, confirmation_modal: null }));
    }

    return (
        <>
            <div>
                <Row justify='center' align='top' gutter={[40, 20]}>
                    <Col xs={24} sm={16} span={16}>
                        <div>
                            <div className='filter-panel' style={{ height: 60 }}>
                                <InputWrapper>
                                    <Input
                                        name="searchItem"
                                        onChange={(e) => doneTyping(e.target.value)}
                                        bordered={false}
                                        prefix={<div style={{ paddingRight: 12, paddingLeft: 8 }}><SearchOutlined style={{ color: '#8c8c8c' }} /></div>}
                                        placeholder={`Search Products`}
                                        style={{ fontSize: 14 }}
                                        size='large'
                                    />
                                </InputWrapper>
                            </div>

                            <div style={{ marginTop: 20 }}>
                                <Row gutter={[20, 20]}>
                                    {
                                        state?.item_loading ?
                                            (
                                                <>
                                                    {[...Array(8)].map((x, i) => {
                                                        return (
                                                            <Col xs={24} sm={12} lg={8} xl={6} span={6} key={i} style={{ height: '100%' }}>
                                                                <LoadingItemCard />
                                                            </Col>
                                                        )
                                                    })}
                                                </>
                                            )
                                            :
                                            (
                                                <>

                                                    {
                                                        state?.items?.length > 0 ? (
                                                            <>
                                                                {
                                                                    state?.items?.map((item, index) => {
                                                                        return (
                                                                            <Col xs={24} sm={12} lg={8} xl={6} span={6} key={index} style={{ height: 'auto' }}>
                                                                                <ItemCard item={item} getItem={getItem} />
                                                                            </Col>
                                                                        )
                                                                    })
                                                                }
                                                            </>
                                                        ) : (
                                                            <>
                                                                <Space direction='vertical' style={{ width: '100%' }}>

                                                                    <EmptyComponent />
                                                                    <Tag color="orange"><InfoCircleOutlined />
                                                                        {
                                                                            purchase_order_redux?.supplier_id ?
                                                                                <span>No Item Found</span> : <span>Select Supplier to view item</span>
                                                                        }
                                                                    </Tag>
                                                                </Space>
                                                            </>
                                                        )
                                                    }

                                                </>
                                            )
                                    }

                                </Row>
                            </div>
                        </div>
                        <div style={{ padding: 24 }}>
                            <Pagination onChange={(page) => setFilter(filter => ({ ...filter, page: page }))} current={filter?.page} total={state?.item_count} showSizeChanger={false} />
                        </div>
                    </Col>
                    <Col xs={24} sm={8} span={8}>
                        {
                            state?.item_loading ?
                                (
                                    <>
                                        <LoadingSubTotal />
                                    </>
                                )
                                :
                                (
                                    <>
                                        <div>
                                            <div style={{ height: 80 }}>
                                                <Input value={state?.invoice_number} style={{ width: '100%', marginBottom: '5px' }} placeholder="DO/INV Number" onChange={(e) => {
                                                    setState(state => ({ ...state, invoice_number: e.target.value }));
                                                }} />

                                                <Select style={{ width: '100%', marginBottom: '5px' }}
                                                    value={purchase_order_redux?.location_id}
                                                    allowClear
                                                    onChange={(value) => {
                                                        dispatch(set_location_id(value ?? null));
                                                    }}
                                                    className='border-input'
                                                    showSearch
                                                    placeholder="Select HQ"
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                    }
                                                    options={state?.location}
                                                />
                                                <Select style={{ width: '100%' }}
                                                    value={purchase_order_redux?.supplier_id}
                                                    allowClear
                                                    onChange={(value) => {
                                                        dispatch(set_supplier_id(value ?? null));
                                                    }}
                                                    className='border-input'
                                                    showSearch
                                                    placeholder="Select a supplier"
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                    }
                                                    options={state?.supplier}
                                                />

                                            </div>

                                            <div style={{ marginTop: 20 }}>
                                                <div>
                                                    <Space direction='vertical' style={{ width: '100%' }}>
                                                        {
                                                            purchase_order_redux?.purchase_items?.length > 0 ?
                                                                (
                                                                    <>
                                                                        {
                                                                            purchase_order_redux?.purchase_items?.map((item, index) => {
                                                                                return (
                                                                                    <div key={index}>
                                                                                        <OrderAddedItem item={item} index={(index + 1)} />
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                    </>
                                                                )
                                                                :
                                                                (
                                                                    <EmptyComponent />
                                                                )
                                                        }

                                                    </Space>
                                                    <div style={{ marginTop: 20 }}>
                                                        <Card
                                                            bordered={false}
                                                            style={{ borderRadius: 6, }}
                                                        >
                                                            <div>
                                                                <SubTotal />


                                                                <div style={{ marginTop: 20 }}>
                                                                    <Button
                                                                        onClick={createOrder}
                                                                        size='large'
                                                                        type='primary'
                                                                        style={{ width: '100%', borderRadius: 4 }}
                                                                        loading={state?.create_loading}
                                                                    >
                                                                        <span style={{ fontWeight: 500 }}>Create Order</span>
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        </Card>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )
                        }

                    </Col>
                </Row>
            </div>

            {/* add to cart */}
            <Modal
                title="Add to Order"
                centered
                okText="Add"
                open={showAddToPO}
                onOk={addToPurchaseOrder}
                onCancel={cancelAddToPurchaseOrder}
            >
                <p>Item Name:   <b>{state?.item?.name}</b></p>
                {/* <p>Item Price:  <b>RM {state?.item?.cost}</b></p> */}

                <Form form={form}>
                    <Form.Item
                        labelCol={{ span: 24 }}
                        label="Total Item"
                        name="t_item"
                        rules={[{ required: true, message: 'Please input total item' }]}
                    >
                        <InputNumber style={{ width: '100%' }} min={1} />
                    </Form.Item>
                    <Form.Item
                        labelCol={{ span: 24 }}
                        label="Item Unit Price (RM)"
                        name="item_unit_price"
                        rules={[{ required: true, message: 'Please input item unit price' }]}
                    >
                        <InputNumber style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item
                        labelCol={{ span: 24 }}
                        label="Free"
                        name="free"
                    >
                        <InputNumber style={{ width: '100%' }} />
                    </Form.Item>
                </Form>
            </Modal>

            <Modal
                transitionName="ant-fade"
                maskTransitionName="ant-fade"
                open={state?.confirmation_modal}
                onOk={updateOrder}
                width={1000}
                closable={false}
                className="custom-modal"
                onCancel={closeConfirmationModal}
                footer={[
                    <Button onClick={closeConfirmationModal}>Make Another Order</Button>,
                    <Button onClick={editOrder}>Edit Order</Button>,
                    <Button type='primary' onClick={updateOrder} loading={state?.update_order_loading}>Complete Order</Button>
                ]}
                title={<><span>Order Confirmation</span></>}
            >
                <div>
                    <CreatePurchaseOrderConfirmation order_id={state?.confirmation_modal?.order_id} />
                </div>
            </Modal>


        </>
    );
}

export default CreatePurchaseOrder;