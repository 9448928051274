import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import LayoutIndex from '../layouts';
import AppRouter from './app.router';

const AuthRouter = () => {
    const authRedux = useSelector(state => state.auth);
    useEffect(() => {
    }, [authRedux?.branch]);
    return (
        <>
            <LayoutIndex>
                <AppRouter />
            </LayoutIndex>
        </>
    );
}

export default AuthRouter;