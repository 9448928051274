import { Button, Col, Divider, Dropdown, Modal, Row, Skeleton, Space, Spin, Tag, Form, Input } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import billingService from '../../../services/billing/billing.service';
import BillingItem from './billing_items';
import { PrinterOutlined, DownOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { set_update_billing } from '../../../actions/billing.action';
import { generateInvoice } from '../functions/invoice';
import CreditNote from './credit_note';

const BillingDetail = ({ billing_id }) => {
    const billing_redux = useSelector(state => state.billing);
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [state, setState] = useState({
        loading: false,
        billing: null,
        edit_modal: false,
        edit: false,
        updated: 0,
        status: null,
        dropdown: [
            {
                key: '1',
                type: 'group',
                label: 'Billing Status',
                children: [
                    {
                        key: '1-1',
                        label: 'Completed',
                        onClick: async () => {
                            const update = await updateBilling(1);
                        }
                    },
                    {
                        key: '1-2',
                        label: 'Void',
                        onClick: async () => {
                            const update = await updateBilling(2);
                        }
                    }
                ]
            },
        ]
    })

    const updateBilling = async (status) => { //status code - 1 : complete, 2 : void
        const updateBillingResponse = await billingService.updateBilling(billing_id, {
            status: status,
        });

        setState(state => ({ ...state, updated: (state?.updated + 1) }))
    }
    const printInvoice = async (print_type) => {
        var printWindow = window.open(' ', 'PrintWindow');
        const { billing_items, branch, supplier, running_number, created_at, total_amount } = state?.billing ?? {};
        const invoice_string = await generateInvoice({
            branch: branch ? branch?.company?.name + '<br/>' + branch?.name : supplier ? supplier?.name : 'N/A',
            items: billing_items,
            running_number: running_number,
            total_amount: total_amount,
            date: moment(created_at).format("DD/MM/YYYY"),
            print_type: print_type
        })
        printWindow.document.write(invoice_string);
        printWindow.print();
        printWindow.close();
    }
    const fetchBillingDetail = async () => {
        setState(state => ({ ...state, loading: true }));
        const billingResponse = await billingService.getBillingById(billing_id);

        setTimeout(() => {
            setState(state => ({
                ...state, loading: false, billing: billingResponse?.billings, edit: billingResponse?.billings?.status == 0 ? true : false, status: billingResponse?.billings?.status,
                // status: orderResponse?.orders.status, edit: edit ?? orderResponse?.orders.status == 0 ? true : false 
            }));

        }, 800);
        return billingResponse?.billings;
    }

    useEffect(() => {
        fetchBillingDetail();
        dispatch(set_update_billing(null));
    }, [billing_id, state?.updated, billing_redux?.update_billing])


    return (
        <>
            <Spin spinning={state?.loading}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div>
                        <div style={{ textAlign: 'left' }}>
                            <div>
                                <Space>
                                    <div>
                                        <span style={{ fontSize: 18, color: '#595959' }}>{state?.billing?.running_number}</span>
                                    </div>
                                    <div>
                                        <Tag color={state?.status == 0 ? 'orange' : state?.status == 1 ? 'green' : 'red'} style={{ border: 'none' }}>
                                            <span>{state?.status == 0 ? 'Pending' : state?.status == 1 ? 'Completed' : 'Void'}</span>
                                        </Tag>
                                    </div>
                                </Space>
                            </div>
                            <div>
                                <span>
                                    <span style={{ fontSize: 24, fontWeight: 500 }}>{`RM ${state?.billing?.total_amount ?? 0}`}</span>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div style={{ marginLeft: 'auto' }}>
                        <Space>
                            <Button
                                type='default' style={{ borderRadius: 4 }}
                                icon={<PrinterOutlined />}
                                onClick={() => printInvoice('DO')}
                            >
                                Print DO
                            </Button>
                            <Button
                                type='default' style={{ borderRadius: 4 }}
                                icon={<PrinterOutlined />}
                                onClick={() => printInvoice('invoice')}
                            >
                                Print Invoice
                            </Button>
                            {
                                state?.edit &&
                                (
                                    <>
                                        <Dropdown
                                            placement='bottomRight'
                                            overlayStyle={{ borderRadius: 12, minWidth: 200 }}
                                            menu={{
                                                items: state?.dropdown,
                                            }}
                                        >
                                            <Button type='primary' className='mini-button' icon={<DownOutlined />} style={{ borderRadius: 4 }}>
                                                Action
                                            </Button>
                                        </Dropdown>
                                    </>
                                )
                            }
                        </Space>
                    </div>
                </div>

                <Divider style={{ marginTop: 12 }} />
                <div style={{ textAlign: 'left' }}>
                    <Row gutter={[20, 20]}>
                        <Col xs={12} md={8} lg={6} span={6}>
                            <div>
                                <div>
                                    <span style={{ fontWeight: 500 }}>From</span>
                                </div>
                                <div>
                                    <span>
                                        {
                                            state?.billing?.billing_location ?
                                                (`${state?.billing?.billing_location?.name}`)
                                                :
                                                state?.billing?.from_branch ?
                                                    (`${state?.billing?.from_branch?.name}`)
                                                    :
                                                    <span style={{ fontSize: 12 }}>No Headquater added</span>
                                        }
                                    </span>
                                </div>
                            </div>
                        </Col>
                        <Col xs={12} md={8} lg={6} span={6}>
                            <div>
                                <div>
                                    <span style={{ fontWeight: 500 }}>To</span>
                                </div>
                                <div>
                                    <span>
                                        {
                                            state?.billing?.branch ? (state?.billing?.branch?.name) : state?.billing?.supplier ?
                                                (state?.billing?.supplier?.name)
                                                :
                                                <span style={{ fontSize: 12 }}>No Record added</span>
                                        }
                                    </span>
                                </div>
                            </div>
                        </Col>
                        <Col xs={12} md={8} lg={6} span={6}>
                            <div>
                                <div>
                                    <span style={{ fontWeight: 500 }}>Issue Date</span>
                                </div>
                                <div>

                                    {
                                        state?.billing?.created_at ?
                                            (
                                                <>
                                                    <span>
                                                        {moment(state?.billing?.created_at).format("D MMM YYYY, h:mm:ss a")}
                                                    </span>
                                                </>
                                            )
                                            :
                                            (
                                                <>
                                                    <span style={{ fontSize: 12 }}>No issue date</span>
                                                </>
                                            )
                                    }
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>

                <div style={{ marginTop: 48 }}>
                    <BillingItem billing_id={billing_id} edit={state?.edit} location_id={state?.billing?.location_id}
                    />
                </div>

                {/* <div style={{ marginTop: 48 }}>
                    {
                        (state?.status === 1) &&
                        <>
                            <CreditNote billing_id={billing_id} edit={state?.status == 1} status_editable={state?.status == 1} />
                        </>

                    }
                </div> */}
            </Spin>

        </>
    );
}

export default BillingDetail;