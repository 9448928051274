import { Button, Card, Col, Form, Image, Input, Modal, Pagination, Row, Space, notification, message, Tooltip, Select, } from 'antd';
import React, { useEffect, useState, useRef } from 'react';
import InputWrapper from '../../../helpers/components/input_wrapper.component';
import { SearchOutlined, ExclamationCircleOutlined, ScanOutlined, InfoCircleFilled, PrinterOutlined } from '@ant-design/icons';
import ItemCard from './item_card';
import { useDispatch, useSelector } from 'react-redux';
import SaleAddedItem from './sale_added_item';
import LoadingItemCard from './loading.item_card';
import SubTotal from './sub_total';
import EmptyComponent from '../../../helpers/components/empty.component';
import { set_sale_total_amount, set_sale_items, validate_user } from '../../../actions/sales_order.action';
import LoadingSubTotal from './loading.sub_total';
import itemService from '../../../services/config/item.service';
import checkoutService from '../../../services/sales/checkout.service';
import CreateSaleConfirmation from './create_sale_confirmation';
import { Link, useHistory } from 'react-router-dom';
import SaleInvoiceWrapper from './sale_invoice_wrapper';

const { Option } = Select;
const CreateSaleOrder = ({ payment_state }) => {
    const componentRef = useRef();
    const pageSize = 16;
    const sales_order_redux = useSelector(state => state.sales_order);
    const dispatch = useDispatch();
    const history = useHistory();
    const [form] = Form.useForm();
    const [submit_form] = Form.useForm();
    const [modal_form] = Form.useForm();
    const checkoutButtonRef = useRef();
    const updateSalesRef = useRef();
    const [state, setState] = useState({
        items: [],
        item_count: 0,
        item_loading: false,
        loading: false,
        selected_payment_method: 1,
        sale: null,
        checkout_loading: false,
        update_sale_loading: false,
        confirmation_modal: null,
        is_scan: true,
        referral_code: null,
        warranty_type : null,
        warranty_duration : null,
        to_print_sale_id: null,
        paid_amount : null,
        item_refresh : 0,
    });

    const [filter, setFilter] = useState({
        page: 1,
        search: null,
        input: null,
    });

    const [timer, setTimer] = useState(null)

    // const updateCustomerDetail = () => {
    //     const { name, phoneNo } = form.getFieldsValue();
    //     dispatch(set_customer({ name: name, phoneNo: phoneNo }));
    //     setState(state => ({ ...state, customer_edit: false }));
    // }

    const fetchItem = async () => {
        setState(state => ({ ...state, item_loading: (state?.item_refresh <= 0), }));
        //get items
        const itemResponse = await itemService.getItem({
            page: (filter?.page - 1),
            size: pageSize,
            //filter
            search: filter?.search
        });
        const items = itemResponse?.items?.rows;
        const item_count = itemResponse?.items?.count;

        setTimeout(() => {
            setState(state => ({ ...state, items: items, item_count: item_count, item_loading: false, }));
        }, 800);

    }

    const scanItem = async (input) => {
        const itemResponse = await itemService.getItem({
            //filter
            scan_item_code: input
        });

        const items = itemResponse?.items?.rows;
        if (items?.length > 0) {
            const item = items[0];
            //add to sales item if exist
            modifySaleItems({
                item: items[0], plus: 1
            });
            notification.info({
                message: "Added sale item",
                description: (
                    <>
                        <Space align='start' style={{ fontWeight: 500, color: '#8c8c8c' }}>
                            <div>
                                <div>
                                    <span>{item?.name}</span>
                                    <span style={{ marginLeft: 6, fontSize: 12, color: '#8c8c8c' }}>{`x1`}</span>
                                </div>
                                <div>
                                    <span style={{ fontSize: 12, color: '#8c8c8c' }}>{`${item?.item_code}`}</span>
                                </div>
                            </div>
                        </Space>
                    </>
                ),
                placement: 'bottomLeft'
            });
        } else {
            notification.warning({
                message: "Item not found",
                description: (
                    <>
                        <Space align='start' style={{ fontWeight: 500, color: '#8c8c8c' }}>
                            <div>
                                <span style={{ fontSize: 12, color: '#8c8c8c' }}>{`${input}`}</span>
                            </div>
                        </Space>
                    </>
                ),
                placement: 'bottomLeft'
            });
        }
        setFilter(filter => ({ ...filter, input: null }));
    }

    const modifySaleItems = ({ item, plus }) => {
        let sales_order_items = (sales_order_redux.sale_items);
        //find whether list already have this item
        let existing_item = sales_order_items?.find(x => x.item_id === item?.item_id);
        if (existing_item) {
            const calculated_quantity = existing_item?.quantity + plus;
            existing_item.quantity = calculated_quantity;
            let changed_quantity = sales_order_items;
            if (calculated_quantity > 0) {
                changed_quantity = sales_order_items?.map((x) => {
                    return (x?.item_id === item?.item_id) ? existing_item : x;
                })
            } else {
                changed_quantity = sales_order_items?.filter((x) => {
                    return (x?.item_id != item?.item_id);
                })
            }

            dispatch(set_sale_items(changed_quantity));
        } else {
            //push to it
            if (plus > 0) {
                dispatch(set_sale_items([...sales_order_items, 
                    { 
                        item_id: item?.item_id, 
                        item_code: item?.item_code, 
                        name: item?.name, 
                        quantity: plus, 
                        price: item?.price, 
                        promotion : item?.promotion,
                        stock_quantity : item?.stock_quantity,
                    }]));
            }
        }
    }

    const createCheckoutSales = async () => {
        if (!localStorage.getItem('branch')) {
            notification.warning({
                message: "Select branch to proceed",
            });
            return
        }
        form.validateFields()
            .then(async (values) => {
                const { name, phoneNo } = form.getFieldsValue();
                const discount_approver = sales_order_redux?.validated_user;
                
                const payment_method = payment_state?.payment_methods?.find(x => x.payment_method_id == state?.selected_payment_method);
                let payment_input_check;
                let payment_input_type;
                if(payment_method?.is_cashless){
                    //check whether referral code is entered
                    payment_input_check = state?.referral_code;
                    payment_input_type = "referral code";
                }else{
                    payment_input_check = state?.paid_amount;
                    payment_input_type = "paid amount";
                }

                if(payment_input_check){
                    setState(state => ({ ...state, checkout_loading: true, sale: null }));
                    let created_sales;
                    const checkoutSalesResponse = await checkoutService.createCheckoutSales({
                        payment_method_id: state?.selected_payment_method,
                        sale_status_id: 1,
                        referral_code: state?.referral_code,
                        sale_items: sales_order_redux?.sale_items?.map(x => {
                            const { item_id, price, quantity, promotion_id, discount_unit_price } = x;
                            return {
                                item_id: item_id,
                                unit_price: price,
                                quantity: quantity,
                                promotion_id : promotion_id,
                                discount_unit_price : discount_unit_price,
                            }
                        }),
                        customer: {
                            name: name,
                            phone_no: phoneNo,
                        },
                        branch_id: localStorage.getItem('branch'),
                        total_amount: sales_order_redux?.sale_total_amount?.toFixed(2),
                        discount_approver : discount_approver?.username ?? null,
                        warranty_type : state?.warranty_type,
                        warranty_duration : state?.warranty_duration,
                    });
                    created_sales = checkoutSalesResponse?.sales;

                    setTimeout(() => {
                        setState(state => ({ ...state, checkout_loading: false, confirmation_modal: created_sales }));
                    }, 500);
                }else{
                    notification.warning({
                        message: `Please enter ${payment_input_type}`,
                    });
                }

                

            })
            .catch(() => {
                setTimeout(() => {
                    notification.warning({
                        message: "Customers' info is required",
                    });
                    // setState(state => ({ ...state, customer_edit: true }));
                }, 500);
            });


    }

    const updateSales = async () => {
        setState(state => ({ ...state, update_sale_loading: true }));
        const updateSaleResponse = await checkoutService.updateCheckoutSales(state?.confirmation_modal?.sale_id, {
            sale_status_id: 2,
        });

        setState(state => ({ ...state, sale: updateSaleResponse?.sale, to_print_sale_id: updateSaleResponse?.sale?.sale_id }));
        setTimeout(() => {
            notification.success({
                message: (<span style={{ fontSize: 14, fontWeight: 500, }}>Checkout Completed</span>),
                description: (
                    <>
                        <a
                            onClick={() => history.push(`/sales/order/${state?.confirmation_modal?.sale_id}`)}
                        >
                            {updateSaleResponse?.sale?.running_number}
                        </a>
                    </>
                ),
                duration: 10,
                placement: 'bottomLeft',
            });

            closeConfirmationModal();
            setState(state => ({ ...state, update_sale_loading: false }));
            document.getElementById("printInvoice").click();
        }, 500);
    }

    const editSales = () => {
        history.push(`/sales/order/${state?.confirmation_modal?.sale_id}`);
        closeConfirmationModal();
    }

    const closeConfirmationModal = () => {
        dispatchResetCheckout();
        setState(state => ({ ...state, confirmation_modal: null }));
    }

    const doneTyping = (input) => {
        clearTimeout(timer);
        setFilter(filter => ({ ...filter, input: input }));
        const newTimer = setTimeout(() => {
            if (state?.is_scan) {
                scanItem(input);
            } else {
                setFilter(filter => ({ ...filter, search: input, page: 1, }));
            }

        }, 300);
        setTimer(newTimer);
    }

    const toggleIsScan = () => {
        setState(state => ({ ...state, is_scan: !state?.is_scan }));
        setFilter(filter => ({ ...filter, input: null, search: null }));

        //display message for scanner
        message.info(`Scanner ${!state?.is_scan ? 'On' : 'Off'}`)
    }

    const dispatchResetCheckout = () => {
        dispatch(set_sale_items([]));
        dispatch(set_sale_total_amount(0));
        dispatch(validate_user(null));
        setState(state => ({ ...state, selected_payment_method: 1, referral_code: null, paid_amount : null, item_refresh : (state?.item_refresh + 1), warranty_duration : null, warranty_type : null, }));
        form.resetFields();
    }

    useEffect(() => {
        fetchItem();
    }, [filter?.page, filter?.search, state?.item_refresh]);


    // const onEnterPressed = (event) => {
    //     if (event?.key === 'Enter') {
    //         event.preventDefault();
    //         if(state?.confirmation_modal){
    //             updateSalesRef?.current?.click();
    //         }else{
    //             //open confirmation
    //             checkoutButtonRef?.current?.click();
    //         }
    //     }
    // }

    // useEffect(() => {
    //     //set key press
    //     // onEnterPressed();
    //     
    //     // document.addEventListener('keydown', onEnterPressed);
    // }, [state?.confirmation_modal])

    useEffect(() => {
        if (state?.confirmation_modal) {
            updateSalesRef.current.focus();
        }
      }, [state?.confirmation_modal]);

    return (
        <>
            <div>
                <Row justify='center' align='top' gutter={[40, 20]}>
                    <Col xs={24} sm={16} span={16}>
                        <div>
                            <div className='filter-panel' style={{ height: 60 }}>
                                <InputWrapper>
                                    <Input
                                        value={filter?.input}
                                        onChange={(e) => doneTyping(e.target.value)}
                                        bordered={false}
                                        prefix={<div style={{ paddingRight: 12, paddingLeft: 8 }}><SearchOutlined style={{ color: '#8c8c8c' }} /></div>}
                                        placeholder={state?.is_scan ? 'Scan Products' : `Search Products`}
                                        suffix={(
                                            <>
                                                <Space>
                                                    <div
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={toggleIsScan}>
                                                        <ScanOutlined style={{ color: state?.is_scan ? "#6765FE" : '#8c8c8c' }} />
                                                    </div>
                                                    <div>
                                                        <Tooltip title="This is the toggle to identify scan or search, when it is on, it will auto add the items when you scan the barcode.">
                                                            <InfoCircleFilled style={{ color: '#8c8c8c' }} />
                                                        </Tooltip>
                                                    </div>
                                                </Space>
                                            </>
                                        )}
                                        style={{ fontSize: 14 }}
                                        size='large'
                                    />
                                </InputWrapper>
                            </div>

                            <div style={{ marginTop: 20 }}>
                                <Row gutter={[20, 20]}>
                                    {
                                        state?.item_loading ?
                                            (
                                                <>
                                                    {[...Array(16)].map((x, i) => {
                                                        return (
                                                            <Col xs={24} sm={12} lg={8} xl={6} span={6} key={i} style={{ height: '100%' }}>
                                                                <LoadingItemCard />
                                                            </Col>
                                                        )
                                                    })}
                                                </>
                                            )
                                            :
                                            (
                                                <>
                                                    {
                                                        state?.items?.map((item, index) => {
                                                            return (
                                                                <Col xs={24} sm={12} lg={8} xl={6} span={6} key={index} style={{ height: 'auto' }}>
                                                                    <ItemCard item={item} />
                                                                </Col>
                                                            )
                                                        })
                                                    }
                                                </>
                                            )
                                    }

                                </Row>
                            </div>
                        </div>
                        <div style={{ padding: 24 }}>
                            <Pagination showSizeChanger={false} pageSize={pageSize} onChange={(page) => setFilter(filter => ({ ...filter, page: page }))} current={filter?.page} total={state?.item_count} />
                        </div>
                    </Col>
                    <Col xs={24} sm={8} span={8}>
                        {
                            payment_state?.loading ?
                                (
                                    <>
                                        <LoadingSubTotal />
                                    </>
                                )
                                :
                                (
                                    <>
                                        <div>
                                            <Card
                                                bordered={false}
                                                style={{ borderRadius: 6, }}
                                            >
                                                <div>
                                                    <Form
                                                        id='customer_form'
                                                        form={form}
                                                    >
                                                        <Form.Item name='name' >
                                                            <Input placeholder='Customer Name' bordered={false} style={{ borderRadius: 6, background: '#f5f5f5' }} />
                                                        </Form.Item>
                                                        <Form.Item name='phoneNo' rules={[{ required: true, message: 'Phone Number is required' }]}>
                                                            <Input placeholder='Phone Number' bordered={false} prefix={<span>+60</span>} style={{ borderRadius: 6, background: '#f5f5f5' }} />
                                                        </Form.Item>
                                                    </Form>
                                                </div>
                                            </Card>

                                            <div style={{ marginTop: 20 }}>
                                                <div>
                                                    <Space direction='vertical' style={{ width: '100%' }}>
                                                        {
                                                            sales_order_redux?.sale_items?.length > 0 ?
                                                                (
                                                                    <>
                                                                        {
                                                                            sales_order_redux?.sale_items?.map((item, index) => {
                                                                                return (
                                                                                    <div key={index}>
                                                                                        <SaleAddedItem item={item} index={(index + 1)} />
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                    </>
                                                                )
                                                                :
                                                                (
                                                                    <EmptyComponent />
                                                                )
                                                        }

                                                    </Space>
                                                    <div style={{marginTop:24,}}>
                                                        <Card
                                                        bordered={false}
                                                        style={{ borderRadius:6,  }}
                                                        >
                                                            <div style={{textAlign:'start',}}>
                                                                <span style={{ color: '#8c8c8c', fontWeight: 500 }}>Warranty</span>
                                                            </div>
                                                            <div style={{marginTop:8,}}>
                                                                <Row gutter={[12, 12]}>
                                                                    <Col span={12}>
                                                                        <div>
                                                                            <Select value={state?.warranty_type} onChange={(e) => setState(state => ({...state, warranty_type : e}))} style={{width:'100%'}}>
                                                                                <Option key="week">Week</Option>
                                                                                <Option key="month">Month</Option>
                                                                            </Select>
                                                                        </div>
                                                                    </Col>
                                                                    <Col span={12}>
                                                                        <div>
                                                                            <Input value={state?.warranty_duration} onChange={(e) => setState(state => ({...state, warranty_duration : e.target.value}))}/>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </Card>
                                                    </div>
                                                    <div style={{ marginTop: 20 }}>
                                                        <Form
                                                        form={submit_form}
                                                        onFinish={createCheckoutSales}
                                                        >
                                                            <Card
                                                                bordered={false}
                                                                style={{ borderRadius: 6, }}
                                                            >
                                                                <div>
                                                                    <SubTotal />
                                                                    
                                                                    <div style={{ marginTop: 80 }}>
                                                                        <div style={{ textAlign: 'left', marginTop:24 }}>
                                                                            <div>
                                                                                <span style={{ color: '#8c8c8c', fontWeight: 500 }}>Payment Method</span>
                                                                            </div>

                                                                            <div style={{ marginTop: 12 }}>
                                                                                <Row gutter={[30, 30]}>
                                                                                    {
                                                                                        payment_state?.payment_methods?.map((payment_method, index) => {
                                                                                            return (
                                                                                                <Col xs={8} sm={18} md={12} lg={8} xl={6} span={6} key={index}>
                                                                                                    <div
                                                                                                        className={`payment-method-container ${state?.selected_payment_method === payment_method?.payment_method_id && 'selected'}`}
                                                                                                        style={{ cursor: 'pointer' }}
                                                                                                        onClick={() => setState(state => ({ ...state, selected_payment_method: payment_method?.payment_method_id }))}
                                                                                                    >
                                                                                                        <div className='payment-method-card'>
                                                                                                            <Image preview={false} src={payment_method?.icon} />
                                                                                                        </div>

                                                                                                        <div style={{ textAlign: 'center' }}>
                                                                                                            <span style={{ fontSize: 12, color: '#8c8c8c' }}>{payment_method?.name}</span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </Col>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </Row>
                                                                                <div style={{ marginTop: 12 }}>
                                                                                    {
                                                                                        payment_state?.payment_methods?.find(x => x.payment_method_id == state?.selected_payment_method)?.is_cashless?
                                                                                        (
                                                                                            <>
                                                                                                <InputWrapper>
                                                                                                    <Input size='large' onChange={(e) => setState(state => ({ ...state, referral_code: e.target.value }))} placeholder='Referral Code' />
                                                                                                </InputWrapper>
                                                                                            </>
                                                                                        )
                                                                                        :
                                                                                        (
                                                                                            <>
                                                                                                <div style={{ textAlign : 'left'}}>
                                                                                                    <div>
                                                                                                        <span style={{ color: '#8c8c8c', fontWeight: 500 }}>Changes</span>
                                                                                                    </div>

                                                                                                    <div style={{marginTop:8}}>
                                                                                                        <div>
                                                                                                            <div style={{ display: 'flex' }}>
                                                                                                                <div>
                                                                                                                    <span style={{ color: '#595959', fontWeight: 500, fontSize: 18 }}>Balance</span>
                                                                                                                </div>
                                                                                                                <div style={{ marginLeft: 'auto' }}>
                                                                                                                    <span style={{ color: '#595959', fontWeight: 500, fontSize: 18 }}>
                                                                                                                        {`MYR ${(state?.paid_amount) ? (state?.paid_amount - sales_order_redux?.sale_total_amount)?.toFixed(2) : 0}`}
                                                                                                                    </span>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                    <div style={{ marginTop: 12 }}>
                                                                                                        <InputWrapper>
                                                                                                            <Input size='large' value={state?.paid_amount} onChange={(e) => setState(state => ({...state, paid_amount : e?.target?.value }))} placeholder='Paid Amount' />
                                                                                                        </InputWrapper>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </>
                                                                                        )
                                                                                    }
                                                                                
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>


                                                                    <div style={{ marginTop: 20 }}>
                                                                        
                                                                            <Button
                                                                            htmlType='submit'
                                                                            onKeyDown={() => {

                                                                            }}
                                                                            // onClick={() => submit_form.submit()}
                                                                            // onClick={createCheckoutSales}
                                                                            size='large'
                                                                            type='primary'
                                                                            style={{ width: '100%', borderRadius: 4 }}
                                                                            loading={state?.checkout_loading}
                                                                            ref={checkoutButtonRef}
                                                                            disabled={sales_order_redux?.sale_items?.length <= 0 || state?.confirmation_modal}
                                                                            >
                                                                                <span style={{ fontWeight: 500 }}>Checkout</span>
                                                                            </Button>
                                                                        
                                                                    </div>
                                                                </div>
                                                            </Card>
                                                        </Form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )
                        }

                    </Col>
                </Row>
            </div>


            <Form
            form={modal_form}
            onFinish={() => updateSales()}
            >
                <Modal
                    transitionName="ant-fade"
                    maskTransitionName="ant-fade"
                    open={state?.confirmation_modal}
                    onOk={() => console.log("cheebye")}
                    width={1000}
                    closable={false}
                    className="custom-modal"
                    style={{ top: 10 }}
                    onCancel={closeConfirmationModal}
                    footer={[

                        // <Button icon={<PrinterOutlined />} style={{ background: '#f5f5f5', border: 'none', borderRadius: 6 }} >Print Invoice</Button>,
                        <Button onClick={closeConfirmationModal}>Make Another Sales</Button>,
                        <Button onClick={editSales}>Edit Sale</Button>,
                        <Button
                        key="ok"
                        ref={updateSalesRef} 
                        type='primary'
                        // onClick={updateSales}
                        onClick={modal_form.submit}
                        htmlType='submit'
                        loading={state?.update_sale_loading}

                        autoFocus={true}
                        >
                            Complete Checkout
                        </Button>
                    ]}

                    title={<><span>Checkout Confirmation</span></>}
                >
                    <div>
                        <CreateSaleConfirmation sale_id={state?.confirmation_modal?.sale_id} />
                    </div>
                </Modal>
            </Form>

            <SaleInvoiceWrapper sale_id={state?.to_print_sale_id} componentRef={componentRef} />,
        </>
    );
}

export default CreateSaleOrder;