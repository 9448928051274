import { InputNumber, Button, Col, Drawer, Form, Input, Modal, Pagination, Row, Space, notification, Select, Dropdown, Radio, Alert } from 'antd';
import InputWrapper from '../../../helpers/components/input_wrapper.component';
import { SearchOutlined, ExclamationCircleOutlined, DownOutlined } from '@ant-design/icons';
import ItemCard from './item_card';
import itemService from '../../../services/config/item.service';
import LoadingItemCard from '../../components/loading.item_card';
import React, { useEffect, useState } from 'react';
import itemCategoryService from '../../../services/config/item_category.service';

const { confirm } = Modal;

const ItemList = () => {
    const [form] = Form.useForm();
    const [modal, contextHolder] = Modal.useModal();
    const [state, setState] = useState({
        items: [],
        item_count: 0,
        item_loading: false,
        loading: false,
        isDisabled: true,
        isUpdate: null,
        item_id: null,
        item_category: [{ value: null, label: null }],
        update_loading: false,
    });
    const [open, setOpen] = useState(false);
    const [filter, setFilter] = useState({
        page: 1,
        search: null,
    });

    const [timer, setTimer] = useState(null);

    const fetchItem = async () => {
        setState(state => ({ ...state, items: [], item_loading: true, }));
        //get items
        const itemResponse = await itemService.getItem({
            page: (filter?.page - 1),
            size: 12,
            //filter
            search: filter?.search
        });
        const items = itemResponse?.items?.rows;
        const item_count = itemResponse?.items?.count;
        fetchItemCategory()
        setTimeout(() => {
            setState(state => ({ ...state, items: items, item_count: item_count, item_loading: false, }));
        }, 800);
    }

    const fetchItemCategory = async () => {
        const itemCategoryResponse = await itemCategoryService.getItemCategory();

        const item_cat = [];
        itemCategoryResponse?.item_category.forEach((data, index) => {
            item_cat[index] = { value: data.item_category_id, label: data.name, };
        });

        setState(state => ({
            ...state,
            item_category: item_cat,
        }))
    }

    const refresh = () => {
        onClose();
        fetchItem();
    };

    const doneTyping = (input) => {
        clearTimeout(timer);
        const newTimer = setTimeout(() => {
            setFilter(filter => ({ ...filter, search: input, page: 1, }));
        }, 300);
        setTimer(newTimer);
    }

    //get item data from item card
    const getItem = (item) => {
        setState(state => ({ ...state, item_id: item.item_id, }))

        form.setFieldsValue({
            code: item.item_code,
            name: item.name,
            price: item.price,
            cost: item.cost,
            markup: item.markup,
            description: item.description,
            category: item.item_category_id,
        });
        openDrawer(true)
    }

    const showDrawer = () => {
        setOpen(true);
    };

    //close drawer
    const onClose = () => {
        clearAll()
        setOpen(false);
    };


    // const onEdit = () => {
    //     setState(state => ({
    //         ...state,
    //         isDisabled: false,
    //     }));
    // };

    const offEdit = () => {
        if (state.isUpdate !== true) {
            onClose();
        }
        setState(state => ({
            ...state,
            isDisabled: true,
        }));
    };

    const onDelete = () => {
        confirm({
            icon: <ExclamationCircleOutlined />,
            content: 'Confirm Delete Item?',
            async onOk() {
                const deleteItemResponse = await itemService.deleteItem(state?.item_id);
                notification.success({
                    message: (<span style={{ fontSize: 14, fontWeight: 500, }}>Item Deleted</span>),
                    duration: 3,
                    placement: 'topRight'
                });
                clearAll();
                refresh();
            },
            onCancel() {
            },
        });

    };

    const openDrawer = (isUpdate) => {
        setState(state => ({
            ...state,
            isDisabled: isUpdate,
            isUpdate: isUpdate,
        }));

        showDrawer();
    }

    const addUpdateItem = async () => {
        setState(state => ({ ...state, update_loading: true }));
        const { code, name, description, price, category, cost, markup, is_all_company } = form.getFieldsValue();
        try {
            let data = {
                code: code,
                name: name,
                price: price,
                cost: cost,
                markup: markup,
                description: description,
                item_category_id: category,
                is_all_company: is_all_company ?? false,
            }
            if (state.isUpdate === true) {
                const updateItemResponse = await itemService.updateItem(state?.item_id, data);

                setTimeout(() => {
                    notification.success({
                        message: (<span style={{ fontSize: 14, fontWeight: 500, }}>Item Updated</span>),
                        duration: 3,
                        placement: 'topRight'
                    });
                    clearAll();
                    refresh();
                }, 800);
            } else {
                if (is_all_company) {
                    const checkItem = await itemService.checkItem({
                        code: code
                    });
                    if (checkItem?.items) {
                        confirm({
                            width: 500,
                            icon: <ExclamationCircleOutlined />,
                            okText: 'Yes',
                            content: (
                                <>
                                    <div>
                                        <span>Similar Item Code found in system.</span>
                                    </div>
                                    <div style={{ marginTop: 20 }}>
                                        <Alert
                                            icon={true}
                                            type='warning'
                                            message={<span>Creating this item will replace existing item which exist in system. Do you wish to proceed?</span>}
                                            style={{ borderRadius: 6 }}
                                        />
                                    </div>

                                </>
                            ),
                            async onOk() {
                                // setState(state => ({ ...state, update_loading: true }));
                                // try {
                                const storeItemResponse = await itemService.storeItem(data);
                                // } catch (e) {
                                //     setState(state => ({ ...state, update_loading: false }));
                                // }
                                setTimeout(() => {
                                    notification.success({
                                        message: (<span style={{ fontSize: 14, fontWeight: 500, }}>{state.isUpdate === true ? 'Item Updated' : 'New Item Added'}</span>),
                                        duration: 3,
                                        placement: 'topRight'
                                    });
                                    clearAll();
                                    refresh();
                                }, 800);
                            },
                            onCancel() {
                                setState(state => ({ ...state, update_loading: false }));
                            },
                        });
                    } else {
                        const storeItemResponse = await itemService.storeItem(data);
                        setTimeout(() => {
                            notification.success({
                                message: (<span style={{ fontSize: 14, fontWeight: 500, }}>{state.isUpdate === true ? 'Item Updated' : 'New Item Added'}</span>),
                                duration: 3,
                                placement: 'topRight'
                            });
                            clearAll();
                            refresh();
                        }, 800);
                    }
                } else {
                    const storeItemResponse = await itemService.storeItem(data);
                    setTimeout(() => {
                        notification.success({
                            message: (<span style={{ fontSize: 14, fontWeight: 500, }}>{state.isUpdate === true ? 'Item Updated' : 'New Item Added'}</span>),
                            duration: 3,
                            placement: 'topRight'
                        });
                        clearAll();
                        refresh();
                    }, 800);
                }
            }

        } catch (err) {
            setState(state => ({ ...state, update_loading: false }));

        }



    }

    const clearAll = () => {
        setState(state => ({
            ...state,
            isDisabled: true,
            isUpdate: null,
            item_id: null,
            update_loading: false,
        }));
        form.resetFields();
    }

    useEffect(() => {
        fetchItem();
    }, [filter?.page, filter?.search])


    return (
        <>
            <div>
                <div>
                    <div>
                        <div className='filter-panel' style={{ height: 60, marginTop: 10, marginBottom: 10 }}>
                            <div style={{ display: 'flex' }}>
                                <div style={{ minWidth: 400 }}>
                                    <InputWrapper>
                                        <Input
                                            name="searchItem"
                                            onChange={(e) => doneTyping(e.target.value)}
                                            bordered={false}
                                            prefix={<div style={{ paddingRight: 12, paddingLeft: 8 }}><SearchOutlined style={{ color: '#8c8c8c' }} /></div>}
                                            placeholder={`Search Products`}
                                            style={{ fontSize: 14 }}
                                            size='large'
                                        />
                                    </InputWrapper>
                                </div>

                                <div style={{ marginLeft: 'auto' }}>
                                    <Button type="primary" onClick={() => openDrawer(false)} style={{ borderRadius: 6 }}>
                                        Add Item
                                    </Button>

                                </div>
                            </div>

                        </div>

                        <div >
                            <Row gutter={[20, 20]}>
                                {
                                    state?.item_loading ?
                                        (
                                            <>
                                                {[...Array(12)].map((x, i) => {
                                                    return (
                                                        <Col xs={24} sm={8} lg={6} xl={4} span={4} key={i} style={{ height: '100%' }}>
                                                            <LoadingItemCard />
                                                        </Col>
                                                    )
                                                })}
                                            </>
                                        )
                                        :
                                        (
                                            <>
                                                {
                                                    state?.items?.map((item, index) => {
                                                        return (
                                                            <Col xs={24} sm={8} lg={6} xl={4} span={4} key={index} style={{ height: 'auto' }}>
                                                                <ItemCard item={item} getItem={getItem} />
                                                            </Col>
                                                        )
                                                    })
                                                }
                                            </>
                                        )
                                }

                            </Row>
                        </div>
                    </div>
                    <div style={{ padding: 24 }}>
                        <Pagination pageSize={12} onChange={(page) => setFilter(filter => ({ ...filter, page: page }))} current={filter?.page} total={state?.item_count} />
                    </div>
                </div>

                <Drawer title={state.isUpdate === true ? 'Item Detail' : 'Add Item'} onOk={() => form.submit()}
                    placement="right"
                    width={window.innerWidth > 900 ? 800 : window.innerWidth - 100}
                    onClose={onClose}
                    open={open}
                    extra={
                        <Space>
                            {
                                state.isUpdate === true && state.isDisabled === true ?
                                    (<>
                                        <Dropdown
                                            placement='bottomRight'
                                            overlayStyle={{ borderRadius: 12, minWidth: 200 }}
                                            menu={{
                                                items: [{
                                                    key: '1',
                                                    type: 'group',
                                                    label: "Action",
                                                    children: [{
                                                        key: '1-1',
                                                        label: "Update",
                                                        onClick: async () => {
                                                            form.submit()
                                                        }
                                                    }, {
                                                        key: '1-2',
                                                        label: "Delete",
                                                        onClick: async () => {
                                                            onDelete();
                                                        }
                                                    },]
                                                },]
                                            }}
                                        >
                                            <Button
                                                type='primary'
                                                className='mini-button'
                                                icon={<DownOutlined />}
                                                style={{ borderRadius: 4 }}
                                                loading={state?.update_loading}
                                            >
                                                Action
                                            </Button>
                                        </Dropdown>

                                        {/* <Button type="danger" onClick={onDelete}>
                                                Delete
                                            </Button>
                                            <Button type="primary" onClick={onEdit}>
                                                Edit
                                            </Button> */}

                                    </>)
                                    :
                                    (<>
                                        <Space>
                                            <Button onClick={offEdit} style={{ borderRadius: 6 }}>Cancel</Button>
                                            <Button loading={state?.update_loading} type="primary" onClick={() => form.submit()} style={{ borderRadius: 6 }}>
                                                OK
                                            </Button>
                                        </Space>
                                    </>)

                            }
                        </Space>
                    }>

                    <Form layout="vertical"
                        onFinish={addUpdateItem}
                        form={form}>
                        <Form.Item
                            name="is_all_company"
                            label="Add to"
                            hidden={state.isUpdate}
                        >
                            <Radio.Group defaultValue={false}>
                                <Radio.Button value={false}>Current Company</Radio.Button>
                                <Radio.Button value={true}>All Company</Radio.Button>
                            </Radio.Group>

                        </Form.Item>
                        <Form.Item
                            name="code"
                            label="Item Code"
                            rules={[{ required: true, message: 'Please enter item code' }]}
                        >
                            <Input
                                placeholder="Please enter item code"
                                // disabled={state.isDisabled} 
                                style={{ borderRadius: 6 }}
                            />

                        </Form.Item>
                        <Form.Item
                            name="name"
                            label="Item Name"
                            rules={[{ required: true, message: 'Please enter item name' }]}
                        >
                            <Input
                                placeholder="Please enter item name"
                                // disabled={state.isDisabled} 
                                style={{ borderRadius: 6 }}
                            />

                        </Form.Item>
                        <Form.Item
                            name="price"
                            label="Price"
                            rules={[{ required: true, message: 'Please enter price' }]}
                        >
                            <InputNumber
                                precision={2}
                                min={0}
                                parser={(value) => value.replace('%', '')}
                                style={{ width: '100%', borderRadius: 6 }}
                            // disabled={state.isDisabled}
                            />
                        </Form.Item>
                        <Form.Item
                            name="cost"
                            label="Cost"
                            rules={[{ required: true, message: 'Please enter cost' }]}
                        >
                            <InputNumber
                                precision={2}
                                min={0}
                                parser={(value) => value.replace('%', '')}
                                style={{ width: '100%', borderRadius: 6 }}
                            // disabled={state.isDisabled}
                            />
                        </Form.Item>
                        <Form.Item
                            name="markup"
                            label="Markup (%)"
                            rules={[{ required: true, message: 'Please enter markup' }]}
                        >
                            <InputNumber
                                precision={2}
                                min={0}
                                parser={(value) => value.replace('%', '')}
                                style={{ width: '100%', borderRadius: 6 }}
                            />
                        </Form.Item>
                        <Form.Item
                            name="category"
                            label="Category"
                        >
                            <Select
                                className='border-input'
                                showSearch
                                placeholder="Select a category"
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                options={state?.item_category}
                            // disabled={state.isDisabled}
                            />
                        </Form.Item>

                        <Form.Item
                            name="description"
                            label="Description"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter description',
                                },
                            ]}
                        >
                            <Input.TextArea
                                rows={4}
                                placeholder="Please enter description"
                                // disabled={state.isDisabled}
                                style={{ borderRadius: 6 }}
                            />
                        </Form.Item>
                    </Form>
                </Drawer>
            </div>
        </>
    );
}

export default ItemList;