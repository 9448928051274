const initialState = {
    //create stock count
    stock_items: [],
}

const createStockReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_STOCK_ITEMS':
            return { ...state, stock_items: action.stock_items }
        default:
            return state;
    }
}

export default createStockReducer;