const initialState = {
    //create purchase
    purchase_items: [],
    update_order: null,
    supplier_id: null,
    location_id: null,
    //entire detailed page
    refresh: 0,
}

const purchaseOrderReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_PURCHASE_ITEMS':
            return { ...state, purchase_items: action.purchase_items }
        case 'SET_UPDATE_ORDER':
            return { ...state, update_order: action.update_order }
        case 'SET_SUPPLIER_ID':
            return { ...state, supplier_id: action.supplier_id }
        case 'SET_LOCATION_ID':
            return { ...state, location_id: action.location_id }
        case 'REFRESH_ORDER_DETAIL':
            return { ...state, refresh: action.refresh }
        default:
            return state;
    }
}

export default purchaseOrderReducer;