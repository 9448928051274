import React from 'react';
import ContainerComponent from '../../helpers/components/container.component';
import PageHeaderComponent from '../../helpers/components/page_header.component';
import CreateAdjust from './components/create_adjust';

const CreateStockAdjust = () => {
    return (
        <>
            <ContainerComponent span={22} top={20}>
                <PageHeaderComponent />
                <CreateAdjust />
            </ContainerComponent>
        </>
    );
}

export default CreateStockAdjust;