import React from 'react';
import ContainerComponent from '../../helpers/components/container.component';
import PageHeaderComponent from '../../helpers/components/page_header.component';
import PromotionListTable from './components/promotion_list_table';

const PromotionList = () => {
    return (
        <>
            <ContainerComponent span={22} top={20}>
                <PageHeaderComponent />
                <PromotionListTable />
            </ContainerComponent>
        </>
    );
}

export default PromotionList;