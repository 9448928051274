import React from 'react';
import ContainerComponent from '../../helpers/components/container.component';
import PageHeaderComponent from '../../helpers/components/page_header.component';
import BillingDetail from './components/billing_detail';
import { useParams } from 'react-router-dom';

const BillingDetails = () => {
    const { id } = useParams();
    return (
        <>
            <ContainerComponent span={22} top={20} bottom={20}>
                <PageHeaderComponent is_back={true} />
                <BillingDetail billing_id={id} />
            </ContainerComponent>
        </>
    );
}

export default BillingDetails;