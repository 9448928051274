import React from 'react';
import TodayReport from './today_report';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, PointElement, LineElement } from 'chart.js';
import OverviewChart from './overview_chart';

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, PointElement, LineElement);
const HomeLayout = () => {
    return (
        <>
            <div>
                {/* today report */}
                <TodayReport />
            </div>

            <div style={{marginTop:60}}>
                {/* overview */}
                <OverviewChart />
            </div>
        </>
    );
}

export default HomeLayout;