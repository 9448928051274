import React from 'react';
import ContainerComponent from '../../helpers/components/container.component';
import PageHeaderComponent from '../../helpers/components/page_header.component';
import ReportSaleWrapper from './report/report_sale_wrapper';

const SaleReport = () => {
    return (
        <>
            <ContainerComponent span={22} top={20}>
                <PageHeaderComponent />
                <ReportSaleWrapper />
            </ContainerComponent>
        </>
    );
}

export default SaleReport;