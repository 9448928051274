import axios from 'axios';
import serviceHelper from '../../helpers/functions/service.helper';


async function getStock(query) {
    const queryString = serviceHelper.queryString(query);
    const stockResponse = await axios.get(`${process.env.REACT_APP_SERVER_URL}stock/get${(queryString ? ("?" + queryString) : "")}`, { withCredentials: true });
    return stockResponse?.data;
}

async function createStock(params = {}) {
    const { location_id, main_branch_id, second_branch_id, stock_items, type, description } = params;
    const stockResponse = await axios.post(`${process.env.REACT_APP_SERVER_URL}stock/store`, {
        location_id, main_branch_id, second_branch_id, stock_items, type, description
    }, { withCredentials: true });

    return stockResponse?.data;
}
async function deleteStock(stock_id) {
    const stockResponse = await axios.post(`${process.env.REACT_APP_SERVER_URL}stock/delete/${stock_id}`, {}, { withCredentials: true });
    return stockResponse?.data;
}

async function getStockCount(query) {
    const queryString = serviceHelper.queryString(query);
    const stockResponse = await axios.get(`${process.env.REACT_APP_SERVER_URL}stock/getStockCount${(queryString ? ("?" + queryString) : "")}`, { withCredentials: true });
    return stockResponse?.data;
}

async function getStockValue(query) {
    const queryString = serviceHelper.queryString(query);
    const stockResponse = await axios.get(`${process.env.REACT_APP_SERVER_URL}stock/getStockValue${(queryString ? ("?" + queryString) : "")}`, { withCredentials: true });
    return stockResponse?.data;
}

async function getFilterStockCount(query) {
    const queryString = serviceHelper.queryString(query);
    const stockResponse = await axios.get(`${process.env.REACT_APP_SERVER_URL}stock/getFilterStockCount${(queryString ? ("?" + queryString) : "")}`, { withCredentials: true });
    return stockResponse?.data;
}

async function createStockCount(params = {}) {
    const { stock_count_items, branch_id, description } = params;

    const createOrderResponse = await axios.post(`${process.env.REACT_APP_SERVER_URL}stock/storeStockCount`,
        {
            stock_count_items,
            branch_id,
            description
        },
        { withCredentials: true })

    return createOrderResponse?.data;
}

async function deleteStockCount(stock_count_id) {
    const stockResponse = await axios.post(`${process.env.REACT_APP_SERVER_URL}stock/deleteStockCount/${stock_count_id}`, {}, { withCredentials: true });
    return stockResponse?.data;
}

async function getStockReturns(query) {
    const queryString = serviceHelper.queryString(query);
    const stockResponse = await axios.get(`${process.env.REACT_APP_SERVER_URL}stock/getStockReturns${(queryString ? ("?" + queryString) : "")}`, { withCredentials: true });
    return stockResponse?.data;
}
async function getFilterStockReturns(query) {
    const queryString = serviceHelper.queryString(query);
    const stockResponse = await axios.get(`${process.env.REACT_APP_SERVER_URL}stock/getFilterStockReturns${(queryString ? ("?" + queryString) : "")}`, { withCredentials: true });
    return stockResponse?.data;
}

async function storeStockReturn(params = {}) {
    const stockResponse = await axios.post(`${process.env.REACT_APP_SERVER_URL}stock/storeStockReturn`, params, { withCredentials: true });

    return stockResponse?.data;
}
async function deleteStockReturn(data = {}) {
    const stockResponse = await axios.post(`${process.env.REACT_APP_SERVER_URL}stock/deleteStockReturn/${data?.stock_return_id}`, data, { withCredentials: true });
    return stockResponse?.data;
}

async function bulkDeleteStockReturn(params = {}) {
    const stockResponse = await axios.post(`${process.env.REACT_APP_SERVER_URL}stock/bulkDeleteStockReturn`, params, { withCredentials: true });
    return stockResponse?.data;
}

async function getStockAdjust(query) {
    const queryString = serviceHelper.queryString(query);
    const stockResponse = await axios.get(`${process.env.REACT_APP_SERVER_URL}stock/getStockAdjust${(queryString ? ("?" + queryString) : "")}`, { withCredentials: true });
    return stockResponse?.data;
}

async function createStockAdjust(params = {}) {
    const createOrderResponse = await axios.post(`${process.env.REACT_APP_SERVER_URL}stock/storeStockAdjust`, params, { withCredentials: true })

    return createOrderResponse?.data;
}

async function deleteStockAdjust(stock_adjust_id) {
    const stockResponse = await axios.post(`${process.env.REACT_APP_SERVER_URL}stock/deleteStockAdjust/${stock_adjust_id}`, {}, { withCredentials: true });
    return stockResponse?.data;
}


export default {
    getStock,
    createStock,
    deleteStock,
    getStockCount,
    createStockCount,
    deleteStockCount,
    getStockReturns,
    storeStockReturn,
    deleteStockReturn,
    getStockAdjust,
    createStockAdjust,
    deleteStockAdjust,
    // bulkDeleteStockReturn,
    getFilterStockReturns,
    getFilterStockCount,
    getStockValue,
}