import './App.less';
import { BrowserRouter as Router } from 'react-router-dom';
import AuthRouter from './routes/auth.router';
import Auth from './Auth';
import moment from 'moment-timezone';

// Set the default timezone
moment.tz.setDefault('Asia/Kuala_Lumpur');

function App() {
  return (
    <div className="App">
      <Router>
        <Auth />
        {/* <AuthRouter /> */}
      </Router>
    </div>
  );
}

export default App;
