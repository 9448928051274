import React from 'react';
import { useParams } from 'react-router-dom';
import ContainerComponent from '../../helpers/components/container.component';
import PageHeaderComponent from '../../helpers/components/page_header.component';
import CustomerDetailInfo from './components/customer_detail_info';

const CustomerDetail = () => {
    const { id } = useParams();
    return (
        <>
            <ContainerComponent span={22}>
                <PageHeaderComponent is_back />
                <CustomerDetailInfo customer_id={id} edit />
            </ContainerComponent>
        </>
    );
}

export default CustomerDetail;