import axios from 'axios';
import serviceHelper from '../../helpers/functions/service.helper';

async function getAuth(){
    const authUserResponse = await axios.get(`${process.env.REACT_APP_SERVER_URL}authentication/user/auth`, { withCredentials : true });
    return authUserResponse?.data;
}

async function login(username, password){
    const loginResponse = await axios.post(`${process.env.REACT_APP_SERVER_URL}authentication/user/login`, {
        username,
        password
    }, {
        withCredentials : true,
    })

    return loginResponse?.data;
}

async function logout(){
    const logoutResponse = await axios.post(`${process.env.REACT_APP_SERVER_URL}authentication/user/logout`, {}, {withCredentials : true});
    return logoutResponse?.data;
}

async function register(){

}

async function validateUser(username, password, is_admin){
    const validateUserResponse = await axios.post(`${process.env.REACT_APP_SERVER_URL}authentication/user/validate_auth`, {
        username,
        password,
        is_admin
    }, {
        withCredentials : true,
    })

    return validateUserResponse?.data;
}

async function changePassword(password){
    const changePasswordResponse = await axios.post(`${process.env.REACT_APP_SERVER_URL}authentication/user/change_password`, { password : password,}, {
        withCredentials : true,
    })

    return changePasswordResponse?.data;
}

export default {
    getAuth,
    login,
    logout,
    register,
    validateUser,
    changePassword,
}