import { Button } from 'antd';
import React, { useEffect, useState } from 'react';
// import ReactToPrint from 'react-to-print';
import { PrinterOutlined } from '@ant-design/icons';
import { SaleInvoice } from './sale_invoice';
import checkoutService from '../../../services/sales/checkout.service';
import moment from 'moment';
import { generateReceipt } from '../functions/receipt';

const SaleInvoiceWrapper = ({ sale_id, componentRef }) => {
    const [state, setState] = useState({
        sale: null,
    });

    const fetchSale = async () => {
        if (sale_id) {
            const salesResponse = await checkoutService.getSaleById(sale_id);

            setState(state => ({ ...state, sale: salesResponse?.sales }));
        }
    }

    const printReceipt = async () => {
        var printWindow = window.open('', 'PrintWindow');
        const { branch, sale_items, creator, running_number, created_at, total_amount, warranty_duration, warranty_type } = state?.sale ?? {};
        const receipt_string = await generateReceipt({
            company: branch?.company,
            branch: branch,
            items: sale_items,
            cashier: creator?.username,
            running_number: running_number,
            total_amount: total_amount,
            warranty_type: warranty_type,
            warranty_duration: warranty_duration,
            date: moment(created_at).format("D MMM YYYY, h:mm:ss a"),
        })

        printWindow.document.write(receipt_string);
        printWindow.print();
        printWindow.close();
    }

    useEffect(() => {
        fetchSale();
    }, [sale_id])

    return (
        <>
            {/* <div style={{display:'none'}}>
            <ReactToPrint
                pageStyle={`@page {
                    size: 80mm 250mm;
                }`}
                trigger={() => {
                    return <Button
                        type='default' style={{ borderRadius: 4, background: '#f5f5f5', border: 'none', }}
                        id="printInvoice"
                        icon={<PrinterOutlined />}
                    >
                        Print Invoice
                    </Button>;
                }}
                content={() => componentRef}
            />

            
                <SaleInvoice ref={el => (componentRef = el)} componentRef={componentRef} sale={state?.sale} />
            </div> */}

            <Button
                type='default' style={{ borderRadius: 4, background: '#f5f5f5', border: 'none', display: 'none' }}
                id="printInvoice"
                icon={<PrinterOutlined />}
                onClick={printReceipt}
            >
                Print Invoice
            </Button>

        </>
    );
}

export default SaleInvoiceWrapper;