import { Col, Form, Image, Input, Row, Select, InputNumber } from 'antd';
import React, { useState, useEffect } from 'react';
import checkoutService from '../../../services/sales/checkout.service';

const { TextArea } = Input;
const { Option } = Select;
const SalePaymentAdd = ({ form }) => {

    const [state, setState] = useState({
        payment_methods: [],
    });

    const fetchPaymentMethod = async () => {
        const paymentMethodResponse = await checkoutService.getPaymentMethod();
        const payment_methods = paymentMethodResponse?.payment_methods;

        setState(state => ({ ...state, payment_methods: payment_methods }));
    }

    useEffect(() => {
        fetchPaymentMethod();
    }, [])

    return (
        <>
            <Form.Item name='payment_method_id' label="Payment Method" shouldUpdate rules={[{ required: true, message: 'Please select method' }]}>
                <Select
                    placeholder={state?.payment_methods?.length > 0 && (state?.payment_methods[0].name)}
                    filterOption={false}
                    className='border-input'
                    style={{ borderRadius: 6 }}
                >
                    {
                        state?.payment_methods?.map((payment_method, index) => {
                            return (
                                <Option value={payment_method?.payment_method_id} key={index}>
                                    <span>{payment_method?.name}</span>
                                </Option>
                            )
                        })
                    }
                </Select>
            </Form.Item>
            <Form.Item name='referral_code' label="Referral Code">
                <Input placeholder='pm_1KWpZ2BkZjV0LB5EvWEirY0w' style={{ borderRadius: 6 }} />
            </Form.Item>
            <Form.Item name='amount' label="Amount" rules={[{ required: true, message: 'Please insert amount' }]}>
                <InputNumber placeholder='49.99' precision={2} style={{ borderRadius: 6, width: '100%' }} />
            </Form.Item>
            <Form.Item name='remarks' label="Descriptions">
                <TextArea placeholder='Write your descriptions here' style={{ borderRadius: 6 }} rows={4} className='border-input' allowClear />
            </Form.Item>
        </>
    );
}

export default SalePaymentAdd;