import axios from 'axios';
import serviceHelper from '../../helpers/functions/service.helper';

async function getBranch(query) {
    const queryString = serviceHelper.queryString(query);
    const branchResponse = await axios.get(`${process.env.REACT_APP_SERVER_URL}config/branch/get${(queryString ? ("?" + queryString) : "")}`, { withCredentials: true });
    return branchResponse?.data;
}

async function getToBranch(query) {
    const queryString = serviceHelper.queryString(query);
    const branchResponse = await axios.get(`${process.env.REACT_APP_SERVER_URL}config/branch/to/get${(queryString ? ("?" + queryString) : "")}`, { withCredentials: true });
    return branchResponse?.data;
}

async function getItem(query) {
    const queryString = serviceHelper.queryString(query);
    const branchResponse = await axios.get(`${process.env.REACT_APP_SERVER_URL}config/branch/getItem${(queryString ? ("?" + queryString) : "")}`, { withCredentials: true });
    return branchResponse?.data;
}


export default {
    getBranch,
    getToBranch,
    getItem,
}