import { Badge, Button, Card, Space, Tag, InputNumber, Input } from 'antd';
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { set_stock_adjust_items } from '../../../actions/create_stock_adjust.action';

const ItemCard = ({ item }) => {
    const create_stock_adjust_redux = useSelector(state => state.create_stock_adjust);
    const dispatch = useDispatch();

    const adjustedQuantity = (item) => {
        let stock_adjust_items = (create_stock_adjust_redux.stock_adjust_items);
        let existing_item = stock_adjust_items?.find(x => x.item_id === item?.item_id);
        if (existing_item) {
            return existing_item?.quantity;
        } else {
            return null;
        }
    }

    const updateNewStockAdjust = (value) => {
        const regex = /^-?\d*\.?\d*$/; // Regex pattern to allow only numbers and negative numbers

        let stock_adjust_items = (create_stock_adjust_redux.stock_adjust_items);
        if (value && regex.test(value)) {

            let existing_item = stock_adjust_items?.find(x => x.item_id === item?.item_id);
            if (existing_item) {
                const calculated_quantity = value;
                existing_item.quantity = calculated_quantity;
                let changed_quantity = stock_adjust_items;
                if (calculated_quantity) {
                    changed_quantity = stock_adjust_items?.map((x) => {
                        return (x?.item_id === item?.item_id) ? existing_item : x;
                    })
                } else {
                    changed_quantity = stock_adjust_items?.filter((x) => {
                        return (x?.item_id != item?.item_id);
                    })
                }

                dispatch(set_stock_adjust_items(changed_quantity));
            } else {
                dispatch(
                    set_stock_adjust_items([
                        ...stock_adjust_items,
                        {
                            item_id: item?.item_id,
                            item_code: item?.item?.item_code,
                            name: item?.item?.name,
                            stock_quantity: item?.quantity,
                            unit_cost: item?.unit_cost ?? 0,
                            price: null,
                            quantity: value,
                        }
                    ])
                );

            }

        } else {
            const changed_quantity = stock_adjust_items?.filter((x) => {
                return (x?.item_id !== item?.item_id);
            })

            dispatch(set_stock_adjust_items(changed_quantity));
        }

    }

    const adjustedPrice = (item) => {
        let stock_adjust_items = (create_stock_adjust_redux.stock_adjust_items);
        let existing_item = stock_adjust_items?.find(x => x.item_id === item?.item_id);
        if (existing_item) {
            return existing_item?.price;
        } else {
            return null;
        }
    }

    const updateNewPrice = (value) => {
        const regex = /^-?\d*\.?\d*$/; // Regex pattern to allow only numbers and negative numbers

        let stock_adjust_items = (create_stock_adjust_redux.stock_adjust_items);

        let existing_item = stock_adjust_items?.find(x => x.item_id === item?.item_id);
        if (existing_item && value && regex.test(value)) {
            const calculated_quantity = value;
            existing_item.price = calculated_quantity;
            let changed_quantity = stock_adjust_items;
            if (calculated_quantity) {
                changed_quantity = stock_adjust_items?.map((x) => {
                    return (x?.item_id === item?.item_id) ? existing_item : x;
                })
            } else {
                changed_quantity = stock_adjust_items?.filter((x) => {
                    return (x?.item_id != item?.item_id);
                })
            }

            dispatch(set_stock_adjust_items(changed_quantity));
        } else {
            let changed_quantity = stock_adjust_items;
            const calculated_quantity = null;
            existing_item.price = calculated_quantity;
            changed_quantity = stock_adjust_items?.map((x) => {
                return (x?.item_id === item?.item_id) ? existing_item : x;
            })
            dispatch(set_stock_adjust_items(changed_quantity));

        }

    }

    return (
        <>
            <Card

                bordered={false}
                style={{ borderRadius: 6, height: '100%' }}
            >
                <div>
                    <div style={{ textAlign: 'left' }}>
                        <div>
                            <span style={{ fontWeight: 500, color: '#8c8c8c', fontSize: 12 }}>{item?.item?.item_code}</span>
                        </div>
                        <div>
                            <span style={{ fontWeight: 500, color: '#595959' }}>{item?.item?.name}</span>
                        </div>
                        <div>
                            <span style={{ color: '#8c8c8c' }}>{`MYR ${item?.unit_cost ?? 0}`}</span>
                        </div>
                        <div style={{ marginTop: 8 }}>
                            <Tag color='geekblue' style={{ border: 'none', borderRadius: 6 }}>{`Quantity : ${item?.quantity ?? 0}`}</Tag>
                        </div>
                        <div style={{ marginTop: 5 }}>
                            <Input min={0} value={adjustedQuantity(item)} style={{ width: '100%' }} onChange={(e) => updateNewStockAdjust(e.target.value)} placeholder="Adjusted Quantity" />
                        </div>
                        <div style={{ marginTop: 5 }}>
                            <Input min={0} value={adjustedPrice(item)} style={{ width: '100%' }} onChange={(e) => updateNewPrice(e.target.value)} placeholder="Adjusted Unit Price" />
                        </div>
                    </div>
                </div>
            </Card>
        </>
    );
}

export default ItemCard;
