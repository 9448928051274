import { Divider, Form, Input, Select } from 'antd';
import React, { useState, useEffect}  from 'react';
import checkoutService from '../../../services/sales/checkout.service';

const SaleEditDetail = ({sale}) => {
    const [state, setState] = useState({
        payment_methods : []
    });

    const fetchPaymentMethod = async () => {
        const paymentMethodResponse = await checkoutService.getPaymentMethod();
        const payment_methods = (paymentMethodResponse?.payment_methods?.map(x => {
            return {
                value : x?.payment_method_id,
                label : x?.name
            }
        }));

        setState(state => ({...state, payment_methods : payment_methods}));
    }

    useEffect(() => {
        fetchPaymentMethod();
    }, []);


    return (
        <>
            <div className='cust-info'>
                <div>
                    <span style={{fontWeight:500, fontSize:16}}>Customer information</span>
                </div>
                <div style={{marginTop:24}}>
                    <Form.Item name='name' label="Name">
                        <Input placeholder='John Doe' style={{borderRadius:6}}/>
                    </Form.Item>
                    <Form.Item name='phoneNo' label="Phone Number">
                        <Input placeholder='123456789' prefix={<span>+60</span>} style={{borderRadius:6}}/>
                    </Form.Item>
                </div>
            </div>
            <Divider />
            <div className='sale-info'>
                <div>
                    <span style={{fontWeight:500, fontSize:16}}>Sales information</span>
                </div>
                <div style={{marginTop:24}}>
                    <Form.Item name='paymentMethod' label="Payment Method">
                        <Select
                        className='border-input'
                        style={{borderRadius:6}}
                        options={state?.payment_methods}
                        />
                    </Form.Item>
                </div>
            </div>
        </>
    );
}

export default SaleEditDetail;