import { ExportOutlined, FilterOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Divider, Input, Space, Spin, Switch, Table, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import InputWrapper from '../../../../helpers/components/input_wrapper.component';
import stockReportService from '../../../../services/stock/report.service';
import stockService from '../../../../services/stock/stock.service';

const StockOverallReport = () => {
    const [timer, setTimer] = useState(null);
    const [state, setState] = useState({
        loading: false,
        stock_items: [],
        page: 1,
        count: 0,
        stock_value: 0,
    });
    const [filter, setFilter] = useState({
        search: null,
        is_negative: null,
    })

    const fetchStockOverallReport = async () => {
        setState(state => ({ ...state, loading: true }));
        const reportResponse = await stockReportService.getOverallStockReport({
            search: filter?.search,
            size: 10,
            page: state?.page - 1,
            ...(filter?.is_negative && ({ is_negative: filter?.is_negative }))
        });

        setTimeout(() => {
            setState(state => ({ ...state, loading: false, stock_items: reportResponse?.items?.rows, count: reportResponse?.items?.count, }));
        }, 800);
    }
    const getStockValue = async () => {
        setState(state => ({ ...state, loading: true }));
        const reportResponse = await stockService.getStockValue();
        console.log(reportResponse?.stock?.total)

        setTimeout(() => {
            setState(state => ({ ...state, loading: false, stock_value: reportResponse?.stock?.total }));
        }, 800);
    }

    const doneTyping = async (input) => {
        clearTimeout(timer);
        // dispatch(set_list_page(0));
        const newTimer = setTimeout(() => {
            setFilter(filter => ({ ...filter, search: input }));
        }, 300);
        setTimer(newTimer);
    }

    useEffect(() => {
        fetchStockOverallReport();
    }, [filter, state?.page])

    useEffect(() => {
        getStockValue();
    }, [])

    return (
        <>
            <div>
                <div className='filterPanel' style={{ display: 'flex', padding: '0 24px' }}>
                    <Space>
                        <div>
                            <InputWrapper>
                                <Input
                                    onChange={(e) => doneTyping(e.target.value)}
                                    bordered={false}
                                    prefix={<div style={{ paddingRight: 12, paddingLeft: 8 }}><SearchOutlined style={{ color: '#8c8c8c' }} /></div>}
                                    placeholder={`Search Items`}
                                    style={{ fontSize: 14, background: '#f5f5f5', minWidth: 300 }}
                                    size='large'
                                    allowClear
                                />
                            </InputWrapper>
                        </div>
                        <div>
                            <Space>
                                <div>
                                    <Switch checkedChildren={'Show Negative'} unCheckedChildren={'Show All'} onChange={(checked) => {
                                        setFilter(filter => ({ ...filter, is_negative: checked }));
                                    }} />
                                </div>
                            </Space>

                            {/* <Button
                            icon={<FilterOutlined style={{color:'#8c8c8c'}} />}
                            onClick={() => {
                                setState(state => ({...state, filter_modal : true}));
                            }}
                            style={{borderRadius:6}}
                            /> */}
                        </div>
                    </Space>
                    <div style={{ marginLeft: 'auto' }}>
                        <Button
                            type='primary'
                            icon={<ExportOutlined />}
                            style={{ borderRadius: 6 }}
                        >
                            Export CSV
                        </Button>
                    </div>
                </div>

                <Divider />
                <div style={{ padding: '0 24px' }}>
                    <div style={{ textAlign: 'left' }}>Total Stock Value<br /><b>RM {state?.stock_value}</b></div>
                    <Spin spinning={state?.loading}>
                        <Table
                            pagination={{
                                pageSize: 10,
                                showSizeChanger: false,
                                total: state?.count,
                                current: state?.page,
                                onChange: (page) => setState(state => ({ ...state, page: page }))
                            }}
                            dataSource={state?.stock_items}
                            className='hub-table'
                            columns={[{
                                title: 'Name',
                                dataIndex: 'name',
                                key: 'name',
                                width: '60%',
                                render: (text, row, index) => {
                                    return (
                                        <>
                                            <div>
                                                <div>
                                                    <span>{text}</span>
                                                </div>
                                                <div>
                                                    <span style={{ color: '#8c8c8c' }}>{row?.item_code}</span>
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                            }, {
                                title: 'Unit Price (RM)',
                                dataIndex: 'unit_price',
                                key: 'unit_price',
                                width: '20%',
                                render: (text, row, index) => {
                                    return (
                                        <>
                                            <span>{row?.branch_item?.unit_cost ?? 0}</span>
                                        </>
                                    )
                                }
                            }, {
                                title: 'Quantity',
                                dataIndex: 'quantity',
                                key: 'quantity',
                                width: '20%',
                                render: (text, row, index) => {
                                    const { branch_item } = row ?? {};
                                    return (
                                        <>
                                            <Tag color={branch_item?.quantity > 3 ? 'green' : 'red'} style={{ border: 'none', borderRadius: 4 }}>
                                                {branch_item?.quantity ?? 0}
                                            </Tag>
                                        </>
                                    )
                                }
                            }, {
                                title: 'Actual Quantity',
                                dataIndex: 'stock_count',
                                key: 'stock_count',
                                width: '20%',
                            }]}
                        />
                    </Spin>
                </div>
            </div>
        </>
    );
}

export default StockOverallReport;