import axios from 'axios';
import serviceHelper from '../../helpers/functions/service.helper';

async function getCreditNotes(query) {
    const queryString = serviceHelper.queryString(query);
    const creditNotesResponse = await axios.get(`${process.env.REACT_APP_SERVER_URL}purchaseorder/creditnote/get${(queryString ? ("?" + queryString) : "")}`, { withCredentials: true });
    return creditNotesResponse?.data;
}

async function storeCreditNotes(params = {}) {
    const creditNotesResponse = await axios.post(`${process.env.REACT_APP_SERVER_URL}purchaseorder/creditnote/store`, params, { withCredentials: true });
    return creditNotesResponse?.data;
}

async function updateCreditNotes(order_credit_note_id, params = {}) {
    const creditNotesResponse = await axios.post(`${process.env.REACT_APP_SERVER_URL}purchaseorder/creditnote/update/${order_credit_note_id}`, params, { withCredentials: true });
    return creditNotesResponse?.data;
}

async function deleteCreditNotes(order_credit_note_id) {
    const creditNotesResponse = await axios.delete(`${process.env.REACT_APP_SERVER_URL}purchaseorder/creditnote/delete/${order_credit_note_id}`, { withCredentials: true });
    return creditNotesResponse?.data;
}

export default {
    getCreditNotes,
    storeCreditNotes,
    updateCreditNotes,
    deleteCreditNotes
}