import { Col, Form, Input, Row, Select, Spin, InputNumber } from 'antd';
import React, { useEffect, useState } from 'react';
import locationService from '../../../services/config/location.service';
import { useDispatch, useSelector } from 'react-redux';
// import { set_selected_items } from '../../../actions/billing.action';

const { Option } = Select;
const BillingItemAdd = ({ form, location_id, edit_modal, billing }) => {
    // const billing_redux = useSelector(state => state.billing);
    const dispatch = useDispatch();
    const [timer, setTimer] = useState(null)
    const [state, setState] = useState({
        loading: true,
        items: [],
        input: null,
        selected_items: null,
        item_id: null,
    });

    const fetchItemOnSearch = async () => {
        setState(state => ({ ...state, loading: true }));
        let itemResponse;

        itemResponse = await locationService.getLocationBranchItem({
            location_id: location_id,
            search: state?.input,
            branchSupplier: billing?.branch_id != null ? 1 : 2,
            branch_id: billing?.branch_id ?? billing?.supplier_id
        });
        const items = itemResponse?.location_item?.rows?.map(x => {
            return {
                value: x.item.item_id,
                label: x.item.name,
                item_code: x.item.item_code,
                price: x.cost,
                markup: x.item.markup,
            }
        })
        setState(state => ({ ...state, items: items, loading: false }));
    }

    const doneTyping = (input) => {
        setTimeout(() => {
            setState(state => ({ ...state, input: input }));
        }, 500);
    }

    const onDropdownSelected = (item_id) => {
        const items = state?.items?.find(x => x.value === item_id);
        form.setFieldsValue({ item_id: item_id, cost: items?.price, price: (items?.price * (1 + ((items?.markup) / 100))).toFixed(2), quantity: '1', markup: items?.markup });

        setState(state => ({ ...state, selected_items: item_id }));
    }

    const changePrice = (value) => {
        const { cost } = form.getFieldsValue();
        form.setFieldsValue({ price: (cost * (1 + ((value) / 100))).toFixed(2) });
    }

    //when editing price
    const changePrice2 = (value) => {
        const { markup } = form.getFieldsValue();
        form.setFieldsValue({ price: (value * (1 + ((markup) / 100))).toFixed(2) });
    }

    useEffect(() => {
        fetchItemOnSearch();
    }, [state?.input])


    return (
        <>
            <Form.Item name='itemId' label="Item" rules={[{ required: true, message: 'Please select an item' }]} shouldUpdate>
                <Select
                    disabled={edit_modal ? true : false}
                    filterOption={false}
                    showSearch
                    className='border-input'
                    style={{ borderRadius: 6 }}
                    onSearch={doneTyping}
                    onChange={onDropdownSelected}
                >
                    {
                        state?.items?.map((item, index) => {
                            return (
                                <Option value={item?.value} key={index}>
                                    <div>
                                        <div><span>{item?.label}</span></div>
                                        <div><span style={{ fontSize: 12, color: '#8c8c8c' }}>{item?.item_code}</span></div>
                                    </div>
                                </Option>
                            )
                        })
                    }
                </Select>
            </Form.Item>
            <Form.Item name='cost' label="Before Markup Price" rules={[{ required: true, message: 'Please enter a price' }]}>
                <InputNumber type='number' style={{ width: '100%', borderRadius: 6 }} onChange={(e) => changePrice2(e)} />
            </Form.Item>
            <Form.Item name='price' label="After Markup Price">
                <InputNumber type='number' style={{ width: '100%', borderRadius: 6 }} disabled />
            </Form.Item>
            <Form.Item name='quantity' label="Quantity" rules={[{ required: true, message: 'Please enter a quantity' }]}>
                <InputNumber type='number' placeholder='1' min={1} style={{ width: '100%', borderRadius: 6 }} />
            </Form.Item>
            <Form.Item name='markup' label="Markup (%)" rules={[{ required: true, message: 'Please enter markup percent' }]}>
                <InputNumber type='number' min={0} style={{ width: '100%', borderRadius: 6 }} addonAfter="%" onChange={(e) => changePrice(e)} />
            </Form.Item>

        </>
    );
}

export default BillingItemAdd;