import axios from 'axios';
import serviceHelper from '../../helpers/functions/service.helper';


async function getPendingTransfer(query) {
    const queryString = serviceHelper.queryString(query);
    const pendingTransferResponse = await axios.get(`${process.env.REACT_APP_SERVER_URL}stock/request/transfer${(queryString ? ("?" + queryString) : "")}`, { withCredentials: true });
    return pendingTransferResponse?.data;
}

async function updatePendingTransfer(stock_id, params){
    const pendingTransferResponse = await axios.post(`${process.env.REACT_APP_SERVER_URL}stock/request/transfer/update/${stock_id}`, params, { withCredentials : true});
    return pendingTransferResponse?.data;
}

export default {
    getPendingTransfer,
    updatePendingTransfer,
}