const initialState = {
    //create stock count
    stock_adjust_items: [],
}

const CreateStockAdjustReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_STOCK_ADJUST_ITEMS':
            return { ...state, stock_adjust_items: action.stock_adjust_items }
        default:
            return state;
    }
}

export default CreateStockAdjustReducer;