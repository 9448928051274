import { Card, List, Spin } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import { Link } from 'react-router-dom';
import chartService from '../../../services/sales/chart.service';

const PopularProduct = () => {

    const { innerWidth } = window;
    const [state, setState] = useState({
        loading : false,
        chart_date : null,
        items : [],
    })

    const options = {
        plugins: {
            legend: {
                display: false
            }
        }
    }

    const fetchPopularProduct = async () => {
        setState(state => ({...state, loading : true}));

        const start_date = moment()?.subtract(7, 'days')?.format('YYYY-MM-DD');
        const end_date = moment()?.format('YYYY-MM-DD');
        
        const popularProductResponse = await chartService.getPopularProduct({
            start_date : start_date,
            end_date : end_date,
            limit : 5,
            type : 'sales_amount'
        });

        const popular_products = popularProductResponse?.popular_items;
        const data = {
        labels: popular_products?.map(x => x.item?.name),
        datasets: [
            {
            label: '(MYR)',
            data: popular_products?.map(x => x.sum_amount),
            backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(255, 206, 86, 0.2)',
                'rgba(75, 192, 192, 0.2)',
                'rgba(153, 102, 255, 0.2)',
                'rgba(255, 159, 64, 0.2)',
            ],
            borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(153, 102, 255, 1)',
                'rgba(255, 159, 64, 1)',
            ],
            borderWidth: 1,
            },
        ],
    }

        setTimeout(() => {
            setState(state => ({...state, loading : false, chart_date : data, items : popular_products,}))
        }, 500);
    }

    useEffect(() => {
        fetchPopularProduct();
    }, []);

    return (
        <>
            <Card
            style={{ borderRadius: 6, height:'100%' }}
            bordered={false}
            >
                <Spin spinning={state?.loading}>
                    <div>
                        <div style={{textAlign:'left'}}>
                            <span style={{fontWeight: 500, color:'#414552', fontSize:14}}>Popular Product By Sales Amount</span>
                        </div>
                    </div>

                    <div style={{padding : 24, display:'flex', justifyContent :'center', alignContent:'center',}}>
                        <div style={{height: (innerWidth <= 768 ? 180 : 150)}}>
                            <Pie
                            data={state?.chart_date ?? {
                                labels : [],
                                datasets : [],
                            }}
                            options={options}
                            />
                        </div>
                    </div>
                    
                    <div style={{marginTop:24}}>
                        <List
                        dataSource={state?.items}
                        // size={'large'}
                        renderItem={(item, index) => {
                            return (
                                <List.Item key={index} style={{padding:'12px 0'}}>
                                    <List.Item.Meta
                                    style={{textAlign:'left'}}
                                    description={
                                        <>
                                            <span style={{fontWeight:500}}>
                                                {item?.item?.name}
                                            </span>
                                        </>
                                    }
                                    />
                                    <div>
                                        <span>
                                            {`MYR ${item?.sum_amount?.toFixed(2)}`}
                                        </span>
                                    </div>
                                </List.Item>
                            )
                        }}
                        />
                    </div>

                    {/* <div style={{marginTop:24, textAlign:'left'}}>
                        <Link to="sales/list?sale_status_id=2">
                            <span className='mini-button mini-style-text'>
                                View All Popular Products
                            </span>
                        </Link>
                    </div> */}
                </Spin>
            </Card>
        </>
    );
}

export default PopularProduct;