import axios from 'axios';
import serviceHelper from '../../helpers/functions/service.helper';

async function exportSalesBilling(query) {
    const queryString = serviceHelper.queryString(query);
    const exportSalesBillingResponse = await axios.get(`${process.env.REACT_APP_SERVER_URL}exportexcel/exportSalesBilling${(queryString ? ("?" + queryString) : "")}`, { withCredentials: true });
    return exportSalesBillingResponse?.data;
}

async function exportBranchStockBalance(query) {
    const queryString = serviceHelper.queryString(query);
    const exportBranchStockBalanceResponse = await axios.get(`${process.env.REACT_APP_SERVER_URL}exportexcel/exportBranchStockBalance${(queryString ? ("?" + queryString) : "")}`, { withCredentials: true });
    return exportBranchStockBalanceResponse?.data;
}

async function exportPurchaseOrder(query) {
    const queryString = serviceHelper.queryString(query);
    const exportPurchaseOrderResponse = await axios.get(`${process.env.REACT_APP_SERVER_URL}exportexcel/exportPurchaseOrder${(queryString ? ("?" + queryString) : "")}`, { withCredentials: true });
    return exportPurchaseOrderResponse?.data;
}

export default {
    exportSalesBilling,
    exportBranchStockBalance,
    exportPurchaseOrder,
}