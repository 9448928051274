import { Divider, Table, Tag } from 'antd';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';

const OverallSaleReportTable = ({sales}) => {
    return (
        <>
            
            <Table
            className='hub-table'
            dataSource={sales}
            columns={[{
                title : 'Transaction No',
                dataIndex : 'running_number',
                key : 'running_number',
                width : '40%',
                render : (text, row, index) => {
                    return (
                        <>
                            <div>
                                <Link to={`/sales/order/${row?.sale_id}`} target="_blank">
                                    <span>
                                        {text}
                                    </span>
                                </Link>
                            </div>
                        </>
                    )
                }
            },{
                title : 'Issue Date',
                dataIndex : 'created_at',
                key : 'created_at',
                width : '40%',
                sorter: (a, b) => moment(a.created_at) - moment(b.created_at),
                render : (text, row, index) => {
                    return (
                        <>
                            <div>
                                <span>{moment(text).format("D MMM YYYY, h:mm:ss a")}</span>
                            </div>
                        </>
                    )
                }
            },{
                title : 'Amount',
                dataIndex : 'total_amount',
                key : 'total_amount',
                sorter: (a, b) => a.total_amount - b.total_amount,
                render : (text, row, index) => {
                    return (
                        <>
                            <div>
                                <span style={{fontWeight:'bold'}}>{`RM${text}`}</span>
                            </div>
                        </>
                    )
                }
            }]}
            />
        </>
    );
}

export default OverallSaleReportTable;