
import moment from 'moment';

export const generateCN = async (item) => {
    // const { running_number, from_location, from_id, from_branch_id, from_branch, from_supplier_id, from_supplier, to_id, to_supplier, to_location, item, quantity, unit_price, created_at } = items;
    const { running_number, sale, amount, return_item, created_at, return_item_unit_price, return_quantity } = item;
    
    return `
    <body>
    <div style="page-break-after: always;">
        <table style="width:100%;">
            <thead class="report-header">
                <tr>
                    <td style="background-color:white;">
                        <div class="divHeader" style="padding-bottom:0px;">
                            <table style="width:100%;">
                                <tbody>
                                    <tr>
                                        <td colspan="2" style="text-align:center;padding-bottom:10px;">
                                            <b>${sale?.branch?.company?.name}</b><span style="font-size:9pt;"> (${sale?.branch?.description})</span> 
                                            <div style="font-size:9pt;">
                                                <div>
                                                    ${sale?.branch?.address}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="2" style="border-top:2px solid black; text-align:center; padding-top:5px;">
                                           <h4><b>SALES CREDIT NOTE</b></h4>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td >
                                            <table style="width:100%;text-align:left">
                                                <tr>
                                                    <td ><b>RETURN FROM :</b></td>
                                                    <td style="width:auto;text-align:right">${sale?.branch?.name}</td>
                                                </tr>
                                                <tr>
                                                    <td><b>RETURN TO :</b></td>
                                                    <td style="width:auto;text-align:right">${sale?.customer?.name}(${sale?.customer?.phone_no})</td>
                                                </tr>
                                            </table>   
                                        </td>
                                        <td >
                                            <table style="width:100%;text-align:right">
                                                <tr>
                                                    <td ><b>CREDIT NOTE NO :</b></td>
                                                    <td>${running_number}</td>
                                                </tr>
                                                <tr>
                                                    <td><b>CREDIT NOTE DATE :</b></td>
                                                    <td>${moment(created_at).format("DD/MM/YYYY")}</td>
                                                </tr>
                                            </table>                                            
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="2" style="border-bottom:1px solid black; text-align:center; padding-top:5px;">
                                           
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </td>
                </tr>
            </thead>
            <hr/>
            <tbody>
                <tr>
                    <td>
                        <div>
                            <table style="width:100%;" border="0" cellpadding="0" cellspacing="0">
                                <tbody>
                                    <tr>
                                        <td class="details" style="width:5%;padding:3px;text-align:center;font-weight:bold;border-width:1px 0px;border-style:solid;border-color:gray;">&nbsp;</td>
                                        <td class="details" style="width:30%;padding:3px;text-align:left;font-weight:bold;border-width:1px 0px;border-style:solid;border-color:gray;">Item</td>
                                        <td class="details" style="width:15%;padding:3px;text-align:right;font-weight:bold;border-width:1px 0px;border-style:solid;border-color:gray;">Qty</td>
                                        <td class="details" style="width:25%;padding:3px;text-align:right;font-weight:bold;border-width:1px 0px;border-style:solid;border-color:gray;">Price (RM)</td>
                                        <td class="details" style="width:25%;padding:3px;text-align:right;font-weight:bold;border-width:1px 0px;border-style:solid;border-color:gray;">Total (RM)</td>        
                                    </tr>

                                    <tr valign="top" style="page-break-inside:avoid;">
                                        <td class="details" style="padding:3px;text-align:left;">1.</td>
                                        <td class="details" style="padding:3px;"><b>${return_item?.item_code}</b><br>${return_item?.name}</td>
                                        <td class="details" style="padding:3px;text-align:right;">${return_quantity}</td>
                                        <td class="details" style="padding:3px;text-align:right;">${(return_item_unit_price * 1).toFixed(2)}</td>
                                        <td class="details" style="padding:3px;text-align:right;">${((return_item_unit_price) * return_quantity)?.toFixed(2)}</td>
                                    </tr>  
                                   
                                    <tr>
                                        <td class="details" colspan="4" style="padding:3px;text-align:right;border-top:1px solid gray;"><b>TOTAL</b></td>
                                        <td class="details" style="padding:3px;text-align:right;border-top:1px solid gray;">${((return_item_unit_price * return_quantity) * 1).toFixed(2)}</td>
                                    </tr>
                                    
                                </tbody>
                            </table>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    </body>`;
}