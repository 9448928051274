import { Table } from 'antd';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';

const OverallCreditNoteTable = ({credit_notes}) => {
    return (
        <>
            <Table
            className='hub-table'
            dataSource={credit_notes}
            columns={[{
                title: 'Credit Note',
                dataIndex: 'running_number',
                key: 'running_number',
                width : '25%',
            },{
                title: 'Sales',
                dataIndex: 'sale',
                key: 'sale',
                width : '25%',
                render : (text, row, index) => {
                    return (
                        <>
                            <div>
                                <Link to={`/sales/order/${row?.sale_id}`} target="_blank">
                                    <span>{text?.running_number}</span>
                                </Link>
                            </div>
                        </>
                    )
                }
            },{
                title: 'Issue Date',
                dataIndex: 'created_at',
                key: 'created_at',
                width : '30%',
                sorter: (a, b) => moment(a.created_at) - moment(b.created_at),
                render: (text, row, index) => {
                    return (
                        <>
                            <div>
                                <span>{moment(text).format("D MMM YYYY, h:mm:ss a")}</span>
                            </div>
                        </>
                    )
                }
            }, {
                title: 'Amount (RM)',
                dataIndex: 'amount',
                key: 'amount',
                sorter: (a, b) => a.amount - b.amount,
                render : (text, row, index) => {
                    return (
                        <>
                            <div>
                                <span style={{fontWeight:'bold'}}>{`RM${text}`}</span>
                            </div>
                        </>
                    )
                }
            },]}
            />
        </>
    );
}

export default OverallCreditNoteTable;