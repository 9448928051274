import { Button, Switch, Col, Form, Empty, Image, InputNumber, Input, Modal, Pagination, Row, Space, notification, message, Table, Select, Tag } from 'antd';
import React, { useEffect, useState, useRef } from 'react';
import InputWrapper from '../../../helpers/components/input_wrapper.component';
import { SearchOutlined, ExclamationCircleOutlined, ArrowRightOutlined, InfoCircleOutlined } from '@ant-design/icons';
import ItemCard from './item_card';
import { useDispatch, useSelector } from 'react-redux';
import LoadingItemCard from '../../components/loading.item_card';
import { set_billing_items } from '../../../actions/billing.action';
import itemService from '../../../services/config/item.service';
import { Link, useHistory } from 'react-router-dom';
import billingService from '../../../services/billing/billing.service';
import branchService from '../../../services/config/branch.service';
import locationService from '../../../services/config/location.service';
import supplierService from '../../../services/config/supplier.service';


const { confirm } = Modal;

const CreateBilling = () => {
    const billing_redux = useSelector(state => state.billing);
    const dispatch = useDispatch();
    const history = useHistory();
    const [form] = Form.useForm();
    const [state, setState] = useState({
        items: [],
        item_count: 0,
        item: [],
        from: [],
        to: [],
        loading: false,
        checkout_loading: false,
        confirmation_modal: null,
        from_id: null,
        to_id: null,
        refresh: 0,
        to_selection: [
            {
                value: 1,
                label: 'To Branch'
            },
            {
                value: 2,
                label: 'To Supplier/Dealer'
            }
        ],
        to_selected: 1,
    });

    const [filter, setFilter] = useState({
        page: 1,
        search: null,
    });

    const [inputFilter, setInputFilter] = useState({
        itemInput: null,
        fromInput: null,
        toInput: null,
    });

    const [timer, setTimer] = useState(null);

    const fetchItemOnSearch = async () => {
        if (state?.refresh == 0)
            dispatch(set_billing_items([]));
        if (!state?.from_id || !state?.to_id) {
            return;
        }
        setState(state => ({ ...state, item_loading: true, }));

        let locationResponse;
        locationResponse = await locationService.getLocationBranchItem({
            page: (filter?.page - 1),
            size: 12,
            location_id: state?.from_id,
            search: inputFilter?.itemInput,
            branchSupplier: state?.to_selected,
            branch_id: state?.to_id
        });
        const items = locationResponse?.location_item?.rows;
        const item_count = locationResponse?.location_item?.count;

        setTimeout(() => {
            setState(state => ({ ...state, items: items, item_count: item_count, item_loading: false, }));
        }, 800);

    }

    const fetchLocationOnSearch = async () => {
        setState(state => ({ ...state }));
        let locationResponse;
        locationResponse = await locationService.getLocation({
            search: inputFilter?.fromInput,
        });

        const location = locationResponse?.location?.map(x => {
            return {
                value: x.location_id,
                label: x.name,
            }
        })

        setState(state => ({ ...state, from: location }));
    }

    const fetchBranchOnSearch = async () => {
        let branchResponse;
        branchResponse = await branchService.getBranch({
            search: inputFilter?.toInput,
            is_hq: false,
        });

        const branch = branchResponse?.branch?.map(x => {
            return {
                value: x.branch_id,
                label: x.name
            }
        })

        setState(state => ({ ...state, to: branch }));
    }

    const fetchSupplierOnSearch = async () => {
        let supplierResponse;
        supplierResponse = await supplierService.getSupplier({
            search: inputFilter?.toInput,
        });

        const branch = supplierResponse?.supplier?.map(x => {
            return {
                value: x.supplier_id,
                label: x.name
            }
        })

        setState(state => ({ ...state, to: branch }));
    }

    // from
    const fromOnDropdownSelected = (location_id) => {
        const newTimer = setTimeout(() => {
            dispatch(set_billing_items([]));

            setState(state => ({ ...state, from_id: location_id }));
        }, 300);

    }

    //to
    const toOnDropdownSelected = (branch_id) => {
        clearTimeout(timer);
        const newTimer = setTimeout(() => {
            setState(state => ({ ...state, to_id: branch_id }));

        }, 300);
        setTimer(newTimer);
    }

    const toSelectionOnDropdownSelected = (value) => {
        clearTimeout(timer);
        const newTimer = setTimeout(() => {
            setFilter(filter => ({ ...filter, page: 1, }));

            setState(state => ({ ...state, to_selected: value, to_id: null, items: [], item_count: 0 }));

        }, 300);
        setTimer(newTimer);
    }
    //item
    const itemDoneTyping = async (input) => {
        clearTimeout(timer);
        const newTimer = setTimeout(() => {
            setInputFilter(filter => ({ ...filter, itemInput: input }));
        }, 300);
        setTimer(newTimer);
    }

    const createBilling = async () => {
        if (!state?.from_id || !state?.to_id) {
            notification.warning({
                message: "Select 'From' and 'To' to proceed",
            });
            return
        }

        if (billing_redux?.billing_items?.length < 1) {
            notification.warning({
                message: "No Item Issued",
            });
            return
        }
        confirm({
            width: 500,
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            content: (
                <>
                    <div>
                        <span>Create new billing?</span>
                    </div>
                    <div style={{ marginTop: 20, maxHeight: '400px', overflowY: 'auto' }}>
                        <Table
                            pagination={false}
                            // size="small"
                            // bordered
                            dataSource={billing_redux?.billing_items}
                            style={{ borderRadius: 6 }}
                            columns={[{
                                title: 'Item',
                                dataIndex: 'item_code',
                                key: 'item_code',
                                render: (text, row, index) => {
                                    return (
                                        <>
                                            <div>
                                                <div>
                                                    <span style={{ fontWeight: 500 }}>{row?.name}</span>
                                                </div>
                                                <div>
                                                    <span style={{ color: '#8c8c8c', fontSize: 12 }}>{row?.item_code}</span>
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                            }, {
                                title: 'Quantity',
                                dataIndex: 'quantity',
                                key: 'quantity',
                            }, {
                                title: 'Unit Price',
                                dataIndex: 'cost',
                                key: 'cost',
                            }, {
                                title: 'Markup (%)',
                                dataIndex: 'markup',
                                key: 'markup',
                            },

                            ]}
                        />
                    </div>


                </>
            ),
            async onOk() {
                setState(state => ({ ...state, checkout_loading: true }));
                try {
                    const createBillingResponse = await billingService.createBilling({
                        location_id: state?.from_id,
                        branch_id: state?.to_selected == 1 ? state?.to_id : null,
                        supplier_id: state?.to_selected == 2 ? state?.to_id : null,
                        billing_items: billing_redux?.billing_items,
                    });

                    setTimeout(() => {
                        dispatch(set_billing_items([]));
                        notification.success({
                            message: (<span style={{ fontSize: 14, fontWeight: 500, }}>Billing Created</span>),
                            duration: 3,
                            placement: 'topRight'
                        });
                        history.push(`/billing/list`);
                    }, 500);

                    clearAll();
                } catch (e) {
                    setState(state => ({ ...state, checkout_loading: false }));
                }
            },
            onCancel() {
            },
        });



    }

    const clearAll = () => {
        setState(state => ({
            ...state,
            item: null,
        }));

        form.resetFields();
    }

    useEffect(() => {
        fetchLocationOnSearch();
    }, [inputFilter.fromInput]);

    useEffect(() => {
        if (state?.to_selected == 1) {
            fetchBranchOnSearch();
        } else {
            fetchSupplierOnSearch();
        }
    }, [inputFilter?.toInput, state?.to_selected]);

    useEffect(() => {
        fetchItemOnSearch();
    }, [inputFilter?.itemInput, state?.from_id, state?.to_selected, state?.to_id, filter?.page]);

    return (
        <>
            <div>
                {/* <Row justify='center' align='top' gutter={[40, 20]}>
                    <Col xs={24} sm={14} span={14}> */}
                <div>
                    <div className='filter-panel' style={{ height: 60 }}>
                        <div style={{ display: 'flex' }}>
                            <div >
                                <Space>
                                    <div style={{ minWidth: 200, textAlign: 'left' }}>
                                        <InputWrapper>
                                            <Input
                                                name="searchItem"
                                                onChange={(e) => itemDoneTyping(e.target.value)}
                                                bordered={false}
                                                prefix={<div style={{ paddingRight: 12, paddingLeft: 8 }}><SearchOutlined style={{ color: '#8c8c8c' }} /></div>}
                                                placeholder={`Search Products`}
                                                style={{ fontSize: 14 }}
                                                size='large'
                                            />
                                        </InputWrapper>
                                    </div>
                                    <div style={{ width: '100%' }}>
                                        <Select
                                            value={state?.to_selected}
                                            options={state?.to_selection}
                                            className='border-input'
                                            onChange={toSelectionOnDropdownSelected} />
                                    </div>

                                </Space>
                            </div>
                            <div style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                                <Space>
                                    <div style={{ marginRight: 5, width: 150 }}>
                                        <Select
                                            value={state?.from_id}
                                            showSearch
                                            className='border-input'
                                            options={state?.from}
                                            style={{ borderRadius: 6, width: '100%' }}
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            onChange={fromOnDropdownSelected}
                                            placeholder='From'
                                        />
                                    </div>
                                    <ArrowRightOutlined />
                                    <div style={{ marginRight: 5, width: 150 }}>
                                        <Select
                                            value={state?.to_id}
                                            options={state?.to}
                                            showSearch
                                            className='border-input'
                                            style={{ borderRadius: 6, width: '100%' }}
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            onChange={toOnDropdownSelected}
                                            placeholder='To'
                                        />
                                    </div>
                                </Space>

                            </div>
                            <div style={{ marginLeft: 'auto' }}>
                                <div>
                                    <Button
                                        onClick={createBilling}
                                        type='primary'
                                        style={{ width: '100%', borderRadius: 4 }}
                                        loading={state?.checkout_loading}
                                    >
                                        <span style={{ fontWeight: 500 }}>Create Billing</span>
                                    </Button>
                                </div>


                            </div>

                        </div>
                    </div>

                    <div style={{ marginTop: 20 }}>
                        <Row gutter={[20, 20]}>
                            {
                                state?.item_loading ?
                                    (
                                        <>
                                            {[...Array(12)].map((x, i) => {
                                                return (
                                                    <Col xs={24} sm={12} lg={8} xl={8} span={6} key={i} style={{ height: '100%' }}>
                                                        <LoadingItemCard />
                                                    </Col>
                                                )
                                            })}
                                        </>
                                    )
                                    :
                                    (
                                        <>

                                            {
                                                state?.items.length > 0 ? state?.items?.map((item, index) => {
                                                    return (
                                                        <Col xs={24} sm={12} lg={8} xl={8} span={6} key={index} style={{ height: 'auto' }}>
                                                            <ItemCard item={item} />
                                                        </Col>
                                                    )
                                                })
                                                    :
                                                    (
                                                        <>
                                                            <Space direction='vertical' style={{ width: '100%' }}>
                                                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                                                <Tag color="orange"><InfoCircleOutlined />
                                                                    {
                                                                        (!state?.from_id || !state?.to_id) ?
                                                                            <span>Select From and To to view Item</span> : <span>No Item Found</span>
                                                                    }
                                                                </Tag>
                                                            </Space>
                                                        </>
                                                    )
                                            }

                                        </>
                                    )
                            }

                        </Row>
                    </div>
                </div>
                <div style={{ padding: 24 }}>
                    <Pagination showSizeChanger={false} onChange={(page) => setFilter(filter => ({ ...filter, page: page }))} current={filter?.page} total={state?.item_count} pageSize={12} />
                </div>
            </div >

        </>
    );
}

export default CreateBilling;
