import { Layout } from 'antd';
import React from 'react';
import MasterContent from './master_content';
import MasterHeader from './master_header';
import MasterSider from './master_sider';

const { Header, Content, Sider } = Layout;
const MasterLayout = ({children}) => {

    return (
        <>
            <Layout>
                <Sider collapsed style={{background : '#313133'}}>
                    <MasterSider />
                </Sider>
                <Layout>
                    <Header style={{background : '#fff'}}>
                        <MasterHeader />
                    </Header>
                    <Content className='main-content'>
                        <div className='content'>
                            <MasterContent>
                                {children}
                            </MasterContent>
                        </div>
                    </Content>
                </Layout>
            </Layout>
        </>
    );
}

export default MasterLayout;