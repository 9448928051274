import React from 'react';
import ContainerComponent from '../../helpers/components/container.component';
import PageHeaderComponent from '../../helpers/components/page_header.component';
import AdjustListTable from './components/adjust_list_table';

const AdjustList = () => {
    return (
        <>
            <ContainerComponent span={22} top={20}>
                <PageHeaderComponent />
                <AdjustListTable />
            </ContainerComponent>
        </>
    );
}

export default AdjustList;