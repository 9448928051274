import React from 'react';
import { Switch } from 'react-router-dom';
import PublicRoute from './public.router';
import HomeIndex from '../containers/home';
import SaleIndex from '../containers/sale';
import SaleCreate from '../containers/sale/create';
import SaleList from '../containers/sale/list';
import ConfigIndex from '../containers/config';
import ItemIndex from '../containers/config/item';
import ItemCategoryIndex from '../containers/config/item_category';
import SupplierIndex from '../containers/config/supplier';
import PromotionIndex from '../containers/config/promotion';
import SaleOrder from '../containers/sale/order';
import CreditNote from '../containers/sale/credit_note';
import PurchaseIndex from '../containers/purchases';
import PurchaseCreate from '../containers/purchases/create';
import PurchaseList from '../containers/purchases/list';
import PurchaseOrder from '../containers/purchases/order';
import CustomerIndex from '../containers/customer';
import CustomerDetail from '../containers/customer/detail';
import SaleReport from '../containers/sale/report';
import StockIndex from '../containers/stock';
import StockIssue from '../containers/stock/issue';
import StockReceive from '../containers/stock/receive';
import StockTransfer from '../containers/stock/transfer';
import StockCount from '../containers/stock/count';
import CreateStockCount from '../containers/stock/create_stock_count';
import CreateNewStock from '../containers/stock/create_new_stock';
import CreateNewStockTransfer from '../containers/stock/create_new_stock_transfer';
import StockReturn from '../containers/stock/stock_return';
import StockAdjust from '../containers/stock/adjust';
import CreateStockAdjust from '../containers/stock/create_stock_adjust';

import StockReport from '../containers/stock/report';
import BillingIndex from '../containers/billing';
import BillingList from '../containers/billing/list';
import CompanyBillingList from '../containers/billing/company_list';
import BillingDetail from '../containers/billing/detail';
import CreateNewBilling from '../containers/billing/create';
import StockRequest from '../containers/stock/request';
import ProfileIndex from '../containers/profile';


const AppRouter = () => {
    return (
        <>
            <Switch>
                {/* Sales */}
                <PublicRoute component={HomeIndex} path="/" exact restricted={false} />
                <PublicRoute component={SaleIndex} path="/sales" exact restricted={false} />
                <PublicRoute component={SaleCreate} path="/sales/create" exact restricted={false} />
                <PublicRoute component={SaleList} path="/sales/list" exact restricted={false} />
                <PublicRoute component={SaleOrder} path="/sales/order/:id" exact restricted={false} />
                <PublicRoute component={CreditNote} path="/sales/creditnote" exact restricted={false} />
                <PublicRoute component={SaleReport} path="/sales/report" exact restricted={false} />

                {/* Customers */}
                <PublicRoute component={CustomerIndex} path="/customers" exact restricted={false} />
                <PublicRoute component={CustomerDetail} path="/customers/:id" exact restricted={false} />

                {/* Purchases */}
                <PublicRoute component={PurchaseIndex} path="/purchases" exact restricted={false} />
                {/* <PublicRoute component={PurchaseSupplier} path="/purchases/suppliers" exact restricted={false} /> */}
                <PublicRoute component={PurchaseCreate} path="/purchases/create" exact restricted={false} />
                <PublicRoute component={PurchaseList} path="/purchases/list" exact restricted={false} />
                <PublicRoute component={PurchaseOrder} path="/purchases/order/:id" exact restricted={false} />

                {/* Stocks */}
                <PublicRoute component={StockIndex} path="/stocks" exact restricted={false} />
                <PublicRoute component={StockIssue} path="/stocks/issue" exact restricted={false} />
                <PublicRoute component={StockReceive} path="/stocks/receive" exact restricted={false} />
                <PublicRoute component={StockTransfer} path="/stocks/transfer" exact restricted={false} />
                <PublicRoute component={StockCount} path="/stocks/count" exact restricted={false} />
                <PublicRoute component={CreateNewStock} path="/stocks/createnewstock" exact restricted={false} />
                <PublicRoute component={CreateNewStockTransfer} path="/stocks/createstocktransfer" exact restricted={false} />
                <PublicRoute component={CreateStockCount} path="/stocks/createcount" exact restricted={false} />
                <PublicRoute component={StockReport} path="/stocks/report" exact restricted={false} />
                <PublicRoute component={StockReturn} path="/stocks/return" exact restricted={false} />
                <PublicRoute component={StockAdjust} path="/stocks/adjust" exact restricted={false} />
                <PublicRoute component={CreateStockAdjust} path="/stocks/createadjust" exact restricted={false} />


                {/* Stock Request */}
                <PublicRoute component={StockRequest} path="/stocks/request" exact restricted={false} />

                {/* Config */}
                <PublicRoute component={ConfigIndex} path="/config" exact restricted={false} />
                <PublicRoute component={ItemIndex} path="/config/item" exact restricted={false} />
                <PublicRoute component={ItemCategoryIndex} path="/config/itemgroup" exact restricted={false} />
                <PublicRoute component={SupplierIndex} path="/config/supplier" exact restricted={false} />
                <PublicRoute component={PromotionIndex} path="/config/promotion" exact restricted={false} />

                {/* Billing */}
                <PublicRoute component={BillingIndex} path="/billing" exact restricted={false} />
                <PublicRoute component={PurchaseCreate} path="/purchases/create" exact restricted={false} />
                <PublicRoute component={BillingList} path="/billing/list" exact restricted={false} />
                <PublicRoute component={CompanyBillingList} path="/billing/company_billing_list" exact restricted={false} />
                <PublicRoute component={BillingDetail} path="/billing/detail/:id" exact restricted={false} />
                <PublicRoute component={CreateNewBilling} path="/billing/create" exact restricted={false} />


                {/* Profile */}
                <PublicRoute component={ProfileIndex} path="/profile/change_password" exact restricted={false} />

            </Switch>
        </>
    );
}

export default AppRouter;