import React from 'react';
import ContainerComponent from '../../helpers/components/container.component';
import PageHeaderComponent from '../../helpers/components/page_header.component';
import BillingListTable from './components/billing_list_table';

const BillingList = () => {

    return (
        <>
            <ContainerComponent span={22} top={20}>
                <PageHeaderComponent />
                <BillingListTable />
            </ContainerComponent>
        </>
    );
}

export default BillingList;