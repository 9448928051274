import { EllipsisOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Divider, Modal, Space, Form, Dropdown } from 'antd';
import React, { useEffect, useState } from 'react';
import paymentService from '../../../services/sales/payment.service';
import SalePaymentAdd from './sale_payment_add';
import checkoutService from '../../../services/sales/checkout.service';
import { useDispatch, useSelector } from 'react-redux';
import { refresh_sale_detail } from '../../../actions/sales_order.action';

const { confirm } = Modal;
const SalePayment = ({ sale_id, edit, status_editable }) => {
    const dispatch = useDispatch();
    const saleOrderRedux = useSelector(state => state.sales_order);
    const [form] = Form.useForm();
    const [state, setState] = useState({
        loading: false,
        payments: [],
        edit_modal: false,
        edit_modal_id: null,
        refresh: 0,
        balance: 0,
        sale : null,
    })

    const openEditModal = ({ sale_payment_id = null, payment_method_id, amount, referral_code, remarks }) => {
        setState(state => ({ ...state, edit_modal: true, edit_modal_id: sale_payment_id }));
        form.setFieldsValue({ SalePaymentId: sale_payment_id, payment_method_id, amount, referral_code, remarks });
    }

    const fetchSalePayment = async () => {
        setState(state => ({ ...state, loading: true }));
        const paymentResponse = await paymentService.getSalePayments({
            sale_id: sale_id,
        });
        const salesResponse = await checkoutService.getSaleById(sale_id);
        const sale = salesResponse?.sales;
        const payments = paymentResponse?.sale_payments;
        let totalAmount = 0;
        for (const item of payments) {
            totalAmount += item?.amount;
        }
        setTimeout(() => {
            setState(state => ({ ...state, payments: payments, loading: false, balance: (sale?.total_amount - totalAmount).toFixed(2), sale : sale }));
        }, 800);
    }

    const addSalePayment = async () => {
        const { amount, payment_method_id, referral_code, remarks } = form.getFieldsValue();

        let paymentResponse;
        const payment_methods_amount = (state?.payments?.filter(x => x.sale_payment_id != state?.edit_modal_id).reduce((accumulator, currentValue) => accumulator + currentValue.amount, 0));
        const total_payment_method = (payment_methods_amount + amount);
        let amountBalance = state?.sale?.total_amount - total_payment_method;


        const proceedAddPaymentMethod = async () => {
            if (state?.edit_modal_id) {
                //update
                paymentResponse = await paymentService.updateSalePayment(state?.edit_modal_id, {
                    amount, payment_method_id, referral_code, remarks
                })
            } else {
                //store
                paymentResponse = await paymentService.storeSalePayment({
                    sale_id: sale_id,
                    amount, payment_method_id, referral_code, remarks
                });
            }

            //refresh detail
            dispatch(refresh_sale_detail(saleOrderRedux?.sale_refresh + 1));

            setState(state => ({ ...state, refresh: (state?.refresh + 1), edit_modal: false, }));
        }

        if(amountBalance < 0){
            confirm({
                title: amountBalance < 0 ? 'Payment amount exceed the balance' : 'Proceed to add payment method?',
                content : amountBalance < 0 ? 'This might cause the sales amount and payment amount not tally.' : '',
                okText : 'Continue',
                async onOk() {
                    await proceedAddPaymentMethod();
                }
            })
        }else{
            await proceedAddPaymentMethod();
        }
        

    }

    const deleteConfirmationModal = async (sale_payment_id) => {
        confirm({
            title: 'Do you want to delete this sale payment?',
            onOk() {
                return new Promise((resolve, reject) => {
                    setTimeout(async () => {
                        await deleteSalePayment(sale_payment_id);
                        resolve();
                    }, 500);
                }).catch((err) => {

                })
            }
        })
    }

    const deleteSalePayment = async (sale_payment_id) => {
        const saleItemsResponse = await paymentService.deleteSalePayment(sale_payment_id);

        //refresh detail
        dispatch(refresh_sale_detail(saleOrderRedux?.sale_refresh + 1));
        setState(state => ({ ...state, refresh: (state?.refresh + 1) }));
    }

    useEffect(() => {
        fetchSalePayment();
    }, [state?.refresh, sale_id, saleOrderRedux?.sale_refresh])

    return (
        <>
            <div style={{ textAlign: 'left', padding: '12px 0' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div>
                        <span style={{ fontWeight: 500, fontSize: 16 }}>Payment details</span>
                    </div>
                    {
                        edit && (status_editable) &&
                        (
                            <>
                                <div style={{ marginLeft: 'auto' }}>
                                    <span onClick={() => openEditModal({})} className='mini-button mini-style-text'>
                                        Add Payments
                                    </span>
                                </div>
                            </>
                        )
                    }

                </div>
            </div>
            <Divider style={{ marginTop: 12 }} />
            <div>
                <div style={{ textAlign: 'left' }}>
                    <Space direction='vertical' style={{ width: '100%' }}>
                        {
                            state?.payments?.map((payment, index) => {
                                const { payment_method, amount, referral_code, remarks, sale_payment_id } = payment ?? {};
                                return (
                                    <div key={index}>
                                        <div style={{ display: 'flex' }}>
                                            <div>
                                                <Space key={index} direction='vertical'>
                                                    <div>
                                                        <Space>
                                                            <div style={{ minWidth: 200 }}>
                                                                <span className='label' style={{ color: '#8c8c8c' }}>Payment Method</span>
                                                            </div>
                                                            <span className='description'>{payment_method?.name}</span>
                                                        </Space>
                                                    </div>
                                                    <div>
                                                        <Space>
                                                            <div style={{ minWidth: 200 }}>
                                                                <span className='label' style={{ color: '#8c8c8c' }}>Referral Code</span>
                                                            </div>
                                                            <span className='description' style={{ color: (referral_code ? "" : '#a3acba') }}>{referral_code ?? "No referral code"}</span>

                                                        </Space>
                                                    </div>
                                                    <div>
                                                        <Space>
                                                            <div style={{ minWidth: 200 }}>
                                                                <span className='label' style={{ color: '#8c8c8c' }}>Amount</span>
                                                            </div>
                                                            <span className='description'>{`RM ${amount}`}</span>
                                                        </Space>
                                                    </div>
                                                    <div>
                                                        <Space>
                                                            <div style={{ minWidth: 200 }}>
                                                                <span className='label' style={{ color: '#8c8c8c' }}>Descriptions</span>
                                                            </div>
                                                            <span className='description' style={{ color: (remarks ? "" : '#a3acba') }}>{remarks ?? "No descriptions"}</span>
                                                        </Space>
                                                    </div>
                                                </Space>
                                            </div>
                                            {
                                                edit && (status_editable) &&
                                                (
                                                    <>
                                                        <div style={{ marginLeft: 'auto' }}>
                                                            <Dropdown
                                                                placement='bottomRight'
                                                                overlayStyle={{ borderRadius: 12, minWidth: 200 }}
                                                                menu={{
                                                                    items: [{
                                                                        key: '1',
                                                                        type: 'group',
                                                                        label: "Action",
                                                                        children: [{
                                                                            key: '1-1',
                                                                            label: "Edit",
                                                                            onClick: async () => {
                                                                                openEditModal({ payment_method_id: payment_method?.payment_method_id, amount, referral_code, remarks, sale_payment_id })
                                                                            }
                                                                        }, {
                                                                            key: '1-2',
                                                                            label: "Delete",
                                                                            onClick: async () => {
                                                                                deleteConfirmationModal(sale_payment_id);
                                                                            }
                                                                        },]
                                                                    },]
                                                                }}
                                                            >
                                                                <Button size='small' icon={<EllipsisOutlined />} style={{ borderRadius: 6 }} />
                                                            </Dropdown>
                                                        </div>
                                                    </>
                                                )
                                            }

                                        </div>
                                        {

                                            index != (state?.payments.length - 1) &&
                                            (
                                                <Divider style={{ margin: 12 }} />
                                            )
                                        }
                                    </div>
                                )
                            })
                        }
                    </Space>
                </div>
            </div>


            <Modal
                open={state?.edit_modal}
                transitionName="ant-fade"
                maskTransitionName="ant-fade"
                title={state?.edit_modal_id ? <><span>Update Payment</span></> : <><span>Add Payment</span></>}
                width={500}
                closable={false}
                className="custom-modal"
                onOk={() => form.submit()}
                onCancel={() => {
                    form.resetFields();
                    setState(state => ({ ...state, edit_modal: false, }))
                }}
            >
                <Form
                    id='sale_payment_form'
                    onFinish={addSalePayment}
                    form={form}
                    layout='vertical'
                >
                    <SalePaymentAdd form={form} />
                </Form>
            </Modal>
        </>
    );
}

export default SalePayment;