import { combineReducers } from 'redux';
import salesOrderReducer from './sales_order.reducer';
import purchaseOrderReducer from './purchase_order.reducer';
import createStockCountReducer from './create_stock_count.reducer';
import createStockReducer from './create_stock.reducer';
import billingReducer from './billing.reducer';
import createStockAdjustReducer from './create_stock_adjust.reducer';


import authReducer from './auth.reducer';

const allReducers = combineReducers({
    sales_order: salesOrderReducer,
    purchase_order: purchaseOrderReducer,
    create_stock_count: createStockCountReducer,
    create_stock: createStockReducer,
    billing: billingReducer,
    create_stock_adjust: createStockAdjustReducer,

    auth: authReducer
});

const rootReducer = (state, action) => {
    return allReducers(state, action);
}

export default rootReducer;