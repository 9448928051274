import { Badge, Button, Card, Space, Tag, InputNumber } from 'antd';
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { set_stock_count_items } from '../../../actions/create_stock_count.action';

const ItemCard = ({ item }) => {
    const create_stock_count_redux = useSelector(state => state.create_stock_count);
    const dispatch = useDispatch();

    const countedQuantity = (item) => {
        let stock_count_items = (create_stock_count_redux.stock_count_items);
        let existing_item = stock_count_items?.find(x => x.item_id === item?.item_id);
        if (existing_item) {
            return existing_item?.quantity;
        } else {
            return null;
        }
    }

    const updateNewStockCount = (value) => {
        let stock_count_items = (create_stock_count_redux.stock_count_items);
        if (value) {

            let existing_item = stock_count_items?.find(x => x.item_id === item?.item_id);
            if (existing_item) {
                const calculated_quantity = value;
                existing_item.quantity = calculated_quantity;
                let changed_quantity = stock_count_items;
                if (calculated_quantity > 0) {
                    changed_quantity = stock_count_items?.map((x) => {
                        return (x?.item_id === item?.item_id) ? existing_item : x;
                    })
                } else {
                    changed_quantity = stock_count_items?.filter((x) => {
                        return (x?.item_id != item?.item_id);
                    })
                }

                dispatch(set_stock_count_items(changed_quantity));
            } else {
                if (value > 0) {
                    dispatch(
                        set_stock_count_items([
                            ...stock_count_items,
                            {
                                item_id: item?.item_id,
                                item_code: item?.item?.item_code,
                                name: item?.item?.name,
                                stock_quantity: item?.quantity,
                                quantity: value,
                            }
                        ])
                    );
                }
            }

        } else {
            const changed_quantity = stock_count_items?.filter((x) => {
                return (x?.item_id !== item?.item_id);
            })

            dispatch(set_stock_count_items(changed_quantity));
        }

    }

    return (
        <>
            <Card

                bordered={false}
                style={{ borderRadius: 6, height: '100%' }}
            >
                <div>
                    <div style={{ textAlign: 'left' }}>
                        <div>
                            <span style={{ fontWeight: 500, color: '#8c8c8c', fontSize: 12 }}>{item?.item?.item_code}</span>
                        </div>
                        <div>
                            <span style={{ fontWeight: 500, color: '#595959' }}>{item?.item?.name}</span>
                        </div>
                        <div>
                            <span style={{ color: '#8c8c8c' }}>{`MYR ${item?.item?.price}`}</span>
                        </div>
                        <div style={{ marginTop: 8 }}>
                            <Tag color='geekblue' style={{ border: 'none', borderRadius: 6 }}>{`Quantity : ${item?.quantity ?? 0}`}</Tag>
                        </div>
                        <div style={{ marginTop: 5 }}>
                            <InputNumber min={0} value={countedQuantity(item)} style={{ width: '100%' }} onChange={updateNewStockCount} placeholder="Counted Quantity" />
                        </div>
                    </div>
                </div>
            </Card>
        </>
    );
}

export default ItemCard;
