import React, { useEffect, useState } from 'react';
import pendingService from '../../../services/stock/pending.service';
import { Button, Drawer, Dropdown, Form, Space, Spin, Table, Tag, message, notification, } from 'antd';
import moment from 'moment';
import { ArrowDownOutlined, CaretDownFilled, DownOutlined, EllipsisOutlined } from '@ant-design/icons';

const TransferPending = () => {
    const [form] = Form.useForm();
    const [state, setState] = useState({
        loading : false,
        pending_requests : [],
        
        isView : null,
        isDisabled : null,
        item : [],

        //refresh table
        refresh : 0,
    });
    const [open, setOpen] = useState(false);

    const fetchPendingTransfer = async () => {
        setState(state => ({...state, loading : true}));
        const pendingTransferResponse = await pendingService.getPendingTransfer();
        const pending_requests = (pendingTransferResponse?.pending_transfers?.rows);
        setState(state => ({...state, loading : false, pending_requests : pending_requests}));
    }

    const openDrawer = (data, isView) => {
        setState(state => ({
            ...state,
            isDisabled: isView,
            isView: isView,
            item: data,
        }));

        showDrawer();
    }

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        clearAll()
        setOpen(false);
    };

    const clearAll = () => {
        setState(state => ({
            ...state,
            item: [],
            isDisabled: true,
            isView: null,
            location_id: null,
            second_branch_id: null,
            update_loading: false,
        }));
        form.resetFields();
    }

    const approveStock = async (stock_id) => {
        const approveStockResponse = await pendingService.updatePendingTransfer(stock_id, {
            status : 1,
        });

        onClose();
        setState(state => ({...state , refresh : state?.refresh + 1,}));


        notification.success({
            message: (<span style={{ fontSize: 14, fontWeight: 500, }}>Stock Transfer Approved</span>),
            duration: 3,
            placement: 'topRight'
        });


    }

    useEffect(() => {
        fetchPendingTransfer();
    }, [state?.refresh])

    return (
        <>
            <Spin spinning={state?.loading}>
                <Table
                className='hub-table'
                dataSource={state?.pending_requests}
                columns={[{
                    title: 'Transaction No',
                    dataIndex: 'running_number',
                    key: 'running_number',
                    // render: (text) => <span>{text.length}</span>,
                },{
                    title: 'From',
                    dataIndex: 'main_branch',
                    key: 'main_branch',
                    render: (text) => <span>{text?.name}</span>,
                },
                {
                    title: 'To',
                    dataIndex: 'second_branch',
                    key: 'second_branch',
                    render: (text) => <span>{text?.name}</span>,
                },
                {
                    title: 'Issue Date',
                    dataIndex: 'created_at',
                    key: 'created_at',
                    render: (text, row, index) => {
                        return (
                            <>
                                <div>
                                    <span>{moment(text).format("D MMM YYYY, h:mm:ss a")}</span>
                                </div>
                            </>
                        )
                    }
                },{
                    title: 'Items Transfered',
                    dataIndex: 'stock_items',
                    key: 'stock_items',
                    render: (text) => <span>{text.length}</span>,
                },
                {
                    title: 'Status',
                    dataIndex: 'status',
                    key: 'status',
                    render: (text, row, index) => {
                        return (
                            <>
                                <div>
                                    <Tag color={text == 0 ? 'orange' : text == 1 ? 'green' : 'red'} style={{ border: 'none' }}>
                                        <span>{text == 0 ? 'Pending' : text == 1 ? 'Completed' : 'Void'}</span>
                                    </Tag>
                                </div>
                            </>
                        )
                    }
                },
                {
                    title: 'Action',
                    dataIndex: 'action',
                    key: 'action',
                    render: (text, row, index) => (
                        <>
                            <div>
                                <Dropdown
                                placement='bottomRight'
                                overlayStyle={{ borderRadius: 12, minWidth: 200 }}
                                menu={{
                                    items: [{
                                        key: '1',
                                        type: 'group',
                                        label: "Action",
                                        children: [{
                                            key: '1-1',
                                            label: "View",
                                            onClick: async () => {
                                                openDrawer(row, true);
                                            }
                                        }, 
                                        ]
                                    },]
                                }}
                                >
                                    <Button size='small' icon={<EllipsisOutlined />} style={{ borderRadius: 6 }} />
                                </Dropdown>
                            </div>
                        </>
                    )
                }
                ]}
                />
            </Spin>


            <Drawer title={state?.isView ? 'Stock Transfer Detail' : 'Add Stock Issue'} onOk={() => form.submit()}
                placement="right"
                width={720}
                onClose={onClose}
                open={open}
            >

                <div style={{ marginBottom: 20, fontSize: 14 }}>
                    <div style={{display:'flex', alignItems:'center'}}>
                        <div>
                            <Space>
                                <div>{`From : ${state?.item?.main_branch?.name}`}</div>
                                <div>{`To : ${state?.item?.second_branch?.name}`}</div>
                            </Space>
                        </div>
                        <div style={{marginLeft:'auto'}}>
                            {
                                (state?.item?.status === 0) ?
                                (
                                    <>
                                        <Dropdown
                                        placement='bottomRight'
                                        overlayStyle={{ borderRadius: 12, minWidth: 200 }}
                                        menu={{
                                            items: [{
                                                key: '1',
                                                type: 'group',
                                                label: "Action",
                                                children: [{
                                                    key: '1-1',
                                                    label: "Approve",
                                                    onClick: async () => {
                                                        await approveStock(state?.item?.stock_id);
                                                    }
                                                }, 
                                                // {
                                                //     key: '1-2',
                                                //     label: "Delete",
                                                //     onClick: async () => {
                                                //         await onDelete(row);
                                                //     }
                                                // },
                                                ]
                                            },]
                                        }}
                                        >
                                            <Button type='primary' icon={<DownOutlined />} style={{ borderRadius: 6 }}>Action</Button>
                                        </Dropdown>
                                    </>
                                )
                                :
                                (
                                    <>
                                        <Tag color='green' style={{border:'none', borderRadius:4,}}>
                                            Completed
                                        </Tag>
                                    </>
                                )
                            }
                            
                        </div>
                    </div>
                    

                </div>


                <Table
                    pagination={false}
                    className='hub-table'
                    dataSource={state?.item?.stock_items}
                    columns={[
                        {
                            title: '#',
                            dataIndex: 'stock_id',
                            key: 'stock_id',
                            render: (text, data, index) => (
                                <span>{index + 1}</span>
                            )
                        },
                        {
                            title: 'Item Code',
                            dataIndex: 'item',
                            key: 'item',
                            render: (text, data) => (
                                <span>{text?.item_code}</span>
                            )
                        },
                        {
                            title: 'Item Name',
                            dataIndex: 'item',
                            key: 'item',
                            render: (text, data) => (
                                <span>{text?.name}</span>
                            )
                        },
                        {
                            title: 'Quantity',
                            dataIndex: 'quantity',
                            key: 'quantity',
                            render: (text, data) => (
                                <span>{text}</span>
                            )
                        },
                    ]}
                />
            </Drawer>
        </>
    );
}

export default TransferPending;