import { SearchOutlined, ExclamationCircleOutlined, EllipsisOutlined, FilterOutlined } from '@ant-design/icons';
import { DatePicker, Tag, Button, Input, Select, Spin, Table, Drawer, Form, Space, notification, Modal, Dropdown, InputNumber, } from 'antd';
import React, { useEffect, useState } from 'react';
import InputWrapper from '../../../helpers/components/input_wrapper.component';
import stockService from '../../../services/stock/stock.service';
import locationService from '../../../services/config/location.service';
import branchService from '../../../services/config/branch.service';
import moment from 'moment';
import { Link, useHistory } from 'react-router-dom';


const { confirm } = Modal;
const { Option } = Select;
const TransferListTable = () => {
    const history = useHistory();
    const [form] = Form.useForm();
    const [form1] = Form.useForm();
    const [state, setState] = useState({
        stock: [],
        item: [],
        from: [],
        to: [],
        location_id: null,
        loading: false,
        isDisabled: true,
        isView: null,
        update_loading: false,
        maxQuantity: null,
        item: [],
        //filter
        filter_modal: false,
    });
    const [filter, setFilter] = useState({
        page: 0,
        search: null,
        start_date: null,
        end_date: null,
        from_id: null,
        to_id: null,
    });

    const [inputFilter, setInputFilter] = useState({
        itemInput: null,
        fromInput: null,
        toInput: null,
    });

    const onFilter = async () => {
        const { date, from, to } = form1.getFieldsValue();
        const start_date = date && moment(date[0]).format("YYYY-MM-DD");
        const end_date = date && moment(date[1]).format("YYYY-MM-DD");
        setFilter(filter => ({ ...filter, start_date: start_date, end_date: end_date, from_id: from, to_id: to }));
        setState(state => ({ ...state, filter_modal: false, }));
    }

    const [timer, setTimer] = useState(null);
    const [open, setOpen] = useState(false);

    const fetchStockList = async () => {
        setState(state => ({ ...state, loading: true, }));

        const stockResponse = await stockService.getStock({
            page: filter?.page,
            size: 10,
            search: filter?.search,
            from: filter?.from_id,
            to: filter?.to_id,
            start_date: filter?.start_date,
            end_date: filter?.end_date,
            type: 3, //transfer
        });
        setTimeout(() => {
            setState(state => ({
                ...state,
                stock: stockResponse?.stock,
                loading: false,
            }))
        }, 800);

    }


    const fetchMainBranchOnSearch = async () => {
        let branchResponse;
        branchResponse = await branchService.getBranch({
            branch_id: state?.second_branch_id,
            search: inputFilter?.fromInput,
        });
        const branch = branchResponse?.branch?.map(x => {
            return {
                value: x.branch_id,
                label: x.name,
            }
        })


        setState(state => ({ ...state, from: branch }));
    }

    const fetchBranchOnSearch = async () => {
        let branchResponse;
        branchResponse = await branchService.getBranch({
            search: inputFilter?.toInput,
        });

        const branch = branchResponse?.branch?.map(x => {
            return {
                value: x.branch_id,
                label: x.name
            }
        })

        setState(state => ({ ...state, to: branch }));
    }

    const doneTyping = async (input) => {
        clearTimeout(timer);
        const newTimer = setTimeout(() => {
            setFilter(filter => ({ ...filter, search: input }));
        }, 300);
        setTimer(newTimer);
    }

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        clearAll()
        setOpen(false);
    };

    const openDrawer = (data, isView) => {
        setState(state => ({
            ...state,
            isDisabled: isView,
            isView: isView,
            item: data,
        }));

        showDrawer();
    }

    const offEdit = () => {
        if (state.isView !== true) {
            onClose();
        }
        setState(state => ({
            ...state,
            isDisabled: true,
        }));
    };

    const onDelete = async (data) => {
        confirm({
            icon: <ExclamationCircleOutlined />,
            content: (
                <>
                    <Space direction='vertical'>
                        <div>
                            <span>Are your sure to delete stock transfer?</span>
                        </div>

                    </Space>
                </>
            ),
            async onOk() {
                const deleteStockResponse = await stockService.deleteStock(data.stock_id);
                notification.success({
                    message: (<span style={{ fontSize: 14, fontWeight: 500, }}>Stock Transfer Deleted</span>),
                    duration: 3,
                    placement: 'topRight'
                });
                clearAll();
                refresh();
            },
            onCancel() {
            },
        });

    };

    // from
    const fromDoneTyping = async (input) => {
        clearTimeout(timer);
        const newTimer = setTimeout(() => {
            setInputFilter(inputFilter => ({ ...inputFilter, fromInput: input }));
        }, 300);
        setTimer(newTimer);
    }

    const fromOnDropdownSelected = (location_id) => {
        // const location = state?.location?.find(x => x.value === location_id);

        form.setFieldsValue({ from: location_id });
        const newTimer = setTimeout(() => {
            setState(state => ({ ...state, location_id: location_id }));
        }, 300);

    }

    //to
    const toDoneTyping = async (input) => {
        clearTimeout(timer);
        const newTimer = setTimeout(() => {
            setInputFilter(inputFilter => ({ ...inputFilter, toInput: input }));
        }, 300);
        setTimer(newTimer);
    }

    const toOnDropdownSelected = (branch_id) => {
        // const items = state?.items?.find(x => x.value === location_id);
        form.setFieldsValue({ to: branch_id });
        // setState(state => ({ ...state, selected_items: item_id }));
    }


    const addStock = () => {
        history.push(`/stocks/createstocktransfer`);
    }
    useEffect(() => {
        fetchMainBranchOnSearch();
    }, [inputFilter.fromInput]);

    useEffect(() => {
        fetchBranchOnSearch();
    }, [inputFilter?.toInput]);


    const refresh = () => {
        onClose();
        fetchStockList();
    };

    const clearAll = () => {
        setState(state => ({
            ...state,
            item: [],
            isDisabled: true,
            isView: null,
            location_id: null,
            second_branch_id: null,
            update_loading: false,
        }));
        form.resetFields();
    }

    useEffect(() => {
        fetchStockList();
    }, [filter])

    return (
        <>
            <div className='filter-panel' style={{ height: 60, }}>
                <div style={{ display: 'flex' }}>
                    <div >
                        <Space>
                            {/* <div style={{ minWidth: 400 }}>
                                <InputWrapper>
                                    <Input
                                        onChange={(e) => doneTyping(e.target.value)}
                                        bordered={false}
                                        prefix={<div style={{ paddingRight: 12, paddingLeft: 8 }}><SearchOutlined style={{ color: '#8c8c8c' }} /></div>}
                                        placeholder={`Search Item`}
                                        style={{ fontSize: 14 }}
                                        size='large'
                                    />
                                </InputWrapper>
                            </div> */}

                            <div>
                                <Button
                                    icon={<FilterOutlined style={{ color: '#8c8c8c' }} />}
                                    onClick={() => {
                                        setState(state => ({ ...state, filter_modal: true }));
                                    }}
                                    style={{ borderRadius: 6 }}
                                >Filter</Button>
                            </div>
                        </Space>
                    </div>
                    <div style={{ marginLeft: 'auto' }}>
                        <Button
                            type="primary"
                            // icon={<PlusOutlined />}
                            onClick={addStock}
                            style={{ borderRadius: 6 }}
                        >
                            Add Stock Transfer
                        </Button>
                    </div>

                </div>
            </div>

            <Spin spinning={state?.loading}>
                <Table
                    scroll={{ x : 400, }}
                    pagination={{
                        size: 10,
                        showSizeChanger: false,
                        total: state?.stock?.count,
                        current: (filter?.page + 1),
                        onChange: (page) => {
                            const newTimer = setTimeout(() => {
                                setFilter(filter => ({ ...filter, page: page - 1 }));
                            }, 300);
                            setTimer(newTimer);
                        }
                    }}
                    className='hub-table'
                    dataSource={state?.stock?.rows}
                    columns={[
                        {
                            title: 'From',
                            dataIndex: 'main_branch',
                            key: 'main_branch',
                            render: (text) => <span>{text?.name}</span>,
                        },
                        {
                            title: 'To',
                            dataIndex: 'second_branch',
                            key: 'second_branch',
                            render: (text) => <span>{text?.name}</span>,
                        },
                        {
                            title: 'Issue Date',
                            dataIndex: 'created_at',
                            key: 'created_at',
                            render: (text, row, index) => {
                                return (
                                    <>
                                        <div>
                                            <span>{moment(text).format("D MMM YYYY, h:mm:ss a")}</span>
                                        </div>
                                    </>
                                )
                            }
                        },
                        {
                            title: 'Items Issued',
                            dataIndex: 'stock_items',
                            key: 'stock_items',
                            render: (text) => <span>{text.length}</span>,
                        },
                        {
                            title: 'Status',
                            dataIndex: 'status',
                            key: 'status',
                            render: (text, row, index) => {
                                return (
                                    <>
                                        <div>
                                            <Tag color={text == 0 ? 'orange' : text == 1 ? 'green' : 'red'} style={{ border: 'none' }}>
                                                <span>{text == 0 ? 'Pending' : text == 1 ? 'Completed' : 'Void'}</span>
                                            </Tag>
                                        </div>
                                    </>
                                )
                            }
                        },
                        {
                            title: 'Action',
                            key: 'action',
                            width: '10%',
                            render: (text, row, index) => {
                                return (
                                    <>
                                        <div>
                                            <Dropdown
                                                placement='bottomRight'
                                                overlayStyle={{ borderRadius: 12, minWidth: 200 }}
                                                menu={{
                                                    items: [{
                                                        key: '1',
                                                        type: 'group',
                                                        label: "Action",
                                                        children: [{
                                                            key: '1-1',
                                                            label: "View",
                                                            onClick: async () => {
                                                                openDrawer(row, true);
                                                            }
                                                        }, {
                                                            key: '1-2',
                                                            label: "Delete",
                                                            onClick: async () => {
                                                                await onDelete(row);
                                                            }
                                                        },]
                                                    },]
                                                }}
                                            >
                                                <Button size='small' icon={<EllipsisOutlined />} style={{ borderRadius: 6 }} />
                                            </Dropdown>
                                        </div>
                                    </>
                                )
                            }
                        }]}
                />
            </Spin>

            <Drawer title={state?.isView ? 'Stock Transfer Detail' : 'Add Stock Issue'} onOk={() => form.submit()}
                placement="right"
                width={720}
                onClose={onClose}
                open={open}
            >

                <div style={{ marginBottom: 20, fontSize: 14 }}>
                    <Space>
                        <div>{`From : ${state?.item?.main_branch?.name}`}</div>
                        <div>{`To : ${state?.item?.second_branch?.name}`}</div>
                    </Space>

                </div>


                <Table
                    scroll={{ x : 400, }}
                    pagination={false}
                    className='hub-table'
                    dataSource={state?.item?.stock_items}
                    columns={[
                        {
                            title: '#',
                            dataIndex: 'stock_id',
                            key: 'stock_id',
                            render: (text, data, index) => (
                                <span>{index + 1}</span>
                            )
                        },
                        {
                            title: 'Item Code',
                            dataIndex: 'item',
                            key: 'item',
                            render: (text, data) => (
                                <span>{text?.item_code}</span>
                            )
                        },
                        {
                            title: 'Item Name',
                            dataIndex: 'item',
                            key: 'item',
                            render: (text, data) => (
                                <span>{text?.name}</span>
                            )
                        },
                        {
                            title: 'Quantity',
                            dataIndex: 'quantity',
                            key: 'quantity',
                            render: (text, data) => (
                                <span>{text}</span>
                            )
                        },
                    ]}
                />
            </Drawer>

            <Modal
                transitionName="ant-fade"
                maskTransitionName="ant-fade"
                open={state?.filter_modal}
                title={<><span>Filter</span></>}
                width={500}
                closable={false}
                className="custom-modal"
                onOk={onFilter}
                onCancel={() => setState(state => ({ ...state, filter_modal: false, }))}
            >
                <Form
                    form={form1}
                >
                    <div>
                        <Form.Item name='date' label="Date">
                            <DatePicker.RangePicker allowClear />
                        </Form.Item>
                        <Form.Item
                            name="from"
                            label="From"
                        >
                            <Select
                                filterOption={false}
                                showSearch
                                className='border-input'
                                style={{ borderRadius: 6 }}
                                onSearch={fromDoneTyping}
                                allowClear
                            >
                                {
                                    state?.from?.map((item, index) => {
                                        return (
                                            <Option value={item?.value} key={index}>
                                                <div>
                                                    <div><span>{item?.label}</span></div>
                                                    {/* <div><span style={{ fontSize: 12, color: '#8c8c8c' }}>{item?.item_code}</span></div> */}
                                                </div>
                                            </Option>
                                        )
                                    })
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="to"
                            label="To"
                        >
                            <Select
                                filterOption={false}
                                showSearch
                                className='border-input'
                                style={{ borderRadius: 6 }}
                                onSearch={toDoneTyping}
                                allowClear
                            >
                                {
                                    state?.to?.map((item, index) => {
                                        return (
                                            <Option value={item?.value} key={index}>
                                                <div>
                                                    <div><span>{item?.label}</span></div>
                                                    <div><span style={{ fontSize: 12, color: '#8c8c8c' }}>{item?.item_code}</span></div>
                                                </div>
                                            </Option>
                                        )
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </div>

                </Form>
            </Modal>
        </>
    );
}

export default TransferListTable;