import { ArrowDownOutlined } from '@ant-design/icons';
import { PageHeader, Space, Spin, Tag } from 'antd';
import React, { useState, useEffect } from 'react';
import creditNoteService from '../../../services/sales/credit_note.service';

const SaleCreditNoteView = ({credit_note_id, refresh}) => {
    const [state, setState] = useState({
        loading : false,
        credit_note : null,
    });

    const fetchCreditNote = async () => {
        setState(state => ({...state, loading : true}));
        const creditNoteResponse = await creditNoteService.getCreditNotes({
            credit_note_id,
        });

        const credit_note = creditNoteResponse?.credit_notes?.rows[0];
        setState(state => ({...state, credit_note : credit_note, loading : false,}));
    }

    useEffect(() => {
        fetchCreditNote();
    }, [credit_note_id, refresh])
    
    return (
        <>
            <Spin spinning={state?.loading}>
                <div style={{padding:12}}>
                    <div>
                        <Tag style={{width:'100%', border:'none'}}>
                            <div style={{display:'flex', alignItems:'center'}}>
                                <div>
                                    <div>
                                        <span style={{fontSize:14}}>{state?.credit_note?.return_item?.name}</span>
                                    </div>
                                    <div>
                                        <span>{state?.credit_note?.return_item?.item_code}</span>
                                    </div>
                                </div>
                                <div style={{marginLeft:'auto'}}>
                                    <div>
                                        <span>{`MYR ${state?.credit_note?.return_item_unit_price * state?.credit_note?.return_quantity}`}</span>
                                    </div>
                                </div>
                            </div>
                            
                        </Tag>
                    </div>
                    <div style={{margin:'auto', textAlign:'center', marginTop:12}}>
                        <ArrowDownOutlined />
                    </div>
                    <div style={{marginTop:12}}>
                        <Space direction='vertical' style={{width:'100%'}}>
                        {
                            state?.credit_note?.credit_note_exchange_items?.map((item, index) => {
                                return (
                                    <div key={index}>
                                        <Tag style={{width:'100%', border:'none'}} color={'purple'}>
                                            <div style={{display:'flex', alignItems:'center'}}>
                                                <div>
                                                    <div>
                                                        <span style={{fontSize:14}}>{item?.item?.name}</span>
                                                    </div>
                                                    <div>
                                                        <span>{item?.item?.item_code}</span>
                                                    </div>
                                                </div>
                                                <div style={{marginLeft:'auto'}}>
                                                    <div>
                                                        <span>{`MYR ${item?.unit_price * item?.quantity}`}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </Tag>
                                    </div>
                                )
                            })
                        }
                        </Space>
                    </div>
                </div>
            </Spin>
        </>
    );
}

export default SaleCreditNoteView;