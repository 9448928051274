import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import AuthRouter from '../../routes/auth.router';
import authenticationService from '../../services/administrator/authentication.service';
import LoginForm from './components/login_form';
import LoginWrapper from './components/login_wrapper';
import './login.css';

const LoginIndex = () => {
    const history = useHistory();
    const [state, setState] = useState({
        loading : false,
    })
    //temporary auto login
    const login = async () => {
        setState(state => ({...state, loading : true}));
        const loginResponse = await authenticationService?.login('kboohan', 'test123');
        if(loginResponse){
            setTimeout(() => {
                setState(state => ({...state, loading : false}));
                
            }, 800);
            
        }
    }

    useEffect(() => {
    }, [])

    return (
        <>
            <LoginWrapper>
                <LoginForm />
            </LoginWrapper>
        </>
    );
}

export default LoginIndex;