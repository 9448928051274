import { DownOutlined } from '@ant-design/icons';
import { Button, Divider, Dropdown, Form, Modal, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import customerService from '../../../services/customer/customer.service';
import SaleListTable from '../../sale/components/sale_list_table';

const { confirm } = Modal;
const CustomerDetailInfo = ({customer_id, edit}) => {
    const [form] = Form.useForm();
    const [state, setState] = useState({
        loading : false,
        customer : null,
        edit_modal : false,
    });

    const fetchCustomers = async () => {
        setState(state => ({...state, loading : true}));
        const customerResponse = await customerService.getCustomers({
            customer_id,
        });

        setState(state => ({...state, loading : false, customer : customerResponse?.customers}));
    }

    const openEditModal = () => {
        setState(state => ({...state, edit_modal : true}));
        // form.setFieldsValue({})
    }

    const deleteConfirmationModal = async () => {
        confirm({
            title : 'Do you want to delete customer?',
            onOk(){
                return new Promise((resolve, reject) => {
                    setTimeout(async () => {
                        // await deleteCreditNote(credit_note_id);
                        resolve();
                    }, 500);
                }).catch((err) => {

                })
            }
        })
    }

    useEffect(() => {
        fetchCustomers();
    }, []);

    return (
        <>
            <div style={{display:'flex', alignItems:'center'}}>
                <div>
                    <div style={{textAlign:'left'}}>
                        <div>
                            <Space>
                                <div>
                                    <span style={{fontSize:24, fontWeight:500}}>{state?.customer?.name ?? 'Anonymous'}</span>
                                </div>
                            </Space>
                        </div>
                        <div>
                            <span>
                                <span style={{fontSize:18, color:'#595959'}}>{`${state?.customer?.phone_no}`}</span>
                            </span>
                        </div>
                    </div>
                </div>
                {
                    edit &&
                    (
                        <>
                            <div style={{marginLeft:'auto'}}>
                                <Dropdown
                                placement='bottomRight'
                                overlayStyle={{borderRadius:12, minWidth:200}}
                                menu={{
                                    items : [{
                                        key: '1',
                                        type: 'group',
                                        label: "Action",
                                        children : [{
                                            key : '1-1',
                                            label : "Edit",
                                            onClick : async () => {
                                                openEditModal();
                                            }
                                        },{
                                            key : '1-2',
                                            label : "Delete",
                                            onClick : async () => {
                                                deleteConfirmationModal();
                                            }
                                        },]
                                    },]
                                }}
                                >
                                    <Button type='primary' className='mini-button' icon={<DownOutlined />} style={{borderRadius:4}}>
                                        Action
                                    </Button>
                                </Dropdown>
                            </div>
                        </>
                    )
                }
            </div>

            
            <div style={{textAlign:'left', padding : '12px 0', marginTop: 24}}>
                <div style={{display:'flex', alignItems:'center'}}>
                    <div>
                        <span style={{fontWeight:500, fontSize:16}}>Sales History</span>
                    </div>
                </div>
                <Divider style={{marginTop: 12}}/>
                <div>
                {
                    state?.customer &&
                    (
                        <>
                            <SaleListTable customer_id={state?.customer?.customer_id} />
                        </>
                    )
                }
                </div>
            </div>
            
            <Modal
            transitionName="ant-fade"
            maskTransitionName="ant-fade"
            open={state?.edit_modal}
            title={<><span>Edit Customer Detail</span></>}
            width={500}
            closable={false}
            className="custom-modal"
            okButtonProps={{
                form : 'customer_form',
                key : "submit",
                htmlType : "submit"
            }}
            onOk={() => form.submit()}
            onCancel={() => setState(state => ({...state, edit_modal : false,}))}
            >
            </Modal>
        </>
    );
}

export default CustomerDetailInfo;