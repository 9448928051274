import { EllipsisOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Divider, Dropdown, Form, Modal, Space, Spin, Table, Tag, notification, } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import creditNoteService from '../../../services/sales/credit_note.service';
import SaleCreditNoteAdd from './sale_credit_note_add';
import { useDispatch, useSelector } from 'react-redux';
import { refresh_sale_detail, set_credit_note_data } from '../../../actions/sales_order.action';
import SaleCreditNoteView from './sale_credit_note_view';

const { confirm } = Modal;

const SaleCreditNote = ({ sale_id, edit, status_editable }) => {
    const dispatch = useDispatch();
    const sales_order_redux = useSelector(state => state.sales_order);
    const [form] = Form.useForm();
    const [state, setState] = useState({
        credit_notes: [],
        loading: false,
        edit_modal: false,
        refresh: 0,
        edit_modal_id: null,

        //view modal
        view_modal : false,
        view_modal_id : null,
    });

    const addCreditNote = async () => {
        form.validateFields()
            .then(async (values) => {
                const { amount, remarks, return_item, return_quantity, exchange_items, return_item_unit_price } = form.getFieldValue();
                let creditNoteResponse;

                // this one i comment it first because might be refund item (boo han)
                // if (amount < 0) {
                //     setTimeout(() => {
                //         notification.warning({
                //             message: "Amount detected less than 0",
                //         });
                //         // setState(state => ({ ...state, customer_edit: true }));
                //     }, 500);
                //     return;
                // }
                if (state?.edit_modal_id) {
                    //run update service
                    creditNoteResponse = await creditNoteService.updateCreditNotes(state?.edit_modal_id, {
                        amount: amount.toFixed(2),
                        remarks: remarks,
                        return_item_id: return_item,
                        return_quantity: return_quantity,
                        exchange_items: exchange_items,
                        return_item_unit_price: return_item_unit_price
                    })
                } else {
                    creditNoteResponse = await creditNoteService.storeCreditNotes({
                        sale_id: sale_id,
                        amount: amount.toFixed(2),
                        remarks: remarks,
                        return_item_id: return_item,
                        return_quantity: return_quantity,
                        exchange_items: exchange_items,
                        return_item_unit_price: return_item_unit_price
                    })
                }

                //refresh detail
                dispatch(refresh_sale_detail(sales_order_redux?.sale_refresh + 1));
                setState(state => ({ ...state, refresh: (state?.refresh + 1), edit_modal: false, }));
                form.resetFields();
                dispatch(set_credit_note_data(null));
            })
            .catch(() => {
            });
    }

    const fetchCreditNote = async () => {
        setState(state => ({ ...state, loading: true }));

        const creditNoteResponse = await creditNoteService.getCreditNotes({
            sale_id: sale_id,
        })
        const credit_notes = creditNoteResponse?.credit_notes?.rows?.map((x, i) => {
            return ({
                ...x, key : i
            })
        });
        
        setState(state => ({ ...state, credit_notes: credit_notes, loading: false }));
    }

    const openEditModal = async ({ row = null }) => {
        dispatch(set_credit_note_data(row));
        setState(state => ({ ...state, edit_modal: true, edit_modal_id: row?.credit_note_id }));
    }

    const openViewModal = async ({ row = null }) => {
        setState(state => ({...state, view_modal_id : row?.credit_note_id, view_modal : true }));
    }

    const deleteConfirmationModal = async (credit_note_id) => {
        confirm({
            title: 'Do you want to delete this credit note?',
            onOk() {
                return new Promise((resolve, reject) => {
                    setTimeout(async () => {
                        await deleteCreditNote(credit_note_id);
                        resolve();
                    }, 500);
                }).catch((err) => {

                })
            }
        })
    }

    const deleteCreditNote = async (credit_note_id) => {
        const creditNoteResponse = await creditNoteService.deleteCreditNotes(credit_note_id);
        //refresh detail
        dispatch(refresh_sale_detail(sales_order_redux?.sale_refresh + 1));
        setState(state => ({ ...state, refresh: (state?.refresh + 1) }));
    }

    useEffect(() => {
        fetchCreditNote();
    }, [state?.refresh])

    return (
        <>
            <div style={{ textAlign: 'left', padding: '12px 0' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div>
                        <span style={{ fontWeight: 500, fontSize: 16 }}>Credit Notes</span>
                    </div>
                    {
                        edit && (status_editable) &&
                        (
                            <>
                                <div style={{ marginLeft: 'auto' }}>
                                    <span onClick={() => openEditModal({})} className='mini-button mini-style-text'>
                                        Add Credit Note
                                    </span>
                                </div>
                            </>
                        )
                    }

                </div>
            </div>
            <Divider style={{ marginTop: 12 }} />
            <Spin spinning={state?.loading}>
                <Table
                    expandable={{
                        expandedRowRender: (record) => (
                            <SaleCreditNoteView credit_note_id={record.credit_note_id} refresh={state?.refresh} />
                        ),
                        // rowExpandable: (record) => record.name !== 'Not Expandable',
                    }}
                    pagination={false}
                    className='hub-table'
                    dataSource={state?.credit_notes}
                    columns={[{
                        title: 'Credit Note',
                        dataIndex: 'running_number',
                        key: 'running_number',
                    }, {
                        title: 'Issue Date',
                        dataIndex: 'created_at',
                        key: 'created_at',
                        render: (text, row, index) => {
                            return (
                                <>
                                    <div>
                                        <span>{moment(text).format("D MMM YYYY, h:mm:ss a")}</span>
                                    </div>
                                </>
                            )
                        }
                        
                    }, {
                        title: 'Amount (RM)',
                        dataIndex: 'amount',
                        key: 'amount',
                    }, {
                        title: 'Remarks',
                        dataIndex: 'remarks',
                        key: 'remarks'
                    }, {
                        title: 'Status',
                        dataIndex: 'credit_note_status',
                        key: 'credit_note_status',
                        render: (text, row, index) => {
                            return (
                                <>
                                    <div>
                                        <Tag color={text?.color} style={{ border: 'none' }}>
                                            <span>{text?.name}</span>
                                        </Tag>
                                    </div>
                                </>
                            )
                        }
                    }, {
                        ...edit && (status_editable) && ({
                            // title : 'Action',
                            key: 'action',
                            dataIndex: 'credit_note_id',
                            width: '10%',
                            render: (text, row, index) => {
                                return (
                                    <>
                                        <div>
                                            <Dropdown
                                                placement='bottomRight'
                                                overlayStyle={{ borderRadius: 12, minWidth: 200 }}
                                                menu={{
                                                    items: [{
                                                        key: '1',
                                                        type: 'group',
                                                        label: "Action",
                                                        children: [{
                                                            key: '1-1',
                                                            label: "Edit",
                                                            onClick: async () => {
                                                                openEditModal({ row })
                                                            }
                                                        }, {
                                                            key: '1-2',
                                                            label: "Delete",
                                                            onClick: async () => {
                                                                deleteConfirmationModal(text);
                                                            }
                                                        },]
                                                    },]
                                                }}
                                            >
                                                <Button size='small' icon={<EllipsisOutlined />} style={{ borderRadius: 6 }} />
                                            </Dropdown>
                                        </div>
                                    </>
                                )
                            }
                        })
                    }]}
                />
            </Spin>

            <Modal
                open={state?.edit_modal}
                transitionName="ant-fade"
                maskTransitionName="ant-fade"
                title={<><span>Add Credit Note</span></>}
                width={500}
                closable={false}
                className="custom-modal"
                onOk={() => form.submit()}
                onCancel={() => {
                    form.resetFields();
                    dispatch(set_credit_note_data(null));
                    setState(state => ({ ...state, edit_modal: false, edit_modal_id: null }));
                }}
            >
                <Form
                    id='credit_note_form'
                    onFinish={addCreditNote}
                    form={form}
                    layout='vertical'
                >
                    <SaleCreditNoteAdd sale_id={sale_id} form={form} />
                </Form>
            </Modal>

            <Modal
            open={state?.view_modal}
            transitionName="ant-fade"
            maskTransitionName="ant-fade"
            title={<><span>Credit Note</span></>}
            width={500}
            // closable={false}
            footer={false}
            className="custom-modal"
            onCancel={() => {
                setState(state => ({ ...state, view_modal_id: null, view_modal : false }));
            }}
            >
                
            </Modal>
        </>
    );
}

export default SaleCreditNote;