import { SearchOutlined, FilterOutlined } from '@ant-design/icons';
import { Button, Input, Form, Spin, Table, Tag, Space, Modal, Select } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import InputWrapper from '../../../helpers/components/input_wrapper.component';
import purchaseOrderService from '../../../services/purchase_order/purchase_order.service';
import supplierService from '../../../services/config/supplier.service';

const { Option } = Select;

const OrderListTable = () => {
    const [form] = Form.useForm();

    const [state, setState] = useState({
        loading: false,
        orders: [],
        order_count: 0,
        filter_modal: false,
        supplier: [],

    })

    const [filter, setFilter] = useState({
        page: 1,
        search: null,
        supplier: null,
    });
    const [timer, setTimer] = useState(null)

    const fetchSupplier = async () => {
        const supplierResponse = await supplierService.getSupplier();
        const supplier = [];
        supplierResponse?.supplier.forEach((data, index) => {
            supplier[index] = { value: data.supplier_id, label: data.name };
        });
        setState(state => ({
            ...state,
            supplier: supplier,
        }))
    }
    const fetchOrderList = async () => {
        setState(state => ({ ...state, loading: true, orders: [] }));
        const orderResponse = await purchaseOrderService.getOrder({
            page: (filter?.page - 1),
            size: 12,
            search: filter?.search,
            supplier: filter?.supplier,

        });
        setTimeout(() => {

            setState(state => ({ ...state, orders: orderResponse?.orders?.rows, loading: false, order_count: orderResponse?.orders?.count, }));
        }, 800);
    }
    const onFilter = async () => {
        const { supplier } = form.getFieldsValue();

        setFilter(filter => ({ ...filter, supplier: supplier, }));
        setState(state => ({ ...state, filter_modal: false, }));
    }

    const doneTyping = async (input) => {
        clearTimeout(timer);
        const newTimer = setTimeout(() => {
            setFilter(filter => ({ ...filter, search: input, page: 1, }));
        }, 300);
        setTimer(newTimer);
    }

    useEffect(() => {
        fetchOrderList();
    }, [filter])
    useEffect(() => {
        fetchSupplier();
    }, [])
    return (
        <>
            <div className='filter-panel' style={{ height: 60, textAlign: 'left' }}>
                <Space>
                    <div>
                        <InputWrapper>
                            <Input
                                onChange={(e) => doneTyping(e.target.value)}
                                bordered={false}
                                prefix={<div style={{ paddingRight: 12, paddingLeft: 8 }}><SearchOutlined style={{ color: '#8c8c8c' }} /></div>}
                                placeholder={`Search Purchase Order`}
                                style={{ fontSize: 14, width: 400 }}
                                size='large'
                            />
                        </InputWrapper>
                    </div>

                    <div>
                        <Button
                            icon={<FilterOutlined style={{ color: '#8c8c8c' }} />}
                            onClick={() => {
                                setState(state => ({ ...state, filter_modal: true }));
                            }}
                            style={{ borderRadius: 6 }}
                        />
                    </div>
                </Space>

            </div>
            <Spin spinning={state?.loading}>
                <Table
                    pagination={{
                        pageSize: 12,
                        showSizeChanger: false,
                        total: state?.order_count,
                        current: filter?.page,
                        onChange: (page) => setFilter(filter => ({ ...filter, page: page }))
                    }}
                    className='hub-table'
                    dataSource={state?.orders}
                    columns={[{
                        title: 'Transaction No',
                        dataIndex: 'running_number',
                        key: 'running_number',
                    }, {
                        title: 'Supplier',
                        dataIndex: 'supplier',
                        key: 'supplier',
                        render: (text, row, index) => {
                            return (
                                <>
                                    {
                                        text ?
                                            (
                                                <>
                                                    <div>
                                                        <div>
                                                            <span style={{ fontWeight: 500 }}>{text?.name}</span>
                                                        </div>
                                                        <div>
                                                            <span style={{ color: '#8c8c8c', fontSize: 12 }}>+60{text?.phone_number}</span>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                            :
                                            (
                                                <>
                                                    <div>
                                                        <span style={{ color: '#8c8c8c', fontSize: 12 }}>No Supplier Record</span>
                                                    </div>
                                                </>
                                            )
                                    }

                                </>
                            )
                        }
                    }, {
                        title: 'Issue Date',
                        dataIndex: 'created_at',
                        key: 'created_at',
                        render: (text, row, index) => {
                            return (
                                <>
                                    <div>
                                        <span>{moment(text).format("D MMM YYYY, h:mm:ss a")}</span>
                                    </div>
                                </>
                            )
                        }
                    }, {
                        title: 'Amount',
                        dataIndex: 'total_amount',
                        key: 'total_amount',
                        render: (text, row, index) => {
                            return (
                                <>
                                    <div>
                                        <span>{`RM${text}`}</span>
                                    </div>
                                </>
                            )
                        }
                    }, {
                        title: 'Status',
                        dataIndex: 'status',
                        key: 'status',
                        render: (text, row, index) => {
                            return (
                                <>
                                    <div>
                                        <Tag color={text == 0 ? 'orange' : text == 1 ? 'green' : 'red'} style={{ border: 'none' }}>
                                            <span>{text == 0 ? 'Pending' : text == 1 ? 'Completed' : 'Void'}</span>
                                        </Tag>
                                    </div>
                                </>
                            )
                        }
                    }, {
                        title: 'Action',
                        key: 'action',
                        width: '10%',
                        render: (text, row, index) => {
                            return (
                                <>
                                    <div>
                                        <Link to={`/purchases/order/${row?.order_id}`}>
                                            <Button type='default' style={{ borderRadius: 6 }}>
                                                <span style={{ color: '#8c8c8c' }}>View</span>
                                            </Button>
                                        </Link>
                                    </div>
                                </>
                            )
                        }
                    }]}
                />
            </Spin>

            <Modal
                transitionName="ant-fade"
                maskTransitionName="ant-fade"
                open={state?.filter_modal}
                title={<><span>Filter</span></>}
                width={500}
                closable={false}
                className="custom-modal"
                // okButtonProps={{
                //     form : 'sale_form',
                //     key : "submit",
                //     htmlType : "submit"
                // }}
                onOk={() => form.submit()}
                onCancel={() => setState(state => ({ ...state, filter_modal: false, }))}
            >
                <Form
                    id="filter_form"
                    onFinish={onFilter}
                    form={form}
                    layout='vertical'
                >
                    <div>
                        <Form.Item name='supplier' label="Supplier">
                            <Select
                                showSearch
                                defaultValue={filter?.supplier}
                                className='border-input'
                                style={{ borderRadius: 6 }}
                                allowClear
                                options={state?.supplier}
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                            >
                            </Select>
                        </Form.Item>
                    </div>

                </Form>
            </Modal>
        </>
    );
}

export default OrderListTable;