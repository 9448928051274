import React from 'react';

const ReportSummary = ({ sales }) => {
    const total_gross_profit = sales?.reduce((a, b) => (a + b?.profit), 0);
    const total_sale_amount = sales?.reduce((a, b) => (a + b?.total_amount), 0);
    const gp_percentage = ((total_gross_profit / total_sale_amount) * 100);
    return (
        <>
            <div style={{padding:'0 24px', paddingTop:24,}}>
                <div style={{textAlign:'left', color:'#7e7e7e'}}>
                    <span>Total GP</span>
                </div>
                <div style={{textAlign:'left'}}>
                    <div>
                        <span style={{fontSize:24, fontWeight:'bold', color:'#595959'}}>{`MYR ${total_gross_profit?.toFixed(2)}`}</span>
                    </div>

                    <div>
                        <span style={{fontSize:18, fontWeight:'bold', color:'#8c8c8c'}}>{`${ isNaN(gp_percentage) ? "0" : gp_percentage?.toFixed(2)}%`}</span>
                    </div>
                </div>
                
            </div>
        </>
    );
}

export default ReportSummary;