import axios from 'axios';
import serviceHelper from '../../helpers/functions/service.helper';

async function getSaleItems(query){
    const queryString = serviceHelper.queryString(query);
    const saleItemsResponse = await axios.get(`${process.env.REACT_APP_SERVER_URL}sales/item/get${(queryString ? ("?" + queryString) : "")}`, { withCredentials : true });
    return saleItemsResponse?.data;
}

async function createSaleItems(params = {}){
    const { sale_id, item_id, unit_price, quantity } = params;
    const saleItemsResponse = await axios.post(`${process.env.REACT_APP_SERVER_URL}sales/item/store`, {
        sale_id, item_id, unit_price, quantity
    }, { withCredentials : true });

    return saleItemsResponse?.data;
}

async function updateSaleItems(sale_item_id, params = {}){
    const { item_id, unit_price, quantity } = params;
    const saleItemsResponse = await axios.post(`${process.env.REACT_APP_SERVER_URL}sales/item/update/${sale_item_id}`, {
        item_id, unit_price, quantity
    }, { withCredentials : true });

    return saleItemsResponse?.data;
}

async function deleteSaleItems(sale_item_id){
    const saleItemsResponse = await axios.delete(`${process.env.REACT_APP_SERVER_URL}sales/item/delete/${sale_item_id}`, {withCredentials : true});
    return saleItemsResponse?.data;
}

async function calculateItemPrice(query){
    const queryString = serviceHelper.queryString(query);
    const itemPriceResponse = await axios.get(`${process.env.REACT_APP_SERVER_URL}sales/item/price?${queryString}`, {withCredentials : true});

    return itemPriceResponse?.data;
}

export default {
    getSaleItems,
    createSaleItems,
    updateSaleItems,
    deleteSaleItems,
    calculateItemPrice,
}