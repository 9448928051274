import { Card, List, Space, Spin, Tag } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import checkoutService from '../../../services/sales/checkout.service';

const PendingSales = () => {
    const [state, setState] = useState({
        loading : false,
        pending_sales : [],
    })

    const fetchPendingSales = async () => {
        setState(state => ({...state, loading : true}));
        const pendingSalesResponse = await checkoutService?.getSales({
            status : 1,
            size : 5,
        })

        setTimeout(() => {
            setState(state => ({...state, pending_sales : pendingSalesResponse?.sales?.rows, loading : false,}));
        }, 500);
    }

    useEffect(() => {
        fetchPendingSales();
    }, [])

    return (
        <>
            <Card
            style={{ borderRadius: 6, height:'100%' }}
            bordered={false}
            >
                <Spin spinning={state?.loading}>
                    <div>
                        <div style={{textAlign:'left'}}>
                            <span style={{fontWeight: 500, color:'#414552', fontSize:14}}>Pending Sales</span>
                        </div>
                    </div>
                    <div style={{marginTop:24}}>
                        <List
                        dataSource={state?.pending_sales}
                        size={'large'}
                        renderItem={(item, index) => {
                            function _pad(num, size) {
                                num = num.toString();
                                while (num.length < size) num = "0" + num;
                                return num;
                            }
                            const running_number = `#${_pad((item?.sale_id), 8)}`;
                            return (
                                <List.Item key={index} style={{padding:'12px 0'}}>
                                    <List.Item.Meta
                                    style={{textAlign:'left'}}
                                    // avatar={<Avatar src={item.picture.large} />}
                                    // title={<span>{item?.customer?.name}</span>}
                                    description={
                                        <Space>
                                            <div>
                                                <Link to={`sales/order/${item?.sale_id}`}>
                                                    <span>
                                                        {running_number}
                                                    </span>
                                                </Link>
                                            </div>
                                            <div>
                                                <span>{`MYR ${item?.total_amount?.toFixed(2)}`}</span>
                                            </div>
                                        </Space>
                                    }
                                    />
                                    <div>
                                        <Tag color={'orange'} style={{border:'none', borderRadius:4, minWidth:40}}>
                                            <span>{item?.sale_status?.name}</span>
                                        </Tag>
                                    </div>
                                </List.Item>
                            )
                        }}
                        />
                    </div>

                    <div style={{marginTop:24, textAlign:'left'}}>
                        <Link to="sales/list?status=1">
                            <span className='mini-button mini-style-text'>
                                View All Pending Sales
                            </span>
                        </Link>
                    </div>
                </Spin>
            </Card>
        </>
    );
}

export default PendingSales;