import { LogoutOutlined } from '@ant-design/icons';
import { Avatar, Col, Divider, Dropdown, Image, Menu, Row, Select, } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import { set_branch } from '../../actions/auth.action';
import authenticationService from '../../services/administrator/authentication.service';
import branchService from '../../services/config/branch.service';
import axios from 'axios';

const MasterHeader = () => {
    const { innerWidth } = window;
    const dispatch = useDispatch();
    const authRedux = useSelector(state => state.auth);
    const history = useHistory();
    const handleLogout = async () => {
        var logoutResponse = await authenticationService.logout();

        //clear local storage
        localStorage.removeItem("branch");
        localStorage.removeItem("company");
        if (logoutResponse) {
            history.go(0);
        }
    }
    const [state, setState] = useState({
        branch: null,
    });

    const fetchBranch = async () => {

        const branchResponse = await branchService.getBranch();

        const branches = [];
        branchResponse?.branch.forEach((data, index) => {
            const branch_name = `${data?.name} - ${data?.company?.name}`;
            branches[index] = { value: data.branch_id, label: branch_name, company_id : data?.company_id };
        });

        setTimeout(() => {
            setState(state => ({
                ...state,
                branch: branches
            }))
        }, 800);

    }

    useEffect(() => {
        fetchBranch();
    }, [])

    return (
        <>
            <Row>
                <Col xs={0} md={8} span={8}>
                    <div style={{textAlign:'start'}}>
                        <Image preview={false} src='/banners/logo-long.png' style={{maxHeight:30}} />
                    </div>
                </Col>
                <Col xs={16} md={8} span={8}>
                    <div>
                        <Select
                            placeholder="Branch"
                            defaultValue={localStorage.getItem('branch') == null ? localStorage.getItem('branch') : localStorage.getItem('branch') * 1}
                            style={{
                                width: innerWidth >= 768 ? 400 : '100%',
                                // maxWidth: 400,
                                // minWidth: 200,
                            }}
                            options={state?.branch}
                            onChange={(value) => {
                                dispatch(set_branch(value));
                                axios.defaults.headers.common['branch_id'] = value;
                                localStorage.setItem('branch', value);
                                
                                //company
                                const company_id = (state?.branch?.find(x => x?.value == value)?.company_id);
                                axios.defaults.headers.common['company_id'] = company_id;
                                localStorage.setItem('company', company_id)
                            }}
                        />
                    </div>
                </Col>
                <Col xs={8} span={8}>
                    <div style={{textAlign:'end'}}>
                        <Dropdown
                            arrow
                            menu={{
                                items: [
                                    {
                                        key: '1',
                                        disabled: true,
                                        style: { padding: '5px 60px' },
                                        label: (
                                            <>
                                                <div style={{ margin: 'auto', textAlign: 'center' }}>
                                                    <div style={{ paddingBottom: 10 }}>
                                                        <Avatar style={{ backgroundColor: '#fde3cf', color: '#f56a00' }}>{authRedux?.user?.username?.charAt(0)}</Avatar>
                                                    </div>
                                                    <div>
                                                        <span style={{ color: '#000' }}>{authRedux?.user?.username}</span>
                                                    </div>
                                                </div>
                                            </>
                                        ),
                                    },
                                    {
                                        key: '2',
                                        // icon: <LogoutOutlined />,
                                        // onClick: handleLogout,
                                        label: (
                                            <>
                                                <Link to="/profile/change_password">
                                                    <span>Change Password</span>
                                                </Link>
                                            </>
                                        ),
                                    },
                                    {
                                        type: 'divider',
                                    },
                                    {
                                        key: '3',
                                        icon: <LogoutOutlined />,
                                        onClick: handleLogout,
                                        label: (
                                            <>
                                                <span>
                                                    Logout
                                                </span>
                                            </>
                                        ),
                                    },
                                ]
                            }}
                            trigger={['click']}>
                            <Avatar style={{ backgroundColor: '#fde3cf', color: '#f56a00', cursor: 'pointer' }}>{authRedux?.user?.username?.charAt(0)}</Avatar>
                        </Dropdown>
                    </div>
                </Col>
            </Row>
            <div style={{ display: 'flex' }}>
                
                
                
            </div>
        </>
    );
}

export default MasterHeader;