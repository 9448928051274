import axios from 'axios';
import serviceHelper from '../../helpers/functions/service.helper';


async function getOrderItems(query) {
    const queryString = serviceHelper.queryString(query);
    const orderItemsResponse = await axios.get(`${process.env.REACT_APP_SERVER_URL}purchaseorder/item/get${(queryString ? ("?" + queryString) : "")}`, { withCredentials: true });
    return orderItemsResponse?.data;
}

async function createOrderItems(params = {}) {
    const { order_id, item_id, unit_price, quantity, free } = params;
    const orderItemsResponse = await axios.post(`${process.env.REACT_APP_SERVER_URL}purchaseorder/item/store`, {
        order_id, item_id, unit_price, quantity, free
    }, { withCredentials: true });

    return orderItemsResponse?.data;
}

async function updateOrderItems(order_item_id, params = {}) {
    const { order_id, item_id, unit_price, quantity, free } = params;
    const orderItemsResponse = await axios.post(`${process.env.REACT_APP_SERVER_URL}purchaseorder/item/update/${order_item_id}`, {
        order_id, item_id, unit_price, quantity, free
    }, { withCredentials: true });

    return orderItemsResponse?.data;
}

async function deleteOrderItems(order_item_id, params = {}) {
    const { order_id } = params;
    const orderItemsResponse = await axios.post(`${process.env.REACT_APP_SERVER_URL}purchaseorder/item/delete/${order_item_id}`, {
        order_id
    }, { withCredentials: true });
    return orderItemsResponse?.data;
}

export default {
    getOrderItems,
    createOrderItems,
    updateOrderItems,
    deleteOrderItems,
}