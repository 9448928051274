import { Col, Divider, Row, Skeleton, Space, Tag } from 'antd';
import moment from 'moment';
import React , { useEffect, useState }from 'react';
import checkoutService from '../../../services/sales/checkout.service';
import SaleOrderDetail from './sale_order_detail';

const CreateSaleConfirmation = ({sale_id}) => {
    const [state, setState] = useState({
        loading : false,
        sale : null,
    });
    const fetchSaleOrder = async () => {
        setState(state => ({...state, loading : true}));
        const salesResponse = await checkoutService.getSaleById(sale_id);
        const sale = salesResponse?.sales;

        setTimeout(() => {
            setState(state => ({...state, loading : false, sale : sale}));
        }, 0);
    }

    useEffect(() => {
        fetchSaleOrder();
    }, [sale_id])

    return (
        <>
            <SaleOrderDetail sale_id={sale_id} refresh={sale_id} edit={false} />
        </>
    );
}

export default CreateSaleConfirmation;