export const set_purchase_items = (purchase_items) => {
    return {
        type: 'SET_PURCHASE_ITEMS',
        purchase_items
    };
}

export const set_update_order = (update_order) => {
    return {
        type: 'SET_UPDATE_ORDER',
        update_order
    };
}

export const set_supplier_id = (supplier_id) => {
    return {
        type: 'SET_SUPPLIER_ID',
        supplier_id
    };
}

export const set_location_id = (location_id) => {
    return {
        type: 'SET_LOCATION_ID',
        location_id
    };
}

export const refresh_order_detail = (refresh) => {
    return {
        type: 'REFRESH_ORDER_DETAIL',
        refresh
    };
}
