import { LoadingOutlined, PlusOutlined, DownOutlined } from '@ant-design/icons';
import { Button, Card, Col, Divider, Row, Spin, Space, Select, Form, Modal, DatePicker, Dropdown, Alert } from 'antd';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Link } from 'react-router-dom';
import chartService from '../../../services/sales/chart.service';
import globalService from '../../../helpers/functions/global.helper';
import * as XLSX from "xlsx";
import exportExcelService from '../../../services/excel/exportExcel.service';
import branchService from '../../../services/config/branch.service';

const TodayReport = () => {
    const canvasRef = useRef();
    const [form] = Form.useForm();
    const [form1] = Form.useForm();
    const [dates, setDates] = useState(null);
    const [value, setValue] = useState(null);
    const [state, setState] = useState({
        loading: false,
        exportSalesOrderLoading: false,
        exportBranchLoading: false,
        printModal: false,
        data: null,
        sales_amount: 0,
        branch: [],
        filter_modal: false,
        filter_mode: null, //1=salesbilling,2=purchaseorder
        branchInput: null,
        dropdown: [
            {
                key: '1',
                type: 'group',
                label: 'Export Excel',
                children: [
                    {
                        key: '1-1',
                        label: 'Sales Billing',
                        onClick: () => {
                            setState(state => ({ ...state, filter_modal: true, filter_mode: 1 }));
                        }
                    },
                    {
                        key: '1-2',
                        label: 'Purchase Order',
                        onClick: () => {
                            setState(state => ({ ...state, filter_modal: true, filter_mode: 2 }));
                        }
                    },
                    {
                        key: '1-3',
                        label: 'Branch Stock',
                        onClick: () => {
                            setState(state => ({ ...state, printModal: true }));
                        }
                    }
                ]
            },
        ]
    })
    const disabledDate = (current) => {
        if (!dates) {
            return false;
        }
        const tooLate = dates[0] && current.diff(dates[0], 'days') >= 31;
        const tooEarly = dates[1] && dates[1].diff(current, 'days') >= 31;
        return !!tooEarly || !!tooLate;
    };
    const onOpenChange = (open) => {
        if (open) {
            setDates([null, null]);
        } else {
            setDates(null);
        }
    };
    var options = {
        responsive: true,
        datasetStrokeWidth: 3,
        pointDotStrokeWidth: 4,
        maintainAspectRatio: false,
        scaleLabel: "1",
        plugins: {
            legend: {
                display: false
            },
            datalabels: {
                display: false,
            },
        },
        scales: {
            x: {
                // grid : {
                //     drawBorder: false,
                //     display : false,
                // },
                ticks: {
                    display: false,
                },
            },
            y: {
                beginAtZero: true,
                grid: {
                    drawBorder: false,
                    display: false,
                },
                ticks: {
                    display: true
                }
            }
        }

    };
    const height = 200;
    const width = "100%";


    const fetchTodaySale = async () => {
        setState(state => ({ ...state, loading: true }));
        const todayChartResponse = await chartService?.getTodayChart();
        return todayChartResponse;
    }

    const initializeChart = (chart_data) => {
        const gradient = canvasRef.current.ctx.createLinearGradient(0, 0, 0, height);
        gradient.addColorStop(0, 'rgba(239, 219, 255, 1)');
        gradient.addColorStop(1, 'rgba(239, 219, 255, 0)');
        const data = {
            labels: chart_data?.map(x => x.hour),
            datasets: [{
                label: 'Sales Amount (MYR)',
                lineTension: 0.4,
                // fill: 'origin',
                // backgroundColor: 'red',
                // backgroundColor : gradient, // Put the gradient here as a fill color
                borderColor: '#722ed1',
                borderWidth: 2,
                pointColor: "#fff",
                pointStrokeColor: '#722ed1',
                pointBackgroundColor: '#d4bff1',
                pointHighlightFill: "#fff",
                pointHighlightStroke: '#722ed1',
                data: chart_data?.map(x => x.sale_amount)
            }]
        }

        return data;
    }
    const fetchBranchOnSearch = async () => {
        let branchResponse;
        branchResponse = await branchService.getBranch({
            search: state?.branchInput,
            // is_hq: false,
        });

        const branch = branchResponse?.branch?.map(x => {
            return {
                value: x.branch_id,
                label: x.name
            }
        })


        setState(state => ({ ...state, branch: branch }));
    }
    const printBranchExcel = () => {
        form.validateFields()
            .then(async (values) => {
                setState(state => ({ ...state, exportBranchLoading: true }));
                const { branch_id, date } = form.getFieldsValue();
                const start_date = date && moment(date[0]).format("YYYY-MM-DD");
                const end_date = date && moment(date).format("YYYY-MM-DD");
                const data = await exportExcelService.exportBranchStockBalance({
                    branch_id: branch_id,
                    start_date: start_date,
                    end_date: end_date,
                });
                // const headers = ['Branch', 'ItemCode', 'ItemName', 'Quantity'];
                setState(state => ({ ...state, exportBranchLoading: false }));
                globalService.exportToExcel(data.listing, XLSX, 'Branch Stock Balance');

            })
            .catch(() => {
                setState(state => ({ ...state, exportBranchLoading: false }));
            });

    }

    const printSaleBillingOrOrder = () => {
        form1.validateFields()
            .then(async (values) => {
                setState(state => ({ ...state, exportSalesOrderLoading: true }));

                const { date } = form1.getFieldsValue();
                const start_date = date && moment(date[0]).format("YYYY-MM-DD");
                const end_date = date && moment(date[1]).format("YYYY-MM-DD");

                if (state?.filter_mode == 1) {
                    const data = await exportExcelService.exportSalesBilling({
                        start_date: start_date,
                        end_date: end_date,
                        branch_id: localStorage.getItem('branch'),
                    });
                    globalService.exportToExcel(data.listing, XLSX, 'Sales Billing');

                } else {
                    const data = await exportExcelService.exportPurchaseOrder({
                        start_date: start_date,
                        end_date: end_date,
                    });
                    globalService.exportToExcel(data.listing, XLSX, 'Purchase Order');

                }

                setState(state => ({ ...state, exportSalesOrderLoading: false }));


            })
            .catch(() => {
                setState(state => ({ ...state, exportSalesOrderLoading: false }));
            });

    }

    useEffect(() => {
        fetchTodaySale().then((sales) => {

            const chart_data = initializeChart(sales?.chart_data);
            setTimeout(() => {
                setState(state => ({ ...state, data: chart_data, sales_amount: sales?.sales_amount, loading: false }))
            }, 500);
        })
        fetchBranchOnSearch();
    }, [])

    return (
        <>
            <div>
                <div style={{ display: 'flex', alignItems: 'end' }}>
                    <div>
                        <div style={{ textAlign: 'left' }}>
                            <span style={{ fontSize: 24, fontWeight: 'bold' }}>{`Today`}</span>
                        </div>
                        <div style={{ textAlign: 'left' }}>
                            <span style={{ color: '#8c8c8c' }}>{moment().format("YYYY-MM-DD")}</span>
                        </div>
                    </div>
                    <div style={{ marginLeft: 'auto' }}>
                        <Space>
                            <Dropdown
                                placement='bottomRight'
                                overlayStyle={{ borderRadius: 12, minWidth: 200 }}
                                menu={{
                                    items: state?.dropdown,
                                }}
                            >
                                <Button type='primary' className='mini-button' icon={<DownOutlined />} style={{ borderRadius: 4 }}>
                                    Export Excel
                                </Button>
                            </Dropdown>

                            <Link to="sales/create">
                                <Button type='primary' className='mini-button' icon={<PlusOutlined />} style={{ borderRadius: 4 }} >
                                    New Sales
                                </Button>
                            </Link>
                        </Space>

                    </div>
                </div>
                <Divider />
                <div style={{ width: width, height: height, padding: '20px 8px' }}>
                    <Spin spinning={state?.loading}>
                        <Row style={{ height: '100%' }} gutter={[60, 20]}>
                            <Col xs={24} md={16} span={16}>
                                <Line
                                    ref={canvasRef}
                                    options={options}
                                    data={(state?.data ?? {
                                        labels: [],
                                        datasets: [],
                                    })}
                                />
                            </Col>
                            <Col xs={24} md={8}>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ textAlign: 'left' }}>
                                        <div>
                                            <span style={{ fontWeight: 500, color: '#414552', fontSize: 14 }}>Sales Amount</span>
                                        </div>
                                        <div>
                                            <span style={{ fontSize: 20 }}>{`MYR ${state?.sales_amount?.toFixed(2)}`}</span>
                                        </div>
                                    </div>

                                    <div style={{ marginLeft: 'auto' }}>
                                        <Link to={`sales/list?start_date=${moment().format("YYYY-MM-DD")}`}>
                                            <span className='mini-button mini-style-text'>
                                                View
                                            </span>
                                        </Link>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Spin>

                </div>
            </div>

            {/* print branch stock balance */}
            <Modal
                transitionName="ant-fade"
                maskTransitionName="ant-fade"
                title={`Export Branch Stock`}
                centered
                okText="Print"
                className="custom-modal"
                open={state?.printModal}
                onOk={printBranchExcel}
                onCancel={() => {
                    form.resetFields();
                    setState(state => ({ ...state, printModal: false }));
                }}
                confirmLoading={state?.exportBranchLoading}
                cancelButtonProps={{ style: { display: 'none' } }}
            >
                <div style={{ paddingBottom: 6 }}>
                    <Alert
                        icon={true}
                        type='warning'
                        message={<span>Kindly allow for a minimum of 3 to 5 minutes for the generation of the results, as it requires a brief period to complete.</span>}
                        style={{ borderRadius: 6 }}
                    />
                </div>
                <Form form={form}>
                    <Form.Item
                        labelCol={{ span: 24 }}
                        label="Branch"
                        name="branch_id"
                        rules={[{ required: true, message: 'Please select a branch' }]}
                    >
                        <Select
                            showSearch
                            className='border-input'
                            options={state?.branch}
                            style={{ borderRadius: 6, width: '100%' }}
                            filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                            placeholder='Branch'
                        />
                    </Form.Item>
                    <Form.Item labelCol={{ span: 24 }} name='date' label="Date" rules={[{ required: true, message: 'Please select date' }]}>
                        <DatePicker
                            allowClear
                        />
                    </Form.Item>
                </Form>

            </Modal>

            <Modal
                centered
                transitionName="ant-fade"
                maskTransitionName="ant-fade"
                open={state?.filter_modal}
                title={<>{state?.filter_mode == 1 ? <span>Export Sales Billing</span> : <span>Export Purchase Order</span>}</>}
                width={500}
                closable={false}
                className="custom-modal"
                onOk={printSaleBillingOrOrder}
                onCancel={() => {
                    form1.resetFields();
                    setState(state => ({ ...state, filter_modal: false, filter_mode: null, }))
                }}
                confirmLoading={state?.exportSalesOrderLoading}
            >
                <Form
                    form={form1}
                >
                    <div>
                        <Form.Item name='date' label="Date">
                            <DatePicker.RangePicker allowClear />
                        </Form.Item>
                    </div>

                </Form>
            </Modal>
        </>
    );
}

export default TodayReport;