import React from 'react';
import ContainerComponent from '../../helpers/components/container.component';
import PageHeaderComponent from '../../helpers/components/page_header.component';
import CreditNoteList from './components/credit_note_list';

const CreditNote = () => {
    return (
        <>
            <ContainerComponent span={22} top={20}>
                <PageHeaderComponent />
                <CreditNoteList />
            </ContainerComponent>
        </>
    );
}

export default CreditNote;