import { SearchOutlined, ExclamationCircleOutlined, EllipsisOutlined } from '@ant-design/icons';
import { Button, Input, Select, Spin, Table, Drawer, Form, Space, notification, Modal, Dropdown, Alert, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import InputWrapper from '../../../helpers/components/input_wrapper.component';
import stockService from '../../../services/stock/stock.service';
import locationService from '../../../services/config/location.service';
import branchService from '../../../services/config/branch.service';
import moment from 'moment';
import { Link, useHistory } from 'react-router-dom';
const { confirm } = Modal;
const { Option } = Select;
const AdjustListTable = () => {
    const [form] = Form.useForm();
    const [state, setState] = useState({
        stock: [],
        loading: false,
        isDisabled: true,
        isView: null,
    });
    const [filter, setFilter] = useState({
        page: 0,
        search: null,
    });

    const [timer, setTimer] = useState(null);
    const [open, setOpen] = useState(false);
    const history = useHistory();


    const fetchStockList = async () => {
        setState(state => ({ ...state, loading: true, }));

        const stockResponse = await stockService.getStockAdjust({
            search: filter?.search,
        });
        setTimeout(() => {
            setState(state => ({
                ...state,
                stock: stockResponse?.stock,
                loading: false,
            }))
        }, 800);

    }


    const doneTyping = async (input) => {
        clearTimeout(timer);
        const newTimer = setTimeout(() => {
            setFilter(filter => ({ ...filter, search: input }));
        }, 300);
        setTimer(newTimer);
    }

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        clearAll()
        setOpen(false);
    };

    const openDrawer = (item, isView) => {
        setState(state => ({
            ...state,
            isDisabled: isView,
            isView: isView,
            item: item
        }));
        if (isView === true) {
            form.setFieldsValue({
                name: item.name,
                description: item.description,
            });
        }

        showDrawer();
    }

    // const onDelete = async (data) => {
    //     confirm({
    //         icon: <ExclamationCircleOutlined />,
    //         content: (
    //             <>
    //                 <Space direction='vertical'>
    //                     <div>
    //                         <span>Are your sure to delete stock adjustment?</span>
    //                     </div>
    //                     <div>
    //                         <Alert
    //                             icon={true}
    //                             type='warning'
    //                             message={<span>This action will revert all the product back to the previous amount.</span>}
    //                             style={{ borderRadius: 6 }}
    //                         />
    //                     </div>
    //                 </Space>
    //             </>
    //         ),
    //         async onOk() {
    //             const deleteStockAdjustResponse = await stockService.deleteStockAdjust(data.stock_adjust_id);
    //             notification.success({
    //                 message: (<span style={{ fontSize: 14, fontWeight: 500, }}>Stock Adjustment Deleted</span>),
    //                 duration: 3,
    //                 placement: 'topRight'
    //             });
    //             clearAll();
    //             refresh();
    //         },
    //         onCancel() {
    //         },
    //     });

    // };


    const addStockAdjust = () => {
        history.push(`/stocks/createadjust`);
    }

    const refresh = () => {
        onClose();
        fetchStockList();
    };

    const clearAll = () => {
        setState(state => ({
            ...state,
            isDisabled: true,
            isView: null,
        }));
        form.resetFields();
    }

    useEffect(() => {
        fetchStockList();
    }, [filter?.search, filter?.page])

    return (
        <>
            <div className='filter-panel' style={{ height: 60, }}>
                <div style={{ display: 'flex' }}>
                    <div style={{ minWidth: 400 }}>
                        <InputWrapper>
                            <Input
                                onChange={(e) => doneTyping(e.target.value)}
                                bordered={false}
                                prefix={<div style={{ paddingRight: 12, paddingLeft: 8 }}><SearchOutlined style={{ color: '#8c8c8c' }} /></div>}
                                placeholder={`Search Branch`}
                                style={{ fontSize: 14 }}
                                size='large'
                            />
                        </InputWrapper>
                    </div>
                    <div style={{ marginLeft: 'auto' }}>
                        <Button
                            type="primary"
                            // icon={<PlusOutlined />}
                            onClick={addStockAdjust}
                            style={{ borderRadius: 6 }}
                        >
                            Add Stock Adjust
                        </Button>
                    </div>

                </div>
            </div>

            <Spin spinning={state?.loading}>
                <Table
                    scroll={{ x: 400, }}
                    pagination={{
                        size: 10,
                        showSizeChanger: false,
                        total: state?.stock?.count,
                        current: (filter?.page + 1),
                        onChange: (page) => {
                            const newTimer = setTimeout(() => {
                                setFilter(filter => ({ ...filter, page: page - 1 }));
                            }, 300);
                            setTimer(newTimer);
                        }
                    }}
                    className='hub-table'
                    dataSource={state?.stock?.rows}
                    columns={[
                        {
                            title: 'Branch',
                            dataIndex: 'branch',
                            key: 'branch',
                            render: (text) => (
                                <span>{text?.name}</span>
                            )
                        },
                        {
                            title: 'Created Date',
                            dataIndex: 'created_at',
                            key: 'created_at',
                            render: (text, row, index) => {
                                return (
                                    <>
                                        <div>
                                            <span>{moment(text).format("D MMM YYYY, h:mm:ss a")}</span>
                                        </div>
                                    </>
                                )
                            }
                        },
                        // {
                        //     title: 'Status',
                        //     dataIndex: 'status',
                        //     key: 'status',
                        //     render: (text, row, index) => {
                        //         return (
                        //             <>
                        //                 <div>
                        //                     <Tag color={text == 0 ? 'orange' : text == 1 ? 'green' : 'red'} style={{ border: 'none' }}>
                        //                         <span>{text == 0 ? 'Pending' : text == 1 ? 'Completed' : 'Void'}</span>
                        //                     </Tag>
                        //                 </div>
                        //             </>
                        //         )
                        //     }
                        // },
                        {
                            title: 'Action',
                            key: 'action',
                            width: '10%',
                            render: (text, row, index) => {
                                return (
                                    <>
                                        <div>
                                            <Button type='default' style={{ borderRadius: 6 }} onClick={() => openDrawer(row, true)}>
                                                <span style={{ color: '#8c8c8c' }}>View</span>
                                            </Button>
                                        </div>
                                    </>
                                )
                            }
                            // render: (text, row, index) => {
                            //     return (
                            //         <>
                            //             <div>
                            //                 <Dropdown
                            //                     placement='bottomRight'
                            //                     overlayStyle={{ borderRadius: 12, minWidth: 200 }}
                            //                     menu={{
                            //                         items: [{
                            //                             key: '1',
                            //                             type: 'group',
                            //                             label: "Action",
                            //                             children: [{
                            //                                 key: '1-1',
                            //                                 label: "View",
                            //                                 onClick: async () => {
                            //                                     openDrawer(row, true);
                            //                                 }
                            //                             }, {
                            //                                 key: '1-2',
                            //                                 label: "Delete",
                            //                                 onClick: async () => {
                            //                                     await onDelete(row);
                            //                                 }
                            //                             },]
                            //                         },]
                            //                     }}
                            //                 >
                            //                     <Button size='small' icon={<EllipsisOutlined />} style={{ borderRadius: 6 }} />
                            //                 </Dropdown>
                            //             </div>
                            //         </>
                            //     )
                            // }
                        }]}
                />
            </Spin>

            <Drawer title='Stock Adjustment Detail'
                placement="right"
                width={720}
                onClose={onClose}
                open={open}
            >
                <div style={{ marginBottom: 20, fontSize: 14 }}>
                    <Space>
                        <span>{`Branch : ${state?.item?.branch?.name}`}</span>
                    </Space>

                </div>


                <Table
                    scroll={{ x: 400, }}
                    pagination={false}
                    className='hub-table'
                    dataSource={state?.item?.stock_adjust_items}
                    columns={[
                        {
                            title: '#',
                            dataIndex: 'stock_adjust_id',
                            key: 'stock_adjust_id',
                            render: (text, data, index) => (
                                <span>{index + 1}</span>
                            )
                        },
                        {
                            title: 'Item Name',
                            dataIndex: 'item',
                            key: 'item',
                            render: (text, data) => (
                                <span>{text?.name}</span>
                            )
                        }, {
                            title: 'Adjustment Quantity',
                            dataIndex: 'adjusted_quantity',
                            key: 'adjusted_quantity',
                            render: (text, data) => (
                                <span style={{ fontWeight: 500, color: 'green' }}>{text}</span>)
                        },
                        {
                            title: 'Adjustment Cost',
                            dataIndex: 'adjusted_cost',
                            key: 'adjusted_cost',
                            render: (text, data) => (
                                <span style={{ fontWeight: 500, color: 'green' }}>{text}</span>)
                        },
                        {
                            title: 'Quantity',
                            dataIndex: 'quantity',
                            key: 'quantity',
                            render: (text, data) => (
                                <span>{text}</span>
                            )
                        },
                        {
                            title: 'Unit Price',
                            dataIndex: 'unit_cost',
                            key: 'unit_cost',
                            render: (text, data) => (
                                <span>{text}</span>
                            )
                        },

                    ]}
                />
            </Drawer>
        </>
    );
}

export default AdjustListTable;