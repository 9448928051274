import React from 'react';
import ContainerComponent from '../../helpers/components/container.component';
import PageHeaderComponent from '../../helpers/components/page_header.component';
import ItemList from './components/item_list';

const Item = () => {
    return (
        <>
            <ContainerComponent span={22} top={20}>
                <PageHeaderComponent />
                <ItemList />
            </ContainerComponent>
        </>
    );
}

export default Item;