import { Col, Divider, Row } from 'antd';
import React from 'react';

const OverallChart = ({sales, credit_notes, sales_amount, credit_notes_amount}) => {
    const total_net_amount = sales_amount + credit_notes_amount;
    return (
        <>
            <div>
                <div style={{textAlign:'left', color:'#7e7e7e'}}>
                    <span>Total Amount</span>
                </div>
                <div style={{textAlign:'left'}}>
                    <span style={{fontSize:24, fontWeight:'bold', color:'#595959'}}>{`MYR ${total_net_amount?.toFixed(2)}`}</span>
                </div>
                
            </div>
            <div style={{border:'1px solid rgba(0, 0, 0, 0.06)', borderRadius : 6, padding:'12px 0', marginTop:12,}}>
                <Row justify={'center'} gutter={[12, 12]}>
                    <Col span={6} xl={6} xs={11} style={{borderRight:'1px solid rgba(0, 0, 0, 0.06)'}}>
                        <div style={{padding:'0 12px'}}>
                            <div style={{textAlign:'left', color:'#7e7e7e'}}>
                                <span>Total Sales Amount</span>
                            </div>

                            <div style={{textAlign:'left'}}>
                                <span style={{fontSize:20, fontWeight:'bold', color:'#595959'}}>{`MYR ${sales_amount?.toFixed(2)}`}</span>
                            </div>
                        </div>
                        {/* <Divider type='vertical' style={{height:'auto'}} /> */}
                    </Col>
                    
                    <Col span={5} xl={6} xs={11} style={{borderRight:'1px solid rgba(0, 0, 0, 0.06)'}}>
                        <div style={{padding:'0 12px'}}>
                            <div style={{textAlign:'left', color:'#7e7e7e'}}>
                                <span>Number Of Sales</span>
                            </div>

                            <div style={{textAlign:'left'}}>
                                <span style={{fontSize:20, fontWeight:'bold', color:'#595959'}}>{sales?.length}</span>
                            </div>
                        </div>
                    </Col>
                    {/* <Divider type='vertical' style={{height:'auto'}} /> */}
                        
                    <Col span={6} xl={6} xs={11} style={{borderRight:'1px solid rgba(0, 0, 0, 0.06)'}}>
                        <div style={{padding:'0 12px'}}>
                            <div style={{textAlign:'left', color:'#7e7e7e'}}>
                                <span>Credit Note Amount</span>
                            </div>

                            <div style={{textAlign:'left'}}>
                                <span style={{fontSize:20, fontWeight:'bold', color:'#595959'}}>{`MYR ${credit_notes_amount?.toFixed(2)}`}</span>
                            </div>
                        </div>
                    </Col>
                    {/* <Divider type='vertical' style={{height:'auto'}} /> */}

                    <Col span={5} xl={6} xs={11}>
                        <div style={{padding:'0 12px'}}>
                            <div style={{textAlign:'left', color:'#7e7e7e'}}>
                                <span>Number of Credit Note</span>
                            </div>

                            <div style={{textAlign:'left'}}>
                                <span style={{fontSize:20, fontWeight:'bold', color:'#595959'}}>{credit_notes?.length}</span>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        
        </>
    );
}

export default OverallChart;