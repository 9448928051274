import axios from 'axios';
import serviceHelper from '../../helpers/functions/service.helper';

async function getPaymentMethod(query) {
    const queryString = serviceHelper.queryString(query);
    const paymentMethodResponse = await axios.get(`${process.env.REACT_APP_SERVER_URL}sales/paymentmethod/get${(queryString ? ("?" + queryString) : "")}`, { withCredentials: true });
    return paymentMethodResponse?.data;
}

async function getSales(query) {
    const queryString = serviceHelper.queryString(query);
    const salesResponse = await axios.get(`${process.env.REACT_APP_SERVER_URL}sales/get${(queryString ? ("?" + queryString) : "")}`, { withCredentials: true });
    return salesResponse?.data;
}

async function getSaleById(id) {
    const salesResponse = await axios.get(`${process.env.REACT_APP_SERVER_URL}sales/get/${id}`, { withCredentials: true });
    return salesResponse?.data;
}

async function createCheckoutSales(params = {}) {
    const { payment_method_id, sale_status_id, sale_items, customer, referral_code, branch_id, total_amount, discount_approver, warranty_type, warranty_duration, } = params;
    const checkoutResponse = await axios.post(`${process.env.REACT_APP_SERVER_URL}sales/checkout`,
        {
            payment_method_id: payment_method_id,
            sale_status_id: sale_status_id,
            sale_items: sale_items,
            customer: customer?.phone_no && customer,
            referral_code: referral_code,
            branch_id: branch_id,
            total_amount: total_amount,
            discount_approver : discount_approver,
            warranty_type : warranty_type,
            warranty_duration : warranty_duration,
        },
        { withCredentials: true })

    return checkoutResponse?.data;
}

async function updateCheckoutSales(sale_id, params = {}) {
    const { payment_method_id, sale_status_id, total_amount, customer } = params;
    const checkoutResponse = await axios.post(`${process.env.REACT_APP_SERVER_URL}sales/update/${sale_id}`,
        {
            payment_method_id: payment_method_id,
            sale_status_id: sale_status_id,
            total_amount: total_amount,
            customer: customer,
        },
        { withCredentials: true });

    return checkoutResponse?.data;
}

async function getSaleStatus(query) {
    const queryString = serviceHelper.queryString(query);
    const saleStatusResponse = await axios.get(`${process.env.REACT_APP_SERVER_URL}sales/status/get${(queryString ? ("?" + queryString) : "")}`, { withCredentials: true });

    return saleStatusResponse?.data;
}

export default {
    getPaymentMethod,
    getSales,
    getSaleById,
    createCheckoutSales,
    updateCheckoutSales,
    getSaleStatus
}