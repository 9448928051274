import React, { useState, useEffect } from 'react';
import checkoutService from '../../../services/sales/checkout.service';
import CreateSaleOrder from './create_sale_order';

const CreateSaleWrapper = () => {
    const [state, setState] = useState({
        payment_methods : [],
        loading : false,
    })

    const fetchPaymentMethod = async () => {
        setState(state => ({...state, loading : true,}));
        //get payment methods
        const paymentMethodResponse = await checkoutService.getPaymentMethod();
        const payment_methods = paymentMethodResponse?.payment_methods;
        setTimeout(() => {
            setState(state => ({...state, payment_methods : payment_methods, loading : false,}))
        }, 800);
    }

    useEffect(() => {
        fetchPaymentMethod();
    }, [])

    return (
        <>
            <CreateSaleOrder payment_state={state} />
        </>
    );
}

export default CreateSaleWrapper;