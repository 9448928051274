import { Table } from 'antd';
import { Link } from 'react-router-dom';
import React from 'react';
import moment from 'moment';

const ReportList = ({ sales, }) => {
    return (
        <>
            <div style={{padding:24,}}>
                <Table
                dataSource={sales}
                className='hub-table'
                columns={[{
                    title : 'Running Number',
                    dataIndex : 'running_number',
                    key : 'running_number',
                    // width : '20%',
                    render : (text, row, index) => {
                        const { sale_id } = row;
                        return (
                            <>
                                <div>
                                    <Link to={`/sales/order/${sale_id}`}>
                                        <span>
                                            {text}
                                        </span>
                                    </Link>
                                </div>
                            </>
                        )
                    }
                },{
                    title : 'Issue Date',
                    dataIndex : 'created_at',
                    key : 'created_at',
                    // width : '20%',
                    render : (text, row, index) => {
                        return (
                            <>
                                <div>
                                    <span>
                                        {moment(text)?.format("YYYY-MM-DD hh:mm:ss a")}
                                    </span>
                                </div>
                            </>
                        )
                    }
                },{
                    title : 'Sales Amount',
                    dataIndex : 'total_amount',
                    key : 'total_amount',
                    // width : '20%',
                    render : (text, row, index) => {
                        const amount = text?.toFixed(2);
                        return (
                            <>
                                <div>
                                    <span>
                                        {`RM${amount}`}
                                    </span>
                                </div>
                            </>
                        )
                    }
                },{
                    title : 'Gross Profit',
                    dataIndex : 'profit',
                    key : 'profit',
                    // width : '20%',
                    render : (text, row, index) => {
                        const { total_amount } = row;
                        const profit = text?.toFixed(2);
                        // Gross Profit Percentage Formula = Gross Profit / Total Sales * 100%
                        const gp_percentage = (profit / total_amount) * 100;
                        return (
                            <>
                                <div>
                                    <div>
                                        <span>
                                            {`RM${profit}`}
                                        </span>
                                    </div>
                                    <div>
                                        <span style={{color:'#8c8c8c'}}>
                                            {`${gp_percentage?.toFixed(2)}%`}
                                        </span>
                                    </div>
                                </div>
                            </>
                        )
                    }
                }]}
                />
            </div>
        </>
    );
}

export default ReportList;