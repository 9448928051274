import React from 'react';
import ContainerComponent from '../../helpers/components/container.component';
import PageHeaderComponent from '../../helpers/components/page_header.component';
import CreatePurchaseOrder from './components/create_purchase_order';

const PurchaseCreate = () => {
    return (
        <>
            <ContainerComponent span={22} top={20}>
                <PageHeaderComponent />
                <CreatePurchaseOrder />
            </ContainerComponent>
        </>
    );
}

export default PurchaseCreate;