import { Table } from 'antd';
import React from 'react';

const ReportSaleItemTable = ({items}) => {
    return (
        <>
            <Table
            className='hub-table'
            dataSource={items}
            columns={[{
                title : 'Item Code',
                dataIndex : 'item_code',
                key : 'item_code',
                width : '20%',
                render : (text, row, index) => {
                    return (
                        <>
                            <div>
                                <span>
                                    {text}
                                </span>
                            </div>
                        </>
                    )
                }
            },{
                title : 'Name',
                dataIndex : 'name',
                key : 'name',
                width : '40%',
                render : (text, row, index) => {
                    return (
                        <>
                            <div>
                                <span>
                                    {text}
                                </span>
                            </div>
                        </>
                    )
                }
            },{
                title : 'Sold Quantity',
                dataIndex : 'sales_quantity',
                key : 'sales_quantity',
                width : '20%',
                sorter: (a, b) => a.sales_quantity - b.sales_quantity,
                render : (text, row, index) => {
                    return (
                        <>
                            <div>
                                <span>
                                    {text}
                                </span>
                            </div>
                        </>
                    )
                }
            },{
                title : 'Total Amount',
                dataIndex : 'sales_amount',
                key : 'sales_amount',
                width : '40%',
                sorter: (a, b) => a.sales_amount - b.sales_amount,
                render : (text, row, index) => {
                    return (
                        <>
                            <div>
                                <span style={{fontWeight:'bold'}}>
                                    {`MYR ${text}`}
                                </span>
                            </div>
                        </>
                    )
                }
            },]}
            />
        </>
    );
}

export default ReportSaleItemTable;