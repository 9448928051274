import { Card, Col, Image, Row, Space } from 'antd';
import React from 'react';

const ReportSalePaymentMethodChart = ({payment_methods}) => {
    return (
        <>
            <Row gutter={[20, 20]}>
            {
                payment_methods?.map((payment_method, index) => {
                    return (
                        <Col key={index} xs={24} sm={12} md={8} xl={6} span={6} style={{height:'auto'}}>
                            <Card
                                className='sub-menu-card'
                                // bordered={false}
                                style={{borderRadius:8, height:'inherit'}}
                                >
                                    <div style={{textAlign:'left'}}>
                                        <div style={{display:'flex'}}>
                                            <Space size={20} align="start" style={{width:'100%'}}>
                                                <div>
                                                    <Image src={payment_method?.icon} preview={false} style={{maxWidth:30, maxHeight:30, borderRadius:6}} />
                                                </div>

                                                <div>
                                                    <div>
                                                        <span style={{fontSize:16, fontWeight:'bold', color:'#595959'}}>{`MYR ${payment_method?.total_amount}`}</span>
                                                    </div>
                                                    <div>
                                                        <span style={{color:'#7e7e7e'}}>{payment_method?.name}</span>
                                                    </div>
                                                </div>
                                            </Space>
                                        </div>
                                        
                                    </div>
                                </Card>
                        </Col>
                    )
                })
            }
            </Row>
        </>
    );
}

export default ReportSalePaymentMethodChart;