import axios from 'axios';
import serviceHelper from '../../helpers/functions/service.helper';

async function getSalePayments(query){
    const queryString = serviceHelper.queryString(query);
    const salePaymentResponse = await axios.get(`${process.env.REACT_APP_SERVER_URL}sales/payment/get${(queryString ? ("?" + queryString) : "")}`, { withCredentials : true });
    return salePaymentResponse?.data;
}

async function storeSalePayment(params = {}){
    const { sale_id, payment_method_id, amount, referral_code, remarks } = params;
    const salePaymentResponse = await axios.post(`${process.env.REACT_APP_SERVER_URL}sales/payment/store`, {
        sale_id, payment_method_id, amount, referral_code, remarks
    }, { withCredentials : true });

    return salePaymentResponse?.data;
}

async function updateSalePayment(sale_payment_id, params = {}){
    const { amount, referral_code, remarks, payment_method_id } = params;
    const salePaymentResponse = await axios.post(`${process.env.REACT_APP_SERVER_URL}sales/payment/update/${sale_payment_id}`, {
        amount, referral_code, remarks, payment_method_id
    }, { withCredentials : true });

    return salePaymentResponse?.data;
}

async function deleteSalePayment(sale_payment_id){
    const salePaymentResponse = await axios.delete(`${process.env.REACT_APP_SERVER_URL}sales/payment/delete/${sale_payment_id}`, { withCredentials : true });

    return salePaymentResponse?.data;
}

export default {
    getSalePayments,
    storeSalePayment,
    updateSalePayment,
    deleteSalePayment,
}