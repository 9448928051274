import axios from 'axios';
import serviceHelper from '../../helpers/functions/service.helper';

async function getTodayChart(query){
    const queryString = serviceHelper.queryString(query);
    const todayChartResponse = await axios.get(`${process.env.REACT_APP_SERVER_URL}chart/sales/today${(queryString ? ("?" + queryString) : "")}`, { withCredentials: true });
    return todayChartResponse?.data;
}

async function getLowInventory(query){
    const queryString = serviceHelper.queryString(query);
    const lowInventoryResponse = await axios.get(`${process.env.REACT_APP_SERVER_URL}chart/low_inventory${(queryString ? ("?" + queryString) : "")}`, { withCredentials: true });
    return lowInventoryResponse?.data;
}

async function getPopularProduct(query){
    const queryString = serviceHelper.queryString(query);
    const popularProductResponse = await axios.get(`${process.env.REACT_APP_SERVER_URL}chart/sales/popular${(queryString ? ("?" + queryString) : "")}`, { withCredentials: true });
    return popularProductResponse?.data;
}

export default {
    getTodayChart,
    getLowInventory,
    getPopularProduct,
}