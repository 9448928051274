import { PageHeader, Skeleton } from 'antd';
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import accessService from '../../services/administrator/access.service';

const PageHeaderComponent = ({is_back}) => {
    const location = useLocation();
    const [state, setState] = useState({
        menu : null,
        loading : false,
    });

    const getBasePath = () => {
        return location.pathname.slice(0, location.pathname.lastIndexOf('/'))
    }

    const fetchMenu = async () => {
        setState(state => ({...state, loading : true}));
        const basePath = `${getBasePath()}/:id`;
        const menuResponse = await accessService.getMenu();
        const menus = menuResponse?.menus;
        let merged_submenus = []
        merged_submenus = merged_submenus.concat.apply(merged_submenus, menus?.map(x => x?.sub_menus));
        const currentMenu = merged_submenus?.find(x => x.link === basePath || x.link === location.pathname);

        setState(state => ({...state, menu : currentMenu, loading : false,}));
    }

    const backToView = () => {
        window.history.back();
    }

    useEffect(() => {
        fetchMenu();
    }, [])

    return (
        <>
            {
                <PageHeader
                onBack={is_back ? backToView : null}
                title={
                    state?.loading ? 
                    (<div style={{padding:16}} />) 
                    : 
                    state?.menu?.name
                }
                style={{paddingLeft:0}}
                />
            }
            
        </>
    );
}

export default PageHeaderComponent;