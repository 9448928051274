import { Skeleton, Space } from 'antd';
import React from 'react';

const LoadingSubTotal = () => {
    return (
        <>
            <div>
                <div style={{minHeight:60}}>
                    <Skeleton title={false} paragraph={{rows : 2}} active />
                </div>

                <div style={{marginTop:20}}>
                    <Space style={{width:'100%'}} direction="vertical">
                        {[...Array(2)].map((x, i) => {
                            return (
                                <Skeleton.Input key={i} active block style={{height:92, width:'100%', borderRadius:6}} />
                            )
                        })}
                    </Space>
                </div>

                <div style={{marginTop:20}}>
                    <Skeleton.Input active block style={{height:400, width:'100%', borderRadius:6}} />
                </div>
            </div>
        </>
    );
}

export default LoadingSubTotal;