function generateSeed(min, max) {
    const min_seed = min ?? 0;
    const max_seed = max ?? 1000;
    return Math.floor(Math.random() * (max_seed - min_seed + 1)) + min_seed;
}

function isMobileScreen() {
    const isMobile = window.innerWidth <= 768;

    return isMobile;
}

function _base64ToArrayBuffer(base64) {
    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
}

function groupBy(objectArray, property) {
    return objectArray.reduce((acc, obj) => {
        const key = obj[property];
        if (!acc[key]) {
            acc[key] = [];
        }
        // Add object to list for given key's value
        acc[key].push(obj);
        return acc;
    }, {});
}

function tryParseJSONObject(jsonString) {
    try {
        var o = JSON.parse(jsonString);

        // Handle non-exception-throwing cases:
        // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
        // but... JSON.parse(null) returns null, and typeof null === "object", 
        // so we must check for that, too. Thankfully, null is falsey, so this suffices:
        if (o && typeof o === "object") {
            return o;
        }
    }
    catch (e) { }

    return false;
};

function exportToExcel(data, XLSX, name) {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'My Sheet');
    XLSX.writeFile(workbook, name + '.xlsx');
}


export default {
    generateSeed,
    isMobileScreen,
    _base64ToArrayBuffer,
    groupBy,
    tryParseJSONObject,
    exportToExcel
}