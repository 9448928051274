import React from 'react';
import ContainerComponent from '../../helpers/components/container.component';
import PageHeaderComponent from '../../helpers/components/page_header.component';
import SaleOrderDetail from './components/sale_order_detail';
import { useParams } from 'react-router-dom';

const SaleOrder = () => {
    const { id } = useParams();
    return (
        <>
            <ContainerComponent span={22} top={20} bottom={20}>
                <PageHeaderComponent is_back={true} />
                <SaleOrderDetail sale_id={id} edit={true}/>
            </ContainerComponent>
        </>
    );
}

export default SaleOrder;