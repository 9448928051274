import saleItemService from "../../../services/sales/sale_item.service";


export const generateReceipt = async ({ company, branch, items, cashier, running_number, date, total_amount, warranty_type, warranty_duration }) => {
    
    const calculateTotal = () => {
        const get_total_amount = Math.min(total_amount, calculateSubTotal());
        return get_total_amount?.toFixed(2);
    }

    const calculateSubTotal = () => {
        const sub_total_amount = items?.reduce((a, b) => a + (b?.quantity * (b?.promotion ? b?.discount_unit_price : b?.unit_price)), 0);
        return sub_total_amount?.toFixed(2);
    }

    const calculateDiscount = async () => {
        let ori_total_amount = 0;
        const sold_items = items;
        const item_ids = (sold_items?.map(x => x?.item)?.map(x => x.item_id))?.toString();
        const itemsResponse = await saleItemService.calculateItemPrice({ item_ids, });
        for(const item of itemsResponse?.items){
            const get_item = sold_items?.find(x => x?.item?.item_code === item?.item_code);
            const item_prices = (get_item?.quantity * item?.price);
            ori_total_amount += item_prices;
        }

        const total_amount = parseFloat(calculateTotal());
        const discount_price = ori_total_amount - total_amount;
        return discount_price?.toFixed(2);
    }

    const generateItemString = () => {
        let items_string = "";
        let i = 1;

        for(const sale_item of items){
            const { item, quantity, unit_price, discount_unit_price, promotion } = sale_item;
            const price = promotion ? discount_unit_price : unit_price;
           
            const append_string = `
            <tr valign="top" style="page-break-inside:avoid;">
                <td class="details" style="padding:3px;text-align:left;">${i}</td>
                <td class="details" style="padding:3px;"><b>${item?.item_code}</b><br>${item?.name}</td>
                <td class="details" style="padding:3px;text-align:right;">${quantity}</td>
                <td class="details" style="padding:3px;text-align:right;">${(price)?.toFixed(2)}</td>
                <td class="details" style="padding:3px;text-align:right;">${((price) * quantity)?.toFixed(2)}</td>
            </tr>`;

            items_string += append_string;
            i++;
        }

        return items_string;
    }

    return `
    <body>
        <table style="width:100%;">
            <thead class="report-header">
                <tr>
                    <td style="background-color:white;">
                        <div class="divHeader" style="padding-bottom:0px;">
                            <table style="width:100%;">
                                <tbody>
                                    <tr>
                                        <td style="text-align:center;">
                                            <b>${company?.name}</b><span style="font-size:10pt;">
                                            <span>(${branch?.description})</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="text-align:center;">
                                            <span>${branch?.address}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="border-width:1px 0px;border-style:solid;border-color:silver;padding:15px 5px;">
                                            <div style="float:left;width:40%;"><b>INVOICE</b></div>
                                            <div style="float:left;">${running_number}</div>
                                            <div style="clear:both;height:5px;"></div>
                                            <div style="float:left;width:40%;"><b>CASH BILL</b></div>
                                            <div style="float:left;">${branch?.name}</div>
                                            <div style="clear:both;height:5px;"></div>
                                            <div style="float:left;width:40%;"><b>CASHIER</b></div>
                                            <div style="float:left;">${cashier}</div>
                                            <div style="clear:both;height:5px;"></div>
                                            <div style="clear:both;height:5px;"></div>
                                            <div style="padding:0px 5px;text-align:center;">${date}</div> 
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </td>
                </tr>
            </thead>

            <tbody>
                <tr>
                    <td>
                        <div>
                            <table style="width:100%;" border="0" cellpadding="0" cellspacing="0">
                                <tbody>
                                    <tr>
                                        <td class="details" style="width:5%;padding:3px;text-align:center;font-weight:bold;border-width:1px 0px;border-style:solid;border-color:gray;">&nbsp;</td>
                                        <td class="details" style="width:30%;padding:3px;text-align:left;font-weight:bold;border-width:1px 0px;border-style:solid;border-color:gray;">Item</td>
                                        <td class="details" style="width:15%;padding:3px;text-align:right;font-weight:bold;border-width:1px 0px;border-style:solid;border-color:gray;">Qty</td>
                                        <td class="details" style="width:25%;padding:3px;text-align:right;font-weight:bold;border-width:1px 0px;border-style:solid;border-color:gray;">Price</td>
                                        <td class="details" style="width:25%;padding:3px;text-align:right;font-weight:bold;border-width:1px 0px;border-style:solid;border-color:gray;">Total</td>
                                    </tr>

                                    ${generateItemString()}
                                   
                                    <tr>
                                        <td class="details" colspan="4" style="padding:3px;text-align:right;border-top:1px solid gray;">Sub Total</td>
                                        <td class="details" style="padding:3px;text-align:right;border-top:1px solid gray;">${calculateSubTotal()}</td>
                                    </tr>
                                    <tr>
                                        <td class="details" colspan="4" style="padding:3px;text-align:right;">Rounding</td>
                                        <td class="details" style="padding:3px;text-align:right;">0.00</td>
                                    </tr>
                                    <tr>
                                        <td class="details" colspan="4" style="padding:3px;text-align:right;">DISCOUNT/WAIVE</td>
                                        <td class="details" style="padding:3px;text-align:right;">${`-${await calculateDiscount()}`}</td>
                                    </tr>
                                   
                                    <tr>
                                        <td class="details" colspan="4" style="padding:3px;text-align:right;font-size:10pt"><b>NET TOTAL</b></td>
                                        <td class="details" style="padding:3px;text-align:right;font-size:10pt"><b>${calculateTotal()}</b></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="divWarranty" style="margin-top:24px;">
            <table style="width:100%;">
                <tbody>
                    <tr>
                        <td style="text-align:start;">
                            <b>Warranty</b><span style="font-size:10pt;">  
                        </td>
                    </tr>
                    <tr>
                        <td style="border-width:1px 0px;border-style:solid;border-color:silver;padding:15px 5px;"> 
                            <div style="float:left;width:40%;"><b>Week</b></div>
                            <div style="float:left;width:100px;border:1px solid red;height:40;display:flex;align-items:center;justify-content:center;">
                                <span>
                                ${warranty_type == 'week' ? (warranty_duration) : ""}
                                </span>
                            </div>
                            <div style="clear:both;height:5px;"></div>
                            <div style="float:left;width:40%;"><b>Month</b></div>
                            <div style="float:left;width:100px;border:1px solid red;height:40;display:flex;align-items:center;justify-content:center;">
                                <span>
                                ${warranty_type == 'month' ? (warranty_duration) : ""}
                                </span>
                            </div>
                        </td>
                    </tr>
                    
                </tbody>
            </table>
        </div>
        <div class="divFooter" style="text-align:center;padding-top:5px;">
            <div style="margin-top:12px;"}>
                **Goods sold are not exchangeable**
            </div>
        </div>
    </body>`;
}