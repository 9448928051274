import { Col, Divider, Row } from 'antd';
import React from 'react';
import OldInventory from './old_inventory';
import PendingSales from './pending_sales';
import PopularProduct from './popular_product';
import PopularProductByQuantity from './popular_product_by_quantity';
import StockReport from './stock_report';

const OverviewChart = () => {
    return (
        <>
            <div>
                <div style={{textAlign:'left'}}>
                    <span style={{ fontSize: 24, fontWeight: 'bold' }}>{`My Overview`}</span>
                </div>
                <Divider />

                <div>
                    <Row gutter={[20, 20]}>
                        <Col xs={24} sm={12} lg={8} span={8}>
                            <PendingSales />
                        </Col>
                       
                        <Col xs={24} sm={12} lg={8} span={8}>
                            <StockReport />
                        </Col>
                        <Col xs={24} sm={12} lg={8} span={8}>
                            <OldInventory />
                        </Col>
                    </Row>
                </div>
                <div style={{marginTop:24}}>
                    {/* chart item */}
                    <Row gutter={[20, 20]}>
                        <Col xs={24} sm={12} lg={8} span={8}>
                            <PopularProduct />
                        </Col>
                        <Col xs={24} sm={12} lg={8} span={8}>
                            <PopularProductByQuantity />
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
}

export default OverviewChart;