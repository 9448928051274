export const set_user = (user) => {
    return {
        type: 'SET_USER',
        user
    };
}

export const set_branch = (branch) => {
    return {
        type : 'SET_BRANCH',
        branch
    }
}