import React from 'react';
import ContainerComponent from '../../helpers/components/container.component';
import PageHeaderComponent from '../../helpers/components/page_header.component';
import TransferListTable from './components/transfer_list_table';

const TransferList = () => {
    return (
        <>
            <ContainerComponent span={22} top={20}>
                <PageHeaderComponent />
                <TransferListTable />
            </ContainerComponent>
        </>
    );
}

export default TransferList;