import React, { useEffect, useState } from 'react';
import ReportList from './report_list';
import checkoutService from '../../../../services/sales/checkout.service';
import { Button, DatePicker, Divider, Form, Input, Modal, Select, Space, Spin, Tag } from 'antd';
import InputWrapper from '../../../../helpers/components/input_wrapper.component';
import { FilterOutlined, SearchOutlined } from '@ant-design/icons';
import moment from 'moment';
import ReportSummary from './report_summary';
import reportService from '../../../../services/sales/report.service';


const { Option } = Select;
const GpReport = ({ search_params }) => {
    const [form] = Form.useForm();
    const [state, setState] = useState({
        loading : false,
        sales : [],
        page : 0,
        count : 0,

        //filter
        filter_modal : false,

        //sale status
        sale_status : [],
    })

    const [filter, setFilter] = useState({
        search : null,

        // advanced filter
        start_date : moment().subtract(7, 'd').format("YYYY-MM-DD"),
        end_date : moment().format("YYYY-MM-DD"),
    });
    const [timer, setTimer] = useState(null)

    const fetchGPReport = async () => {
        setState(state => ({...state, loading : true, }));

        const reportResponse = await reportService?.getReportSaleGP({
            start_date : filter?.start_date,
            end_date : filter?.end_date,
        });

        setState(state => ({...state, loading : false, sales : reportResponse?.sales,}));
    }

    const onFilter = async () => {
        const { date, status, customer } = form.getFieldsValue();
        const start_date = date && moment(date[0]).format("YYYY-MM-DD");
        const end_date = date && moment(date[1]).format("YYYY-MM-DD");
        setFilter(filter => ({...filter, start_date : start_date, end_date : end_date, status : status, customer : customer}));
        setState(state => ({...state, filter_modal : false,}));
    }

    const doneTyping = async (input) => {
        clearTimeout(timer);
        // dispatch(set_list_page(0));
        setState(state => ({...state, page : 0,}));
        const newTimer = setTimeout(() => {
            
            setFilter(filter => ({...filter, search : input}));
        }, 300);
        setTimer(newTimer);
    }

    useEffect(() => {
        fetchGPReport();
    }, [state?.page, filter])

    return (
        <>
            <Spin spinning={state?.loading}>
                <div style={{padding : '0 24px'}}>
                    <div className='filterPanel' style={{display:'flex'}}>
                        <Space>
                            <div>
                                <InputWrapper>
                                    <Input
                                    onChange={(e) => doneTyping(e.target.value)}
                                    bordered={false}
                                    prefix={<div style={{paddingRight:12, paddingLeft:8}}><SearchOutlined style={{color:'#8c8c8c'}}/></div>}
                                    placeholder={`Search Sales Transactions`}
                                    style={{fontSize:14, background:'#f5f5f5', minWidth:300}}
                                    size='large'
                                    allowClear
                                    />
                                </InputWrapper>
                            </div>
                            <div>
                                <Button
                                icon={<FilterOutlined style={{color:'#8c8c8c'}} />}
                                onClick={() => {
                                    setState(state => ({...state, filter_modal : true}));
                                }}
                                style={{borderRadius:6}}
                                />
                            </div>
                        </Space>
                    </div>
                </div>

                <div>
                    <ReportSummary sales={state?.sales} />
                </div>
                <Divider />
                <div>
                    <ReportList sales={state?.sales}/>
                </div>
            </Spin>


            <Modal
            transitionName="ant-fade"
            maskTransitionName="ant-fade"
            open={state?.filter_modal}
            title={<><span>Filter</span></>}
            width={500}
            closable={false}
            className="custom-modal"
            okButtonProps={{
                form : 'sale_form',
                key : "submit",
                htmlType : "submit"
            }}
            onOk={() => form.submit()}
            onCancel={() => setState(state => ({...state, filter_modal : false,}))}
            >
                <Form
                id="filter_form"
                onFinish={onFilter}
                form={form}
                layout='vertical'
                >
                    <div>
                        <Form.Item name='date' label="Date">
                            <DatePicker.RangePicker allowClear defaultValue={[(filter?.start_date &&  moment(filter?.start_date)), (filter?.end_date && moment(filter?.end_date))]}/>
                        </Form.Item>
                    </div>
                    
                </Form>
            </Modal>
        </>
    );
}

export default GpReport;