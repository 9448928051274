import { Card } from 'antd';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const ItemCard = ({ item, getItem }) => {

    return (
        <>
            <Card
                onClick={() => getItem(item)}
                bordered={false}
                style={{ borderRadius: 6, height: '100%', cursor: 'pointer' }}
            // cover={
            //     <div style={{ padding: 10 }}>
            //         <LazyLoadImage
            //             style={{ borderRadius: 6, objectFit: 'contain', height: 120 }}
            //             // height={140}
            //             effect={"blur"}
            //             // src={'banners/car-display.png'}
            //             // src={'https://res.cloudinary.com/adsplore/image/upload/v1673902183/facial_recognition/iPhone-14-Pro-Max_s8jai6.png'}
            //             src={'/banners/product.png'}
            //         />
            //     </div>
            // }
            >
                <div>
                    <div style={{ textAlign: 'left' }}>
                        <div>
                            <span style={{ fontWeight: 500, color: '#8c8c8c', fontSize: 12 }}>{item?.item_code}</span>
                        </div>
                        <div>
                            <span style={{ fontWeight: 500, color: '#595959' }}>{item?.name}</span>
                        </div>
                        <div>
                            <span style={{ color: '#8c8c8c' }}>{`MYR ${item?.cost ?? '-'}`}</span>
                        </div>
                    </div>
                </div>
            </Card>
        </>
    );
}

export default ItemCard;