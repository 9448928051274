import { Button, Form, Input, Modal, PageHeader, message } from 'antd';
import React from 'react';
import { ExclamationCircleFilled } from '@ant-design/icons';
import ContainerComponent from '../../../helpers/components/container.component';
import authenticationService from '../../../services/administrator/authentication.service';

const { confirm } = Modal;
const ChangePassword = () => {
    const [form] = Form.useForm();

    const verifyChangePassword = async () => {
        confirm({
            title: 'Do you want to change password?',
            icon: <ExclamationCircleFilled />,
            onOk() {
                return new Promise(async (resolve, reject) => {
                    changePassword().then(() => {
                        resolve();
                    });
                    
                }).catch(() => console.log('Oops errors!'));
            },
        });
    }

    const changePassword = async () => {
        const { password, verify_password } = form?.getFieldsValue();
        if(password != verify_password){
            message?.error("Password does not match."); return;
        }
        

        const changePasswordResponse = await authenticationService?.changePassword(password);
        if(changePasswordResponse?.update_user){
            message.success("Password Successfully Changed.");
        }

        form.resetFields();
    }

    return (
        <>
            <div>
                <PageHeader
                title={'Change Password'}
                style={{paddingLeft:0}}
                />
            </div>


            <div>
                <ContainerComponent span={14}>
                    <Form
                    onFinish={verifyChangePassword}
                    id={'change_password'}
                    form={form}
                    layout='vertical'
                    >
                        <Form.Item label="New Password" name="password">
                            <Input type="password" />
                        </Form.Item>
                        <Form.Item label="Verify Password" name="verify_password">
                            <Input type="password" />
                        </Form.Item>

                        <Form.Item>
                            <Button
                            type='primary'
                            style={{width:'100%',}}
                            htmlType='submit'
                            >
                                Change Password
                            </Button>
                        </Form.Item>
                    </Form>
                </ContainerComponent>
            </div>
        </>
    );
}

export default ChangePassword;