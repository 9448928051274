import { Form, Image } from 'antd';
import React from 'react';

const LoginWrapper = ({children}) => {
    return (
        <>
            <div style={{width:'100%', minHeight:'100vh', height:'100%', display:'flex', flexDirection:'row', background : 'linear-gradient(160deg, #f7fafc 40%, #fff 60%)', position:'absolute', overflow:'hidden'}}>
                <div className="intro-wrapper">
                    <div style={{padding : 40}}>
                        <Image src='/banners/admin.png' preview={false} width={250} />
                    </div>
                    <div>
                        <span style={{fontSize:24, fontWeight:'bold'}}>
                            <Image src='/banners/logo-long.png' preview={false} width={160} />
                        </span>
                    </div>
                </div>

                <div className="login-content-wrapper" style={{background:'#fff', display: 'flex', flexDirection:'column', flex:'1 1', position:'relative', overflow:'hidden', height:'100%', zIndex:10, justifyContent:'center', height:'100%'}}>
                    {children}
                </div>
            </div>
        </>
    );
}

export default LoginWrapper;