import { Badge, Button, Card, Space, Tag } from 'antd';
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { set_sale_items } from '../../../actions/sales_order.action';

const ItemCard = ({ item }) => {
    const dispatch = useDispatch();
    const sales_order_redux = useSelector(state => state.sales_order);

    const modifySaleItems = ({ plus }) => {
        let sales_order_items = (sales_order_redux.sale_items);
        //find whether list already have this item
        let existing_item = sales_order_items?.find(x => x.item_id === item?.item_id);
        if (existing_item) {
            const calculated_quantity = existing_item?.quantity + plus;
            existing_item.quantity = calculated_quantity;
            let changed_quantity = sales_order_items;
            if (calculated_quantity > 0) {
                changed_quantity = sales_order_items?.map((x) => {
                    return (x?.item_id === item?.item_id) ? existing_item : x;
                })
            } else {
                changed_quantity = sales_order_items?.filter((x) => {
                    return (x?.item_id != item?.item_id);
                })
            }

            dispatch(set_sale_items(changed_quantity));
        } else {
            //push to it
            if (plus > 0) {
                dispatch(set_sale_items([...sales_order_items, 
                    { 
                        item_id: item?.item_id, 
                        item_code: item?.item_code, 
                        name: item?.name, 
                        quantity: plus, 
                        price: item?.price,
                        promotion : item?.promotion,
                        stock_quantity : item?.stock_quantity,
                    }
                ]));
            }
        }
    }

    return (
        <>
            <Badge.Ribbon
            text={item?.promotion[0]?.name}
            color="cyan"
            style={{ display : `${item?.promotion?.length > 0 ? '' : 'none'}`,}}
            >
                <Card
                    bordered={false}
                    style={{ borderRadius: 6, height: '100%' }}
                    // cover={
                    //     <div style={{ padding: 10 }}>
                    //         <LazyLoadImage
                    //             style={{ borderRadius: 6, objectFit: 'contain', height: 120 }}
                    //             // height={140}
                    //             effect={"blur"}
                    //             // src={'https://res.cloudinary.com/adsplore/image/upload/v1673902183/facial_recognition/iPhone-14-Pro-Max_s8jai6.png'}
                    //             src={'/banners/product.png'}
                    //         />
                    //     </div>
                    // }
                >
                    <div>
                        <div style={{ textAlign: 'left' }}>
                            <div>
                                <span style={{ fontWeight: 500, color: '#8c8c8c', fontSize: 12 }}>{item?.item_code}</span>
                            </div>
                            <div>
                                <span style={{ fontWeight: 500, color: '#595959' }}>{item?.name}</span>
                            </div>
                            <div>
                                <span style={{ color: '#8c8c8c' }}>{`MYR ${item?.price}`}</span>
                            </div>
                            {
                                item?.stock_quantity <= 3 &&
                                (
                                    <>
                                        <div style={{marginTop:8}}>
                                            <Tag color='red' style={{border:'none', borderRadius:6,}}>{`Low Inventory`}</Tag>
                                        </div>
                                    </>
                                )
                            }
                            {
                                item?.stock_quantity == null &&
                                (
                                    <>
                                        <div style={{marginTop:8}}>
                                            <Tag color='geekblue' style={{border:'none', borderRadius:6,}}>{`Stock is not issued`}</Tag>
                                        </div>
                                    </>
                                )
                            }
                            
                        </div>

                        <div style={{ margin: '40px 0', }}>
                            <div style={{ position: 'absolute', bottom: 24, right: 24 }}>
                                <Space size={12}>
                                    
                                    <div>
                                        <Button
                                            onClick={() => modifySaleItems({ plus: -1 })}
                                            style={{ borderRadius: 4 }}
                                            size='small'
                                            icon={<MinusOutlined style={{ color: '#8c8c8c' }} />}
                                        />
                                    </div>
                                    <div>
                                        <span>{(sales_order_redux?.sale_items?.find(x => x.item_id === item?.item_id)?.quantity) ?? 0}</span>
                                    </div>
                                    <div>
                                        <Button
                                            onClick={() => modifySaleItems({ plus: 1 })}
                                            style={{ borderRadius: 4 }}
                                            size='small'
                                            icon={<PlusOutlined style={{ color: '#8c8c8c' }} />}
                                        />
                                    </div>
                                </Space>
                            </div>
                        </div>
                    </div>
                </Card>
            </Badge.Ribbon>
        </>
    );
}

export default ItemCard;