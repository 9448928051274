import { Image, Menu } from 'antd';
import React, { useEffect, useState } from 'react';
import menuSupabase from '../../supabase/query/auth.supabase';
import Icon from '@ant-design/icons';
import { Link, useLocation } from 'react-router-dom';
import accessService from '../../services/administrator/access.service';

const MasterSider = () => {
    const location = useLocation();
    //auto defined current url
    const [state, setState] = useState({
        menus : [],
        selectedKeys : null,
    })

    const changeMenu = (menu_id) => {
        setState(state => ({...state, selectedKeys : menu_id}));
    }


    const fetchMenu = async () => {

        const menuResponse = await accessService.getMenu();
        const menus = menuResponse.menus;

        //get default keys
        const default_menu_id = menus?.find(x => x.link === location?.pathname)?.menu_id;
        setState(state => ({...state, menus : menus, selectedKeys : default_menu_id}));
    }

    useEffect(() => {
        fetchMenu();
    }, [])

    return (
        <>
            <div>
                <div style={{padding:16, height:64}}>
                    <Image preview={false} src='/banners/logo.png' style={{maxHeight:40}} />
                </div>
                <div>
                    <Menu 
                    className='main-menu' theme='dark' selectedKeys={[`${state?.selectedKeys}`]}>
                        {
                            state?.menus?.map((menu, index) => {
                                return (
                                    <Menu.Item
                                    onClick={() => changeMenu(menu?.menu_id)}
                                    key={menu?.menu_id}
                                    icon={<Icon component={() => (<Image preview={false} src={menu?.icon} style={{ height:16, width:16}} />)} />}
                                    >
                                        <Link to={menu?.link}>
                                            {menu?.name}
                                        </Link>
                                    </Menu.Item>
                                )
                            })
                        }
                    </Menu>
                </div>
            </div>
        
        </>
    );
}

export default MasterSider;