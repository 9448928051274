import axios from 'axios';
import serviceHelper from '../../helpers/functions/service.helper';

async function createOrder(params = {}) {
    // const { purchase_items, supplier_id, location_id } = params;
    const createOrderResponse = await axios.post(`${process.env.REACT_APP_SERVER_URL}purchaseorder/store`, params,
        { withCredentials: true })

    return createOrderResponse?.data;
}

async function getOrder(query) {
    const queryString = serviceHelper.queryString(query);
    const orderResponse = await axios.get(`${process.env.REACT_APP_SERVER_URL}purchaseorder/get${(queryString ? ("?" + queryString) : "")}`, { withCredentials: true });
    return orderResponse?.data;
}

async function getOrderById(id) {
    const orderResponse = await axios.get(`${process.env.REACT_APP_SERVER_URL}purchaseorder/get/${id}`, { withCredentials: true });
    return orderResponse?.data;
}

async function updateOrder(order_id, params = {}) {
    const checkoutResponse = await axios.post(`${process.env.REACT_APP_SERVER_URL}purchaseorder/update/${order_id}`,
        params,
        { withCredentials: true });

    return checkoutResponse?.data;
}

export default {
    createOrder,
    updateOrder,
    getOrder,
    getOrderById
}