import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { set_user } from './actions/auth.action';
import LoginIndex from './containers/login/login';
import AuthRouter from './routes/auth.router';
import authenticationService from './services/administrator/authentication.service';

const Auth = () => {
    const dispatch = useDispatch(state => state.auth);
    const [state, setState] = useState({
        user : null,
        loading : true,
    })

    //check login
    const checkAuth = async () => {
        setState(state => ({...state, loading : true}));
        const authUserResponse = await authenticationService.getAuth().catch(err => {
            setState(state => ({...state, loading : false}));
        });
        setState(state => ({...state, user : authUserResponse?.user, loading : false}));
        dispatch(set_user(authUserResponse?.user));
    }

    useEffect(() => {
        checkAuth();
    }, [])

    return (
        <>
            {
                !state?.loading &&
                (
                    <>
                        {
                            state?.user ?
                            <AuthRouter /> :
                            <LoginIndex />
                        }
                    </>
                )
            }
            
        </>
    );
}

export default Auth;