import { Badge, Button, Card, Space, Tag, InputNumber } from 'antd';
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { set_stock_items } from '../../../actions/create_stock.action';

const CreateStockItemCard = ({ item, showMarkup }) => {
    const create_stock_redux = useSelector(state => state.create_stock);
    const dispatch = useDispatch();

    const countedQuantity = (item) => {
        let stock_items = (create_stock_redux.stock_items);
        let existing_item = stock_items?.find(x => x.item_id === item?.item_id);
        if (existing_item) {
            return existing_item?.quantity;
        } else {
            return null;
        }
    }

    const markup = (item) => {
        let stock_items = (create_stock_redux.stock_items);
        let existing_item = stock_items?.find(x => x.item_id === item?.item_id);
        if (existing_item) {
            return existing_item?.markup;
        } else {
            return item?.item?.markup;
        }
    }

    const itemCost = (item) => {
        let stock_items = (create_stock_redux.stock_items);
        let existing_item = stock_items?.find(x => x.item_id === item?.item_id);
        if (existing_item) {
            return existing_item?.cost * (1 + (existing_item?.markup / 100));
        } else {
            return item?.unit_cost * (1 + (item?.item?.markup / 100));
        }
    }

    const updateNewStockCount = (value) => {
        let stock_items = (create_stock_redux.stock_items);
        if (value) {
            let existing_item = stock_items?.find(x => x.item_id === item?.item_id);
            if (existing_item) {
                const calculated_quantity = value;
                existing_item.quantity = calculated_quantity;
                let changed_quantity = stock_items;
                if (calculated_quantity > 0) {
                    changed_quantity = stock_items?.map((x) => {
                        return (x?.item_id === item?.item_id) ? existing_item : x;
                    })
                } else {
                    changed_quantity = stock_items?.filter((x) => {
                        return (x?.item_id != item?.item_id);
                    })
                }

                dispatch(set_stock_items(changed_quantity));
            } else {
                if (value > 0) {
                    dispatch(
                        set_stock_items([
                            ...stock_items,
                            {
                                item_id: item?.item?.item_id,
                                item_code: item?.item?.item_code,
                                name: item?.item?.name,
                                markup: item?.item?.markup ?? 0,
                                cost: item?.unit_cost ?? 0,
                                quantity: value,
                            }
                        ])
                    );
                }
            }

        } else {
            const changed_quantity = stock_items?.filter((x) => {
                return (x?.item_id !== item?.item_id);
            })

            dispatch(set_stock_items(changed_quantity));
        }

    }

    const updateMarkup = (value) => {
        let stock_items = (create_stock_redux.stock_items);

        let existing_item = stock_items?.find(x => x.item_id === item?.item_id);
        if (existing_item) {
            existing_item.markup = value > 0 && value ? value : item?.item?.markup;
            let changed_markup = stock_items;
            changed_markup = stock_items?.map((x) => {
                return (x?.item_id === item?.item_id) ? existing_item : x;
            })

            dispatch(set_stock_items(changed_markup));
        }

    }

    const beforeMarkup = (item) => {
        let stock_items = (create_stock_redux.stock_items);
        let existing_item = stock_items?.find(x => x.item_id === item?.item_id);
        if (existing_item) {
            return existing_item?.cost;
        } else {
            return item?.unit_cost ?? 0;
        }
    }

    const updateNewCost = (value) => {
        let stock_items = (create_stock_redux.stock_items);
        let existing_item = stock_items?.find(x => x.item_id === item?.item_id);
        if (existing_item) {
            existing_item.cost = value > 0 && value ? value : item?.unit_cost;
            let changed_cost = stock_items;
            changed_cost = stock_items?.map((x) => {
                return (x?.item_id === item?.item_id) ? existing_item : x;
            })

            dispatch(set_stock_items(changed_cost));
        }

    }

    return (
        <>
            <Card
                bordered={false}
                style={{ borderRadius: 6, height: '100%' }}
            >
                <div>
                    <div style={{ textAlign: 'left' }}>
                        <div>
                            <span style={{ fontWeight: 500, color: '#8c8c8c', fontSize: 12 }}>{item?.item?.item_code}</span>
                        </div>
                        <div>
                            <span style={{ fontWeight: 500, color: '#595959' }}>{item?.item?.name}</span>
                        </div>
                        {
                            showMarkup ?
                                (<>
                                    <div>
                                        <span style={{ color: '#8c8c8c' }}>{`After Markup   : MYR ${itemCost(item).toFixed(2)}`}</span>
                                    </div>
                                </>) :
                                (<></>)
                        }
                        <div style={{ marginTop: 8 }}>
                            <Tag color='geekblue' style={{ border: 'none', borderRadius: 6 }}>{`Quantity : ${item?.quantity ?? 0}`}</Tag>
                        </div>
                        <div style={{ marginTop: 5 }}>
                            <InputNumber min={0} value={countedQuantity(item)} style={{ width: '100%' }} addonBefore='Quantity' onChange={updateNewStockCount} placeholder="Quantity" />

                        </div>
                        {
                            showMarkup ?
                                (<>
                                    <div style={{ marginTop: 5 }}>

                                        <InputNumber min={0} value={beforeMarkup(item)} style={{ width: '100%' }} onChange={updateNewCost} addonBefore='Unit Price' placeholder="Price" />

                                    </div>

                                    <div style={{ marginTop: 5 }}>
                                        <InputNumber min={0} addonBefore="Markup (%)" value={markup(item)} style={{ width: '100%' }} onChange={updateMarkup} addonAfter="%" placeholder="Markup (%)" />
                                    </div>
                                </>) :
                                (<></>)
                        }
                    </div>
                </div>
            </Card>
        </>
    );
}

export default CreateStockItemCard;
