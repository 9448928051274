import { Button, Card, Col, Form, Empty, Image, InputNumber, Input, Modal, Pagination, Row, Space, notification, message, Alert, Select, Radio, Tag, Table } from 'antd';
import React, { useEffect, useState, useRef } from 'react';
import InputWrapper from '../../../helpers/components/input_wrapper.component';
import { SearchOutlined, ExclamationCircleOutlined, ScanOutlined, InfoCircleFilled, PrinterOutlined, InfoCircleOutlined } from '@ant-design/icons';
import ItemCard from './adjust_item_card';
import { useDispatch, useSelector } from 'react-redux';
import LoadingItemCard from '../../components/loading.item_card';
import { set_stock_adjust_items } from '../../../actions/create_stock_adjust.action';
import itemService from '../../../services/config/item.service';
import { Link, useHistory } from 'react-router-dom';
import stockService from '../../../services/stock/stock.service';
import branchService from '../../../services/config/branch.service';
import locationService from '../../../services/config/location.service';

const { confirm } = Modal;

const CreateAdjust = () => {
    const { Option } = Select;
    const create_stock_adjust_redux = useSelector(state => state.create_stock_adjust);
    const dispatch = useDispatch();
    const history = useHistory();
    const [form] = Form.useForm();
    const [state, setState] = useState({
        items: [],
        item_adjust: 0,
        item_loading: false,
        loading: false,
        checkout_loading: false,
        confirmation_modal: null,
        item_refresh: 0,
        item: null,
        branches: [],
        branch: null,
        remark: null,
    });

    const [filter, setFilter] = useState({
        page: 1,
        search: null,
        input: null,
        branchInput: null,
    });

    const [timer, setTimer] = useState(null);

    const fetchItem = async () => {
        if (!state?.branch) {
            return
        }
        setState(state => ({ ...state, item_loading: (state?.item_refresh <= 0), }));
        //get items
        let branch = state?.branches?.find(x => x.branch_id === state?.branch);
        let itemResponse;
        // console.log(branch, state?.branch)
        if (branch?.location_id) {
            itemResponse = await locationService.getItem({
                page: (filter?.page - 1),
                size: 12,
                //filter
                search: filter?.search,
                location_id: branch?.location_id
            });
        } else {
            itemResponse = await branchService.getItem({
                page: (filter?.page - 1),
                size: 12,
                //filter
                search: filter?.search,
                branch_id: branch?.branch_id
            });
        }
        // console.log(itemResponse)
        const items = branch?.location_id ? itemResponse?.location_item?.rows : itemResponse?.branch_item?.rows;
        const item_count = branch?.location_id ? itemResponse?.location_item?.count : itemResponse?.branch_item?.count;
        setTimeout(() => {
            setState(state => ({ ...state, items: items, item_count: item_count, item_loading: false, }));
        }, 800);

    }

    const createStockAdjust = async () => {
        if (!state?.branch) {
            notification.warning({
                message: "Select branch to proceed",
            });
            return
        }
        if (create_stock_adjust_redux?.stock_adjust_items?.length < 1) {
            notification.warning({
                message: "No Item Updated",
            });
            return
        }
        confirm({
            width: 700,
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            content: (
                <>
                    <div>
                        <span>Create new stock adjust?</span>
                    </div>
                    <div style={{ marginTop: 20 }}>
                        <Alert
                            icon={true}
                            type='warning'
                            message={<span>Changes can not be made once it is created. Make sure the number are correct before submission</span>}
                            style={{ borderRadius: 6 }}
                        />
                    </div>
                    <div style={{ marginTop: 20, maxHeight: '400px', overflowY: 'auto' }}>
                        <Table
                            pagination={false}
                            // size="small"
                            // bordered
                            dataSource={create_stock_adjust_redux?.stock_adjust_items}
                            style={{ borderRadius: 6 }}
                            columns={[{
                                title: 'Item',
                                dataIndex: 'item_code',
                                key: 'item_code',
                                render: (text, row, index) => {
                                    return (
                                        <>
                                            <div>
                                                <div>
                                                    <span style={{ fontWeight: 500 }}>{row?.name}</span>
                                                </div>
                                                <div>
                                                    <span style={{ color: '#8c8c8c ', fontSize: 12 }}>{row?.item_code}</span>
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                            },
                            {
                                title: 'Adjustment Quantity',
                                dataIndex: 'quantity',
                                key: 'quantity',
                                render: (text) => {
                                    return (
                                        <>
                                            <div>
                                                <div>
                                                    <span style={{ fontWeight: 500, color: 'green' }}>{text}</span>
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                            },
                            {
                                title: 'Adjustment Cost',
                                dataIndex: 'price',
                                key: 'price',
                                render: (text) => {
                                    return (
                                        <>
                                            <div>
                                                <div>
                                                    <span style={{ fontWeight: 500, color: 'green' }}>{text ?? 'N/A'}</span>
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                            },
                            {
                                title: 'Quantity',
                                dataIndex: 'stock_quantity',
                                key: 'stock_quantity',
                            },
                            {
                                title: 'Unit Price',
                                dataIndex: 'unit_cost',
                                key: 'unit_cost',
                            },

                            ]}
                        />
                    </div>

                </>
            ),
            async onOk() {
                setState(state => ({ ...state, checkout_loading: true }));
                try {
                    const createStockAdjustResponse = await stockService.createStockAdjust({
                        branch_id: state?.branch,
                        // description: state?.remark,
                        stock_adjust_items: create_stock_adjust_redux?.stock_adjust_items?.map(x => {
                            const { item_id, stock_quantity, quantity, unit_cost, price } = x;
                            return {
                                item_id: item_id,
                                adjusted_quantity: quantity,
                                quantity: stock_quantity,
                                unit_cost: unit_cost,
                                price: price,
                            }
                        }),
                    });

                    setTimeout(() => {
                        dispatch(set_stock_adjust_items([]));
                        notification.success({
                            message: (<span style={{ fontSize: 14, fontWeight: 500, }}>Stock Adjustment Created</span>),
                            duration: 3,
                            placement: 'topRight'
                        });
                        history.push(`/stocks/adjust`);
                    }, 500);

                    clearAll();
                } catch (e) {
                    setState(state => ({ ...state, checkout_loading: false }));
                }
            },
            onCancel() {
            },
        });



    }

    const fetchBranchOnSearch = async () => {
        setState(state => ({ ...state, branch: null }));
        let branchResponse;
        branchResponse = await branchService.getBranch({
            search: filter?.branchInput,
        });
        // const branch = branchResponse?.branch?.map(x => {
        //     return {
        //         value: x.branch_id,
        //         label: x.name
        //     }
        // })
        setState(state => ({ ...state, branches: branchResponse?.branch }));
    }

    const branchDoneTyping = async (input) => {
        clearTimeout(timer);
        const newTimer = setTimeout(() => {
            setFilter(filter => ({ ...filter, branchInput: input }));
        }, 300);
        setTimer(newTimer);
    }

    const branchOnDropdownSelected = (branch_id) => {
        setState(state => ({ ...state, branch: branch_id }));
    }

    const doneTyping = (input) => {
        clearTimeout(timer);
        setFilter(filter => ({ ...filter, input: input }));
        const newTimer = setTimeout(() => {
            setFilter(filter => ({ ...filter, search: input, page: 1, }));


        }, 300);
        setTimer(newTimer);
    }

    const clearAll = () => {
        setState(state => ({
            ...state,
            item: null,
        }));

        form.resetFields();
    }

    useEffect(() => {
        fetchItem();
    }, [filter?.page, filter?.search, state?.item_refresh, state?.branch]);

    useEffect(() => {
        dispatch(set_stock_adjust_items([]));
    }, [state?.branch]);

    useEffect(() => {
        fetchBranchOnSearch();
    }, [filter?.branchInput]);


    return (
        <>
            <div>
                {/* <Row justify='center' align='top' gutter={[40, 20]}>
                    <Col xs={24} sm={14} span={14}> */}
                <div>
                    <div className='filter-panel' style={{ height: 60 }}>
                        <div style={{ display: 'flex' }}>
                            <div style={{ minWidth: 400 }}>
                                <InputWrapper>
                                    <Input
                                        name="searchItem"
                                        onChange={(e) => doneTyping(e.target.value)}
                                        bordered={false}
                                        prefix={<div style={{ paddingRight: 12, paddingLeft: 8 }}><SearchOutlined style={{ color: '#8c8c8c' }} /></div>}
                                        placeholder={`Search Products`}
                                        style={{ fontSize: 14 }}
                                        size='large'
                                    />
                                </InputWrapper>
                            </div>
                            <div style={{ marginLeft: 'auto' }}>
                                <Space>
                                    <div style={{ marginRight: 5 }}>
                                        <Select
                                            value={state?.branch}
                                            filterOption={false}
                                            showSearch
                                            className='border-input'
                                            style={{ width: '100%', borderRadius: 6, }}
                                            onSearch={branchDoneTyping}
                                            onChange={branchOnDropdownSelected}
                                            placeholder='Select a branch'
                                        >
                                            {
                                                state?.branches?.map((item, index) => {
                                                    return (
                                                        <Option value={item?.branch_id} key={index}>
                                                            <div>
                                                                <div><span>{item?.name}</span></div>
                                                            </div>
                                                        </Option>
                                                    )
                                                })
                                            }
                                        </Select >
                                    </div>
                                    <div>
                                        <Button
                                            onClick={createStockAdjust}
                                            type='primary'
                                            style={{ width: '100%', borderRadius: 4 }}
                                            loading={state?.checkout_loading}
                                        >
                                            <span style={{ fontWeight: 500 }}>Create Stock Adjust</span>
                                        </Button>
                                    </div>
                                </Space>


                            </div>

                        </div>
                    </div>

                    <div style={{ marginTop: 20 }}>
                        <Row gutter={[20, 20]}>
                            {
                                state?.item_loading ?
                                    (
                                        <>
                                            {[...Array(12)].map((x, i) => {
                                                return (
                                                    <Col xs={24} sm={12} lg={8} xl={8} span={6} key={i} style={{ height: '100%' }}>
                                                        <LoadingItemCard />
                                                    </Col>
                                                )
                                            })}
                                        </>
                                    )
                                    :
                                    (
                                        <>
                                            {
                                                state?.items.length > 0 ? state?.items?.map((item, index) => {
                                                    return (
                                                        <Col xs={24} sm={12} lg={8} xl={8} span={6} key={index} style={{ height: 'auto' }}>
                                                            <ItemCard item={item} />
                                                        </Col>
                                                    )
                                                })
                                                    :

                                                    (
                                                        <>
                                                            <Space direction='vertical' style={{ width: '100%' }}>

                                                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                                                <Tag color="orange"><InfoCircleOutlined />
                                                                    {
                                                                        state?.branch ?
                                                                            <span>No Item Found</span> : <span>Select Branch to view Item</span>
                                                                    }
                                                                </Tag>
                                                            </Space>
                                                        </>
                                                    )
                                            }
                                        </>
                                    )
                            }

                        </Row>
                    </div>
                </div>
                <div style={{ padding: 24 }}>

                    <Pagination onChange={(page) => setFilter(filter => ({ ...filter, page: page }))} current={filter?.page} total={state?.item_count} showSizeChanger={false} pageSize={12} />
                </div>
            </div >

        </>
    );
}

export default CreateAdjust;
