import React from 'react';
import ContainerComponent from '../../helpers/components/container.component';
import SubMenuComponent from '../../helpers/components/sub_menu.component';

const StockIndex = () => {
    return (
        <>
            <ContainerComponent span={22} top={20}>
                <SubMenuComponent />
            </ContainerComponent>
        </>
    );
}

export default StockIndex;