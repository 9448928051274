import { Button, Divider, Dropdown, Form, Modal, PageHeader, Space, Spin, Table, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import saleItemService from '../../../services/sales/sale_item.service';
import { EllipsisOutlined, PlusOutlined } from '@ant-design/icons';
import SaleOrderItemAdd from './sale_order_items_add';
import { useDispatch, useSelector } from 'react-redux';
import { refresh_sale_detail } from '../../../actions/sales_order.action';
import moment from 'moment';

const { confirm } = Modal;
const SaleOrderItem = ({ sale_id, edit, status_editable }) => {
    const dispatch = useDispatch();
    const saleOrderRedux = useSelector(state => state.sales_order);
    const authRedux = useSelector(state => state.auth);
    const [form] = Form.useForm();
    const [state, setState] = useState({
        loading: false,
        sale_items: [],
        edit_modal: false,
        refresh: 0,
        edit_modal_id: null,
    })

    const fetchSaleItems = async () => {
        setState(state => ({ ...state, loading: true }))
        const saleItemsResponse = await saleItemService?.getSaleItems({
            sale_id: sale_id,
        });

        const sale_items = saleItemsResponse?.sale_items;
        setState(state => ({ ...state, sale_items: sale_items, loading: false }))
    }


    const addSaleItem = async () => {
        const { itemId, quantity, price } = form.getFieldsValue();

        let saleItemsResponse;
        if (state?.edit_modal_id) {
            //update
            saleItemsResponse = await saleItemService.updateSaleItems(state?.edit_modal_id, {
                item_id: itemId,
                unit_price: price,
                quantity: quantity,
            })
        } else {
            //store a new sale items
            saleItemsResponse = await saleItemService.createSaleItems({
                sale_id: sale_id,
                item_id: itemId,
                unit_price: price,
                quantity: quantity,
            });
        }

        //refresh detail
        dispatch(refresh_sale_detail(saleOrderRedux?.sale_refresh + 1));

        setState(state => ({ ...state, refresh: (state?.refresh + 1), edit_modal: false, }));
    }

    const openEditModal = ({ sale_item_id = null, item_id, quantity, price, discount_unit_price }) => {
        const get_price = discount_unit_price ?? price;
        setState(state => ({ ...state, edit_modal: true, edit_modal_id: sale_item_id, }));
        form.setFieldsValue({ itemId: item_id, quantity: quantity, price: get_price });
    }

    const deleteConfirmationModal = async (sale_item_id) => {
        confirm({
            title: 'Do you want to delete this sale item?',
            onOk() {
                return new Promise((resolve, reject) => {
                    setTimeout(async () => {
                        await deleteSaleItem(sale_item_id);
                        resolve();
                    }, 500);
                }).catch((err) => {

                })
            }
        })
    }

    const deleteSaleItem = async (sale_item_id) => {
        const saleItemsResponse = await saleItemService.deleteSaleItems(sale_item_id);
        dispatch(refresh_sale_detail(saleOrderRedux?.sale_refresh + 1));
        setState(state => ({ ...state, refresh: (state?.refresh + 1) }));
    }

    useEffect(() => {
        fetchSaleItems();
    }, [state?.refresh, saleOrderRedux?.sale_refresh, sale_id])


    return (
        <>
            <div style={{ textAlign: 'left', padding: '12px 0' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div>
                        <span style={{ fontWeight: 500, fontSize: 16 }}>Sale Items</span>
                    </div>
                    {
                        edit && (status_editable) &&
                        (
                            <>
                                <div style={{ marginLeft: 'auto' }}>
                                    <span onClick={() => openEditModal({})} className='mini-button mini-style-text'>
                                        Add Item
                                    </span>
                                </div>
                            </>
                        )
                    }

                </div>
            </div>
            <Divider style={{ marginTop: 12 }} />
            <Spin spinning={state?.loading}>
                <Table
                    pagination={false}
                    dataSource={state?.sale_items}
                    className='hub-table'
                    columns={[{
                        title: 'Item Code',
                        dataIndex: 'item',
                        key: 'item_code',
                        render: (text, row, index) => {
                            return (
                                <>
                                    <div>
                                        <span>{text?.item_code}</span>
                                    </div>
                                </>
                            )
                        }
                    }, {
                        title: 'Name',
                        dataIndex: 'item',
                        key: 'name',
                        render: (text, row, index) => {
                            return (
                                <>
                                    <div>
                                        <span>{text?.name}</span>
                                    </div>
                                </>
                            )
                        }
                    }, {
                        title: 'Quantity',
                        dataIndex: 'quantity',
                        key: 'quantity',
                        render: (text, row, index) => {
                            return (
                                <>
                                    <Space>
                                        <div>
                                            <span>{text}</span>
                                        </div>
                                        <div>
                                            {
                                                row?.is_credit_note &&
                                                (
                                                    <>
                                                        <div>
                                                            <Tag color={'geekblue'} style={{ border: 'none' }}>
                                                                <span>{`Returned quantity ${row?.credit_note_quantity}`}</span>
                                                            </Tag>
                                                        </div>
                                                    </>
                                                )
                                            }
                                        </div>
                                    </Space>

                                </>
                            )
                        }
                    }, {
                        title: 'Unit Price (RM)',
                        dataIndex: 'unit_price',
                        key: 'unit_price',
                        render: (text, row, index) => {
                            const { promotion, discount_unit_price, unit_price, created_at, updated_at, updator } = row ?? {};
                            const is_row_updated = !(moment(created_at).isSame(updated_at, 'seconds'));

                            return (
                                <>
                                    <div>
                                        {
                                            promotion ?
                                                (
                                                    <>
                                                        <div>
                                                            <Tag color={'cyan'}>
                                                                <span>
                                                                    Promotion : {promotion?.name}
                                                                </span>
                                                            </Tag>
                                                        </div>
                                                        <div>
                                                            <Space>
                                                                <div>
                                                                    <span>{`${(discount_unit_price).toFixed(2)}`}</span>
                                                                </div>
                                                                <div>
                                                                    <span style={{ color: '#8c8c8c', textDecoration: 'line-through' }}>{`${(unit_price)?.toFixed(2)}`}</span>
                                                                </div>
                                                            </Space>
                                                        </div>
                                                    </>
                                                )
                                                :
                                                (
                                                    <>
                                                        <span>{unit_price}</span>
                                                    </>
                                                )

                                        }
                                        {
                                            is_row_updated &&
                                            (
                                                <>
                                                    <div>
                                                        <span style={{ fontSize: 12 }}>{`Updated By ${updator?.username}`}</span>
                                                    </div>
                                                </>
                                            )
                                        }
                                    </div>
                                </>
                            )
                        }
                    }, {
                        ...(authRedux?.user?.role_id == 1) && ({
                            title: 'Unit Price (RM)',
                            key: 'cost',
                            dataIndex: 'cost',
                            render: (text, row, index) => {
                                return (
                                    <>
                                        <span>{(text ?? 0)}</span>
                                    </>
                                )
                            }
                            // width: '10%',
                        })
                    }, {
                        title: 'Total Price (RM)',
                        dataIndex: 'total_price',
                        key: 'total_price',
                        render: (text, row, index) => {
                            const { promotion, total_price, discount_total_price, quantity } = row ?? {};
                            return (
                                <>
                                    {
                                        promotion ?
                                            (
                                                <>
                                                    <Space>
                                                        <div>
                                                            <span>{discount_total_price?.toFixed(2)}</span>
                                                        </div>
                                                        <div>
                                                            <span style={{ color: '#8c8c8c', textDecoration: 'line-through' }}>{total_price?.toFixed(2)}</span>
                                                        </div>
                                                    </Space>


                                                </>
                                            )
                                            :
                                            (
                                                <>
                                                    <span>{text?.toFixed(2)}</span>
                                                </>
                                            )
                                    }
                                </>
                            )
                        }
                    }, {
                        ...edit && (status_editable) && ({
                            title: 'Action',
                            key: 'action',
                            dataIndex: 'sale_item_id',
                            width: '10%',
                            render: (text, row, index) => {
                                return (
                                    <>
                                        <div>
                                            <Dropdown
                                                placement='bottomRight'
                                                overlayStyle={{ borderRadius: 12, minWidth: 200 }}
                                                menu={{
                                                    items: [{
                                                        key: '1',
                                                        type: 'group',
                                                        label: "Action",
                                                        children: [{
                                                            key: '1-1',
                                                            label: "Edit",
                                                            onClick: async () => {
                                                                openEditModal({ sale_item_id: text, item_id: row?.item_id, quantity: row?.quantity, price: row?.unit_price, discount_unit_price: row?.discount_unit_price })
                                                            }
                                                        }, {
                                                            key: '1-2',
                                                            label: "Delete",
                                                            onClick: async () => {
                                                                deleteConfirmationModal(text);
                                                            }
                                                        },]
                                                    },]
                                                }}
                                            >
                                                <Button size='small' icon={<EllipsisOutlined />} style={{ borderRadius: 6 }} />
                                            </Dropdown>
                                        </div>
                                    </>
                                )
                            }
                        })
                    }]}
                />
            </Spin>


            <Modal
                open={state?.edit_modal}
                transitionName="ant-fade"
                maskTransitionName="ant-fade"
                title={state?.edit_modal_id ?
                    <><span>Update Sale Item</span></>
                    : <><span>Add Sale Item</span></>}
                width={500}
                closable={false}
                className="custom-modal"
                onOk={() => form.submit()}
                onCancel={() => setState(state => ({ ...state, edit_modal: false, }))}
            >
                <Form
                    id='sale_item_form'
                    onFinish={addSaleItem}
                    form={form}
                    layout='vertical'
                >
                    <SaleOrderItemAdd form={form} />
                </Form>
            </Modal>
        </>
    );
}

export default SaleOrderItem;