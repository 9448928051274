import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

const MasterContent = ({children}) => {
    return (
        <>
            {children}
        </>
    );
}

export default MasterContent;