import { Col, Form, Input, Row, Select, Spin, InputNumber } from 'antd';
import React, { useEffect, useState } from 'react';
import itemService from '../../../services/config/item.service';

const { Option } = Select;
const OrderItemAdd = ({ form, supplier_id, edit_modal }) => {
    const [timer, setTimer] = useState(null)
    const [state, setState] = useState({
        loading: true,
        items: [],
        input: null,
        selected_items: null,

    });

    const fetchItemOnSearch = async () => {
        setState(state => ({ ...state, loading: true }));
        let itemResponse;
        itemResponse = await itemService.getItem({
            search: state?.input,
        });
        const items = itemResponse?.items?.rows?.map(x => {
            return {
                value: x.item_id,
                label: x.name,
                item_code: x.item_code,
                unit_price: x.cost,
            }
        })
        setState(state => ({ ...state, items: items, loading: false }));
    }

    const doneTyping = (input) => {
        setTimeout(() => {
            setState(state => ({ ...state, input: input }));
        }, 500);
    }

    const onDropdownSelected = (item_id) => {
        const items = state?.items?.find(x => x.value === item_id);
        form.setFieldsValue({ item_id: item_id, unit_price: items?.unit_price, quantity: '1' });
        setState(state => ({ ...state, selected_items: item_id }));
    }

    useEffect(() => {
        fetchItemOnSearch();
    }, [state?.input])

    return (
        <>
            <Form.Item name='itemId' label="Item" rules={[{ required: true, message: 'Please select an item' }]} shouldUpdate>
                <Select
                    disabled={edit_modal ? true : false}
                    filterOption={false}
                    showSearch
                    className='border-input'
                    style={{ borderRadius: 6 }}
                    onSearch={doneTyping}
                    onChange={onDropdownSelected}
                >
                    {
                        state?.items?.map((item, index) => {
                            return (
                                <Option value={item?.value} key={index}>
                                    <div>
                                        <div><span>{item?.label}</span></div>
                                        <div><span style={{ fontSize: 12, color: '#8c8c8c' }}>{item?.item_code}</span></div>
                                    </div>
                                </Option>
                            )
                        })
                    }
                </Select>
            </Form.Item>
            <Form.Item name='unit_price' label="Item Unit Price (RM)" rules={[{ required: true, message: 'Please enter item unit price' }]} >
                <InputNumber type='number' style={{ width: '100%', borderRadius: 6 }} />
            </Form.Item>
            <Form.Item name='quantity' label="Quantity" rules={[{ required: true, message: 'Please enter a quantity' }]}>
                <InputNumber type='number' placeholder='1' min={1} style={{ width: '100%', borderRadius: 6 }} />
            </Form.Item>
            <Form.Item name='free' label="Free" >
                <InputNumber type='number' style={{ width: '100%', borderRadius: 6 }} min={0} />
            </Form.Item>



        </>
    );
}

export default OrderItemAdd;