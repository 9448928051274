import axios from 'axios';
import serviceHelper from '../../helpers/functions/service.helper';

async function createBilling(params = {}) {
    const { billing_items, branch_id, location_id, supplier_id } = params;
    const createBillingResponse = await axios.post(`${process.env.REACT_APP_SERVER_URL}billing/store`,
        {
            billing_items: billing_items,
            branch_id: branch_id,
            supplier_id: supplier_id,
            location_id: location_id,
        },
        { withCredentials: true })

    return createBillingResponse?.data;
}

async function getBilling(query) {
    const queryString = serviceHelper.queryString(query);
    const billingResponse = await axios.get(`${process.env.REACT_APP_SERVER_URL}billing/get${(queryString ? ("?" + queryString) : "")}`, { withCredentials: true });
    return billingResponse?.data;
}

async function getCompanyBilling(query) {
    const queryString = serviceHelper.queryString(query);
    const billingResponse = await axios.get(`${process.env.REACT_APP_SERVER_URL}billing/getCompanyBilling${(queryString ? ("?" + queryString) : "")}`, { withCredentials: true });
    return billingResponse?.data;
}

async function getBillingById(id) {
    const billingResponse = await axios.get(`${process.env.REACT_APP_SERVER_URL}billing/get/${id}`, { withCredentials: true });
    return billingResponse?.data;
}

async function printBilling(query) {
    const queryString = serviceHelper.queryString(query);
    const billingResponse = await axios.get(`${process.env.REACT_APP_SERVER_URL}billing/print${(queryString ? ("?" + queryString) : "")}`, { withCredentials: true });
    return billingResponse?.data;
}

async function updateBilling(billing_id, params = {}) {
    const { status } = params;
    const checkoutResponse = await axios.post(`${process.env.REACT_APP_SERVER_URL}billing/update/${billing_id}`,
        {
            status: status,
        },
        { withCredentials: true });

    return checkoutResponse?.data;
}

async function getCreditNotes(query) {
    const queryString = serviceHelper.queryString(query);
    const creditNotesResponse = await axios.get(`${process.env.REACT_APP_SERVER_URL}billing/creditnote/get${(queryString ? ("?" + queryString) : "")}`, { withCredentials: true });
    return creditNotesResponse?.data;
}

async function storeCreditNotes(params = {}) {
    const creditNotesResponse = await axios.post(`${process.env.REACT_APP_SERVER_URL}billing/creditnote/store`, params, { withCredentials: true });
    return creditNotesResponse?.data;
}

async function updateCreditNotes(billing_credit_note_id, params = {}) {
    const creditNotesResponse = await axios.post(`${process.env.REACT_APP_SERVER_URL}billing/creditnote/update/${billing_credit_note_id}`, params, { withCredentials: true });
    return creditNotesResponse?.data;
}

async function deleteCreditNotes(billing_credit_note_id) {
    const creditNotesResponse = await axios.delete(`${process.env.REACT_APP_SERVER_URL}billing/creditnote/delete/${billing_credit_note_id}`, { withCredentials: true });
    return creditNotesResponse?.data;
}

export default {
    createBilling,
    getBilling,
    getBillingById,
    printBilling,
    updateBilling,
    getCompanyBilling,
    getCreditNotes,
    storeCreditNotes,
    updateCreditNotes,
    deleteCreditNotes
}