import axios from 'axios';
import serviceHelper from '../../helpers/functions/service.helper';

async function getItem(query) {
    const queryString = serviceHelper.queryString(query);
    const itemResponse = await axios.get(`${process.env.REACT_APP_SERVER_URL}config/item/get${(queryString ? ("?" + queryString) : "")}`, { withCredentials: true });
    return itemResponse?.data;
}

async function storeItem(params = {}) {
    const itemResponse = await axios.post(`${process.env.REACT_APP_SERVER_URL}config/item/store`, params, { withCredentials: true });

    return itemResponse?.data;
}

async function checkItem(query) {
    const queryString = serviceHelper.queryString(query);
    const itemResponse = await axios.get(`${process.env.REACT_APP_SERVER_URL}config/item/check${(queryString ? ("?" + queryString) : "")}`, { withCredentials: true });
    return itemResponse?.data;
}

async function deleteItem(item_id) {
    const itemResponse = await axios.post(`${process.env.REACT_APP_SERVER_URL}config/item/delete/${item_id}`, {}, { withCredentials: true });
    return itemResponse?.data;
}

async function updateItem(item_id, params = {}) {
    const { code, name, price, description, item_category_id, cost, markup, is_all_company } = params;
    const itemResponse = await axios.post(`${process.env.REACT_APP_SERVER_URL}config/item/update/${item_id}`, {
        code, name, price, description, item_category_id, cost, markup, is_all_company,
    }, { withCredentials: true });

    return itemResponse?.data;
}

async function getItemDropdown() {

    const itemResponse = await axios.get(`${process.env.REACT_APP_SERVER_URL}config/item/getDropdown`, { withCredentials: true });
    return itemResponse?.data;
}

async function getSupplierItems(query) {
    const queryString = serviceHelper.queryString(query);
    const itemResponse = await axios.get(`${process.env.REACT_APP_SERVER_URL}config/item/getSupplierItems${(queryString ? ("?" + queryString) : "")}`, { withCredentials: true });
    return itemResponse?.data;
}

export default {
    getItem,
    updateItem,
    deleteItem,
    storeItem,
    getItemDropdown,
    getSupplierItems,
    checkItem,
}