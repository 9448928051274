const initialState = {
    //create stock count
    stock_count_items: [],
}

const CreateStockCountReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_STOCK_COUNT_ITEMS':
            return { ...state, stock_count_items: action.stock_count_items }
        default:
            return state;
    }
}

export default CreateStockCountReducer;