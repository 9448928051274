import { Card, Tabs } from 'antd';
import React from 'react';
import ReportSaleItem from './item/report_sale_item';
import OverallSaleReport from './overall/overall_sale_report';
import ReportSalePaymentMethod from './payment_method/report_sale_payment_method';
import { useSelector } from 'react-redux';
import GpReport from './gp/gp_report';

const ReportSaleWrapper = () => {
    const authRedux = useSelector(state => state.auth);
    const onChange = (key) => {
    };
    
    const items = [
    {
        key: '1',
        label: <span style={{margin : '0 20px'}}>Overall Sale Report</span>,
        children: (
            <>
                <OverallSaleReport />
            </>
        ),
    },
    {
        key: '2',
        label: <span style={{margin : '0 20px'}}>Sale Report By Payment Method</span>,
        children: (
            <>
                <ReportSalePaymentMethod />
            </>
        ),
    },
    {
        key: '3',
        label: <span style={{margin : '0 20px'}}>Sale Report By Item</span>,
        children: (
            <>
                <ReportSaleItem />
            </>
        ),
    },
    {...(authRedux?.user?.role_id == 1 && {
        key: '4',
        label: <span style={{margin : '0 20px'}}>Sale Listing Cost</span>,
        children: (
            <>
                <GpReport />
            </>
        ),
    }) }
    ];
    
    return (
        <>
            <Card
            bordered={false}
            style={{borderRadius:6}}
            bodyStyle={{padding:0}}
            >
                <Tabs animated={true} size={'small'} className='report-tabs' defaultActiveKey="1" items={items} onChange={onChange} />
            </Card>
        </>
    );
}

export default ReportSaleWrapper;