import React from 'react';
import ContainerComponent from '../../helpers/components/container.component';
import PageHeaderComponent from '../../helpers/components/page_header.component';
import CustomerList from './components/customer_list';

const CustomerIndex = () => {
    return (
        <>
            <ContainerComponent span={22}>
                <PageHeaderComponent />
                <CustomerList />
            </ContainerComponent>
        </>
    );
}

export default CustomerIndex;