export const set_billing_items = (billing_items) => {
    return {
        type: 'SET_BILLING_ITEMS',
        billing_items
    };
}

export const set_selected_items = (selected_items) => {
    return {
        type: 'SET_SELECTED_ITEMS',
        selected_items
    };
}

export const set_update_billing = (update_billing) => {
    return {
        type: 'SET_UPDATE_BILLING',
        update_billing
    };
}

export const refresh_billing_detail = (refresh) => {
    return {
        type: 'REFRESH_BILLING_DETAIL',
        refresh
    };
}
