import { FilterOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, DatePicker, Form, Input, Modal, Row, Select, Space, Spin, Table, Tag } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { set_list_page, set_sale_list } from '../../../actions/sales_order.action';
import InputWrapper from '../../../helpers/components/input_wrapper.component';
import checkoutService from '../../../services/sales/checkout.service';

const { Option } = Select;
const SaleListTable = ({customer_id, search_params}) => {
    const sales_order_redux = useSelector(state => state.sales_order);
    const auth_redux = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [state, setState] = useState({
        loading : false,
        sales : [],

        //filter
        filter_modal : false,

        //sale status
        sale_status : [],
    })

    const [filter, setFilter] = useState({
        search : null,

        // advanced filter
        start_date : search_params?.get('start_date') ?? null,
        end_date : search_params?.get('end_date') ?? null,
        status : search_params?.get('status') ?? null,
        customer : null,
    });
    const [timer, setTimer] = useState(null)

    const fetchSaleList = async () => {
        setState(state => ({...state, loading : true}));
        const salesResponse = await checkoutService.getSales({
            page : sales_order_redux?.list_page,
            size : 10,
            //filter
            search : filter?.search,
            start_date : filter?.start_date,
            end_date : filter?.end_date,
            customer : filter?.customer,
            status : filter?.status,
            ...(customer_id && { customer_id })
        });

        setTimeout(() => {
            dispatch(set_sale_list(salesResponse?.sales?.rows, salesResponse?.sales?.count));
            setState(state => ({...state, sales : salesResponse?.sales?.rows, loading : false,}));
        }, 500);
    }

    const doneTyping = async (input) => {
        clearTimeout(timer);
        dispatch(set_list_page(0));
        const newTimer = setTimeout(() => {
            setFilter(filter => ({...filter, search : input}));
        }, 300);
        setTimer(newTimer);
    }

    const fetchSaleStatus = async () => {
        const statusResponse = await checkoutService.getSaleStatus();
        setState(state => ({...state, sale_status : statusResponse?.sale_status}));
    }

    const onFilter = async () => {
        const { date, status, customer } = form.getFieldsValue();
        const start_date = date && moment(date[0]).format("YYYY-MM-DD");
        const end_date = date && moment(date[1]).format("YYYY-MM-DD");
        setFilter(filter => ({...filter, start_date : start_date, end_date : end_date, status : status, customer : customer}));
        setState(state => ({...state, filter_modal : false,}));
    }
    
    useEffect(() => { //this useeffect to fetch sale status
        fetchSaleStatus();
    }, [])

    useEffect(() => { // to refresh table
        fetchSaleList();
    }, [sales_order_redux?.list_page, filter])

    return (
        <>
            <div className='filter-panel' style={{height:60, textAlign:'left'}}>
                <Space>
                    <div>
                        <InputWrapper>
                            <Input
                            onChange={(e) => doneTyping(e.target.value)}
                            bordered={false}
                            prefix={<div style={{paddingRight:12, paddingLeft:8}}><SearchOutlined style={{color:'#8c8c8c'}}/></div>}
                            placeholder={`Search Sales Transactions`}
                            style={{fontSize:14, width:400}}
                            size='large'
                            allowClear
                            />
                        </InputWrapper>
                    </div>
                    <div>
                        <Button
                        icon={<FilterOutlined style={{color:'#8c8c8c'}} />}
                        onClick={() => {
                            setState(state => ({...state, filter_modal : true}));
                        }}
                        style={{borderRadius:6}}
                        />
                    </div>
                </Space>
            </div>
            <Spin spinning={state?.loading}>
                <Table
                pagination={{ 
                    size : 10,
                    showSizeChanger: false,
                    total : sales_order_redux?.list_count,
                    current : (sales_order_redux?.list_page + 1),
                    onChange : (page) => {
                        dispatch(set_list_page(page - 1));
                        
                    }
                }}
                className='hub-table'
                dataSource={sales_order_redux?.sale_list}
                columns={[{
                    title : 'Transaction No',
                    dataIndex : 'running_number',
                    key : 'running_number',
                },{
                    title : 'Customer',
                    dataIndex : 'customer',
                    key : 'customer',
                    responsive : ['lg'],
                    render : (text, row, index) => {
                        return (
                            <>
                                {
                                    text ?
                                    (
                                        <>
                                            <div>
                                                <div>
                                                    <span style={{fontWeight:500}}>{text?.name}</span>
                                                </div>
                                                <div>
                                                    <span style={{color:'#8c8c8c', fontSize:12}}>{text?.phone_no}</span>
                                                </div>
                                            </div>
                                        </>
                                    )
                                    :
                                    (
                                        <>
                                            <div>
                                                <span style={{color:'#8c8c8c', fontSize:12}}>No Customer Record</span>
                                            </div>
                                        </>
                                    )
                                }
                                
                            </>
                        )
                    }
                },{
                    title : 'Issue Date',
                    dataIndex : 'created_at',
                    key : 'created_at',
                    responsive : ['lg'],
                    // sorter: (a, b) => moment(a.created_at) - moment(b.created_at),
                    render : (text, row, index) => {
                        return (
                            <>
                                <div>
                                    <span>{moment(text).format("D MMM YYYY, h:mm:ss a")}</span>
                                </div>
                            </>
                        )
                    }
                },{
                    title : 'Amount',
                    dataIndex : 'total_amount',
                    key : 'total_amount',
                    responsive : ['md', 'sm'],
                    render : (text, row, index) => {
                        return (
                            <>
                                <div>
                                    <span>{`RM${text}`}</span>
                                </div>
                            </>
                        )
                    }
                },{
                    ...(auth_redux?.user?.role_id == 1 ? {
                        title : 'GP',
                        dataIndex : 'profit',
                        key : 'profit',
                        responsive : ['md', 'sm'],
                        render : (text, row, index) => {
                            const { total_cost, total_amount } = row;
                            const gp_amount = !isNaN(parseFloat(text)) ? parseFloat(text)?.toFixed(2) : 0;
                            // Gross Profit Percentage Formula = Gross Profit / Total Sales * 100%
                            const gp_percentage = (gp_amount / total_amount) * 100;
                            return (
                                <>
                                    <div>
                                        <div>
                                            <span>{`RM${gp_amount}`}</span>
                                        </div>
                                        <div>
                                            <span style={{fontSize:14, color : '#8c8c8c'}}>{`${gp_percentage?.toFixed(2)}%`}</span>
                                        </div>
                                    </div>
                                </>
                            )
                        }
                    } : {})
                },{
                    title : 'Status',
                    dataIndex : 'sale_status',
                    key : 'sale_status',
                    responsive : ['md'],
                    render : (text, row, index) => {
                        return (
                            <>
                                <div>
                                    <Tag color={text?.color} style={{border:'none'}}>
                                        <span>{text?.name}</span>
                                    </Tag>
                                </div>
                            </>
                        )
                    }
                },{
                    title : 'Action',
                    key : 'action',
                    width : '10%',
                    render : (text, row, index) => {
                        return (
                            <>
                                <div>
                                    <Link to={`/sales/order/${row?.sale_id}`}>
                                        <Button type='default' style={{borderRadius:6}}>
                                            <span style={{color: '#8c8c8c'}}>View</span>
                                        </Button>
                                    </Link>
                                </div>
                            </>
                        )
                    }
                }]}
                />
            </Spin>


            <Modal
            transitionName="ant-fade"
            maskTransitionName="ant-fade"
            open={state?.filter_modal}
            title={<><span>Filter</span></>}
            width={500}
            closable={false}
            className="custom-modal"
            okButtonProps={{
                form : 'sale_form',
                key : "submit",
                htmlType : "submit"
            }}
            onOk={() => form.submit()}
            onCancel={() => setState(state => ({...state, filter_modal : false,}))}
            >
                <Form
                id="filter_form"
                onFinish={onFilter}
                form={form}
                layout='vertical'
                >
                    <div>
                        <Form.Item name='date' label="Date">
                            <DatePicker.RangePicker allowClear defaultValue={[(filter?.start_date && moment(filter?.start_date)), (filter?.end_date && moment(filter?.end_date))]}/>
                        </Form.Item>
                        <Form.Item name='status' label="Status">
                            <Select 
                            // showSearch
                            defaultValue={filter?.status}
                            className='border-input'
                            style={{ borderRadius: 6 }}
                            allowClear
                            >
                                {
                                    state?.sale_status?.map((status, index) => {
                                        return (
                                            <Option value={status?.sale_status_id} key={index}>
                                                <div>
                                                    <Tag color={status?.color} style={{border:'none'}}>
                                                        <span>{status?.name}</span>
                                                    </Tag>
                                                </div>
                                            </Option>
                                        )
                                        
                                    })
                                }
                            </Select>
                        </Form.Item>

                        <Form.Item name='customer' label="Customer">
                            <Input allowClear placeholder='Enter customer name or phone no' style={{borderRadius:6}}/>
                        </Form.Item>
                    </div>
                    
                </Form>
            </Modal>
        </>
    );
}

export default SaleListTable;