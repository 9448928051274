import { DownOutlined, SearchOutlined, ExclamationCircleOutlined, EllipsisOutlined } from '@ant-design/icons';
import { DatePicker, Button, Input, Select, Spin, Table, Drawer, Form, Space, notification, Modal, Dropdown, InputNumber, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import InputWrapper from '../../../helpers/components/input_wrapper.component';
import stockService from '../../../services/stock/stock.service';
import locationService from '../../../services/config/location.service';
import branchService from '../../../services/config/branch.service';
import moment from 'moment';
import { Link, useHistory } from 'react-router-dom';
import * as XLSX from "xlsx";
import globalService from '../../../helpers/functions/global.helper';
import exportExcelService from '../../../services/excel/exportExcel.service';


const { confirm } = Modal;
const { Option } = Select;
const CountListTable = () => {
    const [form] = Form.useForm();
    const [form3] = Form.useForm();

    const [state, setState] = useState({
        stock: [],
        loading: false,
        isDisabled: true,
        isView: null,
        printModal: false
    });
    const [filter, setFilter] = useState({
        page: 0,
        search: null,
    });

    const [timer, setTimer] = useState(null);
    const [open, setOpen] = useState(false);
    const history = useHistory();


    const fetchStockList = async () => {
        setState(state => ({ ...state, loading: true, }));

        const stockResponse = await stockService.getStockCount({
            search: filter?.search,
            page: filter?.page
        });
        setTimeout(() => {
            setState(state => ({
                ...state,
                stock: stockResponse?.stock,
                loading: false,
            }))
        }, 800);

    }


    const doneTyping = async (input) => {
        clearTimeout(timer);
        const newTimer = setTimeout(() => {
            setFilter(filter => ({ ...filter, search: input }));
        }, 300);
        setTimer(newTimer);
    }

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        clearAll()
        setOpen(false);
    };

    const openDrawer = (item, isView) => {
        setState(state => ({
            ...state,
            isDisabled: isView,
            isView: isView,
            item: item
        }));
        if (isView === true) {
            form.setFieldsValue({
                name: item.name,
                description: item.description,
            });
        }

        showDrawer();
    }

    const onDelete = async (data) => {
        confirm({
            icon: <ExclamationCircleOutlined />,
            content: (
                <>
                    <Space direction='vertical'>
                        <div>
                            <span>Are your sure to delete stock count?</span>
                        </div>

                    </Space>
                </>
            ),
            async onOk() {
                const deleteStockCountResponse = await stockService.deleteStockCount(data.stock_count_id);
                notification.success({
                    message: (<span style={{ fontSize: 14, fontWeight: 500, }}>Stock Count Deleted</span>),
                    duration: 3,
                    placement: 'topRight'
                });
                clearAll();
                refresh();
            },
            onCancel() {
            },
        });

    };


    const addStockCount = () => {
        history.push(`/stocks/createcount`);
    }

    const refresh = () => {
        onClose();
        fetchStockList();
    };

    const clearAll = () => {
        setState(state => ({
            ...state,
            isDisabled: true,
            isView: null,
        }));
        form.resetFields();
    }

    const printExcel = (data) => {
        const newData = data?.stock_count_items.map(x => {
            return {
                Branch: data?.branch?.name,
                CreatedAt: moment(data?.created_at).format("DD-MM-YYYY"),
                ItemCode: x?.item?.item_code,
                ItemName: x?.item?.name,
                Quantity: x?.quantity,
                CountedQuantity: x?.counted_quantity,
                QuantityDifference: (x?.quantity * 1) - (x?.counted_quantity * 1)
            }
        })
        globalService.exportToExcel(newData, XLSX, data?.branch?.name + ' ' + moment(data?.created_at).format("DD-MM-YYYY"));

    }
    const fetchBranchOnSearch = async () => {
        let branchResponse;
        branchResponse = await branchService.getBranch({
            search: filter?.branchInput,
        });

        const branch = branchResponse?.branch?.map(x => {
            return {
                value: x.branch_id,
                label: x.name
            }
        })


        setState(state => ({ ...state, branch: branch }));
    }

    const showModal = () => {
        setTimeout(() => {
            setState(state => ({ ...state, printModal: true }));
        }, 300);

    };
    const handleCancel = () => {
        form3.resetFields();
        setState(state => ({ ...state, printModal: false }));
    };

    const searchStockCount = async () => {
        form3.validateFields()
            .then(async (values) => {
                setState(state => ({ ...state, modalLoading: true }));
                let allData = [];
                const { branch_id, date } = form3.getFieldsValue();
                const exportStockCountResponse = await stockService.getFilterStockCount({
                    branch_id: branch_id,
                    start_date: moment(date[0]).format("YYYY-MM-DD"),
                    end_date: moment(date[1]).format("YYYY-MM-DD"),
                });
                for (const sc of exportStockCountResponse?.stock) {
                    const newData = sc?.stock_count_items.map(x => {
                        return {
                            Branch: sc?.branch?.name,
                            CreatedAt: moment(sc?.created_at).format("DD-MM-YYYY"),
                            ItemCode: x?.item?.item_code,
                            ItemName: x?.item?.name,
                            Quantity: x?.quantity,
                            CountedQuantity: x?.counted_quantity,
                            QuantityDifference: (x?.quantity * 1) - (x?.counted_quantity * 1)
                        }
                    })
                    allData.push(...newData);
                }
                globalService.exportToExcel(allData, XLSX, 'Filtered stock count');

                setState(state => ({ ...state, modalLoading: false }));

            })
            .catch(() => {
                setState(state => ({ ...state, modalLoading: false }));
            });
    }

    useEffect(() => {
        fetchStockList();
    }, [filter?.search, filter?.page]);

    useEffect(() => {
        fetchBranchOnSearch();
    }, [])

    return (
        <>
            <div className='filter-panel' style={{ height: 60, }}>
                <div style={{ display: 'flex' }}>
                    <div style={{ minWidth: 400 }}>
                        <InputWrapper>
                            <Input
                                onChange={(e) => doneTyping(e.target.value)}
                                bordered={false}
                                prefix={<div style={{ paddingRight: 12, paddingLeft: 8 }}><SearchOutlined style={{ color: '#8c8c8c' }} /></div>}
                                placeholder={`Search Branch`}
                                style={{ fontSize: 14 }}
                                size='large'
                            />
                        </InputWrapper>
                    </div>
                    <div style={{ marginLeft: 'auto' }}>
                        <Dropdown
                            placement='bottomRight'
                            overlayStyle={{ borderRadius: 12, minWidth: 200 }}
                            menu={{
                                items: [{
                                    key: '1',
                                    type: 'group',
                                    children: [{
                                        key: '1-1',
                                        label: "Add",
                                        onClick: async () => {
                                            addStockCount();
                                        }
                                    }, {
                                        key: '1-2',
                                        label: "Export",
                                        onClick: async () => {
                                            showModal();
                                        },
                                        disabled: state?.selected < 1,
                                    },]
                                },]
                            }}
                        >
                            <Button type='primary' className='mini-button' icon={<DownOutlined />} style={{ borderRadius: 4 }}>
                                Action
                            </Button>
                        </Dropdown>

                        {/* <Button
                            type="primary"
                            // icon={<PlusOutlined />}
                            onClick={addStockCount}
                            style={{ borderRadius: 6 }}
                        >
                            Add Stock Count
                        </Button> */}
                    </div>

                </div>
            </div>

            <Spin spinning={state?.loading}>
                <Table
                    scroll={{ x: 400, }}
                    pagination={{
                        size: 10,
                        showSizeChanger: false,
                        total: state?.stock?.count,
                        current: (filter?.page + 1),
                        onChange: (page) => {
                            const newTimer = setTimeout(() => {
                                setFilter(filter => ({ ...filter, page: page - 1 }));
                            }, 300);
                            setTimer(newTimer);
                        }
                    }}
                    className='hub-table'
                    dataSource={state?.stock?.rows}
                    columns={[
                        {
                            title: 'Branch',
                            dataIndex: 'branch',
                            key: 'branch',
                            render: (text) => (
                                <span>{text?.name}</span>
                            )
                        },
                        {
                            title: 'Created Date',
                            dataIndex: 'created_at',
                            key: 'created_at',
                            render: (text, row, index) => {
                                return (
                                    <>
                                        <div>
                                            <span>{moment(text).format("D MMM YYYY, h:mm:ss a")}</span>
                                        </div>
                                    </>
                                )
                            }
                        },
                        {
                            title: 'Status',
                            dataIndex: 'status',
                            key: 'status',
                            render: (text, row, index) => {
                                return (
                                    <>
                                        <div>
                                            <Tag color={text == 0 ? 'orange' : text == 1 ? 'green' : 'red'} style={{ border: 'none' }}>
                                                <span>{text == 0 ? 'Pending' : text == 1 ? 'Completed' : 'Void'}</span>
                                            </Tag>
                                        </div>
                                    </>
                                )
                            }
                        },
                        {
                            title: 'Action',
                            key: 'action',
                            width: '10%',
                            render: (text, row, index) => {
                                return (
                                    <>
                                        <div>
                                            <Dropdown
                                                placement='bottomRight'
                                                overlayStyle={{ borderRadius: 12, minWidth: 200 }}
                                                menu={{
                                                    items: [{
                                                        key: '1',
                                                        type: 'group',
                                                        label: "Action",
                                                        children: [{
                                                            key: '1-1',
                                                            label: "View",
                                                            onClick: async () => {
                                                                openDrawer(row, true);
                                                            }
                                                        }, {
                                                            key: '1-2',
                                                            label: "Export",
                                                            onClick: async () => {
                                                                printExcel(row);
                                                            }
                                                        }, {
                                                            key: '1-3',
                                                            label: "Delete",
                                                            onClick: async () => {
                                                                await onDelete(row);
                                                            }
                                                        },]
                                                    },]
                                                }}
                                            >
                                                <Button size='small' icon={<EllipsisOutlined />} style={{ borderRadius: 6 }} />
                                            </Dropdown>
                                        </div>
                                    </>
                                )
                            }
                        }]}
                />
            </Spin>

            <Drawer title='Stock Count Detail'
                placement="right"
                width={720}
                onClose={onClose}
                open={open}
            >
                <div style={{ marginBottom: 20, fontSize: 14 }}>
                    <Space>
                        <span>{`Branch : ${state?.item?.branch?.name}`}</span>
                    </Space>

                </div>


                <Table
                    scroll={{ x: 400, }}
                    pagination={false}
                    className='hub-table'
                    dataSource={state?.item?.stock_count_items}
                    columns={[
                        {
                            title: '#',
                            dataIndex: 'stock_count_id',
                            key: 'stock_count_id',
                            render: (text, data, index) => (
                                <span>{index + 1}</span>
                            )
                        },
                        {
                            title: 'Item Name',
                            dataIndex: 'item',
                            key: 'item',
                            render: (text, data) => (
                                <span>{text?.name}</span>
                            )
                        },
                        {
                            title: 'Quantity',
                            dataIndex: 'quantity',
                            key: 'quantity',
                            render: (text, data) => (
                                <span>{text}</span>
                            )
                        },
                        {
                            title: 'Counted Quantity',
                            dataIndex: 'counted_quantity',
                            key: 'counted_quantity',
                            render: (text, data) => (
                                <span>{text}</span>
                            )
                        },
                        {
                            title: 'Quantity Difference',
                            dataIndex: 'counted_quantity',
                            key: 'counted_quantity',
                            render: (text, data) => (
                                <>
                                    <Tag color={data?.quantity - data?.counted_quantity > 0 ? 'red' : 'green'}>{Math.abs(data?.quantity - data?.counted_quantity)}</Tag>
                                </>

                            )
                        },
                    ]}
                />
            </Drawer >

            <Modal
                title={'Export Stock Count'}
                centered
                okText="Search"
                open={state?.printModal}
                onOk={searchStockCount}
                onCancel={handleCancel}
                confirmLoading={state?.modalLoading}
            >

                <Form form={form3}>
                    <Form.Item name='date' label="Date" rules={[{ required: true, message: 'Please select a date range' }]}>
                        <DatePicker.RangePicker allowClear />
                    </Form.Item>

                    <Form.Item
                        labelCol={{ span: 24 }}
                        label="Branch"
                        name="branch_id"
                        rules={[{ required: true, message: 'Please select a branch' }]}
                    >
                        <Select
                            showSearch
                            className='border-input'
                            options={state?.branch}
                            style={{ borderRadius: 6, width: '100%' }}
                            filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                            placeholder='Branch'
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
}

export default CountListTable;