export const set_sale_items = (sale_items) => {
    return {
        type: 'SET_SALE_ITEMS',
        sale_items
    };
}

export const set_customer = (customer) => {
    return {
        type: 'SET_CUSTOMER',
        customer
    }
}

export const set_sale_list = (sale_list, list_count) => {
    return {
        type: 'SET_SALE_LIST',
        sale_list,
        list_count,
    }
}

export const set_list_page = (page) => {
    return {
        type: 'SET_LIST_PAGE',
        page
    }
}

export const set_credit_note_list = (credit_note_list, cn_list_count) => {
    return {
        type: 'SET_CREDIT_NOTE_LIST',
        credit_note_list,
        cn_list_count
    }
}

export const set_credit_note_data = (credit_note_data) => {
    return {
        type: 'SET_CREDIT_DATA',
        credit_note_data,
    }
}

export const set_report_sale_payment_method_list_age = (page) => {
    return {
        type: 'SET_REPORT_SALE_PAYMENT_METHOD_LIST_PAGE',
        page
    }
}

export const refresh_sale_detail = (sale_refresh) => {
    return {
        type: 'REFRESH_SALE_DETAIL',
        sale_refresh
    }
}

export const set_sale_total_amount = (sale_total_amount) => {
    return {
        type: 'SET_SALE_TOTAL_AMOUNT',
        sale_total_amount
    }
}

export const toggle_promotion_modal = (promotion_modal) => {
    return {
        type : 'TOGGLE_PROMOTION_MODAL',
        promotion_modal
    }
}

export const validate_user = (validated_user) => {
    return {
        type : 'VALIDATE_USER',
        validated_user,
    }
}