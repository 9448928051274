import axios from 'axios';
import serviceHelper from '../../helpers/functions/service.helper';


async function getBillingItems(query) {
    const queryString = serviceHelper.queryString(query);
    const billingItemsResponse = await axios.get(`${process.env.REACT_APP_SERVER_URL}billing/item/get${(queryString ? ("?" + queryString) : "")}`, { withCredentials: true });
    return billingItemsResponse?.data;
}

async function createBillingItems(params = {}) {
    const { billing_id, item_id, price, quantity, markup } = params;
    const billingItemsResponse = await axios.post(`${process.env.REACT_APP_SERVER_URL}billing/item/store`, {
        billing_id, item_id, price, quantity, markup
    }, { withCredentials: true });

    return billingItemsResponse?.data;
}

async function updateBillingItems(billing_item_id, params = {}) {
    const { billing_id, item_id, price, quantity, markup } = params;
    const billingItemsResponse = await axios.post(`${process.env.REACT_APP_SERVER_URL}billing/item/update/${billing_item_id}`, {
        billing_id, item_id, price, quantity, markup
    }, { withCredentials: true });

    return billingItemsResponse?.data;
}

async function deleteBillingItems(billing_item_id, params = {}) {
    const { billing_id } = params;
    const billingItemsResponse = await axios.post(`${process.env.REACT_APP_SERVER_URL}billing/item/delete/${billing_item_id}`, {
        billing_id
    }, { withCredentials: true });
    return billingItemsResponse?.data;
}

export default {
    getBillingItems,
    createBillingItems,
    updateBillingItems,
    deleteBillingItems,
}