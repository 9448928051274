import { Card, Tabs } from 'antd';
import React from 'react';
import StockOverallReport from './overall/stock_overall_report';

const StockReportWrapper = () => {
    const items = [
    {
        key: '1',
        label: <span style={{margin : '0 20px'}}>Overall Stock Report</span>,
        children: (
            <>
                <StockOverallReport />
            </>
        ),
    },];

    return (
        <>
            <Card
            bordered={false}
            style={{borderRadius:6}}
            bodyStyle={{padding:0}}
            >
                <Tabs animated={true} size={'small'} className='report-tabs' defaultActiveKey="1" items={items} />
            </Card>
        </>
    );
}

export default StockReportWrapper;