import React from 'react';
import ContainerComponent from '../../helpers/components/container.component';
import PageHeaderComponent from '../../helpers/components/page_header.component';
import CreateStock from './components/create_stock';
import { useParams } from 'react-router-dom';

const CreateNewStock = () => {
    return (
        <>
            <ContainerComponent span={22} top={20}>
                <PageHeaderComponent />
                <CreateStock />
            </ContainerComponent>
        </>
    );
}

export default CreateNewStock;