import { Spin, Table, Form, Input, Space, Select, Button, Radio, Divider, InputNumber, notification, Modal, Checkbox, Dropdown } from 'antd';
import { SearchOutlined, ExclamationCircleOutlined, EllipsisOutlined, DownOutlined } from '@ant-design/icons';
import moment from 'moment';
import InputWrapper from '../../../helpers/components/input_wrapper.component';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
// import { set_credit_note_list } from '../../../actions/returns_order.action';
import stockService from '../../../services/stock/stock.service';
import locationService from '../../../services/config/location.service';
import branchService from '../../../services/config/branch.service';
import supplierService from '../../../services/config/supplier.service';
import itemService from '../../../services/config/item.service';
import { generateCN } from '../functions/printCN';

const { Option } = Select;
const { confirm } = Modal;

const StockReturnList = () => {
    const [form] = Form.useForm();

    const dispatch = useDispatch();
    const [timer, setTimer] = useState(null);

    const [state, setState] = useState({
        loading: false,
        stock_returns: [],
        location: [],
        branch: [],
        supplier: [],
        return_type: 1,
        return_section: 1,
        items: [],
        cn_id: null,
        update_loading: false,
        isUpdate: false,
        isDisabled: false,
        added_return_items: [],
        selected_items: null,
        selected: [],
    })
    const [filter, setFilter] = useState({
        page: 0,
        search: null,
        isPurchase: 1, // check if it is purchase or billing
    });

    const [open, setOpen] = useState(false);
    const showDrawer = () => {
        setState(state => ({ ...state, isUpdate: false }));

        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
        clearAll();
    };
    const modifyReturnNumber = (item_id, quantity) => {

        let added_return_items = state?.added_return_items;
        let existing_item = added_return_items?.find(x => x.value === item_id);
        existing_item.quantity = quantity;
        // let changed_quantity = added_return_items;
        const changed_quantity = added_return_items?.map((x) => {
            return (x?.value === item_id) ? existing_item : x;
        })
        setState(state => ({ ...state, selected_items: item_id, added_return_items: added_return_items }));
        form.setFieldValue("item", added_return_items);
        // calculateTotalAmount();
    }

    const modifyUnitPrice = (item_id, unit_price) => {

        let added_return_items = state?.added_return_items;
        let existing_item = added_return_items?.find(x => x.value === item_id);
        existing_item.unit_price = unit_price;
        // let changed_quantity = added_return_items;
        const changed_quantity = added_return_items?.map((x) => {
            return (x?.value === item_id) ? existing_item : x;
        })
        setState(state => ({ ...state, selected_items: item_id, added_return_items: added_return_items }));
        form.setFieldValue("item", added_return_items);
        // calculateTotalAmount();
    }

    const fetchStockReturns = async () => {
        setState(state => ({ ...state, loading: true }));
        const stockReturnResponse = await stockService.getStockReturns({
            page: filter?.page,
            size: 10,
            search: filter?.search,
            isPurchase: filter?.isPurchase,
        }).catch(err => {
            setState(state => ({ ...state, loading: false }));
        });

        setTimeout(() => {
            // dispatch(set_credit_note_list(credit_notes?.rows, credit_notes?.count));
            setState(state => ({ ...state, loading: false, stock_returns: stockReturnResponse?.stock_returns }));
        }, 500);

    }

    const getBranchSupplier = async () => {

        const branchResponse = await branchService.getBranch();
        const supplierResponse = await supplierService.getSupplier();
        const locationResponse = await locationService.getLocation();

        const branch = branchResponse?.branch?.map(x => {
            return {
                value: x.branch_id,
                label: x.name,
            }
        });

        const supplier = supplierResponse?.supplier?.map(x => {
            return {
                value: x.supplier_id,
                label: x.name,
            }
        });

        const location = locationResponse?.location?.map(x => {
            return {
                value: x.location_id,
                label: x.name,
            }
        });

        setTimeout(() => {
            // dispatch(set_credit_note_list(credit_notes?.rows, credit_notes?.count));
            setState(state => ({
                ...state,
                branch: branch,
                supplier: supplier,
                location: location
            }));
        }, 500);
    };

    const createStockReturn = async () => {
        setState(state => ({ ...state, update_loading: true }));
        const { return_type, return_section, return_from, return_to, item } = form.getFieldsValue();
        try {
            let data = {
                return_type: return_type ?? 1,
                return_section: return_section ?? 1,
                return_from: return_from,
                return_to: return_to,
                items: item,
                // quantity: quantity,
                //remarks: remarks,
            }

            const storeResponse = await stockService.storeStockReturn(data);
            setTimeout(() => {
                notification.success({
                    message: (<span style={{ fontSize: 14, fontWeight: 500, }}>Stock Returned</span>),
                    duration: 3,
                    placement: 'topRight'
                });
                clearAll();
                refresh();
            }, 800);

        } catch (err) {
            setState(state => ({ ...state, update_loading: false }));

        }
    };

    const selectOnchange = (data) => {
        let newData = state?.selected;
        const index = newData.indexOf(data);

        if (index !== -1) {
            // Number exists in the array, so remove it
            newData.splice(index, 1);
        } else {
            // Number doesn't exist in the array, so add it
            newData.push(data);
        }

        setState(state => ({ ...state, selected: newData }));

    }

    const checkboxSelected = (data) => {
        let newData = state?.selected;
        const index = newData.indexOf(data);

        if (index !== -1) {
            // Number exists in the array, so remove it
            return true
        }

        return false
    }

    const deleteCN = async (data) => {
        const newData = {
            stock_return_id: data?.order_credit_note_id ?? data?.billing_credit_note_id,
            return_type: filter?.isPurchase,
            // return_section: state?.return_section
        }
        confirm({
            icon: <ExclamationCircleOutlined />,
            content: (
                <>
                    <Space direction='vertical'>
                        <div>
                            <span>Are your sure to delete stock returned?</span>
                        </div>

                    </Space>
                </>
            ),
            async onOk() {
                const deleteStockResponse = await stockService.deleteStockReturn(newData);
                notification.success({
                    message: (<span style={{ fontSize: 14, fontWeight: 500, }}>Stock Deleted</span>),
                    duration: 3,
                    placement: 'topRight'
                });
                clearAll();
                refresh();
            },
            onCancel() {
            },
        });
    }
    const bulkDeleteCN = async (data) => {
        const newData = {
            ids: state?.selected,
            return_type: filter?.isPurchase,
        }
        confirm({
            icon: <ExclamationCircleOutlined />,
            content: (
                <>
                    <Space direction='vertical'>
                        <div>
                            <span>Are your sure to delete selected stock returned?</span>
                        </div>

                    </Space>
                </>
            ),
            async onOk() {
                const deleteStockResponse = await stockService.bulkDeleteStockReturn(newData);
                notification.success({
                    message: (<span style={{ fontSize: 14, fontWeight: 500, }}>Stock Deleted</span>),
                    duration: 3,
                    placement: 'topRight'
                });
                clearAll();
                refresh();
            },
            onCancel() {
            },
        });
    }

    const printCN = async (b) => {
        var printWindow = window.open(` `, 'Print CN');
        // const { billing_items, branch, running_number, created_at, total_amount, supplier } = b ?? {};
        const cn_string = await generateCN(b, filter?.isPurchase)
        printWindow.document.write(cn_string);
        printWindow.print();
        printWindow.close();

    }
    const bulkPrintCN = async () => {
        const printWindow = window.open(``, 'Print');
        let cn_bulk_string = '';
        const items = await stockService.getFilterStockReturns({
            ids: state?.selected,
            isPurchase: filter?.isPurchase,
        })

        for (const item of items?.stock_returns) {

            cn_bulk_string += await generateCN(item, filter?.isPurchase);
        }

        printWindow.document.write(cn_bulk_string);
        printWindow.print();
        printWindow.close();
        setState(state => ({ ...state, modalLoading: false }));


        // var printWindow = window.open(' ', 'PrintWindow');
        // const { billing_items, branch, running_number, created_at, total_amount, supplier } = b ?? {};
        // const invoice_string = await generateCN({
        //     branch: branch ? branch?.name : supplier?.name,
        //     items: billing_items,
        //     running_number: running_number,
        //     total_amount: total_amount,
        //     date: moment(created_at).format("DD/MM/YYYY"),
        // })
        // printWindow.document.write(invoice_string);
        // printWindow.print();
        // printWindow.close();

    }

    const fetchItemOnSearch = async (input) => {
        // setState(state => ({ ...state, loading: true }));
        const itemResponse = await itemService.getItem({
            // page: (filter?.page - 1),
            // size: 8,
            //filter
            search: input
        });

        const items = itemResponse?.items?.rows?.map(x => {
            return {
                value: x.item_id,
                label: x.name,
                item_code: x.item_code,
            }
        })
        setState(state => ({ ...state, items: items }));
    }

    const onDropdownSelected = (item_id) => {
        //get latest item added
        let new_items = item_id?.filter(x => !state?.added_return_items?.map(x1 => x1.value)?.includes(x));
        let added_return_items = [...state?.added_return_items];

        for (const item of new_items) {
            let items = state?.items?.find(x => x.value === item);
            items.quantity = 1;
            items.unit_price = 1;
            added_return_items = [...added_return_items, items];
        }
        setState(state => ({ ...state, selected_items: item_id, added_return_items: added_return_items }));
        form.setFieldValue("item", added_return_items);
    }

    const onDropdownDeselected = (item_id) => {
        let added_return_items = [...state?.added_return_items];
        let items = added_return_items?.filter(x => !(x.value === item_id));

        setState(state => ({ ...state, selected_items: items?.map(x => x.value), added_return_items: items }));
        form.setFieldValue("item", items);
    }

    const doneTyping = async (input) => {
        clearTimeout(timer);
        const newTimer = setTimeout(() => {
            setFilter(filter => ({ ...filter, search: input }));
        }, 300);
        setTimer(newTimer);
    }

    const doneTyping2 = (input) => {
        clearTimeout(timer);
        const newTimer = setTimeout(() => {
            fetchItemOnSearch(input);
            // setState(state => ({ ...state, input: input }));
        }, 500);
        setTimer(newTimer);
    }

    const clearAll = () => {
        setState(state => ({
            ...state,
            isDisabled: true,
            isUpdate: null,
            return_section: 1,
            return_type: 1,
            update_loading: false,
            added_return_items: [],
            selected_items: null,
            selected: [],

        }));
        form.resetFields();
    }

    useEffect(() => {
        fetchStockReturns();
    }, [filter]);

    const refresh = () => {
        onClose();
        fetchStockReturns();
    };

    useEffect(() => {
        getBranchSupplier();
        fetchItemOnSearch();
    }, []);

    useEffect(() => {
        form.setFieldsValue({ return_from: null, return_to: null });

    }, [state?.return_type, state?.return_section]);

    return (
        <>
            <div>
                <div className='filter-panel' style={{ height: 60 }}>
                    <div style={{ display: 'flex' }}>
                        <div style={{ minWidth: 300 }}>
                            <InputWrapper>
                                <Input
                                    name="searchItem"
                                    onChange={(e) => doneTyping(e.target.value)}
                                    bordered={false}
                                    prefix={<div style={{ paddingRight: 12, paddingLeft: 8 }}><SearchOutlined style={{ color: '#8c8c8c' }} /></div>}
                                    placeholder={`Search Credit Note`}
                                    style={{ fontSize: 14 }}
                                    size='large'
                                />
                            </InputWrapper>
                        </div>
                        <div style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                            <Space>
                                <Radio.Group defaultValue={1} buttonStyle="solid" onChange={(e) => { setFilter(filter => ({ ...filter, isPurchase: e.target.value, page: 0 })); setState(state => ({ ...state, selected: [] })); }}>
                                    <Radio.Button value={1}>Purchase Order</Radio.Button>
                                    <Radio.Button value={2}>Billing</Radio.Button>
                                </Radio.Group>
                            </Space>

                        </div>
                        <div style={{ marginLeft: 'auto' }}>
                            <Dropdown
                                placement='bottomRight'
                                overlayStyle={{ borderRadius: 12, minWidth: 200 }}
                                menu={{
                                    items: [{
                                        key: '1',
                                        type: 'group',
                                        children: [{
                                            key: '1-1',
                                            label: "Create Stock Return",
                                            onClick: async () => {
                                                showDrawer();
                                            }
                                        }, {
                                            key: '1-2',
                                            label: "Print",
                                            onClick: async () => {
                                                bulkPrintCN();
                                            },
                                            disabled: state?.selected < 1,
                                        }, {
                                            key: '1-3',
                                            label: "Delete",
                                            onClick: async () => {
                                                bulkDeleteCN();
                                            },
                                            disabled: state?.selected < 1,
                                        }]
                                    },]
                                }}
                            >
                                <Button type='primary' className='mini-button' icon={<DownOutlined />} style={{ borderRadius: 4 }}>
                                    Action
                                </Button>
                            </Dropdown>

                        </div>
                        {/* <div style={{ marginLeft: 'auto' }}>
                            <Space>
                                <div>
                                    <Button
                                        onClick={bulkPrintCN}
                                        type='primary'
                                        style={{ width: '100%', borderRadius: 4 }}
                                        loading={state?.checkout_loading}
                                        disabled={state?.selected < 1}
                                    >
                                        <span style={{ fontWeight: 500 }}>Bulk Print</span>
                                    </Button>
                                </div>
                                <div>
                                    <Button
                                        onClick={bulkDeleteCN}
                                        type='primary'
                                        style={{ width: '100%', borderRadius: 4 }}
                                        loading={state?.checkout_loading}
                                        disabled={state?.selected < 1}
                                    >
                                        <span style={{ fontWeight: 500 }}>Bulk Delete</span>
                                    </Button>
                                </div>
                                <div>
                                    <Button
                                        onClick={showDrawer}
                                        type='primary'
                                        style={{ width: '100%', borderRadius: 4 }}
                                        loading={state?.checkout_loading}
                                    >
                                        <span style={{ fontWeight: 500 }}>Create Stock Return</span>
                                    </Button>
                                </div>

                            </Space>


                        </div> */}

                    </div>
                </div>
                <div style={{ marginTop: 20 }}>
                    <Spin spinning={state?.loading}>
                        <Table
                            pagination={{
                                size: 10,
                                showSizeChanger: false,
                                total: state?.stock_returns?.count,
                                current: (filter?.page + 1),
                                onChange: (page) => {
                                    const newTimer = setTimeout(() => {
                                        setFilter(filter => ({ ...filter, page: page - 1 }));
                                    }, 300);
                                    setTimer(newTimer);
                                }
                            }}
                            className='hub-table'
                            dataSource={state?.stock_returns?.rows}
                            columns={[{
                                title: 'Slc',
                                dataIndex: 'running_number',
                                key: 'running_number',
                                render: (text, row, index) => {
                                    return (
                                        <>
                                            <div>
                                                <Checkbox checked={checkboxSelected(row?.order_credit_note_id ?? row?.billing_credit_note_id)} disabled={!row?.to_id} onChange={() => selectOnchange(row?.order_credit_note_id ?? row?.billing_credit_note_id)}>
                                                </Checkbox>
                                            </div>
                                        </>
                                    )
                                },
                            },
                            {
                                title: 'Credit Note No',
                                dataIndex: 'running_number',
                                key: 'running_number',
                            },
                            {
                                title: 'Return From',
                                dataIndex: 'created_at',
                                key: 'created_at',
                                render: (text, row, index) => {
                                    return (
                                        <>
                                            {
                                                filter?.isPurchase === 1 ?
                                                    (row?.from_id ?
                                                        row?.from_location?.name : <span>HQ</span>
                                                    ) : (
                                                        row?.from_branch_id ?
                                                            row?.from_branch?.name :
                                                            (row?.from_supplier_id ? row.from_supplier?.name : <span>-</span>)
                                                    )
                                            }
                                        </>
                                    )
                                },
                                responsive: ['lg']
                            },
                            {
                                title: 'Return To',
                                dataIndex: 'created_at',
                                key: 'created_at',
                                render: (text, row, index) => {
                                    return (
                                        <>
                                            {
                                                filter?.isPurchase === 1 ?
                                                    (row?.to_id ?
                                                        row?.to_supplier?.name : <span>-</span>
                                                    ) : (
                                                        row?.to_id ?
                                                            row?.to_location?.name :
                                                            <span>HQ</span>
                                                    )
                                            }
                                        </>
                                    )
                                },
                                responsive: ['lg']
                            },
                            {
                                title: 'Item Returned',
                                dataIndex: 'item',
                                key: 'item',
                                render: (text, row, index) => {
                                    return (
                                        <>
                                            <div>
                                                <div>
                                                    <span style={{ fontWeight: 500 }}>{text?.name}</span>
                                                </div>
                                                <div>
                                                    <span style={{ color: '#8c8c8c', fontSize: 12 }}>{text?.item_code}</span>
                                                </div>
                                            </div>
                                        </>
                                    )
                                },
                                responsive: ['lg']
                            },
                            {
                                title: 'Quantity Returned',
                                dataIndex: 'quantity',
                                key: 'quantity',
                                responsive: ['lg']
                            },
                            {
                                className: filter?.isPurchase == 1 ? "show" : "hide",

                                title: 'Unit Price',
                                dataIndex: 'unit_price',
                                key: 'unit_price',
                                render: (text, row, index) => {
                                    return (
                                        <>
                                            <div>
                                                <span >{text ?? 0}</span>
                                            </div>
                                        </>
                                    )
                                },
                                responsive: ['lg'],
                            },
                            {
                                title: 'Total',
                                dataIndex: 'quantity',
                                key: 'quantity',
                                render: (text, row, index) => {
                                    return (
                                        <>
                                            <div>
                                                <span >{((row?.unit_price ?? 0) * (row?.quantity ?? 0)) ?? 0}</span>
                                            </div>
                                        </>
                                    )
                                },
                                responsive: ['lg'],
                                className: state?.isPurchase === 1 ? "show" : (state?.isPurchase === 2 ? "hide" : "")
                            },
                            {
                                title: 'Issue Date',
                                dataIndex: 'created_at',
                                key: 'created_at',
                                render: (text, row, index) => {
                                    return (
                                        <>
                                            <div>
                                                <span>{moment(text).format("D MMM YYYY, h:mm:ss a")}</span>
                                            </div>
                                        </>
                                    )
                                },
                                responsive: ['lg']
                            },
                            {
                                title: 'Action',
                                dataIndex: 'credit_note_status',
                                key: 'credit_note_status',
                                render: (text, row, index) => {
                                    return (
                                        <>
                                            <div>
                                                <Dropdown
                                                    placement='bottomRight'
                                                    overlayStyle={{ borderRadius: 12, minWidth: 200 }}
                                                    menu={{
                                                        items: [{
                                                            key: '1',
                                                            type: 'group',
                                                            label: "Action",
                                                            children: [{
                                                                key: '1-1',
                                                                label: "Print",
                                                                onClick: async () => {
                                                                    printCN(row);
                                                                    // openDrawer(row, true);
                                                                }
                                                            }, {
                                                                key: '1-2',
                                                                label: "Delete",
                                                                onClick: async () => {
                                                                    await deleteCN(row);
                                                                },
                                                                disabled: !row?.to_id,
                                                            },]
                                                        },]
                                                    }}
                                                >
                                                    <Button size='small' icon={<EllipsisOutlined />} style={{ borderRadius: 6 }} />
                                                </Dropdown>
                                            </div>
                                        </>
                                    )
                                },
                                // render: (text, row, index) => {
                                //     return (
                                //         <>
                                //             <div>

                                //                 <Button type='default' style={{ borderRadius: 6 }} onClick={() => deleteCN(row)} disabled={!row?.to_id}>
                                //                     <span style={{ color: '#8c8c8c' }}>Delete</span>
                                //                 </Button>
                                //             </div>
                                //         </>
                                //     )
                                // },
                                responsive: ['md']
                            },
                            ]}
                        />
                    </Spin >
                </div>

                <div>
                    <Modal
                        title="Return Stock"
                        closable={false}
                        className="custom-modal"
                        onCancel={onClose} open={open}
                        transitionName="ant-fade"
                        maskTransitionName="ant-fade"
                        width={500}
                        onOk={() => form.submit()}
                        confirmLoading={state?.update_loading}

                    >
                        <Form layout="vertical"
                            onFinish={createStockReturn}
                            form={form}>
                            <Form.Item
                                name="return_type"
                                label="Return type"
                                hidden={state.isUpdate}
                            >
                                <Radio.Group defaultValue={1} buttonStyle="solid" onChange={(e) => setState(state => ({ ...state, return_type: e.target.value }))}>
                                    <Radio.Button value={1}>Purchase Order</Radio.Button>
                                    <Radio.Button value={2}>Billing</Radio.Button>
                                </Radio.Group>

                            </Form.Item>
                            <Form.Item
                                name="return_section"
                                label="Return Section"
                                hidden={state?.return_type == 1 || state?.isUpdate == true}
                            >
                                <Radio.Group defaultValue={1} buttonStyle="solid" onChange={(e) => setState(state => ({ ...state, return_section: e.target.value }))}>
                                    <Radio.Button value={1}>Supplier</Radio.Button>
                                    <Radio.Button value={2}>Branch</Radio.Button>
                                </Radio.Group>

                            </Form.Item>
                            <Form.Item
                                name="return_from"
                                label="Return From"
                                rules={[{ required: true, message: 'Please select a location' }]}
                            >
                                <Select
                                    className='border-input'
                                    showSearch
                                    placeholder="Return To"
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={state?.return_type == 1 ? state?.location : (state?.return_section == 1 ? state?.supplier : state?.branch)}
                                // disabled={state.isDisabled}
                                />
                            </Form.Item>
                            <Form.Item
                                name="return_to"
                                label="Return To"
                                rules={[{ required: true, message: 'Please select a location' }]}
                            >
                                <Select
                                    className='border-input'
                                    showSearch
                                    placeholder="Return To"
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={state?.return_type == 1 ? state?.supplier : state?.location}
                                // disabled={state.isDisabled}
                                />
                            </Form.Item>

                            <Form.Item
                                name="item"
                                label="Return Item"
                                rules={[{ required: true, message: 'Please select an item' }]}
                                shouldUpdate
                            >
                                <Select
                                    mode='multiple'
                                    filterOption={false}
                                    className='border-input'
                                    showSearch
                                    placeholder="Item"
                                    onSearch={doneTyping2}
                                    onChange={onDropdownSelected}
                                    onDeselect={onDropdownDeselected}
                                >
                                    {
                                        state?.items?.map((item, index) => {
                                            return (
                                                <Option value={item?.value} key={index}>
                                                    <div>
                                                        <div><span>{item?.label}</span></div>
                                                        <div><span style={{ fontSize: 12, color: '#8c8c8c' }}>{item?.item_code}</span></div>
                                                    </div>
                                                </Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>
                            <div>
                                {
                                    state?.added_return_items?.map((item, index) => {
                                        return (
                                            <Space key={index} direction='vertical' style={{ width: '100%' }}>
                                                <div>
                                                    <Space>
                                                        <div style={{ minWidth: 150 }}>
                                                            <span className='label' style={{ color: '#8c8c8c' }}>Return Item</span>
                                                        </div>
                                                        <span className='description'>{item?.label}</span>

                                                    </Space>
                                                </div>
                                                <div>
                                                    <Space>
                                                        <div style={{ minWidth: 150 }}>
                                                            <span className='label' style={{ color: '#8c8c8c' }}>Item Code</span>
                                                        </div>
                                                        <span className='description' style={{ fontSize: 12 }}>{item?.item_code}</span>
                                                    </Space>
                                                </div>
                                                <div>
                                                    <Space>
                                                        <div style={{ minWidth: 150 }}>
                                                            <span className='label' style={{ color: '#8c8c8c' }}>Quantity</span>
                                                        </div>
                                                        {/* <span className='description'>{item?.quantity} </span> */}
                                                        <InputNumber style={{ borderRadius: 6, minWidth: 60, maxWidth: 100 }} min={1} value={item?.quantity} onChange={value => { modifyReturnNumber(item?.value, value) }} />

                                                    </Space>
                                                </div>
                                                <div>
                                                    <Space>
                                                        <div style={{ minWidth: 150 }}>
                                                            <span className='label' style={{ color: '#8c8c8c' }}>Unit Price</span>
                                                        </div>
                                                        {/* <span className='description'>{item?.quantity} </span> */}
                                                        <InputNumber style={{ borderRadius: 6, minWidth: 60, maxWidth: 100 }} min={1} value={item?.unit_price} onChange={value => { modifyUnitPrice(item?.value, value) }} />

                                                    </Space>
                                                </div>
                                                {

                                                    index != (state?.added_return_items.length - 1) &&
                                                    (
                                                        <Divider style={{ margin: 12 }} />
                                                    )
                                                }
                                            </Space>
                                        )
                                    })
                                }
                            </div>

                            {/* <Form.Item
                                name="quantity"
                                label="Quantity"
                                rules={[{ required: true, message: 'Please enter a quantity' }]}
                            >
                                <InputNumber
                                    min={1}
                                    style={{ width: '100%', borderRadius: 6 }}
                                // disabled={state.isDisabled}
                                />
                            </Form.Item> */}

                            {/* <Form.Item
                                name="remarks"
                                label="Remarks"
                            // rules={[{ required: true, message: 'Please enter a quantity' }]}
                            >
                                <Input
                                    style={{ width: '100%', borderRadius: 6 }}
                                // disabled={state.isDisabled}
                                />
                            </Form.Item> */}




                        </Form>
                    </Modal>
                </div>
            </div >

        </>
    );
}

export default StockReturnList;