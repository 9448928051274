import { SearchOutlined, ExclamationCircleOutlined, EllipsisOutlined } from '@ant-design/icons';
import { Button, Input, Row, Col, Spin, Table, Drawer, Form, Space, notification, Modal, Dropdown, Alert, Tag, Select, Radio, InputNumber, Card } from 'antd';
import React, { useEffect, useState } from 'react';
import InputWrapper from '../../../helpers/components/input_wrapper.component';
import promotionService from '../../../services/config/promotion.service';
import itemService from '../../../services/config/item.service';

const { confirm } = Modal;

const PromotionListTable = () => {
    const [form] = Form.useForm();
    const [state, setState] = useState({
        items: [],
        item_categories : [],
        promotion: [],
        loading: false,
        isDisabled: true,
        isUpdate: null,
        promotion_id: null,
        item_category: [{ value: null, label: null }],
        update_loading: false,
        typeOptions: [
            // {
            //     label: 'Buy & Free',
            //     value: 1,
            // },
            {
                label: '% Discount',
                value: 2,
            },
            {
                label: 'Value Discount',
                value: 3,
            },
        ],
        activeOption: [
            {
                label: 'Active',
                value: 1,
            },
            {
                label: 'Inactive',
                value: 2,
            },
        ],
        type: 0,

        //selected
        selected_items : [],
    });

    const [filter, setFilter] = useState({
        search: null,
    });

    const [timer, setTimer] = useState(null);
    const [open, setOpen] = useState(false);


    const fetchPromotionList = async () => {
        setState(state => ({ ...state, loading: true, }));

        const promotionResponse = await promotionService.getPromotion({
            search: filter?.search,
        });
        setTimeout(() => {
            setState(state => ({
                ...state,
                promotion: promotionResponse?.promotions,
                loading: false,
            }))
        }, 800);

    }

    const fetchItemList = async () => {
        setState(state => ({ ...state, loading: true, }));

        const itemResponse = await itemService.getItemDropdown();
        // console.log(itemResponse);
        // const item = [];
        const items = itemResponse?.item?.map(x => {
            return {
                value : x?.item_id,
                label : `${x?.name} (${x.item_code})`,
                item_category_id : x?.item_category_id,
            }
        })
        const item_categories = itemResponse?.item_categories?.map(x => {
            return {
                value : x?.item_category_id,
                label : x?.name,
            }
        })
        // itemResponse?.item.forEach((data, index) => {
        //     item[index] = { value: data.item_id, label: data.name + ` (${data.item_code})`, };
        // });
        setTimeout(() => {
            setState(state => ({
                ...state,
                items: items,
                item_categories : item_categories,
                loading: false,
            }))
        }, 800);

    }

    const addUpdatePromotion = async () => {
        setState(state => ({ ...state, update_loading: true }));
        const { name, type, buy, free, discount, is_active, item_option } = form.getFieldsValue();
        const getPromotion = await checkLinkage(name);
        confirm({
            icon: <ExclamationCircleOutlined />,
            content: (
                <>
                    <Space direction='vertical'>
                        <div>
                            <span>Are your sure to <span style={{ fontWeight: 500 }}>{state.isUpdate ? 'Update' : 'Add'}</span> this promotion?</span>
                        </div>
                        <div>
                            {
                                getPromotion?.length > 0 &&
                                (
                                    <>
                                        <div>
                                            <Alert
                                                icon={true}
                                                type='warning'
                                                message={<span>Promotion with this Item Category has been created with same or different type of promotion. This process will set other promotion with this Item Category to Inactive.</span>}
                                                style={{ borderRadius: 6 }}
                                            />
                                        </div>
                                    </>
                                )
                            }
                        </div>
                    </Space>
                </>
            ),
            async onOk() {
                //call api to insert into database
                if (state.isUpdate === true) {
                    const updatePromotionResponse = await promotionService.updatePromotion(state?.promotion_id, {
                        name: name,
                        type: type,
                        buy: buy,
                        free: free,
                        discount: discount,
                        is_active: is_active,
                        items: item_option,
                    });
                } else {
                    const storePromotionResponse = await promotionService.storePromotion({
                        name: name,
                        type: type,
                        buy: buy,
                        free: free,
                        discount: discount,
                        is_active: is_active,
                        items: item_option,
                    });
                }


                //after success insert/update
                setTimeout(() => {
                    notification.success({
                        message: (<span style={{ fontSize: 14, fontWeight: 500, }}>{state?.isUpdate ? 'Promotion Updated' : 'New Promotion Added'}</span>),
                        duration: 3,
                        placement: 'topRight'
                    });
                    clearAll();
                    refresh();
                }, 800);
            },
            onCancel() {
                setState(state => ({ ...state, update_loading: false }));
            },
        });

    }

    const doneTyping = async (input) => {
        clearTimeout(timer);
        const newTimer = setTimeout(() => {
            setFilter(filter => ({ ...filter, search: input }));
        }, 300);
        setTimer(newTimer);
    }

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        clearAll()
        setOpen(false);
    };

    const openDrawer = (promotion, isUpdate) => {
        setState(state => ({
            ...state,
            isDisabled: isUpdate,
            isUpdate: isUpdate,
            promotion_id: promotion?.promotion_id,
            type: promotion?.type,
            active: promotion?.is_active
        }));
        if (isUpdate === true) {
            let item_arr = [];
            let items = promotion?.promotion_items;
            items.forEach(i => {
                item_arr.push(i.item_id);
            });
            form.setFieldsValue({
                name: promotion?.name,
                type: promotion?.type,
                buy: promotion?.buy,
                free: promotion?.free,
                discount: promotion?.discount,
                is_active: promotion?.is_active,
                item_option: item_arr,
            });
        }

        showDrawer();
    }

    const offEdit = () => {
        if (state.isUpdate !== true) {
            onClose();
        }
        setState(state => ({
            ...state,
            isDisabled: true,
        }));
    };

    const onDelete = async (data) => {
        confirm({
            icon: <ExclamationCircleOutlined />,
            content: 'Confirm Delete Promotion?',
            async onOk() {
                const deletePromotionResponse = await promotionService.deletePromotion(data.promotion_id);
                notification.success({
                    message: (<span style={{ fontSize: 14, fontWeight: 500, }}>Promotion Deleted</span>),
                    duration: 3,
                    placement: 'topRight'
                });
                clearAll();
                refresh();
            },
            onCancel() {
            },
        });

    };

    const refresh = () => {
        onClose();
        fetchPromotionList();
    };

    const clearAll = () => {
        setState(state => ({
            ...state,
            isDisabled: true,
            isUpdate: null,
            item_category_id: null,
            update_loading: false,
        }));
        form.resetFields();
    }

    const checkLinkage = async (name) => {
        const promotionResponse = await promotionService.getPromotion({
            name: name,
            promotion_id: state?.promotion_id,
        });

        return promotionResponse?.promotions;
    }




    // auto select item when select item category
    const addItemOnItemGroup = (item_category_id) => {
        const items = state?.items?.filter(x => x.item_category_id == item_category_id);
        const group_items = (items?.map(x => x.value));


        form.setFieldsValue({ item_option : group_items })
        // setState(state => ({...state, selected_items : group_items}));
    }



    useEffect(() => {
        fetchPromotionList();
    }, [filter?.search])

    useEffect(() => {
        fetchItemList();
    }, [])

    return (
        <>
            <div className='filter-panel' style={{ height: 60, }}>
                <div style={{ display: 'flex' }}>
                    <div style={{ minWidth: 400 }}>
                        <InputWrapper>
                            <Input
                                onChange={(e) => doneTyping(e.target.value)}
                                bordered={false}
                                prefix={<div style={{ paddingRight: 12, paddingLeft: 8 }}><SearchOutlined style={{ color: '#8c8c8c' }} /></div>}
                                placeholder={`Search Promotion`}
                                style={{ fontSize: 14 }}
                                size='large'
                            />
                        </InputWrapper>
                    </div>
                    <div style={{ marginLeft: 'auto' }}>
                        <Button
                            type="primary"
                            // icon={<PlusOutlined />}
                            onClick={() => openDrawer(null, false)}
                            style={{ borderRadius: 6 }}
                        >
                            Add Promotion
                        </Button>
                    </div>

                </div>
            </div>

            <Spin spinning={state?.loading}>
                <Table
                    className='hub-table'
                    pagination={false}
                    dataSource={state?.promotion}
                    columns={[{
                        title: 'Promotion name',
                        dataIndex: 'name',
                        key: 'name',
                        // render: (text) => <span>{text?.name}</span>,
                    }, {
                        title: 'Discount Type',
                        dataIndex: 'type',
                        key: 'type',
                        render: (text) => <span>{text == 1 ? 'Buy & Free' : text == 2 ? '% Discount' : 'Value Discount'}</span>,
                    }, {
                        title: 'Status',
                        dataIndex: 'is_active',
                        key: 'is_active',
                        render: (text, row, index) => {
                            return (
                                <>
                                    <div>
                                        <Tag color={text == 1 ? 'green' : 'red'} style={{ border: 'none' }}>
                                            <span>{text == 1 ? 'Active' : 'Inactive'}</span>
                                        </Tag>
                                    </div>
                                </>
                            )
                        }
                    }, {
                        title: 'Action',
                        key: 'action',
                        width: '10%',
                        render: (text, row, index) => {
                            return (
                                <>
                                    <div>
                                        <Dropdown
                                            placement='bottomRight'
                                            overlayStyle={{ borderRadius: 12, minWidth: 200 }}
                                            menu={{
                                                items: [{
                                                    key: '1',
                                                    type: 'group',
                                                    label: "Action",
                                                    children: [{
                                                        key: '1-1',
                                                        label: "Edit",
                                                        onClick: async () => {
                                                            openDrawer(row, true);
                                                        }
                                                    }, {
                                                        key: '1-2',
                                                        label: "Delete",
                                                        onClick: async () => {
                                                            await onDelete(row);
                                                        }
                                                    },]
                                                },]
                                            }}
                                        >
                                            <Button size='small' icon={<EllipsisOutlined />} style={{ borderRadius: 6 }} />
                                        </Dropdown>
                                    </div>
                                </>
                            )
                        }
                    }]}
                />
            </Spin>

            <Drawer title={state?.isUpdate ? 'Promotion Detail' : 'Add Promotion'} onOk={() => form.submit()}
                placement="right"
                width={window.innerWidth > 900 ? 800 : window.innerWidth - 100}
                onClose={onClose}
                open={open}
                extra={
                    <Space>
                        {
                            state?.isUpdate ?
                                (
                                    <>
                                        <Button loading={state?.update_loading} type="primary" onClick={() => form.submit()} style={{ borderRadius: 6 }}>
                                            Update
                                        </Button>
                                    </>
                                )
                                :
                                (
                                    <>
                                        <Space>
                                            <Button onClick={offEdit} style={{ borderRadius: 6 }}>Cancel</Button>
                                            <Button loading={state?.update_loading} type="primary" onClick={() => form.submit()} style={{ borderRadius: 6 }}>
                                                OK
                                            </Button>
                                        </Space>
                                    </>
                                )
                        }
                    </Space>
                }>

                <Form layout="vertical"
                    onFinish={addUpdatePromotion}
                    form={form} >
                    <Form.Item
                        name="name"
                        label="Promotion Name"
                        rules={[{ required: true, message: 'Please enter promotion name' }]}
                    >
                        <Input placeholder="Please enter promotion name" style={{ borderRadius: 6 }} />
                    </Form.Item>
                    <Form.Item
                        name="is_active"
                        label="Status"
                        rules={[{ required: true, message: 'Please select status' }]}
                    >
                        <Radio.Group options={state?.activeOption} onChange={(value) => {
                            // setState(state => ({
                            //     ...state,
                            //     type: value.target.value,
                            // }))
                            form.setFieldValue('is_active', value.target.value)
                        }}
                        />
                    </Form.Item>
                    <Form.Item
                        name="type"
                        label="Type"
                        rules={[{ required: true, message: 'Please select type' }]}
                    >
                        <Radio.Group
                            options={state?.typeOptions}
                            onChange={(value) => {
                                setState(state => ({
                                    ...state,
                                    type: value.target.value,
                                }))
                                form.setFieldValue('type', value.target.value)
                            }}
                            optionType="button"
                            buttonStyle="solid"
                        />
                    </Form.Item>
                    {
                        state?.type == 1 ?
                            (
                                <>
                                    <Form.Item
                                        name="buy"
                                        label="Buy"
                                        rules={[
                                            {
                                                required: state?.type == 1,
                                                message: 'please enter total buy',
                                            },
                                        ]}
                                    >
                                        <InputNumber min={1} style={{ borderRadius: 6, width: '100%' }} />
                                    </Form.Item>
                                    <Form.Item
                                        name="free"
                                        label="Free"
                                        rules={[
                                            {
                                                required: state?.type == 1,
                                                message: 'please enter total free',
                                            },
                                        ]}
                                    >
                                        <InputNumber min={1} style={{ borderRadius: 6, width: '100%' }} />
                                    </Form.Item>
                                </>
                            )
                            :
                            (
                                <>
                                    <Form.Item
                                        name="discount"
                                        label="Discount"
                                        rules={[
                                            {
                                                required: state?.type != 1,
                                                message: 'please enter discount',
                                            },
                                        ]}
                                    >
                                        <Input rows={4} placeholder="please enter discount" style={{ borderRadius: 6 }} />
                                    </Form.Item>
                                </>
                            )
                    }

                    <Form.Item
                        name="item_category"
                        label="Item Category"
                    >
                        <Space style={{width:'100%', overflowX:'auto'}}>
                        {
                            state?.item_categories?.map((category, index) => {
                                return (
                                    <div key={index} style={{minHeight:120}}>
                                        <Card
                                        style={{borderRadius:6}}
                                        bodyStyle={{padding:12, height:'100%'}}
                                        >
                                            <div style={{textAlign:'center'}}>
                                                <div>
                                                    <span>
                                                    {category?.label}
                                                    </span>
                                                </div>
                                                <div style={{marginLeft:'auto', marginTop:8}}>
                                                    <Button 
                                                    onClick={() => addItemOnItemGroup(category?.value)}
                                                    type="text"
                                                    size='small'
                                                    style={{background:'#f5f5f5'}}
                                                    >
                                                        Add
                                                    </Button>
                                                </div>
                                            </div>
                                        </Card>
                                    </div>
                                )
                            })
                        }
                        </Space>
                    </Form.Item>

                    <Form.Item
                        name="item_option"
                        label="Items"
                        style={{ width: "100%" }}
                        rules={[
                            {
                                required: true,
                                message: 'Please select items',
                            },
                        ]}
                    >
                        <Select
                            mode="multiple"
                            allowClear
                            placeholder="Select Item"
                            // value={state?.selected_items}
                            // filterOption={(input, option) =>
                            //     (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            // }
                            onChange={(v) => {
                                // setState(state => ({...state, selected_items : v}));
                            }}
                            options={state?.items}
                        />
                    </Form.Item>


                </Form>
            </Drawer>
        </>
    );
}

export default PromotionListTable;