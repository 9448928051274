import { Button, Dropdown, Spin, Table, Tag } from 'antd';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { set_credit_note_list } from '../../../actions/sales_order.action';
import creditNoteService from '../../../services/sales/credit_note.service';
import { DownOutlined } from '@ant-design/icons';
import { generateCN } from '../functions/printCN';
import branchService from '../../../services/config/branch.service';

const CreditNoteList = () => {
    const dispatch = useDispatch();
    const sales_order_redux = useSelector(state => state.sales_order);
    const auth_redux = useSelector(state => state?.auth);
    const [state, setState] = useState({
        loading: false,
        selected_rows : [],
    })

    const fetchCreditNote = async () => {
        setState(state => ({ ...state, loading: true }));
        const creditNoteResponse = await creditNoteService.getCreditNotes().catch(err => {
            setState(state => ({ ...state, loading: false }));
        });
        const credit_notes = creditNoteResponse?.credit_notes;

        setTimeout(() => {
            dispatch(set_credit_note_list(credit_notes?.rows, credit_notes?.count));
            setState(state => ({ ...state, loading: false }));
        }, 500);
    }

    const bulkPrintCN = async () => {
        const printWindow = window.open(``, 'Print');
        let cn_bulk_string = '';
        const items = sales_order_redux?.credit_note_list?.filter(x => state?.selected_rows?.includes(x?.credit_note_id));
        for (const item of items) {
            cn_bulk_string += await generateCN(item);
        }

        printWindow.document.write(cn_bulk_string);
        printWindow.print();
        printWindow.close();
    }

    useEffect(() => {
        fetchCreditNote();
    }, [])

    return (
        <>
            <div style={{ display : 'flex', }}>
                <div style={{ marginLeft : 'auto', }}>
                    <Dropdown
                    placement='bottomRight'
                    overlayStyle={{ borderRadius: 12, minWidth: 200 }}
                    menu={{
                        items: [{
                            key: '1',
                            type: 'group',
                            children: [{
                                key: '1-2',
                                label: "Print",
                                onClick: async () => {
                                    await bulkPrintCN();
                                },
                                // disabled: state?.selected < 1,
                            },]
                        },]
                    }}
                    >
                        <Button type='primary' className='mini-button' icon={<DownOutlined />} style={{ borderRadius: 4 }}>
                            Action
                        </Button>
                    </Dropdown>
                </div>
            </div>
            <div style={{ marginTop : 12, }}>
                <Spin spinning={state?.loading}>
                    <Table
                        rowKey={'credit_note_id'}
                        rowSelection={{
                            type : 'checkbox',
                            onChange: (selectedRowKeys, selectedRows, info) => {
                                setState(state => ({...state, selected_rows : selectedRows?.map(x => x?.credit_note_id), }));
                            },
                            hideSelectAll : true,
                            selectedRowKeys : state?.selected_rows,
                        }}
                        className='hub-table'
                        dataSource={sales_order_redux?.credit_note_list}
                        columns={[
                            {
                                title: 'Credit Note No',
                                dataIndex: 'running_number',
                                key: 'running_number',
                            },
                            {
                                title: 'Transaction No',
                                dataIndex: 'sale',
                                key: 'transaction_no',
                                render: (text, row, index) => {
                                    return (
                                        <>
                                            <div>
                                                <div>
                                                    <Link to={`/sales/order/${text?.sale_id}`}>
                                                        <span style={{ fontWeight: 500 }}>{text?.running_number}</span>
                                                    </Link>
                                                </div>
                                                <div>
                                                    <span style={{ color: '#8c8c8c', fontSize: 12 }}>{text?.customer?.name ?? `No customer added`}</span>
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                            },
                            {
                                title: 'Issue Date',
                                dataIndex: 'created_at',
                                key: 'created_at',
                                render: (text, row, index) => {
                                    return (
                                        <>
                                            <div>
                                                <span>{moment(text).format("D MMM YYYY, h:mm:ss a")}</span>
                                            </div>
                                        </>
                                    )
                                },
                                responsive : ['lg']
                            },
                            {
                                title: 'Amount',
                                dataIndex: 'amount',
                                key: 'amount',
                                render: (text, row, index) => {
                                    return (
                                        <>
                                            <div>
                                                <span>{`RM ${text}`}</span>
                                            </div>
                                        </>
                                    )
                                },
                                responsive : ['md']
                            },
                            {
                                title: 'Status',
                                dataIndex: 'credit_note_status',
                                key: 'credit_note_status',
                                render: (text, row, index) => {
                                    return (
                                        <>
                                            <Tag color={text?.color} style={{ border: 'none' }}>
                                                <span>{text?.name}</span>
                                            </Tag>
                                        </>
                                    )
                                },
                                responsive : ['md']
                            },
                        ]}
                    />
                </Spin>
            </div>
        </>
    );
}

export default CreditNoteList;