import axios from 'axios';
import serviceHelper from '../../helpers/functions/service.helper';

async function getPromotion(query) {
    const queryString = serviceHelper.queryString(query);
    const promotionResponse = await axios.get(`${process.env.REACT_APP_SERVER_URL}config/promotion/get${(queryString ? ("?" + queryString) : "")}`, { withCredentials: true });
    return promotionResponse?.data;
}

async function storePromotion(params = {}) {
    const { name, type, buy, free, discount, is_active, items } = params;
    const promotionResponse = await axios.post(`${process.env.REACT_APP_SERVER_URL}config/promotion/store`, {
        name, type, buy, free, discount, is_active, items
    }, { withCredentials: true });

    return promotionResponse?.data;
}

async function deletePromotion(promotion_id) {
    const promotionResponse = await axios.post(`${process.env.REACT_APP_SERVER_URL}config/promotion/delete/${promotion_id}`, {}, { withCredentials: true });
    return promotionResponse?.data;
}

async function updatePromotion(promotion_id, params = {}) {
    const { name, type, buy, free, discount, is_active, items } = params;
    const promotionResponse = await axios.post(`${process.env.REACT_APP_SERVER_URL}config/promotion/update/${promotion_id}`, {
        name, type, buy, free, discount, is_active, items
    }, { withCredentials: true });

    return promotionResponse?.data;
}

export default {
    getPromotion,
    updatePromotion,
    deletePromotion,
    storePromotion,
}