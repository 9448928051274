import { Badge, Button, Card, Space, Tag, InputNumber } from 'antd';
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { set_billing_items } from '../../../actions/billing.action';

const ItemCard = ({ item }) => {
    const billing_redux = useSelector(state => state.billing);
    const dispatch = useDispatch();

    const countedQuantity = (item) => {
        let billing_items = (billing_redux.billing_items);
        let existing_item = billing_items?.find(x => x.item_id === item?.item?.item_id);
        if (existing_item) {
            return existing_item?.quantity;
        } else {
            return null;
        }
    }
    const beforeMarkup = (item) => {
        let billing_items = (billing_redux.billing_items);
        let existing_item = billing_items?.find(x => x.item_id === item?.item?.item_id);
        if (existing_item) {
            return existing_item?.cost;
        } else {
            return item?.cost;
        }
    }


    const markup = (item) => {
        let billing_items = (billing_redux.billing_items);
        let existing_item = billing_items?.find(x => x.item_id === item?.item?.item_id);
        if (existing_item) {
            return existing_item?.markup;
        } else {
            return item?.item?.markup;
        }
    }

    const itemCost = (item) => {
        let billing_items = (billing_redux.billing_items);
        let existing_item = billing_items?.find(x => x.item_id === item?.item?.item_id);
        if (existing_item) {
            return existing_item?.cost * (1 + (existing_item?.markup / 100));
        } else {
            return item?.cost * (1 + (item?.item?.markup / 100));
        }
    }

    const updateNewBillingCount = (value) => {
        let billing_items = (billing_redux.billing_items);
        if (value) {
            let existing_item = billing_items?.find(x => x.item_id === item?.item?.item_id);
            if (existing_item) {
                const calculated_quantity = value;
                existing_item.quantity = calculated_quantity;
                let changed_quantity = billing_items;
                if (calculated_quantity > 0) {
                    changed_quantity = billing_items?.map((x) => {
                        return (x?.item_id === item?.item?.item_id) ? existing_item : x;
                    })
                } else {
                    changed_quantity = billing_items?.filter((x) => {
                        return (x?.item_id != item?.item?.item_id);
                    })
                }

                dispatch(set_billing_items(changed_quantity));
            } else {
                if (value > 0) {
                    dispatch(
                        set_billing_items([
                            ...billing_items,
                            {
                                item_id: item?.item?.item_id,
                                item_code: item?.item?.item_code,
                                name: item?.item?.name,
                                markup: item?.item?.markup,
                                cost: item?.cost,
                                quantity: value,
                            }
                        ])
                    );
                }
            }

        } else {
            const changed_quantity = billing_items?.filter((x) => {
                return (x?.item_id !== item?.item?.item_id);
            })

            dispatch(set_billing_items(changed_quantity));
        }

    }
    const updateNewBillingCost = (value) => {
        let billing_items = (billing_redux.billing_items);
        let existing_item = billing_items?.find(x => x.item_id === item?.item?.item_id);
        if (existing_item) {
            existing_item.cost = value > 0 && value ? value : item?.cost;
            let changed_cost = billing_items;
            changed_cost = billing_items?.map((x) => {
                return (x?.item_id === item?.item?.item_id) ? existing_item : x;
            })

            dispatch(set_billing_items(changed_cost));
        }

    }

    const updateMarkup = (value) => {
        let billing_items = (billing_redux.billing_items);
        let existing_item = billing_items?.find(x => x.item_id === item?.item?.item_id);
        if (existing_item) {
            existing_item.markup = value > 0 && value ? value : item?.item?.markup;
            let changed_markup = billing_items;
            changed_markup = billing_items?.map((x) => {
                return (x?.item_id === item?.item?.item_id) ? existing_item : x;
            })

            dispatch(set_billing_items(changed_markup));
        }

    }

    return (
        <>
            <Card
                bordered={false}
                style={{ borderRadius: 6, height: '100%' }}
            >
                <div>
                    <div style={{ textAlign: 'left' }}>
                        <div>
                            <span style={{ fontWeight: 500, color: '#8c8c8c', fontSize: 12 }}>{item?.item?.item_code}</span>
                        </div>
                        <div>
                            <span style={{ fontWeight: 500, color: '#595959' }}>{item?.item?.name}</span>
                        </div>
                        {/* <div>
                            <span style={{ color: '#8c8c8c' }}>{`Before Markup  : MYR ${item?.item?.cost ?? '-'}`}</span>
                        </div> */}
                        <div>
                            <span style={{ color: '#8c8c8c' }}>{`After Markup   : MYR ${itemCost(item).toFixed(2)}`}</span>
                        </div>
                        <div style={{ marginTop: 5 }}>
                            <InputNumber min={0} value={countedQuantity(item)} style={{ width: '100%' }} addonBefore="Quantity" onChange={updateNewBillingCount} placeholder="Quantity" />
                        </div>
                        <div style={{ marginTop: 5 }}>
                            <InputNumber min={0} value={beforeMarkup(item)} style={{ width: '100%' }} addonBefore="Unit Price" onChange={updateNewBillingCost} placeholder="Unit Price" />
                        </div>
                        <div style={{ marginTop: 5 }}>
                            <InputNumber min={0} value={markup(item)} style={{ width: '100%' }} onChange={updateMarkup} addonAfter="%" placeholder="Markup (%)" addonBefore="Markup (%)" />
                        </div>
                    </div>
                </div>
            </Card>
        </>
    );
}

export default ItemCard;
