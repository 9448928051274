import { EditOutlined } from '@ant-design/icons';
import { Divider, Space, InputNumber, Form, Modal, Button } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { set_sale_total_amount, toggle_promotion_modal } from '../../../actions/sales_order.action';
import PromotionModal from './promotion_modal';

const SubTotal = () => {
    const [form] = Form.useForm();

    const sales_order_redux = useSelector(state => state.sales_order);
    const [state, setState] = useState({
        sub_total: 0,
        total: 0,
        quantity: 0,
        loading: false,

        //modal
        discount_modal : false,
    });
    const dispatch = useDispatch();
    const calculateAmount = () => {
        let sub_total_amount = 0;
        for (const item of sales_order_redux?.sale_items) {
            sub_total_amount += ((item?.discount_unit_price ?? item?.price) * item?.quantity);
        }

        //get total quantity of items
        const quantity = sales_order_redux?.sale_items?.map(x => x.quantity)?.reduce((partialSum, a) => partialSum + a, 0);

        //calculate total amount
        let total_amount = sub_total_amount;
        dispatch(set_sale_total_amount(total_amount));
        setState(state => ({ ...state, quantity: quantity, sub_total: sub_total_amount, total : total_amount }));
        form.setFieldsValue({ total_amount: total_amount });
        // setState(state => ({ ...state, sub_total: sub_total_amount, total: total_amount, quantity: quantity }));
    }
    const onChange = (value) => {
        dispatch(set_sale_total_amount(value));
    };

    useEffect(() => {
        calculateAmount();
    }, [sales_order_redux?.sale_items])

    return (
        <>
            <div>
                <Space style={{ width: '100%' }} direction="vertical">
                    <div style={{ display: 'flex' }}>
                        <div>
                            <span style={{ color: '#8c8c8c', fontWeight: 500 }}>Subtotal</span>
                        </div>
                        <div style={{ marginLeft: 'auto' }}>
                            <span style={{ color: '#8c8c8c', fontWeight: 500 }}>{`MYR ${state?.sub_total.toFixed(2)}`}</span>
                        </div>
                    </div>
                    <div style={{ display: 'flex' }}>
                        <div>
                            <span style={{ color: '#8c8c8c', fontWeight: 500 }}>Item Count</span>
                        </div>
                        <div style={{ marginLeft: 'auto' }}>
                            <span style={{ color: '#8c8c8c', fontWeight: 500 }}>{state?.quantity}</span>
                        </div>
                    </div>

                    <Divider dashed />

                    <div>
                        <div style={{ display: 'flex' }}>
                            <div>
                                <span style={{ color: '#595959', fontWeight: 500, fontSize: 18 }}>Total</span>
                            </div>
                            <div style={{ marginLeft: 'auto' }}>
                                <Form
                                    form={form}
                                >
                                    <Form.Item name='total_amount' >
                                        <Space>
                                            <div>
                                                <Button
                                                size='small'
                                                style={{ borderRadius: 4, }}
                                                onClick={() =>  dispatch(toggle_promotion_modal(true))}
                                                >
                                                    <EditOutlined style={{color:'#8c8c8c'}} />
                                                </Button>
                                            </div>
                                            <div>
                                                <span style={{ color: '#595959', fontWeight: 500, fontSize: 18 }}>{`MYR ${sales_order_redux?.sale_total_amount?.toFixed(2)}`}</span>
                                            </div>
                                        </Space>
                                        {/* <InputNumber size="large" min={0} precision={2} style={{ borderRadius: 6, }} onChange={onChange} /> */}
                                    </Form.Item>

                                </Form>

                                {/* <span style={{ color: '#595959', fontWeight: 500, fontSize: 18 }}>{`MYR ${state?.total?.toFixed(2)}`}</span> */}
                            </div>
                        </div>
                    </div>
                </Space>
            </div>

            <Modal
            open={sales_order_redux?.promotion_modal}
            transitionName="ant-fade"
            maskTransitionName="ant-fade"
            className="custom-modal"
            title={<><span>Edit Price Amount</span></>}
            onCancel={() => dispatch(toggle_promotion_modal(false))}
            footer={false}
            >
                <PromotionModal sale_total_amount={state?.total} />
            </Modal>
        </>
    );
}

export default SubTotal;