import { ConsoleSqlOutlined } from '@ant-design/icons';
import { render } from '@testing-library/react';
import { Col, Divider, Form, Input, Row, Select, Space, InputNumber } from 'antd';
import React, { useEffect, useState } from 'react';
import itemService from '../../../services/config/item.service';
import saleItemService from '../../../services/sales/sale_item.service';
import { useDispatch, useSelector } from 'react-redux';

const { TextArea } = Input;
const { Option } = Select;

const SaleCreditNoteAdd = ({ form, sale_id }) => {
    const sales_order_redux = useSelector(state => state.sales_order);
    const [timer, setTimer] = useState(null)
    const [state, setState] = useState({
        loading: true,
        // return items
        sale_items: [],
        selected_sale_items: null,
        returning_amount: 0,

        // exhange items
        input: null,
        items: [],
        selected_items: null,
        added_sale_items: [],
    });

    const fetchItemOnSearch = async () => {
        setState(state => ({ ...state, loading: true }));
        let itemResponse;
        itemResponse = await itemService.getItem({
            search : state?.input,
        });
        const items = itemResponse?.items?.rows?.map(x => {
            return {
                value: x.item_id,
                label: x.name,
                item_code: x.item_code,
                price: x.price,
            }
        })

        setState(state => ({ ...state, items: items, loading: false }));
    }

    const fetchSaleItems = async () => {
        const saleItemsResponse = await saleItemService?.getSaleItems({
            sale_id: sale_id,
        });

        const sale_items = saleItemsResponse?.sale_items;
        setState(state => ({ ...state, sale_items: sale_items, }));
    }

    const doneTyping = (input) => {
        clearTimeout(timer);
        const newTimer = setTimeout(() => {
            // fetchItemOnSearch(input);
            setState(state => ({ ...state, input: input }));
        }, 500);
        setTimer(newTimer);
    }

    const onDropdownSelected = (item_id) => {
        //get latest item added
        let new_items = item_id?.filter(x => !state?.added_sale_items?.map(x1 => x1.value)?.includes(x));
        let added_sale_items = [...state?.added_sale_items];

        for (const item of new_items) {
            let items = state?.items?.find(x => x.value === item);
            items.quantity = 1;
            added_sale_items = [...added_sale_items, items];
        }
        setState(state => ({ ...state, selected_items: item_id, added_sale_items: added_sale_items }));
        form.setFieldValue("exchange_items", added_sale_items);
    }

    const onDropdownDeselected = (item_id) => {
        let added_sale_items = [...state?.added_sale_items];
        let items = added_sale_items?.filter(x => !(x.value === item_id));

        setState(state => ({ ...state, selected_items: items?.map(x => x.value), added_sale_items: items }));
        form.setFieldValue("exchange_items", items);
    }

    const modifyExchangeNumber = (item_id, quantity) => {

        let added_sale_items = state?.added_sale_items;
        let existing_item = added_sale_items?.find(x => x.value === item_id);
        existing_item.quantity = quantity;
        let changed_quantity = added_sale_items;
        changed_quantity = added_sale_items?.map((x) => {
            return (x?.value === item_id) ? existing_item : x;
        })
        setState(state => ({ ...state, selected_items: item_id, added_sale_items: added_sale_items }));
        form.setFieldValue("exchange_items", added_sale_items);
        calculateTotalAmount();
    }

    const calculateTotalAmount = () => {
        let added_sale_items = state?.added_sale_items;
        let totalAmount = 0;
        for (const item of added_sale_items) {
            totalAmount += item.price * item.quantity;
        }
        form.setFieldValue('amount', totalAmount - state?.returning_amount);
    }

    const onSelectReturningItem = (item_id) => {
        // get selected item
        const item = state?.sale_items?.find(x => x.item_id == item_id);
        setState(state => ({ ...state, selected_sale_items: item }));
        form.setFieldValue('return_quantity', item?.quantity - item?.credit_note_quantity);
        form.setFieldValue('return_item_unit_price', item?.promotion_id ? item?.discount_unit_price : item?.unit_price);
        calculateReturnAmount();
    }

    const initializeFetch = async () => {

        await fetchItemOnSearch();
        await fetchSaleItems();
    }

    const calculateReturnAmount = () => {
        const unit_price = form.getFieldValue('return_item_unit_price');
        const return_quantity = form.getFieldValue('return_quantity');
        const returning_amount = return_quantity * unit_price;
        setState(state => ({ ...state, returning_amount: returning_amount }));
        const price_arr = state?.added_sale_items?.map(x => x.price);
        const exhange_price = price_arr?.reduce((a, b) => a + b, 0);
        const balance_amount = (exhange_price - returning_amount);
        form.setFieldValue('amount', balance_amount);
    }

    const updateData = async (data) => {
        let items = state?.sale_items?.find(x => x.item_id === data?.return_item_id);
        form.resetFields();
        // const data = sales_order_redux?.credit_note_data;
        const exchange_item = await data?.credit_note_exchange_items?.map(x => {
            return {
                value: x.item_id,
                label: x.item?.name,
                item_code: x.item?.item_code,
                price: x.item?.price,
                quantity: x.quantity
            }
        })
        form.setFieldsValue({
            amount: data?.amount,
            remarks: data?.remarks,
            return_quantity: data?.return_quantity,
            return_item: data?.return_item_id,
            exchange_item_ids: exchange_item,
            exchange_items: exchange_item,
            return_item_unit_price: data?.return_item_unit_price
        });

        calculateReturnAmount();
        setState(state => ({
            ...state,
            edit_modal: true,
            added_sale_items: [...form.getFieldValue('exchange_item_ids')],
            selected_sale_items: items,
        }));


    }

    useEffect(() => {
        initializeFetch();
    }, [state?.input])


    useEffect(() => {
        let item = form.getFieldValue('exchange_items');
        if (!item) {
            initializeFetch();
            setState(state => ({ ...state, added_sale_items: [] }));
        } else {
            calculateTotalAmount();
        }
    }, [form.getFieldValue('exchange_items')])


    useEffect(() => {
        if (sales_order_redux?.credit_note_data) {
            const data = sales_order_redux?.credit_note_data;
            updateData(data);
        } else {
            setState(state => ({ ...state, added_sale_items: [] }));
        }
    }, [sales_order_redux?.credit_note_data])

    return (
        <>
            <div>
                <span style={{ fontSize: 24, fontWeight: 500 }}>RM {state?.returning_amount?.toFixed(2)}</span>
            </div>
            <div style={{ marginTop: 12 }}>
                <Row gutter={[20, 20]}>
                    <Col span={16}>
                        <Form.Item name="return_item" label="Return Item" shouldUpdate rules={[{ required: true, message: 'Select return Item' }]}>
                            <Select
                                filterOption={false}
                                // options={state?.items}
                                showSearch
                                className='border-input'
                                style={{ borderRadius: 6 }}
                                allowClear
                                // onSearch={doneTyping}
                                onChange={onSelectReturningItem}
                                disabled={sales_order_redux?.credit_note_data}
                            >
                                {
                                    state?.sale_items?.map((sale_item, index) => {
                                        const { item, item_id } = sale_item;
                                        return (
                                            <Option value={item_id} key={index}>
                                                <div>
                                                    <div><span>{item?.name}</span></div>
                                                    <div><span style={{ fontSize: 12, color: '#8c8c8c' }}>{item?.item_code}</span></div>
                                                </div>
                                            </Option>
                                        )
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item name='return_quantity' label="Return Quantity" rules={[{ required: true, message: 'Please input return quantity' }]}>
                            <InputNumber onChange={calculateReturnAmount} type='number' placeholder='12' max={state?.selected_sale_items?.quantity - state?.selected_sale_items?.credit_note_quantity} min={1} style={{ borderRadius: 6, width: '100%' }} disabled={sales_order_redux?.credit_note_data} />
                        </Form.Item>
                    </Col>
                </Row>
            </div>
            <Divider />
            <div>
                <div style={{ marginTop: 24 }}>
                    <Form.Item 
                    name="exchange_item_ids" 
                    label="Exchange Item" 
                    shouldUpdate 
                    // rules={[{ required: true, message: 'Please select exchange Item' }]}
                    >
                        <Select
                            mode='multiple'
                            filterOption={false}
                            // options={state?.items}
                            showSearch
                            // allowClear
                            className='border-input'
                            style={{ borderRadius: 6 }}
                            onSearch={doneTyping}
                            onChange={onDropdownSelected}
                            onDeselect={onDropdownDeselected}
                        >
                            {
                                state?.items?.map((item, index) => {
                                    return (
                                        <Option value={item?.value} key={index}>
                                            <div>
                                                <div><span>{item?.label}</span></div>
                                                <div><span style={{ fontSize: 12, color: '#8c8c8c' }}>{item?.item_code}</span></div>
                                            </div>
                                        </Option>
                                    )
                                })
                            }
                        </Select>
                    </Form.Item>
                </div>
                <div>
                    {
                        state?.added_sale_items?.map((item, index) => {
                            return (
                                <Space key={index} direction='vertical' style={{ width: '100%' }}>
                                    <div>
                                        <Space>
                                            <div style={{ minWidth: 150 }}>
                                                <span className='label' style={{ color: '#8c8c8c' }}>Exchange Item</span>
                                            </div>
                                            <span className='description'>{item?.label}</span>

                                        </Space>
                                    </div>
                                    <div>
                                        <Space>
                                            <div style={{ minWidth: 150 }}>
                                                <span className='label' style={{ color: '#8c8c8c' }}>Item Code</span>
                                            </div>
                                            <span className='description' style={{ fontSize: 12 }}>{item?.item_code}</span>
                                        </Space>
                                    </div>
                                    <div>
                                        <Space>
                                            <div style={{ minWidth: 150 }}>
                                                <span className='label' style={{ color: '#8c8c8c' }}>Quantity</span>
                                            </div>
                                            {/* <span className='description'>{item?.quantity} </span> */}
                                            <InputNumber style={{ borderRadius: 6, minWidth: 60, maxWidth: 100 }} min={1} value={item?.quantity} onChange={value => { modifyExchangeNumber(item?.value, value) }} />

                                        </Space>
                                    </div>
                                    <div>
                                        <Space>
                                            <div style={{ minWidth: 150 }}>
                                                <span className='label' style={{ color: '#8c8c8c' }}>Price</span>
                                            </div>
                                            <span className='description'>{`MYR ${item?.price} (MYR ${(item?.price * item?.quantity).toFixed(2)})`}</span>
                                        </Space>
                                    </div>
                                    {

                                        index != (state?.added_sale_items.length - 1) &&
                                        (
                                            <Divider style={{ margin: 12 }} />
                                        )
                                    }
                                </Space>
                            )
                        })
                    }
                </div>



            </div>

            <Divider />

            <Form.Item name='amount' label="Amount" rules={[{ required: true, message: 'Please enter amount' }]}>
                <InputNumber precision={2} value={state?.returning_amount} placeholder='9.00' style={{ borderRadius: 6, width: '100%' }} />
            </Form.Item>
            <Form.Item name="remarks" label="Remarks">
                <TextArea placeholder='Write your remarks here' rows={4} className='border-input' allowClear style={{ resize: 'none' }} />
            </Form.Item>
        </>
    );
}

export default SaleCreditNoteAdd;