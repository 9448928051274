import axios from 'axios';
import serviceHelper from '../../helpers/functions/service.helper';

async function getItemCategory(query) {
    const queryString = serviceHelper.queryString(query);
    const itemCategoryResponse = await axios.get(`${process.env.REACT_APP_SERVER_URL}config/itemcategory/get${(queryString ? ("?" + queryString) : "")}`, { withCredentials: true });
    return itemCategoryResponse?.data;
}

async function storeItemCategory(params = {}) {
    const { name, description } = params;
    const itemCategoryResponse = await axios.post(`${process.env.REACT_APP_SERVER_URL}config/itemcategory/store`, {
        name, description
    }, { withCredentials: true });

    return itemCategoryResponse?.data;
}

async function deleteItemCategory(item_category_id) {
    const itemCategoryResponse = await axios.post(`${process.env.REACT_APP_SERVER_URL}config/itemcategory/delete/${item_category_id}`, {}, { withCredentials: true });
    return itemCategoryResponse?.data;
}

async function updateItemCategory(item_category_id, params = {}) {
    const { name, description } = params;
    const itemCategoryResponse = await axios.post(`${process.env.REACT_APP_SERVER_URL}config/itemcategory/update/${item_category_id}`, {
        name, description
    }, { withCredentials: true });

    return itemCategoryResponse?.data;
}

export default {
    getItemCategory,
    updateItemCategory,
    deleteItemCategory,
    storeItemCategory,
}