import axios from 'axios';
import serviceHelper from '../../helpers/functions/service.helper';

async function getSupplier(query) {
    const queryString = serviceHelper.queryString(query);
    const supplierResponse = await axios.get(`${process.env.REACT_APP_SERVER_URL}config/supplier/get${(queryString ? ("?" + queryString) : "")}`, { withCredentials: true });
    return supplierResponse?.data;
}

async function storeSupplier(params = {}) {
    // const { name, email, phone_number, address_1, address_2, postcode, state, } = params;
    const supplierResponse = await axios.post(`${process.env.REACT_APP_SERVER_URL}config/supplier/store`, params, { withCredentials: true });

    return supplierResponse?.data;
}

async function deleteSupplier(supplier_id) {
    const supplierResponse = await axios.post(`${process.env.REACT_APP_SERVER_URL}config/supplier/delete/${supplier_id}`, {}, { withCredentials: true });
    return supplierResponse?.data;
}

async function updateSupplier(supplier_id, params = {}) {
    // const { name, email, phone_number, address_1, address_2, postcode, state, } = params;
    const supplierResponse = await axios.post(`${process.env.REACT_APP_SERVER_URL}config/supplier/update/${supplier_id}`, params, { withCredentials: true });

    return supplierResponse?.data;
}

export default {
    getSupplier,
    updateSupplier,
    deleteSupplier,
    storeSupplier,
}