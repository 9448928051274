import { LoadingOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, message, PageHeader, Row, notification } from 'antd';
import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import ContainerComponent from '../../../helpers/components/container.component';
import InputWrapper from '../../../helpers/components/input_wrapper.component';
import authenticationService from '../../../services/administrator/authentication.service';

const LoginForm = () => {
    const history = useHistory();
    const [state, setState] = useState({
        loading : false,
        fail_credential : false,
    })

    const loginFn = async (input) => {
        setState(state => ({...state, loading : true}));
        const { username, password } = input;
        
        const loginResponse = await authenticationService?.login(username, password).catch(err => {
            setState(state => ({...state, loading : false, fail_credential : true}));
        });


        if(loginResponse){ // if success login
            setTimeout(() => {
                // setState(state => ({...state, loading : false}));
                history.go(0);
            }, 800);
        }else{
            notification.error({
                message : "Credential not found, please try again."
            });
            setTimeout(() => {
                setState(state => ({...state, loading : false, fail_credential : true}));
            }, 800);
        }
    }

    return (
        <>
            {
                state?.loading ? 
                <LoadingOutlined />
                :
                (
                    <>
                        <ContainerComponent span={12}>
                            <div>
                                <PageHeader style={{paddingLeft:0}} title={<span style={{fontSize:30, fontWeight:'bold'}}>Sign in</span>}></PageHeader>
                            </div>
                            <div style={{marginTop:60}}>
                                <Form layout="vertical" onFinish={loginFn}>
                                    <Row style={{alignItems:'center'}}>
                                        <Col span={24}>
                                            <Form.Item
                                            // help={state && state.error && state.error.find(x => x.name === 'email') && <span style={{color:'#f5222d'}}><ExclamationCircleFilled /> {state && state.error && state.error.find(x => x.name === 'email') && state.error.find(x => x.name === 'email').message}</span> }
                                            label={<span style={{fontWeight:'500'}}>Username</span>}
                                            name="username"
                                            >
                                                <div className={`register-input-wrapper`}>
                                                    <Input size={'large'} bordered={false}  style={{background:'#f5f5f5', borderRadius:6}}/>
                                                </div>
                                            </Form.Item>

                                            <Form.Item
                                            // help={state && state.error && state.error.find(x => x.name === 'password') && <span style={{color:'#f5222d'}}><ExclamationCircleFilled /> {state && state.error && state.error.find(x => x.name === 'password') && state.error.find(x => x.name === 'password').message}</span> }
                                            extra={
                                                <div style={{marginTop:6}}>
                                                    <Link to='/password/forget'>
                                                        <Button type="text" style={{float:'right'}}>Forget Password?</Button>
                                                    </Link>
                                                </div>
                                            }
                                            label={<span style={{fontWeight:'500'}}>Password</span>}
                                            name="password"
                                            >
                                                <div className={`register-input-wrapper`}>
                                                    <Input.Password size={'large'} bordered={false} style={{background:'#f5f5f5', borderRadius:6}}/>
                                                </div>
                                            </Form.Item>
                                        </Col>
                    
                                    </Row>

                                    <Form.Item style={{textAlign:'center', marginTop:20}}>
                                        <Button size={'large'} type="text" htmlType={'submit'} style={{width:'100%', background:'#2f54eb', color:'#fff', border:'none', borderRadius:12, fontWeight:'600'}} >Continue</Button>
                                    </Form.Item>
                                </Form>
                            </div>
                        </ContainerComponent>
                    </>
                )
            }
        </>
    );
}

export default LoginForm;