import { Divider, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const SubTotal = () => {
    const purchase_order_redux = useSelector(state => state.purchase_order);
    const [state, setState] = useState({
        sub_total: 0,
        total: 0,
        quantity: 0,
        loading: false,
    });

    const calculateAmount = () => {
        let sub_total_amount = 0;
        for (const item of purchase_order_redux?.purchase_items) {
            sub_total_amount += (item?.unit_price * item?.quantity);
        }

        //get total quantity of items
        const quantity = purchase_order_redux?.purchase_items?.map(x => (x.quantity + x.free))?.reduce((partialSum, a) => partialSum + a, 0);

        //calculate total amount
        let total_amount = sub_total_amount;
        setState(state => ({ ...state, sub_total: sub_total_amount, total: total_amount, quantity: quantity }));
    }

    useEffect(() => {
        calculateAmount();
    }, [purchase_order_redux?.purchase_items])

    return (
        <>
            <div>
                <Space style={{ width: '100%' }} direction="vertical">
                    <div style={{ display: 'flex' }}>
                        <div>
                            <span style={{ color: '#8c8c8c', fontWeight: 500 }}>Subtotal</span>
                        </div>
                        <div style={{ marginLeft: 'auto' }}>
                            <span style={{ color: '#8c8c8c', fontWeight: 500 }}>{`MYR ${state?.sub_total.toFixed(2)}`}</span>
                        </div>
                    </div>
                    <div style={{ display: 'flex' }}>
                        <div>
                            <span style={{ color: '#8c8c8c', fontWeight: 500 }}>Item Count</span>
                        </div>
                        <div style={{ marginLeft: 'auto' }}>
                            <span style={{ color: '#8c8c8c', fontWeight: 500 }}>{state?.quantity}</span>
                        </div>
                    </div>

                    <Divider dashed />

                    <div>
                        <div style={{ display: 'flex' }}>
                            <div>
                                <span style={{ color: '#595959', fontWeight: 500, fontSize: 18 }}>Total</span>
                            </div>
                            <div style={{ marginLeft: 'auto' }}>
                                <span style={{ color: '#595959', fontWeight: 500, fontSize: 18 }}>{`MYR ${state?.total?.toFixed(2)}`}</span>
                            </div>
                        </div>
                    </div>
                </Space>
            </div>
        </>
    );
}

export default SubTotal;