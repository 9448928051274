import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Card, Col, Dropdown, Radio, Row, Space, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { set_sale_items } from '../../../actions/sales_order.action';

const SaleAddedItem = ({item, index}) => {
    // console.log(item)
    const dispatch = useDispatch();
    const [state, setState] = useState({
        selected_promotion : item?.promotion && (item?.promotion[0] ?? null),
        calculated_price : 0,
    })


    const sales_order_redux = useSelector(state => state.sales_order);
    const removeSaleItem = () => {
        let sales_order_items = (sales_order_redux.sale_items);
        //find whether list already have this item
        let existing_item = sales_order_items?.find(x => x.item_id === item?.item_id);
        const changed_quantity = sales_order_items?.filter((x) => {
            return (x?.item_id != item?.item_id);
        })

        dispatch(set_sale_items(changed_quantity));
    }

    const modifySaleItems = ({plus}) => {
        let sales_order_items = (sales_order_redux.sale_items);
        //find whether list already have this item
        let existing_item = sales_order_items?.find(x => x.item_id === item?.item_id);
        if(existing_item){
            const calculated_quantity = existing_item?.quantity + plus;
            existing_item.quantity = calculated_quantity;
            let changed_quantity = sales_order_items;
            if(calculated_quantity > 0){
                changed_quantity = sales_order_items?.map((x) => {
                    return (x?.item_id === item?.item_id) ? existing_item : x;
                })
            }else{
                changed_quantity = sales_order_items?.filter((x) => {
                    return (x?.item_id != item?.item_id);
                })
            }

            dispatch(set_sale_items(changed_quantity));
        }
        // else{
        //     //push to it
        //     if(plus > 0){
        //         dispatch(set_sale_items([...sales_order_items, {item_id : item?.item_id, item_code : item?.item_code, name : item?.name, quantity : plus, price : item?.price}]));
        //     }
        // }
    }

    const changePromotion = ({target : { value }}) => {
        const item_promotions = item?.promotion;
        const selected_promo = item_promotions?.find(x => x.promotion_id == value);
        const calculated_price = calculatePromotionPrice(selected_promo);
        modifyPrice(calculated_price?.unit_price, selected_promo);
        setState(state => ({...state, selected_promotion : selected_promo, calculated_price : calculated_price?.total_price}));
    }

    const quantityChanged = () => {
        const default_promotion = state?.selected_promotion;
        const calculated_price = calculatePromotionPrice(default_promotion);
        modifyPrice(calculated_price?.unit_price, default_promotion);
        setState(state => ({...state, calculated_price : calculated_price?.total_price}));
    }

    const modifyPrice = (calculated_price, get_promotion) => {
        let sales_order_items = (sales_order_redux.sale_items);
        //find whether list already have this item
        let existing_item = sales_order_items?.find(x => x.item_id === item?.item_id);
        if(existing_item){
            existing_item.discount_unit_price = calculated_price;
            existing_item.promotion_id = get_promotion?.promotion_id;
            let changed_price = sales_order_items;
            changed_price = sales_order_items?.map((x) => {
                return (x?.item_id === item?.item_id) ? existing_item : x;
            })

            dispatch(set_sale_items(changed_price));
        }
    }

    const calculatePromotionPrice = (promotion) => {
        const price = item?.price;
        let calculated_price = 0;
        if(promotion?.type == 1){ // buy one free one

        }
        else if(promotion?.type == 2){ // discount by percentage
            const percentage = promotion?.discount / 100;
            const discount_amount = price * percentage;
            calculated_price = price - discount_amount;
        }
        else if(promotion?.type == 3){ // discount by direct value
            const discount_amount = promotion?.discount;
            calculated_price = price - discount_amount;
        }else{
            calculated_price = price;
        }

        return {
            total_price : (calculated_price * item?.quantity),
            unit_price : calculated_price,
        };
    }

    useEffect(() => {
        quantityChanged();
    }, [item?.quantity])
    
    return (
        <>
            <Card
            className='sale-cart-item'
            bordered={false}
            style={{borderRadius:6}}
            bodyStyle={{padding : 0}}
            >
                <Dropdown
                placement='bottomRight'
                overlayStyle={{borderRadius:12, minWidth:120}}
                arrow
                menu={{
                    items : [{
                        key: '1',
                        label: (
                            <div onClick={() => modifySaleItems({plus : 1})}>
                                <Space style={{color:'#8c8c8c'}}>
                                    <div>
                                        <PlusOutlined style={{color:'#8c8c8c'}}/>
                                    </div>
                                    <div>
                                        <span>Add</span>
                                    </div>
                                </Space>
                            </div>
                        ),
                    },
                    {
                        key: '2',
                        label: (
                            <div onClick={() => modifySaleItems({plus : -1})}>
                                <Space style={{color:'#8c8c8c'}}>
                                    <div>
                                        <MinusOutlined style={{color:'#8c8c8c'}}/>
                                    </div>
                                    <div>
                                        <span>Minus</span>
                                    </div>
                                </Space>
                            </div>
                        ),
                    },
                    {
                        type : 'divider',
                    },
                    {
                        key: '3',
                        label: (
                            <div onClick={removeSaleItem}>
                                <span>Remove</span>
                            </div>
                        ),
                    },]
                }}
                trigger={'click'}
                >              
                    <div
                    // onClick={removeSaleItem}
                    className='mask' 
                    style={{padding : 24}}
                    >
                        <div style={{textAlign:'left'}}>
                            <div style={{display:'flex'}}>
                                <Space align='start' style={{fontWeight:500, color:'#8c8c8c'}}>
                                    <div>
                                        <span>{`${index}.`}</span>
                                    </div>
                                    <div>
                                        <div>
                                            <span>{item?.name}</span>
                                            <span style={{marginLeft:6, fontSize:12, color:'#8c8c8c'}}>{`x${item?.quantity}`}</span>
                                        </div>
                                        <div>
                                            <span style={{fontSize:12, color:'#8c8c8c'}}>{`${item?.item_code}`}</span>
                                        </div>

                                        {
                                            item?.stock_quantity <= 3 && (
                                                <>
                                                    <div style={{marginTop:8}}>
                                                        <Tag color='red' style={{border:'none', borderRadius:6,}}>{`Low Inventory`}</Tag>
                                                    </div>
                                                </>
                                            )
                                        }
                                        

                                        {
                                            item?.promotion &&
                                            (
                                                <>
                                                    <div style={{marginTop : 8}} onClick={(e) => e.stopPropagation()}>
                                                        <Radio.Group
                                                        optionType="button"
                                                        buttonStyle="solid"
                                                        onChange={changePromotion}
                                                        defaultValue={item?.promotion[0]?.promotion_id}
                                                        >
                                                            {
                                                                item?.promotion?.map((promotion, index) => {
                                                                    const { promotion_id, name } = promotion;
                                                                    return (
                                                                        <Radio.Button value={promotion_id}>{name}</Radio.Button>
                                                                    )
                                                                })
                                                            }
                                                            
                                                        </Radio.Group>
                                                    </div>
                                                </>
                                            )
                                        }
                                        
                                    </div>
                                </Space>
                                <div style={{marginLeft:'auto'}}>
                                    {
                                        state?.selected_promotion ?
                                        (
                                            <>
                                                <div>
                                                    <span style={{fontWeight:500, color:'#434343'}}>{`MYR ${(state?.calculated_price).toFixed(2)}`}</span>
                                                </div>
                                                <div>
                                                    <span style={{color:'#8c8c8c', textDecoration:'line-through'}}>{`MYR ${(item?.quantity * item?.price)?.toFixed(2)}`}</span>
                                                </div>
                                                
                                            </>
                                        )
                                        :
                                        (
                                            <>
                                                <span style={{fontWeight:500, color:'#434343'}}>{`MYR ${(state?.calculated_price).toFixed(2)}`}</span>
                                            </>
                                        )
                                    }
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </Dropdown>
            </Card>
        </>
    );
}

export default SaleAddedItem;