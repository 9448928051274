import React from 'react';
import ContainerComponent from '../../helpers/components/container.component';
import PageHeaderComponent from '../../helpers/components/page_header.component';
import CompanyBillingListTable from './components/company_billing_list_table';

const CompanyBillingList = () => {

    return (
        <>
            <ContainerComponent span={22} top={20}>
                <PageHeaderComponent />
                <CompanyBillingListTable />
            </ContainerComponent>
        </>
    );
}

export default CompanyBillingList;