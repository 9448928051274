
export const generateInvoice = async ({ branch, items, running_number, date, total_amount, print_type }) => {
    const generateItemString = () => {
        let items_string = "";
        let i = 1;

        for (const billing_item of items) {
            const { item, quantity, price, markup, billing } = billing_item;
            const markup_price = price * (1 + (markup / 100));

            const append_string = `
            <tr valign="top" style="page-break-inside:avoid;">
                <td class="details" style="padding:3px;text-align:left;">${i}.</td>
                <td class="details" style="padding:3px;"><b>${item?.item_code}</b><br>${item?.name}</td>
                <td class="details" style="padding:3px;text-align:right;">${quantity}</td>
                
                ${print_type === 'invoice' ?
                (`<td class="details" style="padding:3px;text-align:right;">${(markup_price)?.toFixed(2)}</td>
                <td class="details" style="padding:3px;text-align:right;">${((markup_price) * quantity)?.toFixed(2)}</td>`)
                :
                (``)
                }
                
            </tr>`;

            items_string += append_string;
            i++;
        }

        return items_string;
    }

    return `
    <body>
    <div style="page-break-after: always;">
        <table style="width:100%;">
            <thead class="report-header">
                <tr>
                    <td style="background-color:white;">
                        <div class="divHeader" style="padding-bottom:0px;">
                            <table style="width:100%;">
                                <tbody>
                                    <tr>
                                        <td colspan="2" style="text-align:center;padding-bottom:10px;">
                                            <b>PROJECT CROSSOVER SDN BHD</b><span style="font-size:9pt;"> (202001035958)</span> 
                                            <div style="font-size:9pt;">
                                             <div>G34, KOMPLEKS MEGAMALL, NO. 2828, JALAN PERAI JAYA,</div>
                                            <div>13700 PERAI, PULAU PINANG, MALAYSIA</div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="2" style="border-top:2px solid black; text-align:center; padding-top:5px;">
                                           <h4><b>INVOICE</b></h4>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td >
                                            <div>
                                                <b>BILL TO</b>
                                            </div>
                                            <div style="padding-top:5px;">
                                                ${branch}
                                            </div>
                                        </td>
                                        <td >
                                            <table style="width:100%;text-align:right">
                                                <tr>
                                                    <td ><b>INVOICE NO :</b></td>
                                                    <td>${running_number}</td>
                                                </tr>
                                                <tr>
                                                    <td><b>INVOICE DATE :</b></td>
                                                    <td>${date}</td>
                                                </tr>
                                            </table>
                                            
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="2" style="border-bottom:1px solid black; text-align:center; padding-top:5px;">
                                           
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </td>
                </tr>
            </thead>
            <hr/>
            <tbody>
                <tr>
                    <td>
                        <div>
                            <table style="width:100%;" border="0" cellpadding="0" cellspacing="0">
                                <tbody>
                                    <tr>
                                        <td class="details" style="width:${print_type === 'invoice' ? '5%' : 'auto'};padding:3px;text-align:center;font-weight:bold;border-width:1px 0px;border-style:solid;border-color:gray;">&nbsp;</td>
                                        <td class="details" style="width:${print_type === 'invoice' ? '30%' : 'auto'};padding:3px;text-align:left;font-weight:bold;border-width:1px 0px;border-style:solid;border-color:gray;">Item</td>
                                        <td class="details" style="width:${print_type === 'invoice' ? '15%' : 'auto'};padding:3px;text-align:right;font-weight:bold;border-width:1px 0px;border-style:solid;border-color:gray;">Qty</td>
                                        ${
                                            print_type === 'invoice' ?
                                            (`<td class="details" style="width:25%;padding:3px;text-align:right;font-weight:bold;border-width:1px 0px;border-style:solid;border-color:gray;">Price (RM)</td>
                                            <td class="details" style="width:25%;padding:3px;text-align:right;font-weight:bold;border-width:1px 0px;border-style:solid;border-color:gray;">Total (RM)</td>`)
                                            :(
                                                ``
                                            )
                                        }
                                    </tr>

                                    ${generateItemString()}
                                   
                                    ${
                                        print_type === 'invoice' ?
                                        (
                                            `<tr>
                                                <td class="details" colspan="4" style="padding:3px;text-align:right;border-top:1px solid gray;"><b>TOTAL</b></td>
                                                <td class="details" style="padding:3px;text-align:right;border-top:1px solid gray;">${(total_amount * 1).toFixed(2)}</td>
                                            </tr>`
                                        )
                                        :
                                        (``)
                                    }
                                    
                                </tbody>
                            </table>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="divFooter" style="text-align:left;padding-top:5px;">

            <div style="margin-top:12px;">
                Notes :
                <ol>
                    <li>All cheques should be crossed and made payable to <b>PROJECT CROSSOVER SDN BHD</b>.</li>
                    <li>Goods sold are neither returnable nor refundable. Otherwise a cancellation fee of 20% on purchase price will be imposed.</li>
                </ol> 
            </div>
            <div style="margin-top:80px;width:30%;text-align:center;">
                <hr>
                    <b>Authorised Signature</b>
            </div>
        </div>
    </div>

    </body>`;
}