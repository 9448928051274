
import moment from 'moment';

export const generateCN = async (items, isPurchase) => {
    const { running_number, from_location, from_id, from_branch_id, from_branch, from_supplier_id, from_supplier, to_id, to_supplier, to_location, item, quantity, unit_price, created_at } = items;
    // const generateItemString = () => {
    //     let items_string = "";
    //     let i = 1;

    //     for (const cn_item of items) {
    //         const { item, quantity, price, markup, billing } = cn_item;
    //         const markup_price = price * (1 + (markup / 100));

    //         const append_string = `
    //         <tr valign="top" style="page-break-inside:avoid;">
    //             <td class="details" style="padding:3px;text-align:left;">${i}.</td>
    //             <td class="details" style="padding:3px;"><b>${item?.item_code}</b><br>${item?.name}</td>
    //             <td class="details" style="padding:3px;text-align:right;">${quantity}</td>
    //             <td class="details" style="padding:3px;text-align:right;">${(markup_price)?.toFixed(2)}</td>
    //             <td class="details" style="padding:3px;text-align:right;">${((markup_price) * quantity)?.toFixed(2)}</td>

    //         </tr>`;

    //         items_string += append_string;
    //         i++;
    //     }

    //     return items_string;
    // }

    return `
    <body>
    <div style="page-break-after: always;">
        <table style="width:100%;">
            <thead class="report-header">
                <tr>
                    <td style="background-color:white;">
                        <div class="divHeader" style="padding-bottom:0px;">
                            <table style="width:100%;">
                                <tbody>
                                    <tr>
                                        <td colspan="2" style="text-align:center;padding-bottom:10px;">
                                            <b>PROJECT CROSSOVER SDN BHD</b><span style="font-size:9pt;"> (202001035958)</span> 
                                            <div style="font-size:9pt;">
                                             <div>G34, KOMPLEKS MEGAMALL, NO. 2828, JALAN PERAI JAYA,</div>
                                            <div>13700 PERAI, PULAU PINANG, MALAYSIA</div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="2" style="border-top:2px solid black; text-align:center; padding-top:5px;">
                                           <h4><b>${isPurchase === 1 ? 'PURCHASE ORDER' : 'BILLING'} CREDIT NOTE</b></h4>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td >
                                            <table style="width:100%;text-align:left">
                                                <tr>
                                                    <td ><b>RETURN FROM :</b></td>
                                                    <td style="width:auto;text-align:right">${isPurchase * 1 === 1 ? (from_id ? from_location?.name : 'HQ') : (from_branch_id ? from_branch?.name : (from_supplier_id ? from_supplier?.name : '-'))}</td>
                                                </tr>
                                                <tr>
                                                    <td><b>RETURN TO :</b></td>
                                                    <td style="width:auto;text-align:right"> ${isPurchase * 1 === 1 ? (to_id ? to_supplier?.name : '-') : (to_id ? to_location?.name : 'HQ')}</td>
                                                </tr>
                                            </table>   
                                        </td>
                                        <td >
                                            <table style="width:100%;text-align:right">
                                                <tr>
                                                    <td ><b>CREDIT NOTE NO :</b></td>
                                                    <td>${running_number}</td>
                                                </tr>
                                                <tr>
                                                    <td><b>CREDIT NOTE DATE :</b></td>
                                                    <td>${moment(created_at).format("DD/MM/YYYY")}</td>
                                                </tr>
                                            </table>                                            
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="2" style="border-bottom:1px solid black; text-align:center; padding-top:5px;">
                                           
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </td>
                </tr>
            </thead>
            <hr/>
            <tbody>
                <tr>
                    <td>
                        <div>
                            <table style="width:100%;" border="0" cellpadding="0" cellspacing="0">
                                <tbody>
                                    <tr>
                                        <td class="details" style="width:5%;padding:3px;text-align:center;font-weight:bold;border-width:1px 0px;border-style:solid;border-color:gray;">&nbsp;</td>
                                        <td class="details" style="width:30%;padding:3px;text-align:left;font-weight:bold;border-width:1px 0px;border-style:solid;border-color:gray;">Item</td>
                                        <td class="details" style="width:15%;padding:3px;text-align:right;font-weight:bold;border-width:1px 0px;border-style:solid;border-color:gray;">Qty</td>
                                        <td class="details" style="width:25%;padding:3px;text-align:right;font-weight:bold;border-width:1px 0px;border-style:solid;border-color:gray;">Price (RM)</td>
                                        <td class="details" style="width:25%;padding:3px;text-align:right;font-weight:bold;border-width:1px 0px;border-style:solid;border-color:gray;">Total (RM)</td>        
                                    </tr>

                                    <tr valign="top" style="page-break-inside:avoid;">
                                        <td class="details" style="padding:3px;text-align:left;">1.</td>
                                        <td class="details" style="padding:3px;"><b>${item?.item_code}</b><br>${item?.name}</td>
                                        <td class="details" style="padding:3px;text-align:right;">${quantity}</td>
                                        <td class="details" style="padding:3px;text-align:right;">${(unit_price * 1).toFixed(2)}</td>
                                        <td class="details" style="padding:3px;text-align:right;">${((unit_price) * quantity)?.toFixed(2)}</td>
                                    </tr>  
                                   
                                    <tr>
                                        <td class="details" colspan="4" style="padding:3px;text-align:right;border-top:1px solid gray;"><b>TOTAL</b></td>
                                        <td class="details" style="padding:3px;text-align:right;border-top:1px solid gray;">${((unit_price * quantity) * 1).toFixed(2)}</td>
                                    </tr>
                                    
                                </tbody>
                            </table>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    </body>`;
}