import { Button, Carousel, Divider, Form, Input, Radio, Space } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { refresh_sale_detail, set_sale_total_amount, toggle_promotion_modal, validate_user } from '../../../actions/sales_order.action';
import authenticationService from '../../../services/administrator/authentication.service';
import checkoutService from '../../../services/sales/checkout.service';

const PromotionModal = ({sale_total_amount, sale_id}) => {
    const sales_order_redux = useSelector(state => state?.sales_order);
    const [form, promotionForm] = Form.useForm();
    const stepRef = useRef();
    const dispatch = useDispatch();
    const [state, setState] = useState({
        step : 0,
        auth_loading : false,
        auth_success : false,
    })

    const [promo_state, setPromoState] = useState({
        //default value
        type_options: [
            {
                label: '% Discount',
                value: 2,
            },
            {
                label: 'Value Discount',
                value: 3,
            },
        ],

        //input value
        selected_type : null,
        enter_amount : null,
        //result
        calculated_amount : null,

        //ui smooth
        apply_loading : false,
    })

    const validateAuth = async (form) => {
        const { username, password } = form;
        
        if(username && password){
            setState(state => ({...state, auth_loading : true}));
            const validateResponse = await authenticationService.validateUser(username, password, true).catch(() => {
                setState(state => ({...state, auth_loading : false}));
            });

            const user = validateResponse?.user;
            if(user){
                stepRef.current.next();
                setState(state => ({...state, step : state?.step + 1, auth_loading : false, auth_success : true}));

                //validated user
                dispatch(validate_user(user));
            }
        }
    }

    const calculateSaleTotalAmount = ({type_id, amount}) => {
        let calculated_amount = 0;
        if(type_id == 2){
            //run discount by percentage
            const discount_percent = amount / 100;
            const direct_amount = sale_total_amount * discount_percent;
            calculated_amount = sale_total_amount - direct_amount;
        }else{
            //run discount by direct value
            calculated_amount = sale_total_amount - amount;
        }

        setPromoState(promo_state => ({...promo_state, calculated_amount : calculated_amount}));

    }

    const applyCalculatedAmount = async () => {
        setPromoState(promo_state => ({...promo_state, apply_loading : true}));  

        if(sale_id){
            
            //update sale amount to sales
            const updateSaleResponse = await checkoutService.updateCheckoutSales(sale_id, {
                total_amount : (promo_state?.calculated_amount ?? sale_total_amount)
            });

            setTimeout(() => {
                if(updateSaleResponse){
                    //close modal
                    setPromoState(promo_state => ({...promo_state, apply_loading : false}));
                    dispatch(refresh_sale_detail(sales_order_redux?.sale_refresh + 1))
                    dispatch(toggle_promotion_modal(false));
                }
            }, 500);
            
        }else{
            setTimeout(() => {
                dispatch(set_sale_total_amount(promo_state?.calculated_amount ?? sale_total_amount));
                setPromoState(promo_state => ({...promo_state, apply_loading : false}));
    
                //close modal
                dispatch(toggle_promotion_modal(false));
            }, 500);
        }
        
    }

    useEffect(() => {
        
        if(sales_order_redux?.promotion_modal){
            stepRef.current.goTo(0);

            //clear all existing value
            form.resetFields();
            setPromoState(promo_state => ({...promo_state, selected_type : null, enter_amount : null, calculated_amount : null}))
        }
    }, [sales_order_redux?.promotion_modal])

    return (
        <>
            <Carousel
            ref={ref => {
                stepRef.current= ref;
            }}
            swipe={false}
            dots={false}
            >
                <div>
                    <div>
                        <span style={{fontWeight:500}}>Validate User Account</span>
                    </div>
                    <div style={{marginTop:12}}>
                        <Form
                            id='authenticate_form'
                            onFinish={validateAuth}
                            form={form}
                            layout='vertical'
                        >
                            <Form.Item name='username' label="Username">
                                <Input placeholder='Username' style={{ borderRadius: 6 }} />
                            </Form.Item>
                            <Form.Item name='password' label="Password">
                                <Input type='password' placeholder='Password' style={{ borderRadius: 6 }} />
                            </Form.Item>
                        </Form>
                    </div>

                    <div>
                        <Space style={{width:'100%', justifyContent:'end'}}>
                            <Button
                            form='authenticate_form'
                            key={'submit'}
                            htmlType={'submit'}
                            loading={state?.auth_loading}
                            type='primary'>Validate</Button>
                        </Space>
                    </div>
                </div>
                <div>
                    {
                        state?.auth_success &&
                        (
                            <>
                                <div>
                                    {
                                        (promo_state?.calculated_amount) && 
                                        (promo_state?.calculated_amount >= 0) && 
                                        (promo_state?.calculated_amount != sale_total_amount) ?
                                        (
                                            <>
                                                <Space>
                                                    <div>
                                                        <span style={{ color: '#8c8c8c', fontSize: 18, textDecorationLine : 'line-through' }}>{`MYR ${sale_total_amount?.toFixed(2)}`}</span>
                                                    </div>
                                                    <div>
                                                        <span style={{ color: '#595959', fontWeight: 500, fontSize: 18 }}>{`MYR ${promo_state?.calculated_amount?.toFixed(2)}`}</span>
                                                    </div>
                                                </Space>
                                            </>
                                        )
                                        :
                                        (
                                            <>
                                                <span style={{ color: '#595959', fontWeight: 500, fontSize: 18 }}>{`MYR ${sale_total_amount?.toFixed(2)}`}</span>
                                            </>
                                        )
                                    }
                                    
                                </div>
                                <Divider />
                                <div>
                                    <Form
                                    id='promotion_form'
                                    form={promotionForm}
                                    layout='vertical'
                                    >
                                        <Form.Item
                                        name="type"
                                        label="Type"
                                        // rules={[{ required: true, message: 'Please select type' }]}
                                        >
                                            <Radio.Group
                                            // value={promo_state?.selected_type}
                                            options={promo_state?.type_options}
                                            onChange={(v) => {
                                                let type_id = (v.target.value);
                                                setPromoState(promo_state => ({...promo_state, selected_type : type_id}));
                                                calculateSaleTotalAmount({
                                                    type_id : type_id,
                                                    amount : promo_state?.enter_amount
                                                });
                                            }}
                                            optionType="button"
                                            buttonStyle="solid"
                                            />
                                        </Form.Item>
                                        {
                                            promo_state?.selected_type &&
                                            (
                                                <>
                                                    <Form.Item
                                                    name="amount"
                                                    label={`Discount Amount ${promo_state?.selected_type == 2 ? '(%)' : ''}`}
                                                    >
                                                        <Input
                                                        // ref={}
                                                        placeholder={
                                                            `Please enter discount amount (${promo_state?.selected_type == 2 ? 'percentage' 
                                                            : promo_state?.selected_type == 3 ? 'value'
                                                            : ''
                                                            })`
                                                        }
                                                        style={{ borderRadius: 6 }}
                                                        onChange={(e) => {
                                                            const amount = e.target.value;
                                                            setPromoState(promo_state => ({...promo_state, enter_amount : amount}))
                                                            calculateSaleTotalAmount({
                                                                type_id : promo_state?.selected_type,
                                                                amount : amount,
                                                            });
                                                        }}
                                                        />
                                                    </Form.Item>
                                                </>
                                            )
                                        }
                                        
                                    </Form>
                                </div>

                                <div>
                                    <Space style={{width:'100%', justifyContent:'end'}}>
                                        <Button
                                        onClick={applyCalculatedAmount}
                                        loading={promo_state?.apply_loading}
                                        type='primary'
                                        >
                                            Apply
                                        </Button>
                                    </Space>
                                </div>
                            </>
                        )
                    }
                </div>
            </Carousel>
            
        </>
    );
}

export default PromotionModal;