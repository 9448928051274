import axios from 'axios';
import serviceHelper from '../../helpers/functions/service.helper';


async function getOverallStockReport(query) {
    const queryString = serviceHelper.queryString(query);
    const stockResponse = await axios.get(`${process.env.REACT_APP_SERVER_URL}stock/report/overall${(queryString ? ("?" + queryString) : "")}`, { withCredentials: true });
    return stockResponse?.data;
}

export default {
    getOverallStockReport,
}