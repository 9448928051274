
import React from 'react';
import PurchaseOrderDetail from './order_detail';

const CreatePurchaseConfirmation = ({ order_id }) => {

    return (
        <>
            <PurchaseOrderDetail order_id={order_id} edit={false} />
        </>
    );
}

export default CreatePurchaseConfirmation;