import { Col, Divider, Row, Skeleton, Space, Tag, Table, Input } from 'antd';
import React from 'react';
// import ReactToPrint, { PrintContextConsumer } from 'react-to-print';
import moment from 'moment';

export class SaleInvoice extends React.PureComponent {
    render() {
        return (
            <>
                <div className='printable-area' ref={this?.props?.clientRef} style={{ textAlign: 'center', height: 'auto' }}>
                    <div>
                        <div>
                            {this.props.sale?.branch?.name}
                        </div>
                        <div>
                            {this.props.sale?.branch?.address}
                        </div>
                    </div>
                    <Divider />
                    <div style={{ textAlign: 'left' }}>
                        <Row gutter={[20, 20]} >
                            <Col span={12}>
                                <div >
                                    Invoice Number
                                </div>
                            </Col>
                            <Col span={12}>
                                {this.props.sale?.running_number ?? 'N/A'}
                            </Col>
                        </Row>
                        <Row gutter={[20, 20]}>
                            <Col span={12}>
                                <div>
                                    Cashier
                                </div>
                            </Col>
                            <Col span={12}>
                                {this.props.sale?.creator?.name ?? this.props.sale?.creator?.username}
                            </Col>
                        </Row>

                        <div style={{ marginTop: 8 }}>
                            {moment(this.props.sale?.created_at).format("D MMM YYYY, h:mm:ss a")}
                        </div>
                    </div>

                    <Divider />
                    <Table
                        style={{ padding: 0, textAlign: 'right' }}
                        pagination={false}
                        dataSource={this.props.sale?.sale_items}
                        // className='hub-table'
                        size="small"
                        columns={[{
                            title: 'Item',
                            dataIndex: 'item',
                            key: 'name',
                            width: '50%',
                            render: (text, row, index) => {
                                return (
                                    <>
                                        <div>
                                            <span>{text?.name}</span>
                                        </div>
                                    </>
                                )
                            }
                        }, {
                            title: 'Quantity',
                            dataIndex: 'quantity',
                            key: 'quantity',
                            width: '10%',
                        },
                        // {
                        //     title: 'Unit Price (RM)',
                        //     dataIndex: 'unit_price',
                        //     key: 'unit_price',
                        // }, 
                        {
                            title: 'Price (RM)',
                            dataIndex: 'total_price',
                            key: 'total_price',
                            width: '30%',
                            align: 'right',
                            render: (text, row) => {
                                return (
                                    <div style={{ textAlign: 'right' }}>
                                        <span>{(row?.discount_unit_price * row?.quantity).toFixed(2)} </span>
                                    </div>
                                )
                            }
                        }]}
                    />
                    <Divider />
                    <div style={{ textAlign: 'right' }}>
                        <Row>
                            <Col span={16}>
                                Total
                            </Col>
                            <Col span={8} >
                                {this.props.sale?.total_amount?.toFixed(2)}
                            </Col>
                        </Row>
                        {/* <Row>
                            <Col span={6} offset={12}>
                                DISCOUNT/WAIVE
                            </Col>
                            <Col span={6} >
                                0.00
                            </Col>
                        </Row> */}
                        <Row>
                            <Col span={16}>
                                <h3>NET TOTAL</h3>
                            </Col>
                            <Col span={8} >
                                <h3> {this.props.sale?.total_amount?.toFixed(2)}</h3>

                            </Col>
                        </Row>
                        <Row>
                            <Col span={16}>
                                {this.props.sale?.payment_method?.name}
                            </Col>
                            <Col span={8} >
                                {this.props.sale?.total_amount?.toFixed(2)}

                            </Col>
                        </Row>
                    </div>

                    <Divider />
                    <div>
                        {/* <div style={{textAlign:'left', marginTop:8}}>
                            <span style={{fontWeight:500}}>Warranty</span>
                        </div> */}
                        <div>
                            <Table
                                pagination={false}
                                // showHeader={false}
                                dataSource={[{
                                    name: 'Week'
                                }, {
                                    name: 'Month'
                                }]}
                                columns={[{
                                    title: 'Warranty',
                                    dataIndex: 'name',
                                    key: 'name',
                                    width: '60%',

                                }, {
                                    title: 'Duration',
                                    dataIndex: 'duration',
                                    key: 'duration',
                                    width: '40%',
                                    render: (text, row, index) => {
                                        return (
                                            <>
                                                <Input disabled style={{ background: '#fff' }} />
                                            </>
                                        )
                                    }
                                }]}
                            >

                            </Table>
                        </div>
                    </div>

                    <div style={{ marginTop: 12 }}>
                        **Goods sold are not exchangeable**
                    </div>
                </div>

            </>

        );
    }
}