import axios from 'axios';
import serviceHelper from '../../helpers/functions/service.helper';

async function getCustomers(query) {
    const queryString = serviceHelper.queryString(query);
    const customerResponse = await axios.get(`${process.env.REACT_APP_SERVER_URL}customer/get${(queryString ? ("?" + queryString) : "")}`, { withCredentials: true });
    return customerResponse?.data;
}

export default {
    getCustomers
}