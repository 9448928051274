import axios from 'axios';
import serviceHelper from '../../helpers/functions/service.helper';

async function getCreditNotes(query){
    const queryString = serviceHelper.queryString(query);
    const creditNotesResponse = await axios.get(`${process.env.REACT_APP_SERVER_URL}sales/creditnote/get${(queryString ? ("?" + queryString) : "")}`, { withCredentials : true });
    return creditNotesResponse?.data;
}

async function storeCreditNotes(params={}){
    const creditNotesResponse = await axios.post(`${process.env.REACT_APP_SERVER_URL}sales/creditnote/store`, params, { withCredentials : true });
    return creditNotesResponse?.data;
}

async function updateCreditNotes(credit_note_id, params={}){
    const creditNotesResponse = await axios.post(`${process.env.REACT_APP_SERVER_URL}sales/creditnote/update/${credit_note_id}`, params, { withCredentials : true });
    return creditNotesResponse?.data;
}

async function deleteCreditNotes(credit_note_id){
    const creditNotesResponse = await axios.delete(`${process.env.REACT_APP_SERVER_URL}sales/creditnote/delete/${credit_note_id}`, { withCredentials : true });
    return creditNotesResponse?.data;
}

export default {
    getCreditNotes,
    storeCreditNotes,
    updateCreditNotes,
    deleteCreditNotes
}