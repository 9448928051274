import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, } from 'react-router-dom';
import { set_list_page, set_sale_list } from '../../actions/sales_order.action';
import ContainerComponent from '../../helpers/components/container.component';
import PageHeaderComponent from '../../helpers/components/page_header.component';
import SaleListTable from './components/sale_list_table';

const SaleList = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const search = useLocation().search;
    const search_params = new URLSearchParams(search);
    console.log(search_params.get('start_date'));

    useEffect(() => {
        return ()=>{
            if(history && history.action === "PUSH" && history.location.pathname.includes("/sales/order")){
                //the includes might need to enhance abit if there is other conditions
                //do nothing
            }else{
                dispatch(set_sale_list([]));
                dispatch(set_list_page(0));
            }
            
        } 
    }, [])

    return (
        <>
            <ContainerComponent span={22} top={20}>
                <PageHeaderComponent />
                <SaleListTable search_params={search_params} />
            </ContainerComponent>
        </>
    );
}

export default SaleList;