import { ExportOutlined, FilterOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Divider, Form, Input, Modal, Row, Space, Spin } from 'antd';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import InputWrapper from '../../../../helpers/components/input_wrapper.component';
import reportService from '../../../../services/sales/report.service';
import ReportSalePaymentMethodChart from './report_sale_payment_method_chart';
import ReportSalePaymentMethodTable from './report_sale_payment_method_table';

const ReportSalePaymentMethod = () => {
    const [form] = Form.useForm();
    const [state, setState] = useState({
        loading : false,
        payment_methods : [],
        sales : [],
        total_sale_count : 0,
        //filter
        filter_modal : false,
    })
    const [filter, setFilter] = useState({
        search : null,
        start_date : moment().subtract(7, 'd').format("YYYY-MM-DD"),
        end_date : moment().format("YYYY-MM-DD"),
    });
    const [timer, setTimer] = useState(null)

    const fetchReport = async () => {
        setState(state => ({...state, loading : true}));
        const reportResponse = await reportService.getReportWithPaymentMethod({
            start_date : filter?.start_date,
            end_date : filter?.end_date,
            search : filter?.search,
            page : 0,
            size : 10,
        });
        setTimeout(() => {
            setState(state => ({...state, 
                loading : false, 
                payment_methods : reportResponse?.payment_methods, 
                // sales : reportResponse?.sales?.rows, 
                sales : reportResponse?.sales,
                // total_sale_count : reportResponse?.sales?.count,
            }));
        }, 0);
    }


    const doneTyping = async (input) => {
        clearTimeout(timer);
        // dispatch(set_list_page(0));
        const newTimer = setTimeout(() => {
            setFilter(filter => ({...filter, search : input}));
        }, 300);
        setTimer(newTimer);
    }

    const onFilter = async () => {
        const { date } = form.getFieldsValue();
        const start_date = date && moment(date[0]).format("YYYY-MM-DD");
        const end_date = date && moment(date[1]).format("YYYY-MM-DD");

        setFilter(filter => ({...filter, start_date : start_date, end_date : end_date}));
        setState(state => ({...state, filter_modal : false}));
    }

    useEffect(() => {
        fetchReport();
    }, [filter])

    return (
        <>
            <div style={{padding : '0 24px'}}>
                <div className='filterPanel' style={{display:'flex'}}>
                    <Space>
                        <div>
                            <InputWrapper>
                                <Input
                                onChange={(e) => doneTyping(e.target.value)}
                                bordered={false}
                                prefix={<div style={{paddingRight:12, paddingLeft:8}}><SearchOutlined style={{color:'#8c8c8c'}}/></div>}
                                placeholder={`Search Sales Transactions`}
                                style={{fontSize:14, background:'#f5f5f5', minWidth:300}}
                                size='large'
                                allowClear
                                />
                            </InputWrapper>
                        </div>
                        <div>
                            <Button
                            icon={<FilterOutlined style={{color:'#8c8c8c'}} />}
                            onClick={() => {
                                setState(state => ({...state, filter_modal : true}));
                            }}
                            style={{borderRadius:6}}
                            />
                        </div>
                    </Space>
                    <div style={{marginLeft:'auto'}}>
                        <Button
                        type='primary'
                        icon={<ExportOutlined />}
                        style={{borderRadius:6}}
                        >
                            Export CSV
                        </Button>
                    </div>
                </div>
            </div>
            <Divider />
            <Spin spinning={state?.loading}>
                <div>
                    
                    <div style={{marginTop:24, padding : '0 24px'}}>
                        {/* chart */}
                        <ReportSalePaymentMethodChart payment_methods={state?.payment_methods} />
                    </div>
                    <Divider />
                    <div style={{marginTop:40, padding : '0 24px'}}>
                        {/* table */}
                        <ReportSalePaymentMethodTable sales={state?.sales} payment_methods={state?.payment_methods} />
                    </div>
                </div>
            </Spin>



            <Modal
            transitionName="ant-fade"
            maskTransitionName="ant-fade"
            open={state?.filter_modal}
            title={<><span>Filter</span></>}
            width={500}
            closable={false}
            className="custom-modal"
            okButtonProps={{
                form : 'sale_form',
                key : "submit",
                htmlType : "submit"
            }}
            onOk={() => form.submit()}
            onCancel={() => setState(state => ({...state, filter_modal : false,}))}
            >
                <Form
                id="filter_form"
                onFinish={onFilter}
                form={form}
                layout='vertical'
                >
                    <div>
                        <Form.Item name='date' label="Date">
                            <DatePicker.RangePicker 
                            defaultValue={[moment().subtract(7, 'd'), moment()]}
                            />
                        </Form.Item>
                    </div>
                    
                </Form>
            </Modal>
        </>
    );
}

export default ReportSalePaymentMethod;