import axios from 'axios';
import serviceHelper from '../../helpers/functions/service.helper';

async function getMenu(query){
    const queryString = serviceHelper.queryString(query);
    const menuResponse = await axios.get(`${process.env.REACT_APP_SERVER_URL}authentication/menu/get${(queryString ? ("?" + queryString) : "")}`, { withCredentials : true });
    return menuResponse?.data;
}


export default {
    getMenu,
}