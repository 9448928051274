import { supabase } from '../supabase.config';

async function getMenu({menu_id, link, single}){
    let query = supabase.from('menus').select(`
    menu_id,
    name, 
    link,
    icon,
    modules:module_id (*),
    sub_menus:sub_menus(*)
    `).order('sequence', { ascending : true, });

    if(menu_id){
        query = query.eq('menu_id', menu_id);
    }
    if(link){
        query = query.eq('link', link);
    }
    if(single){
        query = query.single();
    }

    const { data, error } = await query;
    return data;
}

export default {
    getMenu,
}