import React from 'react';
import ContainerComponent from '../../helpers/components/container.component';
import CreateSaleWrapper from './components/create_sale_wrapper';
import './sale.css';

const SaleCreate = () => {
    return (
        <>
            <div>
                <ContainerComponent span={22} top={20}>
                    <CreateSaleWrapper />
                </ContainerComponent>
            </div>
        </>
    );
}

export default SaleCreate;