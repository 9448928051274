import React from 'react';
import ContainerComponent from '../../helpers/components/container.component';
import PageHeaderComponent from '../../helpers/components/page_header.component';
import SupplierListTable from './components/supplier_list_table';

const SupplierList = () => {
    return (
        <>
            <ContainerComponent span={22} top={20}>
                <PageHeaderComponent />
                <SupplierListTable />
            </ContainerComponent>
        </>
    );
}

export default SupplierList;