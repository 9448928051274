import { Space, Table, Tag } from 'antd';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const ReportSalePaymentMethodTable = ({sales, payment_methods, total_sale_count}) => {
    const [state, setState] = useState({
        columns : [],
        current : 1
    });
    
    const initialiseTable = () => {
        let columns = [];
        columns.push({ //initialize transactions
            title : 'Transaction No',
            dataIndex : 'running_number',
            key : 'running_number',
            fixed: 'left',
            width : '30%',
            render : (text, row, index) => {
                return (
                    <>
                        <div>
                            <Space size={20}>
                                <div>
                                    <Link to={`/sales/order/${row?.sale_id}`}>
                                        <span>{text ?? "Pending Sale"}</span>
                                    </Link>
                                </div>
                                {/* <div>
                                    <Tag color={row?.sale_status?.color} style={{border:'none'}}>
                                        <span>{row?.sale_status?.name}</span>
                                    </Tag>
                                </div> */}
                            </Space>
                        </div>
                        <div>
                            <span style={{fontSize:12}}>{moment(row?.created_at).format("D MMM YYYY, h:mm:ss a")}</span>
                        </div>
                    </>
                )
            }
        },{
            title : 'Total Amount',
            dataIndex : 'total_amount',
            key : 'total_amount',
            fixed: 'left',
            width : '20%',
            render : (text, row, index) => {
                const total_payment_amount = row?.payment_method_amount?.reduce((accumulator, currentValue) => accumulator + currentValue.total_amount, 0);
                const balance = total_payment_amount - row?.total_amount;
                return (
                    <>
                        <div>
                            <span>{`MYR ${row?.total_amount?.toFixed(2)}`}</span>
                        </div>
                        <div>
                            <Tag color={balance >= 0 ? 'blue' : 'magenta'} style={{border:'none'}}>
                                <span>{balance?.toFixed(2)}</span>
                            </Tag>
                        </div>
                    </>
                )
            }
        });

        let i = 0;
        for(const payment_method of payment_methods){
            //initialise payment methods
            columns.push({
                title : `${payment_method?.name} (MYR)`,
                dataIndex : payment_method?.code,
                key : payment_method?.code,
                width : '20%',
                className : i <= 0 && 'col-border',
                sorter: (a, b) => 
                a?.payment_method_amount?.find(x => x.payment_method_id == payment_method?.payment_method_id)?.total_amount - 
                b.payment_method_amount?.find(x => x.payment_method_id == payment_method?.payment_method_id)?.total_amount,
                render : (text, row, index) => {
                    const payment_method_amount = row?.payment_method_amount?.find(x => x.payment_method_id == payment_method?.payment_method_id);
                    return (
                        <>
                            <div>
                                {payment_method_amount?.total_amount?.toFixed(2)}
                            </div>
                        </>
                    )
                }
            });
            i++;
        }

        setState(state => ({...state, columns : columns}));
    }

    useEffect(() => {
        initialiseTable();
    }, [sales, state?.current])


    return (
        <>
            <Table
            className='hub-table'
            dataSource={sales}
            columns={state?.columns}
            scroll={{
                x : 1300,
            }}
            pagination={{ 
                size : 10,
                showSizeChanger: false,
                total : total_sale_count,
                current : (state?.current),
                onChange : (page) => {
                    setState(state => ({...state, current : page}));
                }
            }}
            />
        
        </>
    );
}

export default ReportSalePaymentMethodTable;