import { Button, Col, Divider, Dropdown, Modal, Row, Skeleton, Space, Spin, Tag, Form, Input, notification } from 'antd';
import moment from 'moment';
import React, { useEffect, useState, useRef } from 'react';
import checkoutService from '../../../services/sales/checkout.service';
import SaleCreditNote from './sale_credit_note';
import SaleOrderItem from './sale_order_items';
import { DownOutlined, EditOutlined, ExclamationCircleFilled, PrinterOutlined } from '@ant-design/icons';
import SaleEditDetail from './sale_edit_detail';
import SalePayment from './sale_payment';
import { useDispatch, useSelector } from 'react-redux';
// import ReactToPrint from 'react-to-print';
import { SaleInvoice } from './sale_invoice';
import ReactDOMServer from 'react-dom/server';
import { generateReceipt } from '../functions/receipt';
import PromotionModal from './promotion_modal';
import { toggle_promotion_modal } from '../../../actions/sales_order.action';

const { confirm } = Modal;

const SaleOrderDetail = ({ sale_id, edit }) => {
    let componentRef = useRef();
    let clientRef = useRef();
    const dispatch = useDispatch();
    const saleOrderRedux = useSelector(state => state.sales_order);
    const authRedux = useSelector(state => state.auth);
    const [form] = Form.useForm();
    const [state, setState] = useState({
        loading: false,
        sale: null,
        edit_modal: false,
        updated: 0,
    })

    const [dropdown, setDropdown] = useState({
        items: [],
        update_status: null,
    });

    const fetchSalesOrder = async () => {
        setState(state => ({ ...state, loading: true }));

        const salesResponse = await checkoutService.getSaleById(sale_id);
        const sale = salesResponse?.sales;


        setTimeout(() => {
            setState(state => ({ ...state, loading: false, sale: sale }));
        }, 500);
        return sale;
    }

    const updateSales = async ({ sale_status_id, name, phoneNo, paymentMethod }) => { //status code - 2 : complete, 3 : void
        const updateSaleResponse = await checkoutService.updateCheckoutSales(sale_id, {
            sale_status_id: sale_status_id,
            ...(phoneNo && {
                customer: {
                    name: name,
                    phone_no: phoneNo
                }
            }),
            payment_method_id: paymentMethod,
        });

        setState(state => ({ ...state, updated: (state?.updated + 1) }))
    }

    const updateSaleDetail = async () => {
        const { name, phoneNo, paymentMethod } = form.getFieldsValue();
        const update_sales = await updateSales({
            name: name,
            phoneNo: phoneNo,
            paymentMethod: paymentMethod
        });

        setState(state => ({ ...state, edit_modal: false }));
    }

    const checkAndConfirm = async (sale_status_id, code, sale) => {
        const balance = sale?.balance;
        if (code == 2 && (balance && balance != 0)) {
            console.log('dont update');

            notification.warning({
                message: 'Oops, Unable to complete sales.',
                description: 'Please make sure payment amount is same with sales amount'
            })
        } else {
            confirm({
                title: 'Do you want to update sales?',
                icon: <ExclamationCircleFilled />,
                content: 'Please make sure the sale details is correct',
                onOk: () => {
                    return new Promise(async (resolve, reject) => {
                        const update_status = await updateSaleStatus(sale_status_id);
                        setTimeout(() => {
                            resolve()
                        }, 500);
                        // setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                    }).catch(() => console.log('Oops errors!'));

                }
            })

        }
    }

    const updateSaleStatus = async (sale_status_id) => {
        const update_sales = await updateSales({
            sale_status_id: sale_status_id,
        });

        setDropdown(dropdown => ({ ...dropdown, update_status: sale_status_id, }));
    }


    const getDropdownItem = async (sale) => {
        let items = [];
        const saleStatusResponse = await checkoutService.getSaleStatus({
            editable: 0,
        });

        const sale_statuses = saleStatusResponse?.sale_status;
        //fetch all status
        if (sale?.sale_status?.editable) {
            let status_item = [];
            for (const sale_status of sale_statuses) {
                status_item.push({
                    key: `1-${sale_status.code}`,
                    label: sale_status.description,
                    onClick: async () => {
                        const update = await checkAndConfirm(sale_status.sale_status_id, sale_status?.code, sale);
                    }
                });
            }

            items.push({
                key: '1',
                type: 'group',
                label: "Sale Status",
                children: status_item,
            },
                {
                    type: 'divider',
                },
            );
        }
        items.push({
            key: '2',
            type: 'group',
            label: "Sale",
            children: [{
                key: '2-1',
                label: 'Edit detail',
                onClick: () => {
                    setState(state => ({ ...state, edit_modal: true }));
                }
            }]
        },
        );
        setDropdown(dropdown => ({ ...dropdown, items: items }));
    }

    const printReceipt = async () => {
        var printWindow = window.open('', 'PrintWindow');
        const { branch, sale_items, creator, running_number, created_at, total_amount, warranty_type, warranty_duration } = state?.sale ?? {};
        const receipt_string = await generateReceipt({
            company: branch?.company,
            branch: branch,
            items: sale_items,
            cashier: creator?.username,
            running_number: running_number,
            total_amount: total_amount,
            warranty_type: warranty_type,
            warranty_duration: warranty_duration,
            date: moment(created_at).format("D MMM YYYY, h:mm:ss a"),
        })

        printWindow.document.write(receipt_string);
        printWindow.print();
        printWindow.close();
    }

    const editFormValue = (sale) => {
        form.setFieldsValue({
            name: sale?.customer?.name,
            phoneNo: sale?.customer?.phone_no,
            paymentMethod: sale?.payment_method_id
        });
    }

    useEffect(() => {
        fetchSalesOrder().then((sale) => {
            getDropdownItem(sale);
            editFormValue(sale);
        });
    }, [sale_id, state?.updated, saleOrderRedux?.sale_refresh])


    return (
        <>
            <Spin spinning={state?.loading}>
                <div style={{ display: 'flex', alignItems: 'end' }}>
                    <div>
                        <div style={{ textAlign: 'left' }}>
                            <div>
                                <div>
                                    <span style={{ fontSize: 18, color: '#595959' }}>{state?.sale?.running_number ?? "Pending Sale"}</span>
                                </div>
                            </div>
                            <div>
                                <Space>
                                    <div>
                                        <span>
                                            <span style={{ fontSize: 24, fontWeight: 500 }}>{`RM ${state?.sale?.total_amount?.toFixed(2) ?? 0}`}</span>
                                        </span>
                                    </div>
                                    <div>
                                        {
                                            edit && state?.sale?.sale_status?.editable &&
                                            (
                                                <>
                                                    <Button
                                                        size='small'
                                                        style={{ borderRadius: 4, }}
                                                        onClick={() => dispatch(toggle_promotion_modal(true))}
                                                    >
                                                        <EditOutlined style={{ color: '#8c8c8c' }} />
                                                    </Button>
                                                </>
                                            )
                                        }

                                    </div>
                                </Space>
                                <div>
                                    <span>{`Amount With Credit Note : RM ${((state?.sale?.total_amount) + (state?.sale?.credit_notes?.reduce((a, b) => (a + b.amount), 0)))?.toFixed(2)}`}</span>
                                </div>

                                {
                                    authRedux?.user?.role_id == 1 && (
                                        <>
                                            <Space>
                                                <div>
                                                    <span>{`Profit : RM ${(state?.sale?.profit)?.toFixed(2)}`}</span>
                                                </div>
                                                {/* Gross Profit Percentage Formula = Gross Profit / Total Sales * 100% */}
                                                <div>
                                                    <span style={{ fontSize: 14, color: '#8c8c8c' }}>{`(${((state?.sale?.profit / state?.sale?.total_amount) * 100)?.toFixed(2)}%)`}</span>
                                                </div>
                                            </Space>
                                        </>
                                    )
                                }

                            </div>
                            <div>
                                <Space>
                                    <div>
                                        <Tag color={state?.sale?.sale_status?.color} style={{ border: 'none' }}>
                                            <span>{`Status : ${state?.sale?.sale_status?.name}`}</span>
                                        </Tag>
                                        <Tag color={(state?.loading || state?.sale?.balance >= 0) ? 'blue' : 'magenta'} style={{ border: 'none' }}>
                                            <span>{`Balance : MYR${state?.sale?.balance ? state?.sale?.balance?.toFixed(2) : 0} `}</span>
                                        </Tag>
                                        {
                                            state?.sale?.warranty_duration &&
                                            (
                                                <>
                                                    <Tag style={{ border: 'none' }}>
                                                        <span>{`Warranty : ${state?.sale?.warranty_duration} ${state?.sale?.warranty_type} `}</span>
                                                    </Tag>
                                                </>
                                            )
                                        }

                                    </div>
                                </Space>
                            </div>
                        </div>
                    </div>


                    <div style={{ marginLeft: 'auto' }}>
                        <Space>

                            {
                                edit &&
                                (
                                    <>
                                        <div>
                                            <Button
                                                type='default' style={{ borderRadius: 4 }}
                                                icon={<PrinterOutlined />}
                                                onClick={printReceipt}
                                            >
                                                Print Invoice
                                            </Button>
                                        </div>
                                        <Dropdown
                                            placement='bottomRight'
                                            overlayStyle={{ borderRadius: 12, minWidth: 200 }}
                                            menu={{
                                                items: dropdown?.items
                                            }}
                                        >
                                            <Button type='primary' className='mini-button' icon={<DownOutlined />} style={{ borderRadius: 4 }}>
                                                Action
                                            </Button>
                                        </Dropdown>
                                    </>
                                )
                            }
                        </Space>
                    </div>

                </div>

                <Divider style={{ marginTop: 12 }} />
                <div style={{ textAlign: 'left' }}>
                    <Row gutter={[20, 20]}>
                        <Col xs={12} md={8} lg={4} span={6}>
                            <div>
                                <div>
                                    <span style={{ fontWeight: 500 }}>Customer</span>
                                </div>
                                <div>
                                    <span>
                                        {
                                            state?.sale?.customer ?
                                                (`${state?.sale?.customer?.name}, ${state?.sale?.customer?.phone_no}`)
                                                :
                                                <span style={{ fontSize: 12 }}>No customer added</span>
                                        }
                                    </span>
                                </div>
                            </div>
                        </Col>

                        <Col xs={12} md={8} lg={6} span={6}>
                            <div>
                                <div>
                                    <span style={{ fontWeight: 500 }}>Issue Date</span>
                                </div>
                                <div>

                                    {
                                        state?.sale?.created_at ?
                                            (
                                                <>
                                                    <span>
                                                        {moment(state?.sale?.created_at).format("D MMM YYYY, h:mm:ss a")}
                                                    </span>
                                                </>
                                            )
                                            :
                                            (
                                                <>
                                                    <span style={{ fontSize: 12 }}>No issue date</span>
                                                </>
                                            )
                                    }
                                </div>
                            </div>
                        </Col>
                        <Col xs={12} md={8} lg={4} span={6}>
                            <div>
                                <div>
                                    <span style={{ fontWeight: 500 }}>Branch</span>
                                </div>
                                <div>
                                    <span>
                                        {state?.sale?.branch?.name}
                                    </span>
                                </div>
                            </div>
                        </Col>
                        {
                            state?.sale?.discount_approver &&
                            (
                                <Col xs={12} md={6} lg={4} span={6}>
                                    <div>
                                        <div>
                                            <span style={{ fontWeight: 500 }}>Discount Approved By</span>
                                        </div>
                                        <div>
                                            <span>
                                                {state?.sale?.discount_approver}
                                            </span>
                                        </div>
                                    </div>
                                </Col>
                            )
                        }


                    </Row>
                </div>

                <div style={{ marginTop: 48 }}>
                    <SalePayment sale_id={sale_id} edit={edit} status_editable={state?.sale?.sale_status?.editable} />
                </div>

                <div style={{ marginTop: 48 }}>
                    <SaleOrderItem sale_id={sale_id} edit={edit} status_editable={state?.sale?.sale_status?.editable} />
                </div>

                <div style={{ marginTop: 48 }}>
                    {
                        (state?.sale?.sale_status?.code === 2) &&
                        <>
                            <SaleCreditNote sale_id={sale_id} edit={edit} status_editable={(state?.sale?.sale_status?.code) == 2} />
                        </>

                    }

                </div>
            </Spin>

            <Modal
                transitionName="ant-fade"
                maskTransitionName="ant-fade"
                open={state?.edit_modal}
                title={<><span>Update Sales Detail</span></>}
                width={500}
                closable={false}
                className="custom-modal"
                okButtonProps={{
                    form: 'sale_form',
                    key: "submit",
                    htmlType: "submit"
                }}
                onOk={() => form.submit()}
                onCancel={() => setState(state => ({ ...state, edit_modal: false, }))}
            >
                <Form
                    id='sale_form'
                    onFinish={updateSaleDetail}
                    form={form}
                    layout='vertical'
                >
                    <SaleEditDetail sale={state?.sale} />
                </Form>
            </Modal>

            {
                edit &&
                (
                    <>
                        <Modal
                            open={saleOrderRedux?.promotion_modal}
                            footer={false}
                            onCancel={() => dispatch(toggle_promotion_modal(false))}
                        >
                            <PromotionModal sale_id={state?.sale?.sale_id} sale_total_amount={state?.sale?.total_amount} />
                        </Modal>
                    </>
                )
            }

        </>
    );
}

export default SaleOrderDetail;