const initialState = {
    //create sales
    sale_items: [],
    sale_total_amount: 0,
    customer: null,

    //sales list
    sale_list: [],
    list_count: 0,
    list_page: 0,

    //credit notes
    credit_note_list: [],
    credit_note_data: null,
    cn_list_count: 0,
    cn_list_page: 0,

    //report
    report_sale_payment_method_list_page: 0,

    //entire detailed page
    sale_refresh: 0,

    //promotion
    promotion_modal : false,
    validated_user : null,
}

const salesOrderReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_SALE_ITEMS':
            return { ...state, sale_items: action.sale_items }
        case 'SET_CUSTOMER':
            return { ...state, customer: action.customer }
        case 'SET_SALE_LIST':
            return { ...state, sale_list: action.sale_list, list_count: action.list_count, }
        case 'SET_LIST_PAGE':
            return { ...state, list_page: action.page }
        case 'SET_CREDIT_NOTE_LIST':
            return { ...state, credit_note_list: action.credit_note_list, list_count: action.cn_list_count }
        case 'SET_CREDIT_DATA':
            return { ...state, credit_note_data: action.credit_note_data }
        case 'SET_REPORT_SALE_PAYMENT_METHOD_LIST_PAGE':
            return { ...state, report_sale_payment_method_list_page: action.page }
        case 'REFRESH_SALE_DETAIL':
            return { ...state, sale_refresh: action.sale_refresh }
        case 'SET_SALE_TOTAL_AMOUNT':
            return { ...state, sale_total_amount: action.sale_total_amount }
        case 'TOGGLE_PROMOTION_MODAL':
            return { ...state, promotion_modal : action.promotion_modal }
        case 'VALIDATE_USER':
            return { ...state, validated_user : action.validated_user, }
        default:
            return state;
    }
}

export default salesOrderReducer;