import { Card, Spin } from 'antd';
import React, { useState } from 'react';

const OldInventory = () => {
    const [state, setState] = useState({
        loading : false,
    })

    return (
        <>
            <Card
            style={{ borderRadius: 6, height:'100%' }}
            bordered={false}
            >
                <Spin spinning={state?.loading}>
                    <div>
                        <div style={{textAlign:'left'}}>
                            <span style={{fontWeight: 500, color:'#414552', fontSize:14}}>Item Stock Is More Than 30 Days</span>
                        </div>
                    </div>
                    <div style={{marginTop:24}}>
                        {/* <List
                        dataSource={state?.low_inventory_items}
                        size={'large'}
                        renderItem={(item, index) => {
                            return (
                                <List.Item key={index} style={{padding:'12px 0'}}>
                                    <List.Item.Meta
                                    style={{textAlign:'left'}}
                                    // avatar={<Avatar src={item.picture.large} />}
                                    // title={<a href="https://ant.design">{item?.item?.name}</a>}
                                    description={<span style={{fontWeight:500}}>{item?.item?.name}</span>}
                                    />
                                    <div>
                                        <Tag color={'red'} style={{border:'none', borderRadius:4, minWidth:40}}>
                                            <span>{item?.quantity}</span>
                                        </Tag>
                                    </div>
                                </List.Item>
                            )
                        }}
                        /> */}
                    </div>

                    {/* <div style={{marginTop:24, textAlign:'left'}}>
                        <Link to="stocks/issue">
                            <span className='mini-button mini-style-text'>
                                Stock Issue
                            </span>
                        </Link>
                    </div> */}
                </Spin>
            </Card>
        </>
    );
}

export default OldInventory;