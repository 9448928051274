import axios from 'axios';
import serviceHelper from '../../helpers/functions/service.helper';

async function getReportWithPaymentMethod(query){
    const queryString = serviceHelper.queryString(query);
    const reportResponse = await axios.get(`${process.env.REACT_APP_SERVER_URL}sales/report/payment_method${(queryString ? ("?" + queryString) : "")}`, { withCredentials : true });
    return reportResponse?.data;
}

async function getOverallSaleReport(query){
    const queryString = serviceHelper.queryString(query);
    const reportResponse = await axios.get(`${process.env.REACT_APP_SERVER_URL}sales/report/overall${(queryString ? ("?" + queryString) : "")}`, { withCredentials : true });
    return reportResponse?.data;
}

async function getReportWithItem(query){
    const queryString = serviceHelper.queryString(query);
    const reportResponse = await axios.get(`${process.env.REACT_APP_SERVER_URL}sales/report/item${(queryString ? ("?" + queryString) : "")}`, { withCredentials : true });
    return reportResponse?.data;
}

async function getReportSaleGP(query){
    const queryString = serviceHelper.queryString(query);
    const reportResponse = await axios.get(`${process.env.REACT_APP_SERVER_URL}sales/report/salegp${(queryString ? ("?" + queryString) : "")}`, { withCredentials : true });
    return reportResponse?.data;
}

export default {
    getReportWithPaymentMethod,
    getOverallSaleReport,
    getReportWithItem,
    getReportSaleGP
}