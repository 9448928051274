import { Button, DatePicker, Divider, Form, Input, Modal, Space, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import InputWrapper from '../../../../helpers/components/input_wrapper.component';
import { SearchOutlined, FilterOutlined, ExportOutlined } from '@ant-design/icons'
import reportService from '../../../../services/sales/report.service';
import ReportSaleItemTable from './report_sale_item_table';
import moment from 'moment';

const ReportSaleItem = () => {
    const [form] = Form.useForm();
    const [state, setState] = useState({
        loading : false,
        filter_modal : false,

        //item
        items : []
    });

    const [filter, setFilter] = useState({
        start_date : moment().subtract(7, 'd').format("YYYY-MM-DD"),
        end_date : moment().format("YYYY-MM-DD"),
        search : null,
    });

    const [timer, setTimer] = useState(null);

    const fetchItemReport = async () => {
        setState(state => ({...state, loading : true}));
        const itemResponse = await reportService.getReportWithItem({
            start_date : filter?.start_date,
            end_date : filter?.end_date,
            search : filter?.search,
        });

        setState(state => ({...state, loading : false, items : itemResponse?.items}))
    }

    const onFilter = async () => {
        const { date } = form.getFieldsValue();
        const start_date = date && moment(date[0]).format("YYYY-MM-DD");
        const end_date = date && moment(date[1]).format("YYYY-MM-DD");

        setFilter(filter => ({...filter, start_date : start_date, end_date : end_date}));
        setState(state => ({...state, filter_modal : false}));
    }

    const doneTyping = async (input) => {
        clearTimeout(timer);
        // dispatch(set_list_page(0));
        const newTimer = setTimeout(() => {
            setFilter(filter => ({...filter, search : input}));
        }, 300);
        setTimer(newTimer);
    }

    useEffect(() => {
        fetchItemReport();
    }, [filter])

    return (
        <>
            <div style={{padding : '0 24px'}}>
                <div className='filterPanel' style={{display:'flex'}}>
                    <Space>
                        <div>
                            <InputWrapper>
                                <Input
                                onChange={(e) => doneTyping(e.target.value)}
                                bordered={false}
                                prefix={<div style={{paddingRight:12, paddingLeft:8}}><SearchOutlined style={{color:'#8c8c8c'}}/></div>}
                                placeholder={`Search Items`}
                                style={{fontSize:14, background:'#f5f5f5', minWidth:300}}
                                size='large'
                                allowClear
                                />
                            </InputWrapper>
                        </div>
                        <div>
                            <Button
                            icon={<FilterOutlined style={{color:'#8c8c8c'}} />}
                            onClick={() => {
                                setState(state => ({...state, filter_modal : true}));
                            }}
                            style={{borderRadius:6}}
                            />
                        </div>
                    </Space>
                    <div style={{marginLeft:'auto'}}>
                        <Button
                        type='primary'
                        icon={<ExportOutlined />}
                        style={{borderRadius:6}}
                        >
                            Export CSV
                        </Button>
                    </div>
                </div>
            </div>
            <Divider />
            <div>
                <Spin spinning={state?.loading}>
                    <div>
                        
                        <div style={{marginTop:24, padding : '0 24px'}}>
                            {/* chart */}
                            <ReportSaleItemTable items={state?.items} />
                        </div>
                        <Divider />
                    </div>
                </Spin>
            </div>


            <Modal
            transitionName="ant-fade"
            maskTransitionName="ant-fade"
            open={state?.filter_modal}
            title={<><span>Filter</span></>}
            width={500}
            closable={false}
            className="custom-modal"
            okButtonProps={{
                form : 'sale_form',
                key : "submit",
                htmlType : "submit"
            }}
            onOk={() => form.submit()}
            onCancel={() => setState(state => ({...state, filter_modal : false,}))}
            >
                <Form
                id="filter_form"
                onFinish={onFilter}
                form={form}
                layout='vertical'
                >
                    <div>
                        <Form.Item name='date' label="Date">
                            <DatePicker.RangePicker 
                            defaultValue={[moment().subtract(7, 'd'), moment()]}
                            />
                        </Form.Item>
                    </div>
                    
                </Form>
            </Modal>
        </>
    );
}

export default ReportSaleItem;