import { Col, Form, Input, Row, Select, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import itemService from '../../../services/config/item.service';

const { Option } = Select;
const SaleOrderItemAdd = ({ form }) => {
    const [timer, setTimer] = useState(null)
    const [state, setState] = useState({
        loading: true,
        items: [],
        input: null,
        selected_items: null,
    });

    const fetchItemOnSearch = async () => {
        setState(state => ({ ...state, loading: true }));
        let itemResponse;
        itemResponse = await itemService.getItem({
            name: state?.input,
        });

        const items = itemResponse?.items?.rows?.map(x => {
            return {
                value: x.item_id,
                label: x.name,
                item_code: x.item_code,
                price: x.price,
            }
        })

        setState(state => ({ ...state, items: items, loading: false }));
    }

    const doneTyping = (input) => {
        clearTimeout(timer);
        const newTimer = setTimeout(() => {
            // fetchItemOnSearch(input);
            setState(state => ({ ...state, input: input }));
        }, 500);
        setTimer(newTimer);
    }

    const onDropdownSelected = (item_id) => {
        const items = state?.items?.find(x => x.value === item_id);
        form.setFieldsValue({ item_id: item_id, price: items?.price });
        setState(state => ({ ...state, selected_items: item_id }));
    }

    useEffect(() => {
        fetchItemOnSearch();
    }, [state?.input])

    return (
        <>
            <Form.Item name='itemId' label="Item" shouldUpdate rules={[{ required: true, message: 'Please select an item' }]}>
                <Select
                    filterOption={false}
                    // options={state?.items}
                    showSearch
                    className='border-input'
                    style={{ borderRadius: 6 }}
                    onSearch={doneTyping}
                    onChange={onDropdownSelected}
                >
                    {
                        state?.items?.map((item, index) => {
                            return (
                                <Option value={item?.value} key={index}>
                                    <div>
                                        <div><span>{item?.label}</span></div>
                                        <div><span style={{ fontSize: 12, color: '#8c8c8c' }}>{item?.item_code}</span></div>
                                    </div>
                                </Option>
                            )
                        })
                    }
                </Select>
            </Form.Item>
            <Row gutter={[20, 20]}>
                <Col span={12}>
                    <Form.Item name='quantity' label="Quantity" rules={[{ required: true, message: 'Please enter a quantity' }]}>
                        <Input type='number' placeholder='1' min={1} style={{ borderRadius: 6 }} />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name='price' label="Price" rules={[{ required: true, message: 'Please enter a price' }]}>
                        <Input disabled type='number' placeholder='19.00' style={{ borderRadius: 6, background:'#fff', color: '#8c8c8c' }} />
                    </Form.Item>
                </Col>
            </Row>

        </>
    );
}

export default SaleOrderItemAdd;