const initialState = {
    //create stock count
    billing_items: [],
    selected_items: {
        item_id: null,
        price: null,
        quantity: null,
        markup: null,
    },
    update_billing: null,
    //entire detailed page
    refresh: 0,
}

const billingReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_BILLING_ITEMS':
            return { ...state, billing_items: action.billing_items }
        case 'SET_UPDATE_BILLING':
            return { ...state, update_billing: action.update_billing }
        case 'SET_SELECTED_ITEMS':
            return { ...state, selected_items: action.selected_items }
        case 'REFRESH_BILLING_DETAIL':
            return { ...state, refresh: action.refresh }
        default:
            return state;
    }
}

export default billingReducer;