import { SearchOutlined, DownOutlined, FilterOutlined } from '@ant-design/icons';
import { Button, Input, Form, Spin, Space, Table, DatePicker, Dropdown, Modal, Select, Tag } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import InputWrapper from '../../../helpers/components/input_wrapper.component';
import billingService from '../../../services/billing/billing.service';
import branchService from '../../../services/config/branch.service';
import supplierService from '../../../services/config/supplier.service';
import { generateInvoice } from '../functions/invoice';

const { Option } = Select;
const BillingListTable = () => {
    const history = useHistory();
    const [form] = Form.useForm();
    const [form1] = Form.useForm();

    const [state, setState] = useState({
        loading: false,
        billings: [],
        billing_count: 0,
        printModal: false,
        branch: [],
        modalLoading: false,
        searchModal: false,
        filter_modal: false,
        print_type: null,
        dropdown: [
            {
                key: '1',
                type: 'group',
                children: [
                    {
                        key: '1-1',
                        label: 'Add Billing',
                        onClick: async () => {
                            addBilling();
                        }
                    },
                    {
                        key: '1-2',
                        label: 'Print Billing',
                        onClick: async () => {
                            showModal();
                        }
                    },
                    {
                        key: '1-3',
                        label: 'Print DO',
                        onClick: async () => {
                            showModal("do");
                        }
                    }
                ]
            },
        ],
        to_selection: [
            {
                value: 1,
                label: 'To Branch'
            },
            {
                value: 2,
                label: 'To Supplier/Dealer'
            }
        ],
        to_selected: 1,
    })
    const [filter, setFilter] = useState({
        page: 1,
        search: null,
        branchInput: null,
        supplier: null,
        type: null,
    });
    const [timer, setTimer] = useState(null)
    const addBilling = () => {
        history.push(`/billing/create`);
    }
    const onFilter = async () => {
        const { supplier, billTo } = form1.getFieldsValue();
        setFilter(filter => ({ ...filter, supplier: supplier, type: billTo ?? 1 }));
        setState(state => ({ ...state, filter_modal: false, }));
    }

    const fetchBillingList = async () => {
        setState(state => ({ ...state, loading: true, billings: [] }));
        const billingResponse = await billingService.getBilling({
            page: (filter?.page - 1),
            size: 12,
            search: filter?.search,
            type: filter?.type,
            supplier: filter?.supplier
        });
        setTimeout(() => {

            setState(state => ({ ...state, billings: billingResponse?.billings?.rows, loading: false, billing_count: billingResponse?.billings?.count, }));
        }, 800);
    }

    const fetchBranchOnSearch = async () => {
        let branchResponse;
        branchResponse = await branchService.getBranch({
            search: filter?.branchInput,
        });

        const branch = branchResponse?.branch?.map(x => {
            return {
                value: x.branch_id,
                label: x.name
            }
        })


        setState(state => ({ ...state, branch: branch }));
    }
    const fetchSupplierOnSearch = async () => {
        let supplierResponse;
        supplierResponse = await supplierService.getSupplier({
            search: filter?.branchInput,
        });

        const branch = supplierResponse?.supplier?.map(x => {
            return {
                value: x.supplier_id,
                label: x.name
            }
        })


        setState(state => ({ ...state, branch: branch }));
    }
    const toSelectionOnDropdownSelected = (value) => {
        clearTimeout(timer);
        const newTimer = setTimeout(() => {
            setState(state => ({ ...state, to_selected: value, to_id: null }));

        }, 300);
        setTimer(newTimer);
    }

    const searchInvoice = async () => {
        form.validateFields()
            .then(async (values) => {
                setState(state => ({ ...state, modalLoading: true }));
                const { branch_id, selection, date } = form.getFieldsValue();
                const printBillingResponse = await billingService.printBilling({
                    branch_id: branch_id,
                    selection: selection ?? 1,
                    start_date: moment(date[0]).format("YYYY-MM-DD"),
                    end_date: moment(date[1]).format("YYYY-MM-DD"),
                });
                let invoice_bulk_string = '';
                const printWindow = window.open(' ', 'Print Window');

                for (const item of printBillingResponse?.billing) {
                    const { billing_items, branch, running_number, created_at, total_amount, supplier } = item ?? {};
                    invoice_bulk_string += await generateInvoice({
                        branch: branch ? branch?.company?.name + '<br/>' + branch?.name : supplier?.name,
                        items: billing_items,
                        running_number: running_number,
                        total_amount: total_amount,
                        date: moment(created_at).format("DD/MM/YYYY"),
                        print_type: state?.print_type,
                    })

                }

                printWindow.document.write(invoice_bulk_string);
                printWindow.print();
                printWindow.close();
                setState(state => ({ ...state, modalLoading: false }));

            })
            .catch(() => {
                setState(state => ({ ...state, modalLoading: false }));
            });
    }

    const showModal = (print_type = "invoice") => {
        setTimeout(() => {
            setState(state => ({ ...state, printModal: true, print_type: print_type, }));
        }, 300);

    };

    const handleCancel = () => {
        form.resetFields();
        setState(state => ({ ...state, printModal: false }));
    };

    const doneTyping = async (input) => {
        clearTimeout(timer);
        const newTimer = setTimeout(() => {
            setFilter(filter => ({ ...filter, search: input, page: 1, }));
        }, 300);
        setTimer(newTimer);
    }

    useEffect(() => {
        if (state?.to_selected == 1) {
            fetchBranchOnSearch();
        } else {
            fetchSupplierOnSearch();
        }
        form.setFieldsValue({ branch_id: null });
    }, [state?.to_selected]);

    useEffect(() => {
        fetchBillingList();
    }, [filter])



    return (
        <>
            <div className='filter-panel' style={{ height: 60, marginTop: 10, marginBottom: 10 }} >
                <div style={{ display: 'flex' }}>
                    <Space>
                        <div>
                            <InputWrapper>
                                <Input
                                    onChange={(e) => doneTyping(e.target.value)}
                                    bordered={false}
                                    prefix={<div style={{ paddingRight: 12, paddingLeft: 8 }}><SearchOutlined style={{ color: '#8c8c8c' }} /></div>}
                                    placeholder={`Search Billing`}
                                    style={{ fontSize: 14, width: 400 }}
                                    size='large'
                                />
                            </InputWrapper>
                        </div>

                        <div>
                            <Button
                                icon={<FilterOutlined style={{ color: '#8c8c8c' }} />}
                                onClick={() => {
                                    setState(state => ({ ...state, filter_modal: true }));
                                }}
                                style={{ borderRadius: 6 }}
                            />
                        </div>
                    </Space>
                    <div style={{ marginLeft: 'auto' }}>
                        <Dropdown
                            placement='bottomRight'
                            overlayStyle={{ borderRadius: 12, minWidth: 200 }}
                            menu={{
                                items: state?.dropdown
                            }}
                        >
                            <Button type='primary' className='mini-button' icon={<DownOutlined />} style={{ borderRadius: 4 }}>
                                Action
                            </Button>
                        </Dropdown>

                    </div>
                </div>

            </div>
            <Spin spinning={state?.loading}>
                <Table
                    pagination={{
                        pageSize: 12,
                        showSizeChanger: false,
                        total: state?.billing_count,
                        current: filter?.page,
                        onChange: (page) => setFilter(filter => ({ ...filter, page: page }))
                    }}
                    className='hub-table'
                    dataSource={state?.billings}
                    columns={[{
                        title: 'Transaction No',
                        dataIndex: 'running_number',
                        key: 'running_number',
                    }, {
                        title: 'From',
                        dataIndex: 'billing_location',
                        key: 'billing_location',
                        render: (text, row, index) => {
                            return (
                                <>
                                    {
                                        row?.billing_location ?
                                            (
                                                <>
                                                    <div>
                                                        <div>
                                                            <span style={{ fontWeight: 500 }}>{row?.billing_location?.name}</span>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                            :
                                            row?.from_branch ?
                                                (
                                                    <>
                                                        <div>
                                                            <div>
                                                                <span style={{ fontWeight: 500 }}>{row?.from_branch?.name}</span>
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                                :
                                                (
                                                    <>
                                                        <div>
                                                            <span style={{ color: '#8c8c8c', fontSize: 12 }}>No Headquater Record</span>
                                                        </div>
                                                    </>
                                                )
                                    }

                                </>
                            )
                        }
                    }, {
                        title: 'To',
                        dataIndex: 'branch',
                        key: 'branch',
                        render: (text, row, index) => {
                            return (
                                <>
                                    {
                                        row?.branch ?
                                            (
                                                <>
                                                    <div>
                                                        <div>
                                                            <span style={{ fontWeight: 500 }}>{row?.branch?.name}</span>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                            :
                                            row?.supplier ?
                                                (
                                                    <>
                                                        <div>
                                                            <span style={{ fontWeight: 500 }}>{row?.supplier?.name}</span>
                                                        </div>
                                                    </>
                                                ) :
                                                (
                                                    <>
                                                        <div>
                                                            <span style={{ color: '#8c8c8c', fontSize: 12 }}>No Record</span>
                                                        </div>
                                                    </>
                                                )
                                    }

                                </>
                            )
                        }
                    }, {
                        title: 'Description',
                        dataIndex: 'description',
                        key: 'description',
                    },
                    {
                        title: 'Issue Date',
                        dataIndex: 'created_at',
                        key: 'created_at',
                        render: (text, row, index) => {
                            return (
                                <>
                                    <div>
                                        <span>{moment(text).format("D MMM YYYY, h:mm:ss a")}</span>
                                    </div>
                                </>
                            )
                        }
                    },
                    {
                        title: 'Total',
                        dataIndex: 'total_amount',
                        key: 'total_amount',
                        render: (text, row, index) => {
                            return (
                                <>
                                    <div>
                                        <span>{`RM${text}`}</span>
                                    </div>
                                </>
                            )
                        }
                    },
                    {
                        title: 'Status',
                        dataIndex: 'status',
                        key: 'status',
                        render: (text, row, index) => {
                            return (
                                <>
                                    <div>
                                        <Tag color={text == 0 ? 'orange' : text == 1 ? 'green' : 'red'} style={{ border: 'none' }}>
                                            <span>{text == 0 ? 'Pending' : text == 1 ? 'Completed' : 'Void'}</span>
                                        </Tag>
                                    </div>
                                </>
                            )
                        }
                    },
                    {
                        title: 'Action',
                        key: 'action',
                        width: '10%',
                        render: (text, row, index) => {
                            return (
                                <>
                                    <div>
                                        <Link to={`/billing/detail/${row?.billing_id}`}>
                                            <Button type='default' style={{ borderRadius: 6 }}>
                                                <span style={{ color: '#8c8c8c' }}>View</span>
                                            </Button>
                                        </Link>
                                    </div>
                                </>
                            )
                        }
                    }]}
                />
            </Spin>



            {/* print billing */}
            <Modal
                title={`Print ${state?.print_type === 'invoice' ? 'Invoice' : 'DO'}`}
                centered
                okText="Search"
                open={state?.printModal}
                onOk={searchInvoice}
                onCancel={handleCancel}
                confirmLoading={state?.modalLoading}
            >

                <Form form={form}>
                    <Form.Item name='date' label="Date" rules={[{ required: true, message: 'Please select a date range' }]}>
                        <DatePicker.RangePicker allowClear />
                    </Form.Item>
                    <Form.Item
                        labelCol={{ span: 24 }}
                        label="Bill To"
                        name="selection">
                        <Select
                            defaultValue={1}
                            options={state?.to_selection}
                            className='border-input'
                            onChange={toSelectionOnDropdownSelected}
                        />
                    </Form.Item>
                    <Form.Item
                        labelCol={{ span: 24 }}
                        label="Branch/Supplier"
                        name="branch_id"
                        rules={[{ required: true, message: 'Please select a branch/supplier' }]}
                    >
                        <Select
                            showSearch
                            className='border-input'
                            options={state?.branch}
                            style={{ borderRadius: 6, width: '100%' }}
                            filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                            placeholder='Branch/Supplier'
                        />
                    </Form.Item>
                </Form>
            </Modal>

            <Modal
                transitionName="ant-fade"
                maskTransitionName="ant-fade"
                open={state?.filter_modal}
                title={<><span>Filter</span></>}
                width={500}
                closable={false}
                className="custom-modal"
                // okButtonProps={{
                //     form : 'sale_form',
                //     key : "submit",
                //     htmlType : "submit"
                // }}
                onOk={() => form1.submit()}
                onCancel={() => setState(state => ({ ...state, filter_modal: false, }))}
            >
                <Form
                    id="filter_form"
                    onFinish={onFilter}
                    form={form1}
                    layout='vertical'
                >
                    <Form.Item
                        labelCol={{ span: 24 }}
                        label="Bill To"
                        name="billTo">
                        <Select
                            defaultValue={1}
                            options={state?.to_selection}
                            className='border-input'
                            onChange={toSelectionOnDropdownSelected}
                        />
                    </Form.Item>
                    <Form.Item
                        labelCol={{ span: 24 }}
                        label="Branch/Supplier"
                        name="supplier"
                        rules={[{ required: true, message: 'Please select a branch/supplier' }]}
                    >
                        <Select
                            showSearch
                            className='border-input'
                            options={state?.branch}
                            style={{ borderRadius: 6, width: '100%' }}
                            filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                            placeholder='Branch/Supplier'
                        />
                    </Form.Item>

                </Form>
            </Modal>
        </>
    );
}

export default BillingListTable;
