import React from 'react';
import ContainerComponent from '../../helpers/components/container.component';
import PageHeaderComponent from '../../helpers/components/page_header.component';
import CreateCount from './components/create_count';

const CreateStockCount = () => {
    return (
        <>
            <ContainerComponent span={22} top={20}>
                <PageHeaderComponent />
                <CreateCount />
            </ContainerComponent>
        </>
    );
}

export default CreateStockCount;