import { Card, Space } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { set_purchase_items } from '../../../actions/purchase_order.action';

const PurchaseAddedItem = ({ item, index }) => {
    const dispatch = useDispatch();
    const purchase_order_redux = useSelector(state => state.purchase_order);
    const removePurchaseItem = () => {
        let purchase_order_items = (purchase_order_redux.purchase_items);
        //find whether list already have this item
        // let existing_item = purchase_order_items?.find(x => x.item_id === item?.item_id);
        const changed_quantity = purchase_order_items?.filter((x) => {
            return (x?.item_id !== item?.item_id);
        })

        dispatch(set_purchase_items(changed_quantity));
    }

    return (
        <>
            <Card
                className='order-cart-item'
                bordered={false}
                style={{ borderRadius: 6 }}
                bodyStyle={{ padding: 0 }}
            >
                <div onClick={removePurchaseItem} className='mask' style={{ padding: 24 }}>
                    <div style={{ textAlign: 'left' }}>
                        <div style={{ display: 'flex' }}>
                            <Space align='start' style={{ fontWeight: 500, color: '#8c8c8c' }}>
                                <div>
                                    <span>{`${index}.`}</span>
                                </div>
                                <div>
                                    <div>
                                        <span>{item?.name}</span>
                                        <span style={{ marginLeft: 6, fontSize: 12, color: '#8c8c8c' }}>{`x${item?.quantity} +${item?.free}`}</span>
                                    </div>
                                    <div>
                                        <span style={{ fontSize: 12, color: '#8c8c8c' }}>{`${item?.item_code}`}</span>
                                    </div>
                                </div>
                            </Space>
                            <div style={{ marginLeft: 'auto' }}>
                                <span style={{ fontWeight: 500, color: '#434343' }}>{`MYR ${(item?.unit_price * item?.quantity).toFixed(2)}`}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </Card>
        </>
    );
}

export default PurchaseAddedItem;