import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Spin, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import InputWrapper from '../../../helpers/components/input_wrapper.component';
import customerService from '../../../services/customer/customer.service';

const CustomerList = () => {
    const [state, setState] = useState({
        loading : false,
        customers : [],
        count : 0,
        page : 0,
    });
    const [filter, setFilter] = useState({
        search : null,
    });
    const [timer, setTimer] = useState(null)

    const fetchCustomers = async () => {
        setState(state => ({...state, loading : true}));
        const customerResponse = await customerService.getCustomers({
            page : state?.page,
            //search
            search : filter?.search
        });
        const customers = customerResponse?.customers;

        setTimeout(() => {
            setState(state => ({...state, loading : false, customers : customers?.rows, count : customers?.count}))
        }, 800);
    }

    const doneTyping = async (input) => {
        clearTimeout(timer);
        setState(state => ({...state, page : 0}))
        const newTimer = setTimeout(() => {
            setFilter(filter => ({...filter, search : input}));
        }, 300);
        setTimer(newTimer);
    }

    useEffect(() => {
        fetchCustomers();
    }, [state?.page, filter])

    return (
        <>
            <div className='filter-panel' style={{height:60, maxWidth:400}}>
                <InputWrapper>
                    <Input
                    onChange={(e) => doneTyping(e.target.value)}
                    bordered={false}
                    prefix={<div style={{paddingRight:12, paddingLeft:8}}><SearchOutlined style={{color:'#8c8c8c'}}/></div>}
                    placeholder={`Search Customers`}
                    style={{fontSize:14}}
                    size='large'
                    allowClear
                    />
                </InputWrapper>
            </div>
            <Spin spinning={state?.loading}>
                <Table
                pagination={{ 
                    size : 10,
                    showSizeChanger: false,
                    total : state?.count,
                    current : (state?.page + 1),
                    onChange : (page) => {
                        setState(state => ({...state, page : (page - 1)}))
                    }
                }}
                className='hub-table'
                dataSource={state?.customers}
                columns={[{
                    title : 'Name',
                    dataIndex : 'name',
                    key : 'name',
                },{
                    title : 'Phone No',
                    dataIndex : 'phone_no',
                    key : 'phone_no',
                    responsive : ['sm']
                },{
                    title : 'Action',
                    key : 'action',
                    width : '10%',
                    render : (text, row, index) => {
                        return (
                            <>
                                <div>
                                    <Link to={`/customers/${row?.customer_id}`}>
                                        <Button type='default' style={{borderRadius:6}}>
                                            <span style={{color: '#8c8c8c'}}>View</span>
                                        </Button>
                                    </Link>
                                </div>
                            </>
                        )
                    }
                }]}
                />
            </Spin>
        </>
    );
}

export default CustomerList;