import { SearchOutlined, ExclamationCircleOutlined, EllipsisOutlined } from '@ant-design/icons';
import { Button, Input, Row, Col, Spin, Table, Drawer, Form, Space, notification, Modal, Dropdown, Select, } from 'antd';
import React, { useEffect, useState } from 'react';
import InputWrapper from '../../../helpers/components/input_wrapper.component';
import supplierService from '../../../services/config/supplier.service';
// import itemService from '../../../services/config/item.service';
const { confirm } = Modal;

const SupplierListTable = () => {
    const [form] = Form.useForm();
    const [state, setState] = useState({
        supplier: [],
        // items: [],
        loading: false,
        isDisabled: true,
        isUpdate: null,
        update_loading: false,
        supplier_id: null,
        states: [
            { value: "Johor", text: "Johor" },
            { value: "Kedah", text: "Kedah" },
            { value: "Kelantan", text: "Kelantan" },
            { value: "Kuala Lumpur", text: "Kuala Lumpur" },
            { value: "Labuan", text: "Labuan" },
            { value: "Melaka", text: "Melaka" },
            { value: "Negeri Sembilan", text: "Negeri Sembilan" },
            { value: "Pahang", text: "Pahang" },
            { value: "Perak", text: "Perak" },
            { value: "Putrajaya", text: "Putrajaya" },
            { value: "Perlis", text: "Perlis" },
            { value: "Pulau Pinang", text: "Pulau Pinang" },
            { value: "Sabah", text: "Sabah" },
            { value: "Sarawak", text: "Sarawak" },
            { value: "Selangor", text: "Selangor" },
            { value: "Terengganu", text: "Terengganu" },
        ]
    });
    const [filter, setFilter] = useState({
        search: null,
    });

    const [timer, setTimer] = useState(null);
    const [open, setOpen] = useState(false);


    const fetchSupplierList = async () => {
        setState(state => ({ ...state, loading: true, }));

        const supplierResponse = await supplierService.getSupplier({
            search: filter?.search,
        });
        setTimeout(() => {
            setState(state => ({
                ...state,
                supplier: supplierResponse?.supplier,
                loading: false,
            }))
        }, 800);

    }

    // const fetchItemList = async () => {
    //     setState(state => ({ ...state, loading: true, }));

    //     const itemResponse = await itemService.getItemDropdown();
    //     const item = [];
    //     itemResponse?.item.forEach((data, index) => {
    //         item[index] = { value: data.item_id, label: data.name + ` (${data.item_code})`, };
    //     });
    //     setTimeout(() => {
    //         setState(state => ({
    //             ...state,
    //             items: item,
    //             loading: false,
    //         }))
    //     }, 800);

    // }

    const addUpdateSupplier = async () => {
        setState(state => ({ ...state, update_loading: true }));
        const { name, email, phone_number, address_1, address_2, postcode, state_option, supplier_code, dealer_code } = form.getFieldsValue();
        // call api to insert into database
        if (state.isUpdate === true) {
            const updateSupplierResponse = await supplierService.updateSupplier(state?.supplier_id, {
                name: name,
                email: email,
                phone_number: phone_number,
                address_1: address_1,
                address_2: address_2,
                postcode: postcode,
                state: state_option,
                supplier_code: supplier_code,
                dealer_code: dealer_code
            });
        } else {
            const storeSupplierResponse = await supplierService.storeSupplier({
                name: name,
                email: email,
                phone_number: phone_number,
                address_1: address_1,
                address_2: address_2,
                postcode: postcode,
                state: state_option,
                supplier_code: supplier_code,
                dealer_code: dealer_code
            });
        }


        //after success insert/update
        setTimeout(() => {
            notification.success({
                message: (<span style={{ fontSize: 14, fontWeight: 500, }}>{state?.isUpdate ? 'Supplier Updated' : 'New Supplier Added'}</span>),
                duration: 3,
                placement: 'topRight'
            });
            clearAll();
            refresh();
        }, 800);
    }

    const doneTyping = async (input) => {
        clearTimeout(timer);
        const newTimer = setTimeout(() => {
            setFilter(filter => ({ ...filter, search: input }));
        }, 300);
        setTimer(newTimer);
    }

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        clearAll()
        setOpen(false);
    };

    const openDrawer = (supplier, isUpdate) => {
        setState(state => ({
            ...state,
            isDisabled: isUpdate,
            isUpdate: isUpdate,
            supplier_id: supplier?.supplier_id
        }));
        if (isUpdate === true) {
            // let item_arr = [];
            // let items = supplier?.supplier_items;
            // items.forEach(i => {
            //     item_arr.push(i.item_id);
            // });
            form.setFieldsValue({
                name: supplier.name,
                email: supplier.email,
                phone_number: supplier.phone_number,
                address_1: supplier.address_1,
                address_2: supplier.address_2,
                postcode: supplier.postcode,
                state_option: supplier.state,
                supplier_code: supplier.supplier_code,
                dealer_code: supplier.dealer_code,
            });
        }

        showDrawer();
    }

    const offEdit = () => {
        if (state.isUpdate !== true) {
            onClose();
        }
        setState(state => ({
            ...state,
            isDisabled: true,
        }));
    };

    const onDelete = async (data) => {
        confirm({
            icon: <ExclamationCircleOutlined />,
            content: (
                <>
                    <Space direction='vertical'>
                        <div>
                            <span>Are your sure to delete <span style={{ fontWeight: 500 }}>{data.name}</span>?</span>
                        </div>
                    </Space>
                </>
            ),
            async onOk() {
                const deleteSupplierResponse = await supplierService.deleteSupplier(data.supplier_id);
                notification.success({
                    message: (<span style={{ fontSize: 14, fontWeight: 500, }}>Supplier Deleted</span>),
                    duration: 3,
                    placement: 'topRight'
                });
                clearAll();
                refresh();
            },
            onCancel() {
            },
        });

    };

    const refresh = () => {
        onClose();
        fetchSupplierList();
    };

    const clearAll = () => {
        setState(state => ({
            ...state,
            isDisabled: true,
            isUpdate: null,
            supplier_id: null,
            update_loading: false,
        }));
        form.resetFields();
    }

    useEffect(() => {
        fetchSupplierList();
    }, [filter?.search])

    // useEffect(() => {
    //     fetchItemList();
    // }, [])

    return (
        <>
            <div className='filter-panel' style={{ height: 60, }}>
                <div style={{ display: 'flex' }}>
                    <div style={{ minWidth: 400 }}>
                        <InputWrapper>
                            <Input
                                onChange={(e) => doneTyping(e.target.value)}
                                bordered={false}
                                prefix={<div style={{ paddingRight: 12, paddingLeft: 8 }}><SearchOutlined style={{ color: '#8c8c8c' }} /></div>}
                                placeholder={`Search Supplier`}
                                style={{ fontSize: 14 }}
                                size='large'
                            />
                        </InputWrapper>
                    </div>
                    <div style={{ marginLeft: 'auto' }}>
                        <Button
                            type="primary"
                            // icon={<PlusOutlined />}
                            onClick={() => openDrawer(null, false)}
                            style={{ borderRadius: 6 }}
                        >
                            Add Supplier
                        </Button>
                    </div>

                </div>
            </div>

            <Spin spinning={state?.loading}>
                <Table
                    className='hub-table'
                    pagination={false}
                    dataSource={state?.supplier}
                    columns={[{
                        title: 'Supplier',
                        dataIndex: 'name',
                        key: 'name',
                        // render: (text) => <span>{text}</span>,
                    }, {
                        title: 'Phone Number',
                        dataIndex: 'phone_number',
                        key: 'phone_number',
                        render: (text) => <span>+60{text}</span>,
                    }, {
                        title: 'Action',
                        key: 'action',
                        width: '10%',
                        render: (text, row, index) => {
                            return (
                                <>
                                    <div>
                                        <Dropdown
                                            placement='bottomRight'
                                            overlayStyle={{ borderRadius: 12, minWidth: 200 }}
                                            menu={{
                                                items: [{
                                                    key: '1',
                                                    type: 'group',
                                                    label: "Action",
                                                    children: [{
                                                        key: '1-1',
                                                        label: "Edit",
                                                        onClick: async () => {
                                                            openDrawer(row, true);
                                                        }
                                                    }, {
                                                        key: '1-2',
                                                        label: "Delete",
                                                        onClick: async () => {
                                                            await onDelete(row);
                                                        }
                                                    },]
                                                },]
                                            }}
                                        >
                                            <Button size='small' icon={<EllipsisOutlined />} style={{ borderRadius: 6 }} />
                                        </Dropdown>
                                    </div>
                                </>
                            )
                        }
                    }]}
                />
            </Spin>

            <Drawer title={state?.isUpdate ? 'Supplier Detail' : 'Add Supplier'} onOk={() => form.submit()}
                placement="right"
                width={window.innerWidth > 900 ? 800 : window.innerWidth - 100}
                onClose={onClose}
                open={open}
                extra={
                    <Space>
                        {
                            state?.isUpdate ?
                                (
                                    <>
                                        <Button loading={state?.update_loading} type="primary" onClick={() => form.submit()} style={{ borderRadius: 6 }}>
                                            Update
                                        </Button>
                                    </>
                                )
                                :
                                (
                                    <>
                                        <Space>
                                            <Button onClick={offEdit} style={{ borderRadius: 6 }}>Cancel</Button>
                                            <Button loading={state?.update_loading} type="primary" onClick={() => form.submit()} style={{ borderRadius: 6 }}>
                                                OK
                                            </Button>
                                        </Space>
                                    </>
                                )
                        }
                    </Space>
                }>

                <Form layout="vertical"
                    onFinish={addUpdateSupplier}
                    form={form} >
                    <Form.Item
                        name="name"
                        label="Supplier Name"
                        rules={[{ required: true, message: 'Please enter supplier name' }]}
                    >
                        <Input placeholder="Please enter supplier name" style={{ borderRadius: 6 }} />
                    </Form.Item>
                    <Form.Item
                        name="supplier_code"
                        label="Supplier Code"
                    // rules={[{ required: true, message: 'Please enter supplier name' }]}
                    >
                        <Input placeholder="Please enter supplier code" style={{ borderRadius: 6 }} />
                    </Form.Item>
                    <Form.Item
                        name="dealer_code"
                        label="Dealer Code"
                    // rules={[{ required: true, message: 'Please enter supplier name' }]}
                    >
                        <Input placeholder="Please enter dealer code" style={{ borderRadius: 6 }} />
                    </Form.Item>

                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="phone_number"
                                label="Phone Number"
                                rules={[
                                    {
                                        required: true,
                                        message: 'please enter phone number',
                                    },
                                ]}
                            >
                                <Input placeholder='123456789' prefix={<span>+60</span>} style={{ borderRadius: 6 }} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="email"
                                label="Email Address"
                            >
                                <Input style={{ borderRadius: 6 }} />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Form.Item
                        name="address_1"
                        label="Address 1"
                    // rules={[
                    //     {
                    //         required: true,
                    //         message: 'please enter address 1',
                    //     },
                    // ]}
                    >
                        <Input style={{ borderRadius: 6 }} />
                    </Form.Item>


                    <Form.Item
                        name="address_2"
                        label="Address 2 (Optional)"
                    >
                        <Input style={{ borderRadius: 6 }} />
                    </Form.Item>

                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="postcode"
                                label="Postcode"
                            // rules={[
                            //     {
                            //         required: true,
                            //         message: 'please enter postcode',
                            //     },
                            // ]}
                            >
                                <Input style={{ borderRadius: 6 }} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="state_option"
                                label="State"
                            // rules={[
                            //     {
                            //         required: true,
                            //         message: 'please enter state',
                            //     },
                            // ]}
                            >
                                <Select
                                    placeholder="Select a state"
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={state?.states}
                                />
                            </Form.Item>
                        </Col>

                        {/* <Form.Item
                            name="item_option"
                            label="Suppliers Item"
                            style={{ width: "100%" }}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select items',
                                },
                            ]}
                        >
                            <Select
                                mode="multiple"
                                allowClear
                                placeholder="Select Item"
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                options={state?.items}
                            />
                        </Form.Item> */}
                    </Row>
                </Form>
            </Drawer>
        </>
    );
}

export default SupplierListTable;