import { Button, Col, Divider, Dropdown, Modal, Row, Skeleton, Space, Spin, Tag, Form, Input } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import purchaseOrderService from '../../../services/purchase_order/purchase_order.service';
import OrderItem from './order_items';
import { DownOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { set_update_order } from '../../../actions/purchase_order.action';
import CreditNote from './credit_note';

const PurchaseOrderDetail = ({ order_id, edit }) => {
    const purchase_order_redux = useSelector(state => state.purchase_order);
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [state, setState] = useState({
        loading: false,
        edit_invoice_number: false,
        invoice_number: null,
        order: null,
        edit_modal: false,
        edit: false,
        updated: 0,
        status: null,
        dropdown: [
            {
                key: '1',
                type: 'group',
                label: 'Order Status',
                children: [
                    {
                        key: '1-1',
                        label: 'Completed',
                        onClick: async () => {
                            const update = await updateOrder(1);
                        }
                    },
                    {
                        key: '1-2',
                        label: 'Void',
                        onClick: async () => {
                            const update = await updateOrder(2);
                        }
                    }
                ]
            },
        ]
    })

    const updateOrder = async (status) => { //status code - 1 : complete, 2 : void
        const updateOrderResponse = await purchaseOrderService.updateOrder(order_id, {
            status: status,
        });

        setState(state => ({ ...state, updated: (state?.updated + 1), edit_invoice_number: false, }))
    }

    const editInvoiceNumber = async () => {
        const updateOrderResponse = await purchaseOrderService.updateOrder(order_id, {
            invoice_number: state?.invoice_number,
        });

        setState(state => ({ ...state, edit_invoice_number: false, updated: (state?.updated + 1) }))
    }

    const fetchPurchaseOrder = async () => {
        setState(state => ({ ...state, loading: true }));
        const orderResponse = await purchaseOrderService.getOrderById(order_id);

        setTimeout(() => {
            setState(state => ({ ...state, loading: false, order: orderResponse?.orders, invoice_number: orderResponse?.orders.invoice_number, status: orderResponse?.orders.status, edit: edit ?? orderResponse?.orders.status == 0 ? true : false }));

        }, 800);
        return orderResponse?.orders;
    }

    useEffect(() => {
        fetchPurchaseOrder();
        dispatch(set_update_order(null));
    }, [order_id, state?.updated, purchase_order_redux?.update_order])


    return (
        <>
            <Spin spinning={state?.loading}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div>
                        <div style={{ textAlign: 'left' }}>
                            <div>
                                <Space>
                                    <div>
                                        <span style={{ fontSize: 18, color: '#595959' }}>{state?.order?.running_number}</span>
                                    </div>
                                    <div>
                                        <Tag color={state?.status == 0 ? 'orange' : state?.status == 1 ? 'green' : 'red'} style={{ border: 'none' }}>
                                            <span>{state?.status == 0 ? 'Pending' : state?.status == 1 ? 'Completed' : 'Void'}</span>
                                        </Tag>
                                    </div>
                                </Space>
                            </div>
                            <div>
                                <span>
                                    <span style={{ fontSize: 24, fontWeight: 500 }}>{`RM ${state?.order?.total_amount ?? 0}`}</span>
                                </span>
                            </div>
                        </div>
                    </div>
                    {
                        state?.edit &&
                        (
                            <>
                                <div style={{ marginLeft: 'auto' }}>
                                    <Dropdown
                                        placement='bottomRight'
                                        overlayStyle={{ borderRadius: 12, minWidth: 200 }}
                                        menu={{
                                            items: state?.dropdown,

                                        }}
                                    >
                                        <Button type='primary' className='mini-button' icon={<DownOutlined />} style={{ borderRadius: 4 }}>
                                            Action
                                        </Button>
                                    </Dropdown>
                                </div>
                            </>
                        )
                    }
                </div>

                <Divider style={{ marginTop: 12 }} />
                <div style={{ textAlign: 'left' }}>
                    <Row gutter={[20, 20]}>
                        <Col xs={12} md={8} lg={6} span={6}>
                            <div>
                                <div>
                                    <span style={{ fontWeight: 500 }}>Supplier</span>
                                </div>
                                <div>
                                    <span>
                                        {
                                            state?.order?.supplier ?
                                                (`${state?.order?.supplier?.name}, +60${state?.order?.supplier?.phone_number}`)
                                                :
                                                <span style={{ fontSize: 12 }}>No Supplier added</span>
                                        }
                                    </span>
                                </div>
                            </div>
                        </Col>

                        <Col xs={12} md={8} lg={6} span={6}>
                            <div>
                                <div>
                                    <span style={{ fontWeight: 500 }}>Issue Date</span>
                                </div>
                                <div>

                                    {
                                        state?.order?.created_at ?
                                            (
                                                <>
                                                    <span>
                                                        {moment(state?.order?.created_at).format("D MMM YYYY, h:mm:ss a")}
                                                    </span>
                                                </>
                                            )
                                            :
                                            (
                                                <>
                                                    <span style={{ fontSize: 12 }}>No issue date</span>
                                                </>
                                            )
                                    }
                                </div>
                            </div>
                        </Col>
                        <Col xs={12} md={8} lg={6} span={6}>
                            <div>
                                <div>
                                    <span style={{ fontWeight: 500 }}>Headquater</span>
                                </div>
                                <div>
                                    <span>
                                        {
                                            state?.order?.location ?
                                                (state?.order?.location?.name)
                                                :
                                                <span style={{ fontSize: 12 }}>No Supplier added</span>
                                        }
                                    </span>
                                </div>
                            </div>
                        </Col>
                        <Col xs={12} md={8} lg={6} span={6}>
                            <div>
                                <div>
                                    <Space>
                                        <span style={{ fontWeight: 500 }}>DO / INV Number</span>
                                        {
                                            state?.edit_invoice_number ?
                                                (
                                                    <>
                                                        <span className='mini-button mini-style-text' onClick={() => editInvoiceNumber()}>
                                                            Save
                                                        </span>
                                                        <span className='mini-button mini-style-text' onClick={() => {
                                                            setState(state => ({ ...state, edit_invoice_number: false }))
                                                        }}>
                                                            Cancel
                                                        </span>
                                                    </>
                                                ) :
                                                (<>
                                                    <span className='mini-button mini-style-text' onClick={() => {
                                                        setState(state => ({ ...state, edit_invoice_number: true }))
                                                    }}>
                                                        Edit
                                                    </span>
                                                </>)


                                        }

                                    </Space>

                                </div>
                                <div>
                                    <span>
                                        {
                                            state?.edit_invoice_number ?
                                                <Input defaultValue={state?.invoice_number} placeholder='DO / INV Number' onChange={(e) => {
                                                    setState(state => ({ ...state, invoice_number: e.target.value }))
                                                }} />
                                                :
                                                (
                                                    state?.order?.invoice_number ?? <span style={{ fontSize: 12 }}>No DO / INV Number added</span>
                                                )
                                        }
                                    </span>
                                </div>
                            </div>
                        </Col>

                    </Row>
                </div>

                <div style={{ marginTop: 48 }}>
                    <OrderItem order_id={order_id} edit={state?.edit} supplier_id={state?.order?.supplier_id}
                    />
                </div>
                {/* <div style={{ marginTop: 48 }}>
                    {
                        (state?.status === 1) &&
                        <>
                            <CreditNote order_id={order_id} edit={state?.status == 1} status_editable={state?.status == 1} />
                        </>

                    }
                </div> */}
            </Spin>

        </>
    );
}

export default PurchaseOrderDetail;