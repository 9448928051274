import { Card, Skeleton } from 'antd';
import React from 'react';

const LoadingItemCard = () => {
    return (
        <>
            <Card
            className='shadow masonry-item feed-item'
            bodyStyle={{padding : 12}}
            style={{borderRadius: 8, width:'100%'}}
            // cover={
            //     <div style={{padding:10}}>
            //         <Skeleton.Input active block size={'large'} style={{width:'100%', height: 120, minWidth:0, borderRadius: '8px 8px 0 0'}}/>
            //     </div>
            // }
            >
                <div style={{minHeight:100}}>
                    <div style={{textAlign:'center'}}>
                        <div>
                            <Skeleton.Input active block style={{ maxHeight : 20, minWidth:0,}} />
                        </div>
                        <div style={{marginTop:22}}>
                            <Skeleton.Input active block style={{ maxHeight : 16, minWidth:0, }} />
                            <Skeleton.Input active block style={{ maxHeight : 16, minWidth:0, }} />
                        </div>
                        <div>
                            <div style={{float:'right'}}>
                                <Skeleton.Input active block style={{ maxHeight : 12, minWidth:0,}} />
                            </div>
                        </div>
                    </div>
                </div>
            </Card>
        
        </>
    );
}

export default LoadingItemCard;