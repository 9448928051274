import React from 'react';
import ContainerComponent from '../../helpers/components/container.component';
import ChangePassword from './components/change_password';

const ProfileIndex = () => {
    return (
        <>
            <ContainerComponent span={22} top={20} bottom={20}> 
                <ChangePassword />
            </ContainerComponent>
        </>
    );
}

export default ProfileIndex;