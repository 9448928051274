import React from 'react';
import ContainerComponent from '../../helpers/components/container.component';
import PageHeaderComponent from '../../helpers/components/page_header.component';
import ReceiveListTable from './components/receive_list_table';

const ReceiveList = () => {
    return (
        <>
            <ContainerComponent span={22} top={20}>
                <PageHeaderComponent />
                <ReceiveListTable />
            </ContainerComponent>
        </>
    );
}

export default ReceiveList;