import { EllipsisOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Divider, Dropdown, Form, Modal, Space, Spin, Table, Tag, notification, } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import orderCreditNoteService from '../../../services/purchase_order/purchase_order_credit_note.service';
import CreditNoteAdd from './credit_note_add';
import { useDispatch, useSelector } from 'react-redux';
import { refresh_order_detail, } from '../../../actions/purchase_order.action';

const { confirm } = Modal;

const CreditNote = ({ order_id, edit, status_editable }) => {
    const dispatch = useDispatch();
    const purchase_order_redux = useSelector(state => state.purchase_order);
    const [form] = Form.useForm();
    const [state, setState] = useState({
        credit_notes: [],
        loading: false,
        edit_modal: false,
        refresh: 0,
        edit_modal_id: null,

        //view modal
        view_modal: false,
        view_modal_id: null,
    });

    const addCreditNote = async () => {
        form.validateFields()
            .then(async (values) => {
                const { remarks, itemId, quantity, unit_price } = form.getFieldValue();
                let creditNoteResponse;
                if (state?.edit_modal_id) {
                    //run update service
                    creditNoteResponse = await orderCreditNoteService.updateCreditNotes(state?.edit_modal_id, {
                        order_id: order_id,
                        remarks: remarks,
                        item_id: itemId,
                        quantity: quantity,
                        unit_price: unit_price,
                    })
                } else {
                    creditNoteResponse = await orderCreditNoteService.storeCreditNotes({
                        order_id: order_id,
                        remarks: remarks,
                        item_id: itemId,
                        quantity: quantity,
                        unit_price: unit_price,

                    })
                }

                //refresh detail
                dispatch(refresh_order_detail(purchase_order_redux?.refresh + 1));
                setState(state => ({ ...state, refresh: (state?.refresh + 1), edit_modal: false, }));
                form.resetFields();
                // dispatch(set_credit_note_data(null));
            })
            .catch(() => {
            });
    }

    const fetchCreditNote = async () => {
        setState(state => ({ ...state, loading: true }));

        const creditNoteResponse = await orderCreditNoteService.getCreditNotes({
            order_id: order_id,
        })
        setState(state => ({ ...state, credit_notes: creditNoteResponse?.order_credit_note, loading: false }));
    }

    const openEditModal = async ({ row = null }) => {
        // dispatch(set_credit_note_data(row));
        setState(state => ({ ...state, edit_modal: true, edit_modal_id: row?.order_credit_note_id }));
        form.setFieldsValue({ itemId: row?.item_id, quantity: row?.quantity, remarks: row?.remarks, unit_price: row?.unit_price, })
    }

    const openViewModal = async ({ row = null }) => {
        setState(state => ({ ...state, view_modal_id: row?.credit_note_id, view_modal: true }));
    }

    const deleteConfirmationModal = async (credit_note_id) => {
        confirm({
            title: 'Do you want to delete this credit note?',
            onOk() {
                return new Promise((resolve, reject) => {
                    setTimeout(async () => {
                        await deleteCreditNote(credit_note_id);
                        resolve();
                    }, 500);
                }).catch((err) => {

                })
            }
        })
    }

    const deleteCreditNote = async (credit_note_id) => {
        const creditNoteResponse = await orderCreditNoteService.deleteCreditNotes(credit_note_id);
        //refresh detail
        dispatch(refresh_order_detail(purchase_order_redux?.refresh + 1));
        setState(state => ({ ...state, refresh: (state?.refresh + 1) }));
    }

    useEffect(() => {
        fetchCreditNote();
    }, [state?.refresh])

    return (
        <>
            <div style={{ textAlign: 'left', padding: '12px 0' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div>
                        <span style={{ fontWeight: 500, fontSize: 16 }}>Credit Notes</span>
                    </div>
                    {
                        edit && (status_editable) &&
                        (
                            <>
                                <div style={{ marginLeft: 'auto' }}>
                                    <span onClick={() => openEditModal({})} className='mini-button mini-style-text'>
                                        Add Credit Note
                                    </span>
                                </div>
                            </>
                        )
                    }

                </div>
            </div>
            <Divider style={{ marginTop: 12 }} />
            <Spin spinning={state?.loading}>
                <Table
                    pagination={false}
                    className='hub-table'
                    dataSource={state?.credit_notes}
                    columns={[{
                        title: 'Credit Note',
                        dataIndex: 'running_number',
                        key: 'running_number',
                    }, {
                        title: 'Returned Item',
                        dataIndex: 'item',
                        key: 'item',
                        render: (text) => {
                            return (
                                <>
                                    <div>
                                        <div>
                                            <span style={{ fontWeight: 500 }}>{text?.name}</span>
                                        </div>
                                        <div>
                                            <span style={{ color: '#8c8c8c', fontSize: 12 }}>{text?.item_code}</span>
                                        </div>
                                    </div>
                                </>
                            )
                        }
                    }, {
                        title: 'Returned Quantity',
                        dataIndex: 'quantity',
                        key: 'quantity',
                    }, {
                        title: 'Unit Price (RM)',
                        dataIndex: 'unit_price',
                        key: 'unit_price',
                        render: (text, row, index) => {
                            return (
                                <>
                                    <div>
                                        <span>{(text * 1).toFixed(3)}</span>
                                    </div>
                                </>
                            )
                        }
                    }, {
                        title: 'Total Price (RM)',
                        dataIndex: 'quantity',
                        key: 'quantity',
                        render: (text, row, index) => {
                            return (
                                <>
                                    <div>
                                        <span>{(row?.quantity * row?.unit_price).toFixed(3)}</span>
                                    </div>
                                </>
                            )
                        }
                    }, {
                        title: 'Remarks',
                        dataIndex: 'remarks',
                        key: 'remarks'
                    }, {
                        title: 'Issue Date',
                        dataIndex: 'created_at',
                        key: 'created_at',
                        render: (text, row, index) => {
                            return (
                                <>
                                    <div>
                                        <span>{moment(text).format("D MMM YYYY, h:mm:ss a")}</span>
                                    </div>
                                </>
                            )
                        }

                    }, {
                        ...edit && (status_editable) && ({
                            title: 'Action',
                            key: 'action',
                            dataIndex: 'order_credit_note_id',
                            width: '10%',
                            render: (text, row, index) => {
                                return (
                                    <>
                                        <div>
                                            <Dropdown
                                                placement='bottomRight'
                                                overlayStyle={{ borderRadius: 12, minWidth: 200 }}
                                                menu={{
                                                    items: [{
                                                        key: '1',
                                                        type: 'group',
                                                        label: "Action",
                                                        children: [{
                                                            key: '1-1',
                                                            label: "Edit",
                                                            onClick: async () => {
                                                                openEditModal({ row })
                                                            }
                                                        }, {
                                                            key: '1-2',
                                                            label: "Delete",
                                                            onClick: async () => {
                                                                deleteConfirmationModal(text);
                                                            }
                                                        },]
                                                    },]
                                                }}
                                            >
                                                <Button size='small' icon={<EllipsisOutlined />} style={{ borderRadius: 6 }} />
                                            </Dropdown>
                                        </div>
                                    </>
                                )
                            }
                        })
                    }]}
                />
            </Spin>

            <Modal
                open={state?.edit_modal}
                transitionName="ant-fade"
                maskTransitionName="ant-fade"
                title={<><span>Add Credit Note</span></>}
                width={500}
                closable={false}
                className="custom-modal"
                onOk={() => form.submit()}
                onCancel={() => {
                    form.resetFields();
                    // dispatch(set_credit_note_data(null));
                    setState(state => ({ ...state, edit_modal: false, edit_modal_id: null }));
                }}
            >
                <Form
                    id='credit_note_form'
                    onFinish={addCreditNote}
                    form={form}
                    layout='vertical'
                >
                    <CreditNoteAdd order_id={order_id} form={form} edit_modal_id={state?.edit_modal_id} />
                </Form>
            </Modal>

            <Modal
                open={state?.view_modal}
                transitionName="ant-fade"
                maskTransitionName="ant-fade"
                title={<><span>Credit Note</span></>}
                width={500}
                // closable={false}
                footer={false}
                className="custom-modal"
                onCancel={() => {
                    setState(state => ({ ...state, view_modal_id: null, view_modal: false }));
                }}
            >

            </Modal>
        </>
    );
}

export default CreditNote;