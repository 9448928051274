import React from 'react';
import ContainerComponent from '../../helpers/components/container.component';
import PageHeaderComponent from '../../helpers/components/page_header.component';
import IssueListTable from './components/issue_list_table';

const IssueList = () => {
    return (
        <>
            <ContainerComponent span={22} top={20}>
                <PageHeaderComponent />
                <IssueListTable />
            </ContainerComponent>
        </>
    );
}

export default IssueList;