import { Card, Col, Image, PageHeader, Row, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import authSupabase from '../../supabase/query/auth.supabase';
import ContainerComponent from '../../helpers/components/container.component';
import accessService from '../../services/administrator/access.service';
import SubMenuComponent from '../../helpers/components/sub_menu.component';

const SaleIndex = () => {
    return (
        <>
            <div>
                <ContainerComponent span={22} top={20}>
                    <SubMenuComponent />
                </ContainerComponent>
                
            </div>
        </>
    );
}

export default SaleIndex;