import axios from 'axios';
import serviceHelper from '../../helpers/functions/service.helper';


async function getLocation(query) {
    const queryString = serviceHelper.queryString(query);
    const locationResponse = await axios.get(`${process.env.REACT_APP_SERVER_URL}config/location/get${(queryString ? ("?" + queryString) : "")}`, { withCredentials: true });
    return locationResponse?.data;
}

async function getItem(query) {
    const queryString = serviceHelper.queryString(query);
    const locationResponse = await axios.get(`${process.env.REACT_APP_SERVER_URL}config/location/getItem${(queryString ? ("?" + queryString) : "")}`, { withCredentials: true });
    return locationResponse?.data;
}

async function getLocationBranchItem(query) {
    const queryString = serviceHelper.queryString(query);
    const locationResponse = await axios.get(`${process.env.REACT_APP_SERVER_URL}config/location/getLocationBranchItem${(queryString ? ("?" + queryString) : "")}`, { withCredentials: true });
    return locationResponse?.data;
}

export default {
    getLocation,
    getItem,
    getLocationBranchItem,
}